import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormBuilder, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserdataService } from 'src/app/shared/userdata.service';
import { DriverService } from 'src/app/shared/driver/driver.service';

@Component({
  selector: 'app-collectcash',
  templateUrl: './collectcash.component.html',
  styleUrls: ['./collectcash.component.css']
})
export class CollectcashComponent implements OnInit {

  id=''
  presentCash = 0
  formGroup = new FormGroup({
    driverId: new FormControl('', [Validators.required]),
    entry_by: new FormControl('', [Validators.required]),
    cash_amount: new FormControl('', [Validators.required]),
    txn_type: new FormControl('', [Validators.required]),
    userId: new FormControl('', [Validators.required]),
    txnId: new FormControl('', [Validators.required]),
  })

  constructor(private toast: ToastrService, private formBuilder: FormBuilder, private driver: DriverService, private router: Router, private userdata: UserdataService, private activate: ActivatedRoute) { }

  ngOnInit(): void {
    var added = this.userdata.getUserType()
    var userId = this.userdata.getId()
    this.formGroup.patchValue({ entry_by: added });
    this.formGroup.patchValue({ userId: userId });
    this.formGroup.patchValue({ txn_type: 2 });
    this.getCashInHandList() 
  }

  get f() {
    return this.formGroup.controls 
  }

  getCashInHandList(){
    this.id = this.activate.snapshot.paramMap.get('id')
    // let body = new URLSearchParams()
    // body.set('driverId',this.id)
    this.driver.cashInHandHistory({'driverId':this.id}).subscribe(
      (res:any) => {
        //console.log(res.data)
        //this.toast.success(res.message,'Success')
        this.presentCash = res.presentCash
        this.formGroup.patchValue({ driverId: this.id });
        this.formGroup.patchValue({ cash_amount: res.presentCash });
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  public onsubmit() {
    this.driver.collectCash(this.formGroup.value).subscribe(
      (res:any) => {
        //console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/driver/cashInHand');
      },
      err => {
        console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
