<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="active breadcrumb-item">Customers</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Manager</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title float-left">View Customers</h3>                    
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Tables -->
                <input
                type="text" class="form-control search-input"
                placeholder="Type to filter the name column..."
                (keyup)="updateFilter($event)"
                />
                <ngx-datatable
                #table
                class="material"
                [columns]="columns"
                [columnMode]="ColumnMode.force"
                [headerHeight]="50"
                [footerHeight]="50"
                rowHeight="auto"
                [limit]="10"
                [rows]="rows"
                #myFilterTable
                >
                    <ngx-datatable-column name="Name" prop="customer_name">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.customer_name}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Email" prop="customer_email">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.customer_email}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Phone" prop="customer_phone">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.customer_phone}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Rewards" prop="current_rewards">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.current_rewards}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Total Orders" prop="orders_detail.length">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.orders_detail.length}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Status">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span class="badge badge-pill badge-success" *ngIf="row.status=='Enabled'" (click)="changeStatus('Disabled', row._id)">
                                {{ row.status }}
                            </span>
                            <span class="badge badge-pill badge-danger" *ngIf="row.status=='Disabled'" (click)="changeStatus('Enabled', row._id)">
                                {{ row.status }}
                            </span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Action">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <a [routerLink]="['/admin/customer/', row._id]" title="View Details" class="btn btn-primary">View Details</a>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
                <!-- /tables -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->