<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/admin/driver/view']">Driver</a></li>
        <li class="active breadcrumb-item">Driver Cash In Hand</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Manager</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">
            <!-- Card Header -->
            <div class="dt-card__header">
                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title float-left">View Driver Cash In Hand</h3>
                    <a class="btn btn-primary float-right" [routerLink]="['/admin/driver/add']">Add Driver</a><br/>
                </div>
                <!-- /card heading -->
            </div>
            <!-- /card header -->
            
            <!-- Card Body -->
            <div class="dt-card__body">                
                
                <!-- Tables -->
                <ngx-datatable
                #table
                class="material"
                [columns]="columns"
                [columnMode]="ColumnMode.force"
                [headerHeight]="50"
                [footerHeight]="50"
                rowHeight="auto"
                [limit]="10"
                [rows]="rows"
                #myFilterTable
                >
                    <ngx-datatable-column name="Driver Name" prop="row.driverdetail[0].driver_name">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.driverdetail[0].driver_name}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Cash Amount" prop="row.current_cash">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.current_cash}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Action" prop="created_at">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <a [routerLink]="['/admin/driver/cashHistory/', row._id]" title="Cash History"><i class="icon icon-list icon-fw icon-lg icon-delete"></i></a>&nbsp;<a [routerLink]="['/admin/driver/collect/', row._id]" title="Collect Cash" *ngIf="row.current_cash>0"><i class="icon icon-revenue-new icon-fw icon-lg icon-edit"></i></a>&nbsp;
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
                <!-- /tables -->

            </div>
            <!-- /card body -->
        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->