import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata.service';

@Injectable({
  providedIn: 'root'
})
export class ProducttagService {

  BaseUrl = ''
  Token = ''

  constructor(private http: HttpClient, @Inject('BASE_URL') _base,private userdata: UserdataService) {
    this.BaseUrl = _base
    this.Token = userdata.getToken()
  }

  public addTag(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/addproducttag",form, { headers: header_object})
  }

  public listTag(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/allproducttag",form,{ headers: header_object})
  }

  public getTagById(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token).set('Content-Type', 'application/x-www-form-urlencoded')
    return this.http.post(this.BaseUrl + "/api/fetchproducttagbyid",form.toString(), { headers: header_object})
  }

  public updateTag(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/updateproducttag", form, { headers: header_object})
  }

}
