import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DriverService } from 'src/app/shared/driver/driver.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-single-mydriver',
  templateUrl: './single-mydriver.component.html',
  styleUrls: ['./single-mydriver.component.css'],
  providers: [DatePipe]
})
export class SingleMydriverComponent implements OnInit {

  id=""
  rows:any=""
  imageURL = ''
  
  constructor(private toast: ToastrService,private driver: DriverService, private userdata:UserdataService, private activate: ActivatedRoute,private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl,private datePipe: DatePipe) { 
    this.imageURL = _imageurl
  }

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getSingleDriver()
  }

  transformDate(date)
  {
    return this.datePipe.transform( date,'MM-dd-yyyy');
  }

  public getSanitizerUrl(photoname){
    return this.trusturl.bypassSecurityTrustUrl(this.imageURL + photoname)
  } 

  getSingleDriver(){
    this.driver.restGetDriverById({'_id':this.id,'restId':this.userdata.getId()}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.rows = res.data
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
