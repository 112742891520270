import { Component } from '@angular/core';
import { MessagingService } from './shared/messaging/messaging.service';
import { UserdataService } from './shared/userdata.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Canada Food';
  message;
  token;
  constructor(private messagingService: MessagingService,private userdata:UserdataService) { }

  ngOnInit() {
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage
  }
}
