import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { OffersService } from 'src/app/shared/offers/offers.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-list-myoffers',
  templateUrl: './list-myoffers.component.html',
  styleUrls: ['./list-myoffers.component.css'],
  providers: [DatePipe]
})
export class ListMyoffersComponent implements OnInit {

  imageURL = ''
  constructor(private toast: ToastrService,private offer: OffersService,private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl, private userdata:UserdataService, private datePipe:DatePipe) { 
    this.imageURL = _imageurl
  }

  p: number = 1;
  term: string;
  temp = [];
  user = ""
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]
  columns = [{ prop: 'Offer Code' },{ name: 'Offer Amount' }, { name: 'Min. Order' }, { name: 'Max. Discount' },{ name: 'Details' }, { name: 'End Date' },{ name: 'Image' },{ name: 'Status' }, { name: 'Added By' }, { name: 'Action' }];
  ngOnInit(): void {
    this.user = this.userdata.getId()
    // for page number 1 as offset starts with 0
    //this.table.offset = 0;
    this.getOfferList()
    
  }

  getOfferList(){
    this.offer.restListOffer({'restId':this.userdata.getId()}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.rows = res.data
        this.temp = this.rows
        console.log(res.data)
      },
      err => {
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  public getSanitizerUrl(photoname){
    return this.trusturl.bypassSecurityTrustUrl(this.imageURL + photoname)
  }  

  transformDate(date)
  {
    return this.datePipe.transform( date,'MM-dd-yyyy');
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.offers_code.toLowerCase().indexOf(val) !== -1 ||
      d.status.toLowerCase().indexOf(val) !== -1 ||
      !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  } 

  changeStatus(val,id){
    const data = new FormData()
    var userId = this.userdata.getId()
    data.append("status",val)
    data.append("_id",id)
    data.append("userId",userId)
    this.offer.updateOffer(data).subscribe(
      (res:any) => {
        this.toast.success(res.message,'Success')
        this.getOfferList()
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
        this.getOfferList()
      }
    )
  }

}
