import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { DatePipe } from "@angular/common";
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { AddsService } from 'src/app/shared/adds/adds.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-listadvertisement',
  templateUrl: './listadvertisement.component.html',
  styleUrls: ['./listadvertisement.component.css'],
  providers: [DatePipe]
})
export class ListadvertisementComponent implements OnInit {

  imageURL = ''
  constructor(private toast: ToastrService,private adds: AddsService,private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl, private datePipe: DatePipe, private userdata: UserdataService) {
    this.imageURL = _imageurl
  }

  p: number = 1;
  term: string;
  temp = [];
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]
  columns = [{ prop: 'Name' }, { name: 'Date' }, { name: 'Ad Alignment' }, { name: 'Restaurant' },{ name: 'Image' }, { name: 'Status' }, { name: 'Added By' }, { name: 'Action' }];
  ngOnInit(): void {
    // for page number 1 as offset starts with 0
    //this.table.offset = 0;
    this.getAdList()

  }

  getAdList(){
    this.adds.listAdvertisement({}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  public getSanitizerUrl(photoname){
    return this.trusturl.bypassSecurityTrustUrl(this.imageURL + photoname)
  }

  transformDate(date)
  {
    return this.datePipe.transform( date,'MM-dd-yyyy');
  }

  changeStatus(val,id){
    const data = new FormData()
    var updated_by = this.userdata.getUserType()
    var updated_Id = this.userdata.getId()
    data.append("status",val)
    data.append("_id",id)
    data.append("updated_Id",updated_Id)
    data.append("updated_by",updated_by)
    this.adds.updateAdvertisement(data).subscribe(
      (res:any) => {
        this.toast.success(res.message,'Success')
        this.getAdList()
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
        this.getAdList()
      }
    )
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.ads_name.toLowerCase().indexOf(val) !== -1 ||
      d.ads_type.toLowerCase().indexOf(val) !== -1 ||
      d.status.toLowerCase().indexOf(val) !== -1 ||
      !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

}
