import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable'; 
import { UserdataService } from 'src/app/shared/userdata.service';
import { ProductService } from 'src/app/shared/product/product.service';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { OrdersService } from 'src/app/shared/orders/orders.service';
import { DatePipe } from "@angular/common";
import { ActivatedRoute } from '@angular/router';
import { DriverService } from 'src/app/shared/driver/driver.service';

@Component({
  selector: 'app-orderdriver',
  templateUrl: './orderdriver.component.html',
  styleUrls: ['./orderdriver.component.css']
})
export class OrderdriverComponent implements OnInit {

  myGroup = new FormGroup({
    restId: new FormControl(''),
    startDate: new FormControl(''),
    endDate: new FormControl(''),
    search: new FormControl(''),
  });
  constructor(private toast: ToastrService,private product: ProductService, private userdata: UserdataService,private orders:OrdersService, private datePipe: DatePipe,private restaurant:RestaurantService, private activate: ActivatedRoute,private driver: DriverService) { }

  p: number = 1;
  term: string;
  temp = [];
  restList = []
  id=''
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]
  columns = [{ prop: 'Restaurant Name' },{ name: 'Order Name' },{ name: 'Payment Type' },{ name: 'Total' },{ name: 'Driver Amount' },{ name: 'Tip Amount' },{ name: 'Admin Commission' }, { name: 'Status' }];
  ngOnInit(): void {
    this.getRestaurant()
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getAllOrders()
    // for page number 1 as offset starts with 0
    //this.table.offset = 0;    
  }

  getAllOrders(){ 
    let body = new URLSearchParams()
    body.set('driverId',this.id)
    this.driver.driverOrder(body).subscribe(
      (res:any) => {
        ////
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  } 

  getOrderListSearch(){
    let body = new URLSearchParams()
    if(this.myGroup.get('restId').value!="")
    {
      body.set('restId',this.myGroup.get('restId').value)
    }
    if(this.myGroup.get('startDate').value!=""){
      body.set('startdate',this.myGroup.get('startDate').value)
    }
    if(this.myGroup.get('endDate').value!=""){
      body.set('enddate',this.myGroup.get('endDate').value)
    }
    body.set('driverId',this.id)
    this.driver.driverOrder(body).subscribe(
      (res:any) => {
        this.rows =[]
        //console.log(res)
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  }

  getOrderListDate(event){
    let body = new URLSearchParams()
    body.set('restId',event.target.value)
    this.orders.allOrders(body).subscribe(
      (res:any) => {
        this.rows =[]
        //console.log(res)
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.order_name.toLowerCase().indexOf(val) !== -1 ||
      !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  transformDate(date){
    return this.datePipe.transform( date,'MM-dd-yyyy');
  }

  getRestaurant(){
    let body = new URLSearchParams();
    body.set('status', 'Enabled');
    this.restaurant.searchRestaurant(body).subscribe(
      (res:any) => {
        //console.log(res)
        this.restList = res.data
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  }

}
