import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-myearning',
  templateUrl: './myearning.component.html',
  styleUrls: ['./myearning.component.css']
})
export class MyearningComponent implements OnInit {

  myGroup = new FormGroup({
    'startdate' : new FormControl(''),
    'enddate' : new FormControl(''),
  })

  constructor(private toast: ToastrService,private restaurant: RestaurantService,private userdata: UserdataService,private activate: ActivatedRoute, private datePipe: DatePipe) { }

  p: number = 1;
  term: string;
  temp = [];
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]  
  currentWallet = 0
  totaltxns = 0
  id=""

  columns = [{ prop: 'Type' }, { name: 'Order ID' }, { name: 'Total Earned' }, { name: 'Transaction ID' }, { name: 'Amount Paid' }, { name: 'Balance' }, { name: 'Date' }];

  ngOnInit(): void {
    this.getTransactionList()
    this.id = this.userdata.getId()
  }

  getTransactionList(){
    this.restaurant.restWalletTransactions({'restId':this.userdata.getId()}).subscribe(
      (res:any) => {
        //console.log(res.data)
        //this.toast.success(res.message,'Success')
        this.rows = []
        this.temp = []
        this.rows = res.data
        this.temp = this.rows
        this.currentWallet = res.currentWallet
        this.totaltxns = res.totaltxns
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  transformDate(date){
    return this.datePipe.transform( date,'MM-dd-yyyy');
  }

  searchByDate(){
    if(this.myGroup.get('startdate').value !='' && this.myGroup.get('enddate').value!=''){
      this.restaurant.restWalletTransactions({'restId':this.userdata.getId(),'startdate':this.myGroup.get('startdate').value,'enddate':this.myGroup.get('enddate').value}).subscribe(
        (res:any) => {
          //console.log(res.data)
          //this.toast.success(res.message,'Success')
          this.rows = []
          this.temp = []
          this.rows = res.data
          this.temp = this.rows
          //this.currentWallet = res.currentWallet
        },
        err => {
          //console.log(err)
          this.toast.error(err.error.message,'Error')
        }
      )
    }
    else if(this.myGroup.get('startdate').value =='' && this.myGroup.get('enddate').value==''){
      this.getTransactionList()    
    }
    else
    {
      this.toast.error('Enter Start Date and End Date','Error')
    }
  }

  searchButton(val){
    //------Today --------//
    var date = new Date
    var dd = String(date.getDate()).padStart(2, '0')
    var mm = String(date.getMonth()+1).padStart(2, '0')
    var yy = String(date.getFullYear())
    var today = yy+'-'+mm+'-'+dd
    //-------Dynamic days Before-----//
    var thatDay = date.getDate() - val; //Current Date
    date.setDate(thatDay);
    let day = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let year = date.getFullYear();
    var finalDate = year+'-'+month+'-'+day
    this.restaurant.restWalletTransactions({'restId':this.userdata.getId(),'startdate':finalDate,'enddate':today}).subscribe(
      (res:any) => {
        //console.log(res.data)
        //this.toast.success(res.message,'Success')
        this.rows = []
        this.temp = []
        this.rows = res.data
        this.temp = this.rows
        //this.currentWallet = res.currentWallet
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
