import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ProducttagService } from 'src/app/shared/producttag/producttag.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-addproducttag',
  templateUrl: './addproducttag.component.html',
  styleUrls: ['./addproducttag.component.css']
})
export class AddproducttagComponent implements OnInit {

  formGroup = new FormGroup({
    producttag_name: new FormControl('', [Validators.required]),
    userId: new FormControl('', [Validators.required]),
    added_by: new FormControl('', [Validators.required]),
  }) 

  constructor(private toast: ToastrService, private userdata: UserdataService,private tag: ProducttagService,private ngxService: NgxUiLoaderService, private router: Router) { }

  ngOnInit(): void {
    var id = this.userdata.getId()
    var added = this.userdata.getUserType()
    this.formGroup.patchValue({userId: id});
    this.formGroup.patchValue({added_by: added});
  }

  get f(){
    return this.formGroup.controls
  }

  public onsubmit(){
    this.ngxService.start()
    this.tag.addTag(this.formGroup.value).subscribe(
      (res:any) => {
        this.ngxService.stop()
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/productTag/view')
      },
      err => {
        this.ngxService.stop()
        this.toast.error(err.error.message,'Error')
      }
    )
  }


}
