import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { RestcategoryService } from 'src/app/shared/restcategory/restcategory.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-add-mycategory',
  templateUrl: './add-mycategory.component.html',
  styleUrls: ['./add-mycategory.component.css']
})
export class AddMycategoryComponent implements OnInit {

  formGroup = new FormGroup({
    rest_cat_name: new FormControl('', [Validators.required]),
    userId: new FormControl('', [Validators.required]),
    added_by: new FormControl('', [Validators.required]),
    restId: new FormControl('', [Validators.required]),
  })

  constructor(private category:RestcategoryService, private userdata: UserdataService, private toast: ToastrService, private router: Router, private restaurant: RestaurantService) { }

  ngOnInit(): void {
    var id = this.userdata.getId()
    var added = this.userdata.getUserType()
    this.formGroup.patchValue({userId: id});
    this.formGroup.patchValue({added_by: added});
    this.formGroup.patchValue({restId: this.userdata.getId()});
  }

  get f(){
    return this.formGroup.controls
  }

  public onsubmit(){
    this.category.restAddCategory(this.formGroup.value).subscribe(
      (res:any) => {
        //console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/restaurant/category/view');
      },
      err => {
        console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }
} 
