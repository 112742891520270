<!-- Page Header -->
<!-- <div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/admin/orders/all']">Orders</a></li>
        <li class="active breadcrumb-item">Cancelled & Rejected Orders</li>
    </ol>
</div> -->
<!-- /page header -->
<!-- Grid --> 
<div class="row">
    <!-- Grid Item -->
    <!-- <div class="col-xl-12">
        <h3 class="dt-card__title float-left">View Cancelled & Rejected Orders</h3>
    </div> -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">
            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Tables --> 
                <input
                type="text" class="form-control search-input"
                placeholder="Type to filter the name column..."
                (keyup)="updateFilter($event)"
                />
                <ngx-datatable
                #table
                class="material"
                [columns]="columns"
                [columnMode]="ColumnMode.force"
                [headerHeight]="50"
                [footerHeight]="50"
                rowHeight="auto"
                [limit]="10"
                [rows]="rows"
                #myFilterTable
                >
                    <ngx-datatable-column name="Restaurant Name" prop="restId.rest_name">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.restId.rest_name}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Order Id" prop="orderId">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.orderId}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Payment Type" prop="payment_type">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.payment_type}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Total" prop="total_paid">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.total_paid |currency:'CAD':'symbol-narrow'}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Status">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span class="badge badge-pill badge-primary" *ngIf="row.order_current_status==104">
                                Rejected
                            </span>
                            <span class="badge badge-pill badge-info" *ngIf="row.order_current_status==109">
                                Cancelled
                            </span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Date" prop="created_at">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{transformDate(row.created_at)}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Refund">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        <button class="btn btn-sm btn-danger" *ngIf="row.is_cod && !row.is_online" (click)="cancelCodOrder(row._id)">REFUND</button>
                        <button class="btn btn-sm btn-danger" *ngIf="!row.is_cod && row.is_online" [routerLink]="['/admin/orders/refundOrder/',row._id]">REFUND</button>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="View">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <a [routerLink]="['/admin/orders/single/', row._id]" title="View Order"><i class="icon icon-eye icon-fw icon-lg icon-view"></i></a>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
                <!-- /tables -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->  