import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'src/app/shared/product/product.service';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-list-mymenu',
  templateUrl: './list-mymenu.component.html',
  styleUrls: ['./list-mymenu.component.css']
})
export class ListMymenuComponent implements OnInit {

  imageURL = ''
  constructor(private toast: ToastrService,private product: ProductService, private userdata: UserdataService, private restaurant: RestaurantService,private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl) { 
    this.imageURL = _imageurl 
  }

  p: number = 1;
  term: string;
  temp = [];
  restList = []
  restId :any = ""
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]
  columns = [{ prop: 'Category Name' },{ name: 'Sub-Category Name' },{ name: 'Product Name' },{ name: 'Image' }, { name: 'Status' }, { name: 'Action' }];
  ngOnInit(): void {
    this.getProductList()
  }

  getProductList(){
    this.product.listProduct({'restId':this.userdata.getId()}).subscribe(
      (res:any) => {
        //console.log(res)
        //this.toast.success(res.message,'Success')
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  } 

  changeStatus(val,id){
    const data = new FormData()
    var updated_by = this.userdata.getUserType()
    var updated_Id = this.userdata.getId()
    data.append("status",val)
    data.append("_id",id)
    data.append("updated_Id",updated_Id)
    data.append("updated_by",updated_by)
    this.product.updateProduct(data).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.getProductList()
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
        this.getProductList()
      }
    )
  }

  public getSanitizerUrl(photoname){
    return this.trusturl.bypassSecurityTrustUrl(this.imageURL + photoname)
  } 

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.product_name.toLowerCase().indexOf(val) !== -1 ||
      d.status.toLowerCase().indexOf(val) !== -1 ||
      d.added_by.toLowerCase().indexOf(val) !== -1 ||
      !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }
}
