import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/shared/customer/customer.service';
import { OrdersService } from 'src/app/shared/orders/orders.service';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-detailcustomer',
  templateUrl: './detailcustomer.component.html',
  styleUrls: ['./detailcustomer.component.css']
})
export class DetailcustomerComponent implements OnInit {

  statusList = [
    {
      'value':101,
      'name':'Placed'
    },
    {
      'value':102,
      'name':'Payment Declined'
    },
    {
      'value':103,
      'name':'Cooking'
    },
    {
      'value':104,
      'name':'Rejected'
    },
    {
      'value':105,
      'name':'Cooked'
    },
    {
      'value':106,
      'name':'Pickup'
    },
    {
      'value':107,
      'name':'On Way'
    },
    {
      'value':108,
      'name':'Delivered'
    },
    {
      'value':109,
      'name':'Canceled'
    },
    {
      'value':110,
      'name':'Timeout'
    },
    {
      'value':111,
      'name':'Refunded'
    },
    {
      'value':112,
      'name':'Cancel & Refund'
    }
  ]
  imageURL = ''

  constructor(private toast: ToastrService, private userdata: UserdataService,private orders:OrdersService, private datePipe: DatePipe,private restaurant:RestaurantService, private activate:ActivatedRoute,private customer:CustomerService,private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl) {
    this.imageURL = _imageurl
  }
  
  id = ''
  temp = [];
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]
  columns = [{ prop: 'Restaurant Name' },{ name: 'Order Name' },{ name: 'Payment Type' },{ name: 'Total' }, { name: 'Driver' }, { name: 'Status' }, { name: 'Action' }];
  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getAllOrders()
    this.getFavRestDetails()
    this.myWallet()
  }

  //-----Fav Rest
  favRestList = []

  getAllOrders(){
    let body = new URLSearchParams()
    //body.set('today',"true")
    body.set('customerId',this.id)
    this.orders.allOrders(body).subscribe(
      (res:any) => {
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  }  

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.order_name.toLowerCase().indexOf(val) !== -1 ||
      d.restId.rest_name.toLowerCase().indexOf(val) !== -1 ||
      !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  transformDate(date){
    return this.datePipe.transform( date,'MM-dd-yyyy');
  }

  getFavRestDetails(){
    this.customer.getCustomerById({'_id':this.id}).subscribe(
      (res:any) => {
        this.favRestList = res.data.myfav_rest
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  }

  public getSanitizerUrl(photoname){
    return this.trusturl.bypassSecurityTrustUrl(this.imageURL + photoname)
  }

  //Reward History
  walletHistory = []
  myWallet(){
    this.customer.rewardhistory({'customerId':this.id}).subscribe(
      (res:any) => {
        this.walletHistory = res.data
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
