import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserdataService } from 'src/app/shared/userdata.service';
import { Router } from '@angular/router';
import { RolesService } from 'src/app/shared/roles/roles.service';

@Component({
  selector: 'app-addrole',
  templateUrl: './addrole.component.html',
  styleUrls: ['./addrole.component.css']
})
export class AddroleComponent implements OnInit {

  formGroup = new FormGroup({
    roles_name: new FormControl('', [Validators.required]),
    userId: new FormControl('', [Validators.required]),
    added_by: new FormControl('', [Validators.required]),
    premission_name: new FormControl('', [Validators.required]),
  }) 

  permissionList = ['Category','Subcategory','Roles','Manager','Services','Cuisines','Tags','City','Rest Category','Rest Subcategory','Restaurant','Menu','Drivers','Offers','Orders','Ads','Delivery Charges','Customize','Reports','Support']

  constructor(private toast: ToastrService, private userdata: UserdataService,private role: RolesService, private router: Router) { }

  ngOnInit(): void {
    var id = this.userdata.getId()
    var added = this.userdata.getUserType()
    this.formGroup.patchValue({userId: id});
    this.formGroup.patchValue({added_by: added});
  }

  get f(){
    return this.formGroup.controls
  }

  public onsubmit(){
    this.role.addrole(this.formGroup.value).subscribe(
      (res:any) => {
        this.toast.success(res.message,'Success')
        //this.formGroup.reset()
        this.router.navigateByUrl('/admin/roles/view');
      },
      err => {
        this.toast.error(err.error.message,'Error')
        //this.formGroup.reset()
      }
    )
  }

}
