import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserdataService } from 'src/app/shared/userdata.service';
import { AddsService } from 'src/app/shared/adds/adds.service';
import { DatePipe } from "@angular/common";
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';

@Component({
  selector: 'app-editadvertisement',
  templateUrl: './editadvertisement.component.html',
  styleUrls: ['./editadvertisement.component.css'],
  providers: [DatePipe]
})
export class EditadvertisementComponent implements OnInit {

  alignList = ["Horizontal","Vertical"]
  restList = []
  id = ''
  startdate=""
  enddate=""
  formGroup = new FormGroup({
    ads_name: new FormControl('', [Validators.required]),
    start_date: new FormControl('', [Validators.required]),
    end_date: new FormControl('', [Validators.required]),
    ads_type: new FormControl('', [Validators.required]),
    ads_image: new FormControl(''),
    is_rest_ads: new FormControl(''),
    restId: new FormControl('', [Validators.required]),
    updated_Id: new FormControl('', [Validators.required]),
    updated_by: new FormControl('', [Validators.required]),
    _id: new FormControl('', [Validators.required]),
  })

  constructor(private activate: ActivatedRoute, private adds: AddsService,private toast: ToastrService, private router:Router, private userdata: UserdataService, private datePipe: DatePipe, private restaurant: RestaurantService) { }

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getRestaurant()
    this.getDataById() 
  }

  getRestaurant(){
    let body = new URLSearchParams();
    body.set('status', 'Enabled');
    this.restaurant.searchRestaurant(body).subscribe(
      (res:any) => {
        //console.log(res)
        this.restList = res.data
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  }

  public onFile(evnt){
    if(evnt.target.files.length > 0)
    {
      this.formGroup.patchValue({
        ads_image: evnt.target.files[0]
      })
    }
  }

  get f(){
    return this.formGroup.controls
  }

  compareFn(c1: any, c2:any): boolean {     
    return c1 && c2 ? c1.id === c2.id : c1 === c2; 
  }

  getDataById(){
    let body = new URLSearchParams();
    body.set('_id', this.id);
    this.adds.getAdvertisementById(body).subscribe(
      (res:any) => {
        //console.log(res.result)
        this.formGroup.patchValue({ads_name: res.data.ads_name});     
        // this.formGroup.patchValue({start_date: res.data.start_date});    
        // this.formGroup.patchValue({end_date: res.data.end_date});     
        this.formGroup.patchValue({ads_type: res.data.ads_type});   
        this.formGroup.patchValue({is_rest_ads: res.data.is_rest_ads});     
        this.formGroup.patchValue({restId: res.data.restId});       
        this.formGroup.patchValue({_id: res.data._id});     
        // this.formGroup.patchValue({category_image: res.category_image});     
        var id = this.userdata.getId()
        var added = this.userdata.getUserType()
        this.formGroup.patchValue({updated_Id: id});
        this.formGroup.patchValue({updated_by: added});
        this.startdate = this.datePipe.transform(res.data.start_date, 'yyyy-MM-dd')
        this.enddate = this.datePipe.transform(res.data.end_date, 'yyyy-MM-dd')
        this.f   
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        this.router.navigateByUrl('/admin/adcenter/view')
      }
    )
  }

  public onsubmit(){
    if(this.formGroup.get("restId").value=="")
    {
      var is_rest_ads = "false"
    }
    else
    {
      var is_rest_ads = "true"
    }
    const data = new FormData()
    data.append("_id",this.formGroup.get("_id").value)
    data.append("updated_Id",this.formGroup.get("updated_Id").value)
    data.append("updated_by",this.formGroup.get("updated_by").value)
    data.append("ads_name",this.formGroup.get("ads_name").value)
    data.append("start_date",this.datePipe.transform(this.formGroup.get("start_date").value, 'MM-dd-yyyy'))
    data.append("end_date",this.datePipe.transform(this.formGroup.get("end_date").value, 'MM-dd-yyyy'))
    data.append("ads_type",this.formGroup.get("ads_type").value)
    data.append("ads_image",this.formGroup.get("ads_image").value)
    data.append("restId",this.formGroup.get("restId").value)
    data.append("is_rest_ads",is_rest_ads)

    this.adds.updateAdvertisement(data).subscribe(
      (res:any) => {
        //console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/adcenter/view');
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }


}
