import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'src/app/shared/product/product.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-pending-mymenu',
  templateUrl: './pending-mymenu.component.html',
  styleUrls: ['./pending-mymenu.component.css']
})
export class PendingMymenuComponent implements OnInit {

  constructor(private toast: ToastrService,private product: ProductService, private userdata: UserdataService) { }

  p: number = 1;
  term: string; 
  temp = [];
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]
  columns = [{ name: 'Type' },{ name: 'Product Name' },{ prop: 'Category' }, { prop: 'SubCategory' },{ prop: 'Description' },   { name: 'Status' }];
  ngOnInit(): void {
    this.getProductList()    
  }

  getProductList(){
    this.product.restPendingProduct({'restId':this.userdata.getId()}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.rows = res.data
        this.temp = this.rows
        //console.log(res.data)
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.product_name.toLowerCase().indexOf(val) !== -1 ||
      d.restcatId.rest_cat_name.toLowerCase().indexOf(val) !== -1 ||
      d.status.toLowerCase().indexOf(val) !== -1 ||  !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }
}
