<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
      <li class="breadcrumb-item"><a [routerLink]="['/admin/support/customerIssue/list']">Customer Support Issue</a></li>
      <li class="active breadcrumb-item">Refund</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Manager</h1> -->
  </div>
  <!-- /page header -->
  <!-- Grid -->
  <div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">
  
      <!-- Card -->
      <div class="dt-card dt-card__full-height">
  
        <!-- Card Header -->
        <div class="dt-card__header">
  
          <!-- Card Heading -->
          <div class="dt-card__heading">
            <h3 class="dt-card__title">Refund Order</h3>
          </div>
          <!-- /card heading -->
  
        </div>
        <!-- /card header -->
  
        <!-- Card Body -->
        <div class="dt-card__body">
  
          <!-- Form -->
          <form [formGroup]="formGroup" (submit)="onSubmit()">
  
            <!-- Form Group -->
            <div class="form-group form-row">
              <label class="col-xl-3 col-form-label text-sm-right" for="name">Refund Method</label>
              <div class="col-xl-9">
                <input type="text" class="form-control" formControlName="refudmethod"
                  placeholder="Eg. Coupon, PayTM, Cash">
                <small class="form-text" *ngIf="f.refudmethod.invalid && (f.refudmethod.dirty || f.refudmethod.touched)">
                  <small *ngIf="f.refudmethod.errors.required">Invalid Value</small>
                </small>
              </div>
            </div>
            <!-- /form group -->
  
            <!-- Form Group -->
            <div class="form-group form-row">
              <label class="col-xl-3 col-form-label text-sm-right" for="name">Refund Type</label>
              <div class="col-xl-9">
                <input type="radio" name="refundtype" (click)="checkRefundType($event)" value="refund" checked> Refund
                &nbsp;
                <input type="radio" name="refundtype" (click)="checkRefundType($event)" value="coupon"> Coupon &nbsp;
              </div> 
            </div>
            <!-- /form group -->
  
            <!-- Form Group -->
            <div class="form-group form-row">
              <label class="col-xl-3 col-form-label text-sm-right" for="name">Is Restaurant Fault?</label>
              <div class="col-xl-9">
                <input type="radio" name="is_rest_also_bear" (click)="isRestBear($event)" value="true" checked> Yes &nbsp;
                <input type="radio" name="is_rest_also_bear" (click)="isRestBear($event)" value="false"> No &nbsp;
              </div>
            </div>
            <!-- /form group -->
  
            <!-- Form Group -->
            <div class="form-group form-row" *ngIf="isRestBearRefund && this.isRefund">
              <label class="col-xl-3 col-form-label text-sm-right" for="rest_bear_percentage">Restaurant Fault
                Percentage</label>
              <div class="col-xl-9">
                <select name="rest_bear_percentage" id="rest_bear_percentage" class="form-control"
                  formControlName="rest_bear_percentage" [compareWith]="compareFn">
                  <option *ngFor="let per of percentageList" [value]="per">{{per}}%</option>
                </select>
              </div>
            </div>
            <!-- /form group -->
  
            <!-- Form Group -->
            <div class="form-group form-row" *ngIf="isRefund">
              <label class="col-xl-3 col-form-label text-sm-right" for="txnId">Refund ID</label>
              <div class="col-xl-9">
                <input type="text" class="form-control" formControlName="txnId" placeholder="Refund ID">
                <small class="form-text" *ngIf="f.txnId.invalid && (f.txnId.dirty || f.txnId.touched)">
                  <small *ngIf="f.txnId.errors.required">Invalid Value</small>
                </small>
              </div>
            </div>
            <!-- /form group -->
  
            <!-- Form Group -->
            <div class="form-group form-row">
              <label class="col-xl-3 col-form-label text-sm-right" for="solution_text">Remarks</label>
  
              <div class="col-xl-9">
                <input type="text" class="form-control" id="solution_text" placeholder="Enter Remarks"
                  name="solution_text" formControlName="solution_text">
                <small class="form-text"
                  *ngIf="f.solution_text.invalid && (f.solution_text.dirty || f.solution_text.touched)">
                  <small *ngIf="f.solution_text.errors.required">Invalid Value</small>
                </small>
              </div>
            </div>
            <!-- /form group -->
  
            <!-- Form Group -->
            <div class="form-group form-row">
              <div class="col-xl-9 offset-xl-3">
                <button type="submit" class="btn btn-primary text-uppercase" [disabled]="!formGroup.valid">Save
                </button>
              </div>
            </div>
            <!-- /form group -->
  
          </form>
          <!-- /form -->
  
        </div>
        <!-- /card body -->
  
      </div>
      <!-- /card -->
  
    </div>
    <!-- /grid item -->
  
  </div>
  <!-- /grid -->
  