// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDUuF9lbTgqczAsuTwJqx5AGRrmEp9_pjI",
    authDomain: "foodadmin-760aa.firebaseapp.com",
    databaseURL: "https://foodadmin-760aa.firebaseio.com",
    projectId: "foodadmin-760aa",
    storageBucket: "foodadmin-760aa.appspot.com",
    messagingSenderId: "507430858749",
    appId: "1:507430858749:web:682e181f66ea0410fd3394",
    measurementId: "G-NLGJ9W021G"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
