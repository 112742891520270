<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/admin/restaurant/view']">View Restaurant</a></li>
        <li class="active breadcrumb-item">Restaurant Details</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Tag</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <div class="col-6"><img [src]="getSanitizerUrl(rows.logo)" class="img img-fluid img-circle" height="100" width="100"></div>
                    <div class="col-6"><h3 class="dt-card__title float-left">{{rows.rest_name}}</h3></div>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">
                <table class="table table-borderless">
                    <tr>
                        <th>Owner Name</th>
                        <th>Owner Phone</th>
                        <th>Restaurant Phone</th>
                        <th>Email</th>
                    </tr>
                    <tr>
                        <td>{{rows.person_name}}</td>
                        <td>{{rows.person_phone}}</td>
                        <td>{{rows.rest_phone}}</td>
                        <td>{{rows.person_email}}</td>
                    </tr>
                    <tr><td colspan="4"><hr></td></tr>
                    <tr>
                        <th>City</th>
                        <th>Street Number</th>
                        <th>Address</th>
                        <th>Address In Doc.</th>
                    </tr>
                    <tr>
                        <td>{{rows.rest_city.city_name}}</td>
                        <td>{{rows.rest_street_number}}</td>
                        <td>{{rows.rest_address}}</td>
                        <td>{{rows.address_in_docs}}</td>
                    </tr>
                    <tr><td colspan="4"><hr></td></tr>
                    <tr>
                        <th>Seating Type</th>
                        <th>Payment Type</th>
                        <th>Food Type</th>
                        <th>Is SFCR?</th>
                    </tr>
                    <tr>
                        <td>{{rows.setting_type}}</td>
                        <td>{{rows.payment_type}}</td>
                        <td>{{rows.vegtype}}</td>
                        <td>{{rows.is_sfcr}}</td>
                    </tr>
                    <tr><td colspan="4"><hr></td></tr>
                    <tr>
                        <th>Is Taxable?</th>
                        <th>Tax Number</th>
                        <th>Tax Registration Date</th>
                        <th>SFCR Number</th>
                    </tr>
                    <tr>
                        <td>{{rows.is_tax}}</td>
                        <td>{{rows.tax_number}}</td>
                        <td>{{transformDate(rows.tax_reg_date)}}</td>
                        <td>{{rows.sfcr_number}}</td>
                    </tr>
                    <tr><td colspan="4"><hr></td></tr>
                    <tr>
                        <th colspan="2">Services</th>
                        <th colspan="2">Cuisine</th>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <span *ngFor="let service of rows.services" class="badge badge-pill badge-info mr-1"><a class="icon icon-remove icon-fw" (click)="deleteAddOn(rows._id,service._id,'services')"></a>
                                {{service.serviceId.name}}
                            </span>
                        </td>
                        <td colspan="2">
                            <span *ngFor="let cuisine of rows.cuisines" class="badge badge-pill badge-primary mr-1"><a class="icon icon-remove icon-fw" (click)="deleteAddOn(rows._id,cuisine._id,'cuisines')"></a>
                                {{cuisine.cuisineId.cuisine_name}}
                            </span>
                        </td>
                    </tr>
                    <tr><td colspan="4"><hr></td></tr>
                    <tr>
                        <th colspan="4">Tags</th>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <span *ngFor="let tag of rows.tags" class="badge badge-pill badge-warning mr-1"><a class="icon icon-remove icon-fw" (click)="deleteAddOn(rows._id,tag._id,'tags')"></a>
                                {{tag.tagId.tag_name}}
                            </span>
                        </td>
                    </tr>
                    <tr><td colspan="4"><hr></td></tr>
                    <tr>
                        <th>ID Proof</th>
                        <th>Tax Proof</th>
                        <th>Logo</th>
                        <th>SFCR Proof</th>
                    </tr>
                    <tr>
                        <td><img [src]="getSanitizerUrl(rows.person_id_proof)" class="img img-fluid img-circle" height="100" width="100"></td>
                        <td><img [src]="getSanitizerUrl(rows.tax_proof)" class="img img-fluid img-circle" height="100" width="100"></td>
                        <td><img [src]="getSanitizerUrl(rows.logo)" class="img img-fluid img-circle" height="100" width="100"></td>
                        <td><img [src]="getSanitizerUrl(rows.sfcr_proof)" class="img img-fluid img-circle" height="100" width="100"></td>
                    </tr>
                    <tr><td colspan="4"><hr></td></tr>
                    <!-- <tr>
                        <th colspan="2">Day</th>
                        <th colspan="2">Timing</th>
                    </tr>
                    <tr>
                        <td colspan="2">Monday</td>
                        <td colspan="2">
                            <span *ngIf="rows.timming.monday.length > 0; else templateNameMonday">
                                {{rows.timming.monday.starttime}} to  {{rows.timming.monday.endtime}}
                            </span>
                            <ng-template #templateNameMonday>
                                CLOSED
                            </ng-template>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">Tuesday</td>
                        <td colspan="2">
                            <span *ngIf="rows.timming.tuesday.length > 0; else templateNameTuesday">
                                {{rows.timming.tuesday.starttime}} to  {{rows.timming.tuesday.endtime}}
                            </span>
                            <ng-template #templateNameTuesday>
                                CLOSED
                            </ng-template>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">Wednesday</td>
                        <td colspan="2">
                            <span *ngIf="rows.timming.wednesday.length > 0; else templateNameWednesday">
                                {{rows.timming.wednesday.starttime}} to  {{rows.timming.wednesday.endtime}}
                            </span>
                            <ng-template #templateNameWednesday>
                                CLOSED
                            </ng-template>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">Thursday</td>
                        <td colspan="2">
                            <span *ngIf="rows.timming.thursday.length > 0; else templateNameThursday">
                                {{rows.timming.thursday.starttime}} to  {{rows.timming.thursday.endtime}}
                            </span>
                            <ng-template #templateNameThursday>
                                CLOSED
                            </ng-template>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">Friday</td>
                        <td colspan="2">
                            <span *ngIf="rows.timming.friday.length > 0; else templateNameFriday">
                                {{rows.timming.friday.starttime}} to  {{rows.timming.friday.endtime}}
                            </span>
                            <ng-template #templateNameFriday>
                                CLOSED
                            </ng-template>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">Saturday</td>
                        <td colspan="2">
                            <span *ngIf="rows.timming.saturday.length > 0; else templateNameSaturday">
                                {{rows.timming.saturday.starttime}} to  {{rows.timming.saturday.endtime}}
                            </span>
                            <ng-template #templateNameSaturday>
                                CLOSED
                            </ng-template>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">Sunday</td>
                        <td colspan="2">
                            <span *ngIf="rows.timming.sunday.length > 0; else templateNameSunday">
                                {{rows.timming.sunday.starttime}} to  {{rows.timming.sunday.endtime}}
                            </span>
                            <ng-template #templateNameSunday>
                                CLOSED
                            </ng-template>
                        </td>
                    </tr>
                    <tr><td colspan="4"><hr></td></tr> -->
                    <tr>
                        <th>Added By</th>
                        <td>{{rows.added_by}}</td>
                    </tr>
                </table>
                
            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid --> 