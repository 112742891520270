<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/restaurant/subcategory/view']">Sub-Category</a></li>
        <li class="active breadcrumb-item">Add</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Category</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title">Add Sub-Category</h3>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Form -->
                <form [formGroup]="formGroup" (submit)="onsubmit()">
                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="restcatId">Category Name</label>

                        <div class="col-xl-9">
                            <select class="form-control" id="restcatId" name="restcatId" formControlName="restcatId">
                                <option value="" [selected]="true" [disabled]="true">Select Category</option>
                                <option *ngFor="let category of categoryList" [value]="category._id">{{category.rest_cat_name}}</option>
                            </select>
                            <small class="form-text" *ngIf="f.restcatId.invalid && (f.restcatId.dirty || f.restcatId.touched)">
                                <small *ngIf="f.restcatId.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="restsubcategory_name">Sub-Category Name</label>

                        <div class="col-xl-9">
                            <input type="text" class="form-control" id="restsubcategory_name" placeholder="Enter Category Name" name="restsubcategory_name" formControlName="restsubcategory_name">
                            <small class="form-text" *ngIf="f.restsubcategory_name.invalid && (f.restsubcategory_name.dirty || f.restsubcategory_name.touched)">
                                <small *ngIf="f.restsubcategory_name.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <div class="col-xl-9 offset-xl-3">
                            <button type="submit" class="btn btn-primary text-uppercase" [disabled]="!formGroup.valid">Save
                            </button>
                        </div>
                    </div>
                    <!-- /form group -->

                </form>
                <!-- /form -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->