import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TimingService } from 'src/app/shared/timing/timing.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-addtiming',
  templateUrl: './addtiming.component.html',
  styleUrls: ['./addtiming.component.css']
})
export class AddtimingComponent implements OnInit {

  formGroup = new FormGroup({
    timming_name: new FormControl('', [Validators.required]),
    userId: new FormControl('', [Validators.required]),
    added_by: new FormControl('', [Validators.required]),
  }) 

  constructor(private toast: ToastrService, private userdata: UserdataService,private timing: TimingService,private ngxService: NgxUiLoaderService, private router: Router) { }

  ngOnInit(): void {
    var id = this.userdata.getId()
    var added = this.userdata.getUserType()
    this.formGroup.patchValue({userId: id});
    this.formGroup.patchValue({added_by: added});
  }

  get f(){
    return this.formGroup.controls
  }

  public onsubmit(){
    this.ngxService.start()
    this.timing.addTiming(this.formGroup.value).subscribe(
      (res:any) => {
        this.ngxService.stop()
        this.toast.success(res.message,'Success')
        // this.formGroup.reset()
        this.router.navigateByUrl('/admin/timing/view');
      },
      err => {
        this.ngxService.stop()
        this.toast.error(err.error.message,'Error')
        // this.formGroup.reset()
      }
    )
  }

} 
