import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DriverService } from 'src/app/shared/driver/driver.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-add-mydriver',
  templateUrl: './add-mydriver.component.html',
  styleUrls: ['./add-mydriver.component.css']
})
export class AddMydriverComponent implements OnInit {

  visaList = ["Visitor Visa","Student Visa","Work Permit","Citizenship"]

  formGroup = new FormGroup({
    driver_email: new FormControl('', [Validators.required,Validators.email]),
    driver_name: new FormControl('', [Validators.required]),
    driver_phone: new FormControl('', [Validators.required, Validators.pattern("[0-9]{10}")]),
    driver_address: new FormControl('', [Validators.required]),
    driver_password: new FormControl('', [Validators.required]),
    other_detail: new FormControl(''),
    vehicle_type: new FormControl('', [Validators.required]),
    vehicle_number: new FormControl('', [Validators.required]),
    driving_lic_number: new FormControl('', [Validators.required]),
    vehicle_reg_pic: new FormControl('', [Validators.required]),
    dl_reg_pic: new FormControl('', [Validators.required]),
    dl_back_pic  : new FormControl('', [Validators.required]),
    vehicle_insurance_pic   : new FormControl('', [Validators.required]),
    other_id_pic   : new FormControl(''),
    driver_idproff_pic: new FormControl('', [Validators.required]),
    added_by: new FormControl('', [Validators.required]),
    is_self_reg: new FormControl('', [Validators.required]),
    sin_number: new FormControl('', [Validators.required,Validators.pattern("[0-9]{9}")]),
    visa_type: new FormControl('', [Validators.required]),
    restId: new FormControl('', [Validators.required]),
    province: new FormControl('', [Validators.required]),
    zip_code : new FormControl('', [Validators.required,Validators.pattern("[A-Za-z]{1}[0-9]{1}[A-Za-z]{1}[0-9]{1}[A-Za-z]{1}[0-9]{1}")]),
    dlexpire : new FormControl('', [Validators.required]),
    is_payment_per_order: new FormControl(false, [Validators.required]),
  })

  constructor(private toast: ToastrService, private driver: DriverService, private router: Router, private userdata: UserdataService) {}

  ngOnInit(): void {
    var added = this.userdata.getUserType()
    this.formGroup.patchValue({ added_by: added });
    this.formGroup.patchValue({ is_self_reg: false });
    this.formGroup.patchValue({ restId: this.userdata.getId() });
  }

  get f() {
    return this.formGroup.controls
  }

  public proofFile(evnt){
    if(evnt.target.files.length > 0)
    {
      this.formGroup.patchValue({
        driver_idproff_pic: evnt.target.files[0]
      })
    }
  }

  public taxFile(evnt){
    if(evnt.target.files.length > 0)
    {
      this.formGroup.patchValue({
        dl_reg_pic: evnt.target.files[0]
      })
    }
  }

  public vehicleFile(evnt){
    if(evnt.target.files.length > 0)
    {
      this.formGroup.patchValue({
        vehicle_reg_pic: evnt.target.files[0]
      })
    }
  }

  public licenseBackFile(evnt){
    if(evnt.target.files.length > 0)
    {
      this.formGroup.patchValue({
        dl_back_pic: evnt.target.files[0]
      })
    }
  }

  public vehicleInsuranceFile(evnt){
    if(evnt.target.files.length > 0)
    {
      this.formGroup.patchValue({
        vehicle_insurance_pic : evnt.target.files[0]
      })
    }
  }

  public otherFile(evnt){
    if(evnt.target.files.length > 0)
    {
      this.formGroup.patchValue({
        other_id_pic : evnt.target.files[0]
      })
    }
  }

  public onsubmit() {
    const data = new FormData()
    data.append("driver_email",this.formGroup.get("driver_email").value)
    data.append("driver_name",this.formGroup.get("driver_name").value)
    data.append("driver_phone",this.formGroup.get("driver_phone").value)
    data.append("driver_address",this.formGroup.get("driver_address").value)
    data.append("driver_password",this.formGroup.get("driver_password").value)
    data.append("other_detail",this.formGroup.get("other_detail").value)
    data.append("vehicle_type",this.formGroup.get("vehicle_type").value)
    data.append("vehicle_number",this.formGroup.get("vehicle_number").value)
    data.append("driving_lic_number",this.formGroup.get("driving_lic_number").value)
    data.append("vehicle_reg_pic",this.formGroup.get("vehicle_reg_pic").value)
    data.append("dl_reg_pic",this.formGroup.get("dl_reg_pic").value)
    data.append("driver_idproff_pic",this.formGroup.get("driver_idproff_pic").value)
    data.append("dl_back_pic",this.formGroup.get("dl_back_pic").value)
    data.append("vehicle_insurance_pic",this.formGroup.get("vehicle_insurance_pic").value)
    data.append("other_id_pic",this.formGroup.get("other_id_pic").value)
    data.append("added_by",this.formGroup.get("added_by").value)
    data.append("is_self_reg",this.formGroup.get("is_self_reg").value)
    data.append("sin_number",this.formGroup.get("sin_number").value)
    data.append("visa_type",this.formGroup.get("visa_type").value)
    data.append("dlexpire",this.formGroup.get("dlexpire").value)
    data.append("province",this.formGroup.get("province").value)
    data.append("zip_code",this.formGroup.get("zip_code").value)
    data.append("restId",this.formGroup.get("restId").value)
    this.driver.restAddDriver(data).subscribe(
      (res:any) => {
        //console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/restaurant/driver/view');
      },
      err => {
        console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
