import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrdersService } from 'src/app/shared/orders/orders.service';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { UserdataService } from 'src/app/shared/userdata.service';
import Swal from 'src/assets/node_modules/sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.css'],
  providers: [DatePipe]
})
export class MyprofileComponent implements OnInit {

  imageURL = ''
  restDetails:any = ''
  constructor(private router:Router,private toast: ToastrService, private userdata: UserdataService,private orders:OrdersService, private datePipe: DatePipe,private restaurant:RestaurantService, private activate:ActivatedRoute,private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl) {
    this.imageURL = _imageurl
  }

  ngOnInit(): void {
    this.getRestaurantDetails()
    this.formGroup.patchValue({'restId':this.userdata.getId()})
    this.passwordGroup.patchValue({'restId':this.userdata.getId()})
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  getRestaurantDetails(){
    this.restaurant.getRestaurantById({'_id':this.userdata.getId()}).subscribe(
      (res:any) =>{
        //console.log(res.data)
        this.restDetails = res.data
        this.formGroup.patchValue({'rest_phone':this.restDetails.rest_phone})
        this.formGroup.patchValue({'rest_description':this.restDetails.rest_description})
        this.formGroup.patchValue({'restId':this.userdata.getId()})
      },err =>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  public getSanitizerUrl(photoname){
    return this.trusturl.bypassSecurityTrustUrl(this.imageURL + photoname)
  } 

  transformDate(date)
  {
    return this.datePipe.transform( date,'MM-dd-yyyy');
  }

  deleteAddOn(restId,id,type){
    Swal({
      title: 'Are you sure you want to delete this?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      reverseButtons: true
  }).then((result) => {
      if (result.value) {
        var user = this.userdata.getId()
        this.restaurant.deleteTags({'restId':restId,'typeId':id,'type':type,'addedId':user}).subscribe(
          (res:any) => {
            this.toast.success(res.message,'Success')
            this.getRestaurantDetails()
          },
          err => {
            //console.log(err)
            this.toast.error(err.error.message,'Error')
            this.getRestaurantDetails()
          }
        )
      }
  })
  }

  //------------Update profile
  formGroup = new FormGroup({
    rest_phone: new FormControl('', [Validators.required,Validators.pattern("[0-9]{10}")]),
    rest_description: new FormControl('', [Validators.required]),
    logo: new FormControl('', [Validators.required]),
    restId: new FormControl('', [Validators.required]),
  })

  get f(){
    return this.formGroup.controls
  }
  imageError=''
  public onFile(evnt){
    this.imageError = null
    if(evnt.target.files.length > 0)
    {
      const max_size = 2097152
      //const max_size = 1000;
      if (evnt.target.files[0].size > max_size) {
        this.imageError = 'Maximum size allowed is ' + max_size / 1024/1024 + 'MB';
        this.f.logo.errors.required ='error'
        return false
      }
      else{
        this.formGroup.patchValue({
          logo: evnt.target.files[0]
        })
      }      
    }
  }

  onsubmit(){
    const data = new FormData()
    data.append("rest_phone",this.formGroup.get("rest_phone").value)
    data.append("rest_description",this.formGroup.get("rest_description").value)
    data.append("restId",this.formGroup.get("restId").value)
    data.append("logo",this.formGroup.get("logo").value)
  }

  //-----------Update password
  passwordGroup = new FormGroup({
    oldpassword: new FormControl('', [Validators.required]),
    newpassword: new FormControl('', [Validators.required]),
    restId: new FormControl('', [Validators.required]),
  })
  get p(){
    return this.passwordGroup.controls
  }

  updatePassword(){
    this.restaurant.changePasswordRest(this.passwordGroup.value).subscribe(
      (res:any) => {
        this.toast.success(res.message,'Success')
        this.passwordGroup.reset()
      },
      err => {
        this.toast.error(err.error.message,'Error')
      }
    )
  }
}
