<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/admin/restaurant/view']">Restaurant</a></li>
        <li class="active breadcrumb-item">Restaurant Payout</li>
    </ol>
    <!-- <h1 class="dt-page__title">Edit Manager</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title">Restaurant Payout</h3>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Form -->
                <form [formGroup]="formGroup" (submit)="onsubmit()">

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="amount_paid">Amount ($)</label>

                        <div class="col-xl-9">
                            <input type="text" class="form-control" id="amount_paid" placeholder="Enter Amount" name="amount_paid" formControlName="amount_paid" [readonly]="true">
                            <small class="form-text" *ngIf="f.amount_paid.invalid && (f.amount_paid.dirty || f.amount_paid.touched)">
                                <small *ngIf="f.amount_paid.errors.required">Invalid Value</small>
                                <small *ngIf="f.amount_paid.errors.max">Invalid Value</small>
                            </small>
                        </div>
                    </div>
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="txnId">Transaction ID / Cheque Number</label>

                        <div class="col-xl-9">
                            <input type="text" class="form-control" id="txnId" placeholder="Enter Transaction ID / Cheque Number" name="txnId" formControlName="txnId">
                            <small class="form-text" *ngIf="f.txnId.invalid && (f.txnId.dirty || f.txnId.touched)">
                                <small *ngIf="f.txnId.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>
                    <!-- /form group --> 

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <div class="col-xl-9 offset-xl-3">
                            <button type="submit" class="btn btn-primary text-uppercase" [disabled]="!formGroup.valid">Save
                            </button>
                        </div>
                    </div>
                    <!-- /form group -->

                </form>
                <!-- /form -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->