<!-- Page Header -->
<div class="dt-page__header">
  <h1 class="dt-page__title">Dashboard</h1>
</div>
<!-- /page header -->

<!-- Grid -->
<div class="row" *ngIf="isAdmin">

  <!-- Grid Item -->
  <a class="col" [routerLink]="['/admin/customer/view']">
    <!-- Card -->
    <div class="dt-card">
      <!-- Card Body -->
      <div class="dt-card__body d-flex flex-sm-column fix-height-div">
        <div class="mb-sm-7 mr-7 mr-sm-0">
          <i class="icon icon-users dt-icon-bg bg-primary text-primary"></i>
        </div>
        <div class="flex-1">
          <div class="d-flex align-items-center mb-2">
            <span class="h2 mb-0 font-weight-500 mr-2">{{totalCustomer}}</span>
          </div>
          <div class="h5 mb-2">Total User</div>
        </div>
      </div>
      <!-- /card body -->
    </div>
    <!-- /card -->
  </a>
  <!-- /grid item -->

  <!-- Grid Item -->
  <a class="col" [routerLink]="['/admin/restaurant/view']">
    <!-- Card -->
    <div class="dt-card">

      <!-- Card Body -->
      <div class="dt-card__body d-flex flex-sm-column fix-height-div">
        <div class="mb-sm-7 mr-7 mr-sm-0">
          <i class="icon icon-company dt-icon-bg bg-success text-success"></i>
        </div>
        <div class="flex-1">
          <div class="d-flex align-items-center mb-2">
            <span class="h2 mb-0 font-weight-500 mr-2">{{totalRest}}</span>
          </div>
          <div class="h5 mb-2">Total Restaurant</div>
        </div>
      </div>
      <!-- /card body -->

    </div>
    <!-- /card -->
  </a>
  <!-- /grid item -->

  <!-- Grid Item -->
  <a class="col" [routerLink]="['/admin/driver/view']">
    <!-- Card -->
    <div class="dt-card">

      <!-- Card Body -->
      <div class="dt-card__body d-flex flex-sm-column fix-height-div">
        <div class="mb-sm-7 mr-7 mr-sm-0">
          <i class="icon icon-customer dt-icon-bg bg-secondary text-secondary"></i>
        </div>
        <div class="flex-1">
          <div class="d-flex align-items-center mb-2">
            <span class="h2 mb-0 font-weight-500 mr-2">{{totalDriver}}</span>
          </div>
          <div class="h5 mb-2">Total Drivers</div>
        </div>
      </div>
      <!-- /card body -->

    </div>
    <!-- /card -->
  </a>
  <!-- /grid item -->

  <!-- Grid Item -->
  <a class="col" [routerLink]="['/admin/offers/view']">
    <!-- Card -->
    <div class="dt-card">
      <!-- Card Body -->
      <div class="dt-card__body d-flex flex-sm-column fix-height-div">
        <div class="mb-sm-7 mr-7 mr-sm-0">
          <i class="icon icon-percentage dt-icon-bg bg-orange text-orange"></i>
        </div>
        <div class="flex-1">
          <div class="d-flex align-items-center mb-2">
            <span class="h2 mb-0 font-weight-500 mr-2">{{activeOffer}}</span>
          </div>
          <div class="h5 mb-2">Active Offers</div>
        </div>
      </div>
      <!-- /card body -->
    </div>
    <!-- /card -->
  </a>
  <!-- /grid item -->

  <!-- Grid Item -->
  <a class="col" [routerLink]="['/admin/adcenter/view']">
    <!-- Card -->
    <div class="dt-card">
      <!-- Card Body -->
      <div class="dt-card__body d-flex flex-sm-column fix-height-div">
        <div class="mb-sm-7 mr-7 mr-sm-0">
          <i class="icon icon-important dt-icon-bg bg-sky-blue text-sky-blue"></i>
        </div>
        <div class="flex-1">
          <div class="d-flex align-items-center mb-2">
            <span class="h2 mb-0 font-weight-500 mr-2">{{activeAds}}</span>
          </div>
          <div class="h5 mb-2">Active Adds</div>
        </div>
      </div>
      <!-- /card body -->
    </div>
    <!-- /card -->
  </a>
  <!-- /grid item -->

  <!-- Grid Item -->
  <!-- <div class="col-xl-2 col-sm-4">
    
    <div class="dt-card">
      
      <div class="dt-card__body d-flex flex-sm-column fix-height-div">
        <div class="mb-sm-7 mr-7 mr-sm-0">
          <i class="icon icon-revenue2 dt-icon-bg bg-light-pink text-light-pink"></i>
        </div>
        <div class="flex-1">
          <div class="d-flex align-items-center mb-2">
            <span class="h2 mb-0 font-weight-500 mr-2">$12,588</span>
          </div>
          <div class="h5 mb-2">Today's Earning</div>
        </div>
      </div>
      
    </div>
    
  </div> -->
  <!-- /grid item -->

</div>
<!-- /grid -->

<!-- Grid -->
<div class="row" *ngIf="isAdmin">

  <!-- Grid Item -->
  <div class="col-md-12 col-12">

    <!-- Card -->
    <div class="dt-card">

      <!-- Card Body -->
      <div class="dt-card__body p-xl-8 py-sm-8 py-6 px-4">

        <span class="badge badge-secondary badge-top-right">Orders</span>

        <!-- Media -->
        <div class="media">
          <!-- <i class="icon icon-orders bg-success text-success "></i> -->
          <i class="icon icon-orders dt-icon-bg bg-info text-info icon-5x mr-xl-5 mr-1 mr-sm-3 align-self-center"></i>
          <!-- Media Body -->
          <div class="media-body">
            <ul class="invoice-list">
              <li class="invoice-list__item">
                <span class="invoice-list__number">{{OrderStatus.pendingorder}}</span> <span
                  class="dot-shape bg-primary"></span><span class="invoice-list__label">Placed</span>
              </li>
              <li class="invoice-list__item">
                <span class="invoice-list__number">{{OrderStatus.paymentpending}}</span> <span
                  class="dot-shape bg-warning"></span><span class="invoice-list__label">Payment Pending</span>
              </li>
              <li class="invoice-list__item">
                <span class="invoice-list__number">{{OrderStatus.orderpreparing}}</span> <span
                  class="dot-shape bg-info"></span><span class="invoice-list__label">Preparing</span>
              </li>
              <li class="invoice-list__item">
                <span class="invoice-list__number">{{OrderStatus.rejectedorder}}</span> <span
                  class="dot-shape bg-danger"></span><span class="invoice-list__label">Rejected</span>
              </li>
              <li class="invoice-list__item">
                <span class="invoice-list__number">{{OrderStatus.preparedorder}}</span> <span
                  class="dot-shape bg-light-cyan"></span><span class="invoice-list__label">Prepared</span>
              </li>
              <li class="invoice-list__item">
                <span class="invoice-list__number">{{OrderStatus.pickedup}}</span> <span
                  class="dot-shape bg-light-primary"></span><span class="invoice-list__label">Pickup</span>
              </li>
              <li class="invoice-list__item">
                <span class="invoice-list__number">{{OrderStatus.ontheway}}</span> <span
                  class="dot-shape bg-info"></span><span class="invoice-list__label">On Way</span>
              </li>
              <li class="invoice-list__item">
                <span class="invoice-list__number">{{OrderStatus.delivered}}</span> <span
                  class="dot-shape bg-success"></span><span class="invoice-list__label">Delivered</span>
              </li>
              <li class="invoice-list__item">
                <span class="invoice-list__number">{{OrderStatus.cancalledorders}}</span> <span
                  class="dot-shape bg-danger"></span><span class="invoice-list__label">Canceled</span>
              </li>
            </ul>
          </div>
          <!-- /media body -->

        </div>
        <!-- /media -->

      </div>
      <!-- /card body -->

    </div>
    <!-- /card -->

  </div>
  <!-- /grid item -->

</div>
<!-- /grid -->

<!-- Grid -->
<div class="row" *ngIf="isAdmin">
  <!-- Grid Item -->
  <div class="col-xl-12 order-xl-12">

      <!-- Card -->
      <div class="dt-card">

          <!-- Card Header -->
          <div class="dt-card__header bg-primary pb-7 mb-0 rounded-top">

              <!-- Card Heading -->
              <div class="dt-card__heading">
                  <div class="d-flex align-items-center mb-3">
                      <i class="icon icon-invoice-new icon-fw icon-2x text-white mr-2"></i>
                      <h3 class="dt-card__title text-white">Unassigned Orders</h3>
                  </div>
                  <!-- <p class="f-16 text-white mb-0">25400 Invoice Generated</p> -->
              </div>
              <!-- /card heading -->
          </div>
          <!-- /card header -->

          <!-- Card Body -->
          <div class="dt-card__body p-0 ps-custom-scrollbar ps">

              <!-- Widget-->
              <div class="dt-widget dt-widget-xl dt-widget-separator dt-widget-hover">
                  <div *ngIf="OrderStatus.notassigndriver?.length > 0 ; else noResultsOrder">
                      <!-- Widget Item -->
                      <div class="dt-widget__item" *ngFor="let unassign of OrderStatus.notassigndriver">

                          <!-- Shape -->
                          <div class="dot-shape dot-shape-lg bg-warning mx-5"></div>
                          <!-- /shape -->

                          <!-- Widget Info -->
                          <div class="dt-widget__info align-text-top">
                              <a class="dt-widget__title"
                                  [routerLink]="['/admin/orders/assignOrder/', unassign._id]">Order Amount:
                                  {{unassign.total_paid | currency:'CAD':'symbol-narrow'}}</a>
                              <p class="mb-0">Order Id: {{unassign.orderId}}</p>
                              <p class="mb-0">Restaurant: {{unassign.restId.rest_name}} -
                                  {{unassign.restId.rest_address}}</p>
                              <p class="mb-0">Delivery Charges: {{unassign.del_charges |
                                  currency:'CAD':'symbol-narrow'}}</p>
                              <p class="mb-0">Tip Amount: {{unassign.tip_amount | currency:'CAD':'symbol-narrow'}}</p>
                              <p class="mb-0">Delivery Address: {{unassign.addressId.address_full}}</p>
                          </div>
                          <!-- /widget info -->
                          <!-- Widget Extra -->
                          <div class="dt-widget__extra">
                              <div class="dt-task">
                                  <div class="dt-task__number">Assign Order</div>
                                  <a class="dt-task__redirect"
                                      [routerLink]="['/admin/orders/assignOrder/', unassign._id]"> <i
                                          class="icon icon-open-new-tab"></i>
                                  </a>
                              </div>
                          </div>
                          <!-- /widget extra -->
                      </div>
                      <!-- /widget item -->
                  </div>
                  <ng-template #noResultsOrder class="dt-widget__item">
                      <p class="p-2">No results</p>
                  </ng-template>

              </div>
              <!-- /widget-->

          </div>
          <!-- /card body -->

      </div>
      <!-- /card -->
  </div>
  <!-- /grid item -->
</div>
<!-- /grid-->

<!-- Grid -->
<div class="row" *ngIf="isAdmin">
  <!-- Grid Item -->
  <div class="col-xl-6 order-xl-3">

    <!-- Card -->
    <div class="dt-card">

      <!-- Card Header -->
      <div class="dt-card__header mb-4">

        <!-- Card Heading -->
        <div class="dt-card__heading">
          <h3 class="dt-card__title">New Restaurant Request</h3>
        </div>
        <!-- /card heading -->

        <!-- Card Tools -->
        <div class="dt-card__tools">
          <a [routerLink]="['/admin/restaurant/listaggreement']" class="dt-card__more">See All</a>
        </div>
        <!-- /card tools -->

      </div>
      <!-- /card header -->

      <!-- Card Body -->
      <div class="dt-card__body p-0 max-h-350 ps-custom-scrollbar ps">

        <!-- Widget-->
        <div class="dt-widget dt-widget-xl dt-widget-separator dt-widget-hover">
          <div *ngIf="pendingRestaurantList?.length > 0 ; else noResultsRest">
            <!-- Widget Item -->
            <div class="dt-widget__item" *ngFor="let restPending of pendingRestaurantList">

              <div class="dt-widget__item widget-padding" *ngIf="restPending.current_status=='Pending'">
                <!-- Shape -->
                <div class="dot-shape dot-shape-lg bg-warning mx-5"></div>
                <!-- /shape -->

                <!-- Widget Info -->
                <div class="dt-widget__info align-text-top">
                  <a class="dt-widget__title" href="javascript:void(0)">{{restPending.rest_name}}</a>
                  <p class="mb-0">{{restPending.rest_phone}}</p>
                  <p class="mb-0">{{restPending.rest_city.city_name}} - {{restPending.rest_address}}</p>
                </div>
                <!-- /widget info -->
                <!-- Widget Extra -->
                <div class="dt-widget__extra">
                  <div class="dt-task">
                    <div class="dt-task__number">Send aggreement</div>
                    <a class="dt-task__redirect" [routerLink]="['/admin/restaurant/aggreement/', restPending._id]"> <i
                        class="icon icon-open-new-tab"></i>
                    </a>
                  </div>
                </div>
                <!-- /widget extra -->
              </div>
              <div class="dt-widget__item widget-padding" *ngIf="restPending.current_status=='Agreement Sent'">
                <!-- Shape -->
                <div class="dot-shape dot-shape-lg bg-info mx-5"></div>
                <!-- /shape -->

                <!-- Widget Info -->
                <div class="dt-widget__info align-text-top">
                  <a class="dt-widget__title" href="javascript:void(0)">{{restPending.rest_name}}</a>
                  <p class="mb-0">{{restPending.rest_phone}}</p>
                  <p class="mb-0">{{restPending.rest_city.city_name}} - {{restPending.rest_address}}</p>
                </div>
                <!-- /widget info -->
                <!-- Widget Extra -->
                <div class="dt-widget__extra">
                  <div class="dt-task">
                    <div class="dt-task__number">Approve/Disapprove</div>
                    <a class="dt-task__redirect" [routerLink]="['/admin/restaurant/edit/', restPending._id]"> <i
                        class="icon icon-open-new-tab"></i>
                    </a>
                  </div>
                </div>
                <!-- /widget extra -->
              </div>
            </div>
            <!-- /widget item -->
          </div>
          <ng-template #noResultsRest class="dt-widget__item">
            <p class="p-2">No results</p>
          </ng-template>

        </div>
        <!-- /widget-->

      </div>
      <!-- /card body -->

    </div>
    <!-- /card -->
  </div>
  <!-- /grid item -->
  <!-- Grid Item -->
  <div class="col-xl-6 order-xl-3">

    <!-- Card -->
    <div class="dt-card">

      <!-- Card Header -->
      <div class="dt-card__header mb-4">

        <!-- Card Heading -->
        <div class="dt-card__heading">
          <h3 class="dt-card__title">New Driver Request</h3>
        </div>
        <!-- /card heading -->

        <!-- Card Tools -->
        <div class="dt-card__tools">
          <a [routerLink]="['/admin/driver/listaggreement']" class="dt-card__more">See All</a>
        </div>
        <!-- /card tools -->

      </div>
      <!-- /card header -->

      <!-- Card Body -->
      <div class="dt-card__body p-0 max-h-350 ps-custom-scrollbar ps">

        <!-- Widget-->
        <div class="dt-widget dt-widget-xl dt-widget-separator dt-widget-hover">
          <div *ngIf="restPendingDriver?.length > 0 ; else noResults">
            <!-- Widget Item -->
            <div class="dt-widget__item" *ngFor="let pending of restPendingDriver">
              <div class="dt-widget__item widget-padding" *ngIf="pending.current_status=='Registered'">
                <!-- Shape -->
                <div class="dot-shape dot-shape-lg bg-warning mx-5"></div>
                <!-- /shape -->

                <!-- Widget Info -->
                <div class="dt-widget__info align-text-top">
                  <a class="dt-widget__title" href="javascript:void(0)">{{pending.driver_name}}</a>
                  <p class="mb-0">{{pending.driver_email}}</p>
                  <p class="mb-0">{{pending.driver_phone}}</p>
                </div>
                <!-- /widget info -->
                <!-- Widget Extra -->
                <div class="dt-widget__extra">
                  <div class="dt-task">
                    <div class="dt-task__number">Send aggreement</div>
                    <a class="dt-task__redirect" [routerLink]="['/admin/driver/aggreement/', pending._id]"> <i
                        class="icon icon-open-new-tab"></i>
                    </a>
                  </div>
                </div>
                <!-- /widget extra -->
              </div>
              <div class="dt-widget__item widget-padding" *ngIf="pending.current_status=='Agreement Sent'">
                <!-- Shape -->
                <div class="dot-shape dot-shape-lg bg-info mx-5"></div>
                <!-- /shape -->

                <!-- Widget Info -->
                <div class="dt-widget__info align-text-top">
                  <a class="dt-widget__title" href="javascript:void(0)">{{pending.driver_name}}</a>
                  <p class="mb-0">{{pending.driver_email}}</p>
                  <p class="mb-0">{{pending.driver_phone}}</p>
                </div>
                <!-- /widget info -->
                <!-- Widget Extra -->
                <div class="dt-widget__extra">
                  <div class="dt-task">
                    <div class="dt-task__number">Approve/Disapprove</div>
                    <a class="dt-task__redirect" [routerLink]="['/admin/driver/verify/', pending._id]"> <i
                        class="icon icon-open-new-tab"></i>
                    </a>
                  </div>
                </div>
                <!-- /widget extra -->
              </div>
            </div>
            <!-- /widget item -->
          </div>
          <ng-template #noResults class="dt-widget__item">
            <p class="p-2">No results</p>
          </ng-template>
        </div>
        <!-- /widget-->

      </div>
      <!-- /card body -->

    </div>
    <!-- /card -->
  </div>
  <!-- /grid item -->
</div>
<!-- /grid-->

<!---------------------------Restaurant Dashboard---------------------->
<!-- Grid -->
<div class="row" *ngIf="!isAdmin">
  <!-- Grid Item -->
  <div class="col">
    <div class="dt-card bg-primary text-white">

      <!-- Card Header -->
      <div class="dt-card__header pt-6 px-6 mb-4">

        <!-- Card Heading -->
        <div class="dt-card__heading">
          <h3 class="dt-card__title text-white m-0">Current Status</h3>
        </div>
        <!-- /card heading -->

      </div>
      <!-- /card header -->

      <!-- Card Body -->
      <div class="dt-card__body px-6">
        <!-- Grid -->
        <div class="row no-gutters">
          <!-- Grid Item -->
          <div class="col-12 pr-2 text-center">
            <div class="font-weight-500 mb-3">
              <a href="javascript:void(0)" class="btn btn-secondary btn-xs text-uppercase">
                <span class="display-4">{{restVariousCount.currentreststatus? 'ON':'OFF'}}</span>
              </a>
            </div>
            <small>You can Log-In in app to change your Status</small>
          </div>
          <!-- /grid item -->
        </div>
        <!-- /grid -->
      </div>
      <!-- /card body -->

    </div>
  </div>
  <!-- /grid item -->
  <!-- Grid Item -->
  <div class="col">
    <!-- Card -->
    <div class="dt-card">
      <!-- Card Body -->
      <div class="dt-card__body d-flex flex-sm-column fix-height-div">
        <div class="mb-sm-7 mr-7 mr-sm-0">
          <i class="icon icon-users dt-icon-bg bg-primary text-primary"></i>
        </div>
        <div class="flex-1">
          <div class="d-flex align-items-center mb-2">
            <span class="h2 mb-0 font-weight-500 mr-2">{{restVariousCount.totalorders}}</span>
          </div>
          <div class="h5 mb-2">Total Orders</div>
        </div>
      </div>
      <!-- /card body -->
    </div>
    <!-- /card -->
  </div>
  <!-- /grid item -->

  <!-- Grid Item -->
  <div class="col">
    <!-- Card -->
    <div class="dt-card">

      <!-- Card Body -->
      <div class="dt-card__body d-flex flex-sm-column fix-height-div">
        <div class="mb-sm-7 mr-7 mr-sm-0">
          <i class="icon icon-company dt-icon-bg bg-success text-success"></i>
        </div>
        <div class="flex-1">
          <div class="d-flex align-items-center mb-2">
            <span class="h2 mb-0 font-weight-500 mr-2">{{restVariousCount.totalproduct}}</span>
          </div>
          <div class="h5 mb-2">Total Products</div>
        </div>
      </div>
      <!-- /card body -->

    </div>
    <!-- /card -->
  </div>
  <!-- /grid item -->

  <!-- Grid Item -->
  <div class="col">
    <!-- Card -->
    <div class="dt-card">

      <!-- Card Body -->
      <div class="dt-card__body d-flex flex-sm-column fix-height-div">
        <div class="mb-sm-7 mr-7 mr-sm-0">
          <i class="icon icon-customer dt-icon-bg bg-secondary text-secondary"></i>
        </div>
        <div class="flex-1">
          <div class="d-flex align-items-center mb-2">
            <span class="h2 mb-0 font-weight-500 mr-2">{{restVariousCount.totaldriver}}</span>
          </div>
          <div class="h5 mb-2">Total Drivers</div>
        </div>
      </div>
      <!-- /card body -->

    </div>
    <!-- /card -->
  </div>
  <!-- /grid item -->

  <!-- Grid Item -->
  <div class="col">
    <!-- Card -->
    <div class="dt-card">
      <!-- Card Body -->
      <div class="dt-card__body d-flex flex-sm-column fix-height-div">
        <div class="mb-sm-7 mr-7 mr-sm-0">
          <i class="icon icon-percentage dt-icon-bg bg-orange text-orange"></i>
        </div>
        <div class="flex-1">
          <div class="d-flex align-items-center mb-2">
            <span class="h2 mb-0 font-weight-500 mr-2">{{restVariousCount.totaloffer}}</span>
          </div>
          <div class="h5 mb-2">Active Offers</div>
        </div>
      </div>
      <!-- /card body -->
    </div>
    <!-- /card -->
  </div>
  <!-- /grid item -->
</div>
<!-- /grid -->

<!-- Grid -->
<div class="row" *ngIf="!isAdmin">

  <!-- Grid Item -->
  <div class="col-md-12 col-12">

    <!-- Card -->
    <div class="dt-card">

      <!-- Card Body -->
      <div class="dt-card__body p-xl-8 py-sm-8 py-6 px-4">

        <span class="badge badge-secondary badge-top-right">Orders</span>

        <!-- Media -->
        <div class="media">
          <!-- <i class="icon icon-orders bg-success text-success "></i> -->
          <i class="icon icon-orders dt-icon-bg bg-info text-info icon-5x mr-xl-5 mr-1 mr-sm-3 align-self-center"></i>
          <!-- Media Body -->
          <div class="media-body">
            <ul class="invoice-list">
              <li class="invoice-list__item">
                <span class="invoice-list__number">{{restCountOrder.pendingorder}}</span> <span
                  class="dot-shape bg-primary"></span><span class="invoice-list__label">Pending</span>
              </li>
              <li class="invoice-list__item">
                <span class="invoice-list__number">{{restCountOrder.orderpreparing}}</span> <span
                  class="dot-shape bg-warning"></span><span class="invoice-list__label">Cooking</span>
              </li>
              <li class="invoice-list__item">
                <span class="invoice-list__number">{{restCountOrder.preparedorder}}</span> <span
                  class="dot-shape bg-info"></span><span class="invoice-list__label">Cooked</span>
              </li>
              <li class="invoice-list__item">
                <span class="invoice-list__number">{{restCountOrder.ontheway}}</span> <span
                  class="dot-shape bg-light-pink"></span><span class="invoice-list__label">On-Way</span>
              </li>
              <li class="invoice-list__item">
                <span class="invoice-list__number">{{restCountOrder.delivered}}</span> <span
                  class="dot-shape bg-light-cyan"></span><span class="invoice-list__label">Delivered</span>
              </li>
              <li class="invoice-list__item">
                <span class="invoice-list__number">{{restCountOrder.cancalledorders}}</span> <span
                  class="dot-shape bg-danger"></span><span class="invoice-list__label">Canceled</span>
              </li>
            </ul>
          </div>
          <!-- /media body -->

        </div>
        <!-- /media -->

      </div>
      <!-- /card body -->

    </div>
    <!-- /card -->

  </div>
  <!-- /grid item -->

</div>
<!-- /grid -->
<!-- Grid -->
<div class="row" *ngIf="!isAdmin">
<!-- Grid Item -->
<div class="col-md-6 order-md-6">

  <!-- Card -->
  <div class="dt-card">

    <!-- Card Header -->
    <div class="dt-card__header bg-primary mb-0 rounded-top">

      <!-- Card Heading -->
      <div class="dt-card__heading">
        <div class="d-flex align-items-center mb-3">
          <i class="icon icon-invoice-new icon-fw icon-2x text-white mr-2"></i>
          <h3 class="dt-card__title text-white">Active Drivers = {{restActiveDriver.length}}</h3>
        </div>
        <!-- <p class="f-16 text-white mb-0">25400 Invoice Generated</p> -->
      </div>
      <!-- /card heading -->
    </div>
    <!-- /card header -->

    <!-- Card Body -->
    <div class="dt-card__body p-0 ps-custom-scrollbar ps">

      <!-- Widget-->
      <div class="dt-widget dt-widget-xl dt-widget-separator dt-widget-hover">
        <div *ngIf="restActiveDriver?.length > 0 ; else noResultsrestActiveDriver">
          <!-- Widget Item -->
          <div class="dt-widget__item  p-custom pl-1" *ngFor="let active of restActiveDriver">

            <!-- Shape -->
            <div class="dot-shape dot-shape-lg bg-success mr-1"></div>
            <!-- /shape -->

            <!-- Widget Info -->
            <div class="dt-widget__info align-text-top d-flex justify-content-between">
              <a class="dt-widget__title" href="javascript:void(0)">{{active.driver_name}}</a>
              <p class="mb-0">{{active.driver_email}}</p>
            </div>
            <!-- /widget info -->
          </div>
          <!-- /widget item -->
        </div>
        <ng-template #noResultsrestActiveDriver class="dt-widget__item">
          <p class="p-2">No Active Driver</p>
        </ng-template>

      </div>
      <!-- /widget-->

    </div>
    <!-- /card body -->

  </div>
  <!-- /card -->
</div>
<!-- /grid item -->
<!-- Grid Item -->
<div class="col-xl-6 order-xl-3">

  <!-- Card -->
  <div class="dt-card">

    <!-- Card Header -->
    <div class="dt-card__header mb-4">

      <!-- Card Heading -->
      <div class="dt-card__heading">
        <h3 class="dt-card__title">New Driver Request</h3>
      </div>
      <!-- /card heading -->

      <!-- Card Tools -->
      <div class="dt-card__tools">
        <a [routerLink]="['/restaurant/driver/view']" class="dt-card__more">See All</a>
      </div>
      <!-- /card tools -->

    </div>
    <!-- /card header -->

    <!-- Card Body -->
    <div class="dt-card__body p-0  ps-custom-scrollbar ps">

      <!-- Widget-->
      <div class="dt-widget dt-widget-xl dt-widget-separator dt-widget-hover">
        <div *ngIf="restPendingDriver?.length > 0 ; else noResults">
          <!-- Widget Item -->
          <div class="dt-widget__item" *ngFor="let pending of restPendingDriver">
            <div class="dt-widget__item widget-padding" *ngIf="pending.current_status=='Registered'">
              <!-- Shape -->
              <div class="dot-shape dot-shape-lg bg-warning mx-5"></div>
              <!-- /shape -->

              <!-- Widget Info -->
              <div class="dt-widget__info align-text-top">
                <a class="dt-widget__title" href="javascript:void(0)">{{pending.driver_name}}</a>
                <p class="mb-0">{{pending.driver_email}}</p>
                <p class="mb-0">{{pending.driver_phone}}</p>
              </div>
              <!-- /widget info -->
              <!-- Widget Extra -->
              <div class="dt-widget__extra">
                <div class="dt-task">
                  <div class="dt-task__number">Send aggreement</div>
                  <a class="dt-task__redirect" [routerLink]="['/admin/driver/aggreement/', pending._id]"> <i
                      class="icon icon-open-new-tab"></i>
                  </a>
                </div>
              </div>
              <!-- /widget extra -->
            </div>
            <div class="dt-widget__item widget-padding" *ngIf="pending.current_status=='Agreement Sent'">
              <!-- Shape -->
              <div class="dot-shape dot-shape-lg bg-info mx-5"></div>
              <!-- /shape -->

              <!-- Widget Info -->
              <div class="dt-widget__info align-text-top">
                <a class="dt-widget__title" href="javascript:void(0)">{{pending.driver_name}}</a>
                <p class="mb-0">{{pending.driver_email}}</p>
                <p class="mb-0">{{pending.driver_phone}}</p>
              </div>
              <!-- /widget info -->
              <!-- Widget Extra -->
              <div class="dt-widget__extra">
                <div class="dt-task">
                  <div class="dt-task__number">Approve/Disapprove</div>
                  <a class="dt-task__redirect" [routerLink]="['/admin/driver/verify/', pending._id]"> <i
                      class="icon icon-open-new-tab"></i>
                  </a>
                </div>
              </div>
              <!-- /widget extra -->
            </div>
          </div>
          <!-- /widget item -->
        </div>
        <ng-template #noResults class="dt-widget__item">
          <p class="p-2">No results</p>
        </ng-template>
      </div>
      <!-- /widget-->

    </div>
    <!-- /card body -->

  </div>
  <!-- /card -->
</div>
<!-- /grid item -->
</div>
<!-- /grid -->