import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from 'src/app/shared/category/category.service';
import { ProductService } from 'src/app/shared/product/product.service';
import { ProducttagService } from 'src/app/shared/producttag/producttag.service';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { RestcategoryService } from 'src/app/shared/restcategory/restcategory.service';
import { RestsubcategoryService } from 'src/app/shared/restsubcategory/restsubcategory.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-edit-mymenu',
  templateUrl: './edit-mymenu.component.html',
  styleUrls: ['./edit-mymenu.component.css']
})
export class EditMymenuComponent implements OnInit {

  formGroup = new FormGroup({
    _id: new FormControl('', [Validators.required]),
    product_name: new FormControl('', [Validators.required]),
    product_image: new FormControl(''),
    meta_keywords: new FormControl(''),
    updated_Id: new FormControl('', [Validators.required]),
    product_description: new FormControl(''),
    updated_by: new FormControl('', [Validators.required]),    
    restId: new FormControl('', [Validators.required]),   
    other_tags: new FormControl(''), 
    catId: new FormControl('',[Validators.required]), 
    restcatId: new FormControl('',[Validators.required]), 
    restsubcatId: new FormControl('',[Validators.required]), 
    veg_type: new FormControl('',[Validators.required]), 
  })

  gcategoryList = []
  categoryList = []
  subcategoryList = []
  tagList = []
  defaultTag = []
  other_tag = []
  vegOption = ''
  id=''

  constructor(private toast: ToastrService, private restaurant: RestaurantService, private router: Router, private tag: ProducttagService, private userdata: UserdataService, private category: RestcategoryService, private subcategory: RestsubcategoryService, private globalCat: CategoryService,private fb:FormBuilder,private product: ProductService,private activate : ActivatedRoute) { }

  ngOnInit(): void {
    var idd = this.userdata.getId()
    var updated_by = this.userdata.getUserType()
    this.formGroup.patchValue({ updated_Id: idd });
    this.formGroup.patchValue({ updated_by: updated_by });
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getDataById()
    this.getCategory()
    this.getGlobalCategory()
    this.getTag()
  }

  getDataById(){
    let body = new URLSearchParams();
    body.set('_id', this.id);
    this.product.getProductById(body).subscribe(
      (res:any) => {
        //console.log(res)
        this.formGroup.patchValue({_id: res.data._id}); 
        this.formGroup.patchValue({product_name: res.data.product_name}); 
        this.formGroup.patchValue({meta_keywords: res.data.meta_keywords}); 
        this.formGroup.patchValue({product_description: res.data.product_description});
        this.formGroup.patchValue({restId: res.data.restId._id}); 
        this.formGroup.patchValue({catId: res.data.catId._id}); 
        this.formGroup.patchValue({restcatId: res.data.restcatId._id}); 
        this.formGroup.patchValue({restsubcatId: res.data.restsubcatId._id}); 
        this.formGroup.patchValue({veg_type: res.data.veg_type}); 
        this.vegOption = res.data.veg_type
        this.f 
        res.data.other_tags.forEach(element => {
          this.defaultTag.push({'_id':element.producttagId._id,'producttag_name':element.producttagId.producttag_name})
          //console.log(this.defaultTag)
        });  
        this.getSubcategoryList()
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        this.router.navigateByUrl('/restaurant/menu/view')
      }
    )
  }

  getCategory() {
    this.category.listCategory({ 'restId': this.userdata.getId() }).subscribe(
      (res: any) => {
        this.categoryList = res.data 
      },
      err => {
        this.toast.error(err.error.message, 'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  }

  getSubcategoryList() {
    this.subcategory.listSubcategory({ 'restcatId': this.formGroup.get('restcatId').value, 'restId':this.userdata.getId() }).subscribe(
      (res: any) => {
        this.subcategoryList = res.data
      },
      err => {
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  getGlobalCategory() {
    let body = new URLSearchParams();
    body.set('status', 'Enabled');
    this.globalCat.listCategory({}).subscribe(
      (res:any) => {
        this.gcategoryList = res.data
      },
      err => {
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  }

  getTag() {
    this.tag.listTag({}).subscribe(
      (res: any) => {
        this.tagList = res.data
      },
      err => {
        //console.log(err)
      }
    )
  }

  get f() {
    return this.formGroup.controls
  }

  compareFn(c1: any, c2:any): boolean {     
    return c1 && c2 ? c1.id === c2.id : c1 === c2; 
  }

  public onFile(evnt){
    if(evnt.target.files.length > 0)
    {
      this.formGroup.patchValue({
        product_image: evnt.target.files[0]
      })
    }
  }


  public onsubmit(){
    //-------Other tags json------//
    var other = this.formGroup.get("other_tags").value
    other.forEach(element => {
      this.other_tag.push(element._id)
    });
    //-------/other tags json-----//
    const data = new FormData()
    data.append("_id",this.formGroup.get("_id").value)
    data.append("product_name",this.formGroup.get("product_name").value)
    data.append("product_image",this.formGroup.get("product_image").value)
    data.append("meta_keywords",this.formGroup.get("meta_keywords").value)
    data.append("product_description",this.formGroup.get("product_description").value)
    data.append("updated_by",this.formGroup.get("updated_by").value)
    data.append("other_tags",JSON.stringify(this.other_tag))
    data.append("updated_Id",this.formGroup.get("updated_Id").value)
    data.append("restId",this.formGroup.get("restId").value)
    data.append("catId",this.formGroup.get("catId").value)
    data.append("restcatId",this.formGroup.get("restcatId").value)
    data.append("restsubcatId",this.formGroup.get("restsubcatId").value)
    data.append("veg_type",this.formGroup.get("veg_type").value)
    this.product.restUpdateProduct(data).subscribe(
      (res:any) => {
        // console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/restaurant/menu/view')
      },
      err => {
        // console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
