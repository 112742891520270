<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/admin/menu/approve']">Approve Menu</a></li>
        <li class="active breadcrumb-item">Details</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Tag</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-6" *ngIf="!rows.is_new_added">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <div class="col-12 mb-4"><h3 class="dt-card__title float-left">Old Data</h3></div><br/>
                    <div class="col-6"><img [src]="getSanitizerUrl(originalprod.product_image)" class="img img-fluid img-circle" height="100" width="100"></div>
                    <div class="col-6"><h3 class="dt-card__title float-left">{{originalprod.product_name}}</h3></div>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">
                <table class="table table-borderless">
                    <tr *ngIf="!rows.is_addon_update">
                        <th>Category Name</th>
                        <th>Restaurant Name</th>
                        <th>Restaurant Category</th>
                        <th>Restaurant Subcategory</th>
                    </tr>
                    <tr *ngIf="!rows.is_addon_update">
                    <td *ngIf="originalprod.catId">{{originalprod.catId.category_name}}</td>
                        <td *ngIf="originalprod.restId">{{originalprod.restId.rest_name}}</td>
                        <td *ngIf="originalprod.restcatId">{{originalprod.restcatId.rest_cat_name}}</td>
                        <td *ngIf="originalprod.restsubcatId">{{originalprod.restsubcatId.restsubcategory_name}}</td>
                    </tr>
                    <tr *ngIf="!rows.is_addon_update"><td colspan="4"><hr></td></tr>
                    <tr *ngIf="!rows.is_addon_update">
                        <th>Product Description</th>
                        <th>Meta Keywords</th>
                        <th>Service Type</th>
                        <th>Food Type</th>
                    </tr>
                    <tr *ngIf="!rows.is_addon_update">
                        <td>{{originalprod.product_description}}</td>
                        <td>{{originalprod.meta_keywords}}</td>
                        <td>{{originalprod.service_type}}</td>
                        <td>{{originalprod.veg_type}}</td>
                    </tr>
                    <tr *ngIf="!rows.is_addon_update"><td colspan="4"><hr></td></tr>
                    <tr>
                        <th colspan="4">Other Tags</th>
                    </tr>
                    <tr *ngIf="!rows.is_addon_update">
                        <td colspan="4">
                            <span *ngFor="let tag of originalprod.other_tags" class="badge badge-pill badge-info mr-1">
                                {{tag.producttagId.producttag_name}}
                            </span>
                        </td>
                    </tr>
                    <tr *ngIf="!rows.is_addon_update"><td colspan="4"><hr></td></tr>
                    <tr *ngIf="rows.is_addon_update">
                        <th colspan="4">Add-Ons&nbsp;</th>
                    </tr>
                    <tr *ngIf="rows.is_addon_update">
                        <td colspan="4">
                            <div *ngFor="let add of originalprod.addon" class="mb-1">
                                {{add.addontype}} <br/>
                                <span *ngFor="let addsub of add.addonvalue" class="badge badge-pill badge-primary mr-1">
                                    {{addsub.valuename}}-{{addsub.valuedetail}} 
                                </span>                                
                            </div>
                        </td>
                    </tr>
                </table>
                
            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->
    <!-- Grid Item-->
    <div class="col-6">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">
    
            <!-- Card Header -->
            <div class="dt-card__header">
    
                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <div class="col-12 mb-4"><h3 class="dt-card__title float-left">Updated Data</h3></div><br/>
                    <div class="col-6"><img [src]="getSanitizerUrl(rows.product_image)" class="img img-fluid img-circle" height="100" width="100"></div>
                    <div class="col-6"><h3 class="dt-card__title float-left">{{rows.product_name}}</h3></div>
                </div>
                <!-- /card heading -->
    
            </div>
            <!-- /card header -->
    
            <!-- Card Body -->
            <div class="dt-card__body">
                <table class="table table-borderless">
                    <tr *ngIf="!rows.is_addon_update">
                        <th>Category Name</th>
                        <th>Restaurant Name</th>
                        <th>Restaurant Category</th>
                        <th>Restaurant Subcategory</th>
                    </tr>
                    <tr *ngIf="!rows.is_addon_update">
                        <td>
                            <span *ngIf="rows.catId">{{rows.catId.category_name}}</span>
                            <span *ngIf="!rows.catId">--</span>
                        </td>
                        <td>
                            <span *ngIf="rows.restId">{{rows.restId.rest_name}}</span>
                            <span *ngIf="!rows.restId">--</span>
                            <!-- {{rows.restId.rest_name}} -->
                        </td>
                        <td>
                            <span *ngIf="rows.restcatId">{{rows.restcatId.rest_cat_name}}</span>
                            <span *ngIf="!rows.restcatId">--</span>
                            <!-- {{rows.restcatId.rest_cat_name}} -->
                        </td>
                        <td>
                            <span *ngIf="rows.restsubcatId">{{rows.restsubcatId.restsubcategory_name}}</span>
                            <span *ngIf="!rows.restsubcatId">--</span>
                            <!-- {{rows.restsubcatId.restsubcategory_name}} -->
                        </td>
                    </tr>
                    <tr *ngIf="!rows.is_addon_update"><td colspan="4"><hr></td></tr>
                    <tr *ngIf="!rows.is_addon_update">
                        <th>Product Description</th>
                        <th>Meta Keywords</th>
                        <th>Service Type</th>
                        <th>Food Type</th>
                    </tr>
                    <tr *ngIf="!rows.is_addon_update">
                        <td>{{rows.product_description}}</td>
                        <td>{{rows.meta_keywords}}</td>
                        <td>{{rows.service_type}}</td>
                        <td>{{rows.veg_type}}</td>
                    </tr>
                    <tr *ngIf="!rows.is_addon_update"><td colspan="4"><hr></td></tr>
                    <tr *ngIf="!rows.is_addon_update">
                        <th colspan="4">Other Tags</th>
                    </tr>
                    <tr *ngIf="!rows.is_addon_update">
                        <td colspan="4" *ngIf="rows.other_tags?.length >0">
                            <span *ngFor="let tag of rows.other_tags" class="badge badge-pill badge-info mr-1">
                                {{tag.producttagId.producttag_name}}
                            </span>
                            <span *ngIf="rows.other_tags.length <=0">--</span>
                        </td>
                    </tr>
                    <tr *ngIf="!rows.is_addon_update || rows.is_new_added"><td colspan="4"><hr></td></tr>
                    <tr *ngIf="rows.is_addon_update || rows.is_new_added">
                        <th colspan="4">Add-Ons&nbsp;</th>
                    </tr>
                    <tr *ngIf="rows.is_addon_update || rows.is_new_added">
                        <td colspan="4">
                            <div *ngFor="let add of rows.addon" class="mb-1">
                                {{add.addontype}} <br/>
                                <span *ngFor="let addsub of add.addonvalue" class="badge badge-pill badge-primary mr-1">
                                    {{addsub.valuename}}-{{addsub.valuedetail | currency:'CAD':'symbol-narrow'}} 
                                </span>                                
                            </div>
                            <span *ngIf="rows.addon.length <=0">--</span>
                        </td>
                    </tr>
                    <!-- <tr><td colspan="4"><hr></td></tr>
                    <tr>
                        <th>Added By</th>
                        <td>{{rows.added_by}}</td>
                        <th>Updated By</th>
                        <td>{{rows.updated_by}}</td>
                    </tr> -->
                </table>
                
            </div>
            <!-- /card body -->
    
        </div>
        <!-- /card -->
    
    </div>
    <!--/grid item-->
</div>
<!-- /grid --> 