import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable'; 
import { UserdataService } from 'src/app/shared/userdata.service';
import { CustomizeService } from 'src/app/shared/customize/customize.service';

@Component({
  selector: 'app-listslider',
  templateUrl: './listslider.component.html',
  styleUrls: ['./listslider.component.css']
})
export class ListsliderComponent implements OnInit {

  imageURL = ''
  constructor(private toast: ToastrService,private customize: CustomizeService,private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl, private userdata:UserdataService) { 
    this.imageURL = _imageurl
  }

  p: number = 1;
  term: string;
  temp = [];
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]
  columns = [{ prop: 'Title' }, { name: 'Image' }, { name: 'Action' }];
  ngOnInit(): void {
    // for page number 1 as offset starts with 0
    //this.table.offset = 0;
    this.getSliderList()
    
  }

  getSliderList(){
    this.customize.listSlider({userId:this.userdata.getId()}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.rows = res.banners[0].bannerInfo
        this.temp = this.rows
        console.log(res)
      },
      err => {
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  public getSanitizerUrl(photoname){
    return this.trusturl.bypassSecurityTrustUrl(this.imageURL + photoname)
  }  

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.title.toLowerCase().indexOf(val) !== -1 ||
      !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  } 

  deleteSliderList(id){
    this.customize.deleteSlider({'userId':this.userdata.getId(),'_id':id}).subscribe(
      (res:any) => {
        this.getSliderList()
        this.toast.success(res.message,'Success')
      },
      err => {
        this.toast.error(err.error.message,'Error')
      }
    )
  }
}
