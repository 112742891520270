import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'src/app/shared/product/product.service';
import { UserdataService } from 'src/app/shared/userdata.service';
import Swal from 'src/assets/node_modules/sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-detail-mymenu',
  templateUrl: './detail-mymenu.component.html',
  styleUrls: ['./detail-mymenu.component.css']
})
export class DetailMymenuComponent implements OnInit {

  id = ""
  rows: any = ""
  imageURL = ''
  constructor(private toast: ToastrService, private product: ProductService, private userdata: UserdataService, private activate: ActivatedRoute, private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl) {
    this.imageURL = _imageurl
  }

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getSingleProduct()
  }

  public getSanitizerUrl(photoname) {
    return this.trusturl.bypassSecurityTrustUrl(this.imageURL + photoname)
  }

  getSingleProduct() {
    let body = new URLSearchParams()
    body.set("_id", this.id)
    this.product.getProductById(body).subscribe(
      (res: any) => {
        //this.toast.success(res.message,'Success')
        this.rows = res.data
      },
      err => {
        console.log(err)
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  deleteAddOn(product, id, type) {
    Swal.fire({
      title: 'Are you sure you want to delete this?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.product.deleteAddonTag({ '_id': product, 'addonId': id, 'deletetype': type }).subscribe(
          (res: any) => {
            this.toast.success(res.message, 'Success')
            this.getSingleProduct()
          },
          err => {
            //console.log(err)
            this.toast.error(err.error.message, 'Error')
            this.getSingleProduct()
          }
        )
      }
    })
  }

}
