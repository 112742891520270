import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CustomizeService } from 'src/app/shared/customize/customize.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-list-support-questions',
  templateUrl: './list-support-questions.component.html',
  styleUrls: ['./list-support-questions.component.css']
})
export class ListSupportQuestionsComponent implements OnInit {

  constructor(private customize: CustomizeService, private toast: ToastrService,private userdata: UserdataService) { }
  
  dataList :any = ''
  ngOnInit(): void {
    this.getSupportQuestion()
  }

  getSupportQuestion(){
    this.customize.listSupportQuestion({'customization_type':8,'userId':this.userdata.getId()}).subscribe(
      (res:any) => {
        this.dataList = res.toprest.other_points
        //console.log(this.row)
      },
      err => {
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  deleteSupportQuestion(id){
    this.customize.deleteSupportQuestion({'customization_type':8,'userId':this.userdata.getId(),'_id':id}).subscribe(
      (res:any) => {
        this.dataList = res.toprest.other_points
        this.getSupportQuestion()
        //console.log(this.rows)  
      },
      err => {
        this.toast.error(err.error.message,'Error')
        this.getSupportQuestion()
      }
    )
  }

}
