import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'src/app/shared/product/product.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-detailapprove',
  templateUrl: './detailapprove.component.html',
  styleUrls: ['./detailapprove.component.css']
})
export class DetailapproveComponent implements OnInit {

  id=""
  rows:any=""
  imageURL = ''
  constructor(private toast: ToastrService,private product: ProductService, private userdata:UserdataService, private activate: ActivatedRoute,private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl) { 
    this.imageURL = _imageurl
  } 

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getApproveDetail()
  }

  public getSanitizerUrl(photoname){
    return this.trusturl.bypassSecurityTrustUrl(this.imageURL + photoname)
  } 

  originalprod:any=''
  getApproveDetail(){
    this.product.singleApproveProduct({"_id":this.id}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.rows = res.data
        this.originalprod = res.originalprod
        //console.log(res.data)
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
