
    <!-- Header container -->
    <div class="dt-header__container">
      <!-- Brand -->
      <div class="dt-brand">
        <!-- Brand tool -->
        <div class="dt-brand__tool" data-toggle="main-sidebar">
          <div class="hamburger-inner"></div>
        </div>
        <!-- /brand tool -->
        <!-- Brand logo --><span class="dt-brand__logo">
          <!-- <h2>Veg Food</h2> -->
      <a class="dt-brand__logo-link" [routerLink]="['/admin/home']">
        <img class="dt-brand__logo-symbol d-sm-none" src="../../../assets/assets/images/Green Logo.png" alt="Veggie Lyfe">
        <img class="dt-brand__logo-symbol d-none d-sm-inline-block" src="../../../assets/assets/images/Green Logo.png" alt="Veggie Lyfe">
      </a>
    </span>
        <!-- /brand logo -->
      </div>
      <!-- /brand -->
      <!-- Header toolbar-->
      <div class="dt-header__toolbar">
        <!-- Header Menu Wrapper -->
        <div class="dt-nav-wrapper">
          <!-- Header Menu -->
          <ul class="dt-nav d-lg-none">
            <li class="dt-nav__item dt-notification-search dropdown">
              <!-- Dropdown Link -->
              <a href="#" class="dt-nav__link dropdown-toggle no-arrow" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="icon icon-search icon-fw icon-lg"></i> </a>
              <!-- /dropdown link -->
              <!-- Dropdown Option -->
              <div class="dropdown-menu">
                <!-- Search Box -->
                <form class="search-box right-side-icon">
                  <input class="form-control form-control-lg" type="search" placeholder="Search in app...">
                  <button type="submit" class="search-icon"><i class="icon icon-search icon-lg"></i></button>
                </form>
                <!-- /search box -->
              </div>
              <!-- /dropdown option -->
            </li>
          </ul>
          <!-- /header menu -->
         
          <!-- Header Menu -->
          <ul class="dt-nav">
            <li class="dt-nav__item dropdown">
              <!-- Dropdown Link -->
              <a href="#" class="dt-nav__link dropdown-toggle no-arrow dt-avatar-wrapper" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <img class="dt-avatar size-30" src="https://via.placeholder.com/150x150" alt="Domnic Harris"> <span class="dt-avatar-info d-none d-sm-block">
              <span class="dt-avatar-name">{{name}}</span> </span>
              </a>
              <!-- /dropdown link -->
              <!-- Dropdown Option -->
              <div class="dropdown-menu dropdown-menu-right">
                <div class="dt-avatar-wrapper flex-nowrap p-6 mt-n2 bg-gradient-purple text-white rounded-top"> <img class="dt-avatar" src="https://via.placeholder.com/150x150" alt="Domnic Harris"> <span class="dt-avatar-info">
                <span class="dt-avatar-name">{{name}}</span> <span class="f-12">{{usertype}}</span> </span>
                </div>
                <a class="dropdown-item" href="javascript:void(0)" (click)="logout()"> <i class="icon icon-editors icon-fw mr-2 mr-sm-1"></i>Logout </a>
              </div>
              <!-- /dropdown option -->
            </li>
          </ul>
          <!-- /header menu -->
        </div>
        <!-- Header Menu Wrapper -->
      </div>
      <!-- /header toolbar -->
    </div>
    <!-- /header container -->