<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="active breadcrumb-item">Driver</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Manager</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title float-left">View Driver New Request</h3>
                    <a class="btn btn-primary float-right" [routerLink]="['/admin/driver/add']">Add</a>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Tables -->
                <input
                type="text" class="form-control search-input"
                placeholder="Type to filter the name column..."
                (keyup)="updateFilter($event)"
                />
                <ngx-datatable
                #table
                class="material"
                [columns]="columns"
                [columnMode]="ColumnMode.force"
                [headerHeight]="50"
                [footerHeight]="50"
                rowHeight="auto"
                [limit]="10"
                [rows]="rows"
                #myFilterTable
                >
                    <ngx-datatable-column name="Restaurant" prop="restId.rest_name">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.restId.rest_name}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Name" prop="driver_name">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.driver_name}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Email" prop="driver_email">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.driver_email}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Phone" prop="driver_phone">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.driver_phone}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Address" prop="driver_address">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{row.driver_address}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Status">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span class="badge badge-pill badge-warning" *ngIf="row.current_status=='Registered'">
                                New Request
                            </span>
                            <span class="badge badge-pill badge-info" *ngIf="row.current_status=='Application Updated'">
                                Application Upd.
                            </span>
                        </ng-template>
                    </ngx-datatable-column>
                    
                    <ngx-datatable-column name="Action">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <a [routerLink]="['/admin/driver/details/', row._id]" title="Driver Details"><i class="icon icon-eye icon-fw icon-lg text-success"></i></a><a [routerLink]="['/admin/driver/verify/', row._id]" title="Edit Driver Status" ><i class="icon icon-editors icon-fw icon-lg icon-edit"></i></a>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
                <!-- /tables -->

            </div>
            <!-- /card body -->
        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->