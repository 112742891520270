<!-- Page Header -->
<!-- <div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/admin/orders/all']">Orders</a></li>
        <li class="active breadcrumb-item">Cancelled & Rejected Orders</li>
    </ol>
</div> -->
<!-- /page header -->
<!-- Grid --> 
<div class="row">
    <!-- Grid Item -->
    <!-- <div class="col-xl-12">
        <h3 class="dt-card__title float-left">View Cancelled & Rejected Orders</h3>
    </div> -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">
            <!-- Card Body -->
            <div class="dt-card__body">

                 <!-- Tables --> 
                 <input
                 type="text" class="form-control search-input"
                 placeholder="Type to filter the name column..."
                 (keyup)="updateFilter($event)"
                 />
                 <ngx-datatable
                 #table
                 class="material"
                 [columns]="columns"
                 [columnMode]="ColumnMode.force"
                 [headerHeight]="50"
                 [footerHeight]="50"
                 rowHeight="auto"
                 [limit]="10"
                 [rows]="rows"
                 #myFilterTable
                 >
                     <ngx-datatable-column name="Restaurant Name" prop="restId.rest_name">
                         <ng-template let-row="row" ngx-datatable-cell-template>
                         {{row.restId.rest_name}}
                         </ng-template>
                     </ngx-datatable-column>
                     <ngx-datatable-column name="Order Id" prop="orderId">
                         <ng-template let-row="row" ngx-datatable-cell-template>
                         {{row.orderId}}
                         </ng-template>
                     </ngx-datatable-column>
                     <ngx-datatable-column name="Payment Type" prop="payment_type">
                         <ng-template let-row="row" ngx-datatable-cell-template>
                         {{row.payment_type}}
                         </ng-template>
                     </ngx-datatable-column>
                     <ngx-datatable-column name="Total" prop="total_paid">
                         <ng-template let-row="row" ngx-datatable-cell-template>
                         {{row.total_paid |currency:'CAD':'symbol-narrow'}}
                         </ng-template>
                     </ngx-datatable-column>
                     <ngx-datatable-column name="Driver" prop="driverId.driver_name">
                         <ng-template let-row="row" ngx-datatable-cell-template>
                         <span *ngIf="row.driverId">
                             {{row.driverId.driver_name}}
                         </span>
                         </ng-template>
                     </ngx-datatable-column>
                     <ngx-datatable-column name="Status">
                         <ng-template let-row="row" ngx-datatable-cell-template>
                             <span class="badge badge-pill badge-primary" *ngIf="row.order_current_status==101">
                                 Placed
                             </span>
                             <span class="badge badge-pill badge-danger" *ngIf="row.order_current_status==102">
                                 Payment Declined
                             </span>
                             <span class="badge badge-pill badge-warning" *ngIf="row.order_current_status==103">
                                 Cooking
                             </span>
                             <span class="badge badge-pill badge-danger" *ngIf="row.order_current_status==104">
                                 Rejected
                             </span>
                             <span class="badge badge-pill badge-warning" *ngIf="row.order_current_status==105">
                                 Cooked
                             </span>
                             <span class="badge badge-pill badge-info" *ngIf="row.order_current_status==106">
                                 Pickup
                             </span>
                             <span class="badge badge-pill badge-info" *ngIf="row.order_current_status==107">
                                 On Way
                             </span>
                             <span class="badge badge-pill badge-success" *ngIf="row.order_current_status==108">
                                 Delivered
                             </span>
                             <span class="badge badge-pill badge-danger" *ngIf="row.order_current_status==109">
                                 Canceled
                             </span>
                             <span class="badge badge-pill badge-danger" *ngIf="row.order_current_status==110">
                                 TimeOut
                             </span>
                             <span class="badge badge-pill badge-danger" *ngIf="row.order_current_status==111">
                                 Refunded
                             </span>
                             <span class="badge badge-pill badge-danger" *ngIf="row.order_current_status==112">
                                 Cancel & Refund
                             </span>
                         </ng-template>
                     </ngx-datatable-column>
                     <ngx-datatable-column name="Date" prop="created_at">
                         <ng-template let-row="row" ngx-datatable-cell-template>
                         {{transformDate(row.created_at)}}
                         </ng-template>
                     </ngx-datatable-column>
                     <ngx-datatable-column name="Action">
                         <ng-template let-row="row" ngx-datatable-cell-template>
                             <a [routerLink]="['/admin/orders/single/', row._id]" title="View Order"><i class="icon icon-eye icon-fw icon-lg icon-view"></i></a>
                             <a [routerLink]="['/admin/orders/update/', row._id]" title="Change Order Status"><i class="icon icon-editors icon-fw icon-lg icon-view"></i></a>
                             <a [routerLink]="['/admin/refund/', row._id]" title="Refund"><i class="icon icon-revenue2 icon-fw icon-lg icon-view" *ngIf="row.order_current_status==109 || row.order_current_status==104"></i></a>
                         </ng-template>
                     </ngx-datatable-column>
                 </ngx-datatable>
                 <!-- /tables -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->  