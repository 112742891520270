import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { RestcategoryService } from 'src/app/shared/restcategory/restcategory.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-approvecategory',
  templateUrl: './approvecategory.component.html',
  styleUrls: ['./approvecategory.component.css']
})
export class ApprovecategoryComponent implements OnInit {

  constructor(private toast: ToastrService,private category: RestcategoryService, private userdata: UserdataService) { }

  p: number = 1;
  term: string;
  temp = [];
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]
  columns = [{ name: 'Type' },{ prop: 'Restaurant Name' }, { name: 'Category Name' },{ name: 'New Name' }, { name: 'Status' }, { name: 'Added By' }, { name: 'Action' }];
  ngOnInit(): void {
    // for page number 1 as offset starts with 0
    //this.table.offset = 0;
    this.getCategoryList()
    
  }

  getCategoryList(){
    this.category.pendingApproveCategory({}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.rest_cat_name.toLowerCase().indexOf(val) !== -1 ||
      d.restId.rest_name.toLowerCase().indexOf(val) !== -1 ||
      d.status.toLowerCase().indexOf(val) !== -1 ||  !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  changeStatus(val,id,restId){
    var user_id = this.userdata.getId()
    var added = this.userdata.getUserType()
    let body = new URLSearchParams();
    body.set('status', val);
    body.set('_id', id);
    body.set('updated_Id', user_id);
    body.set('updated_by', added);
    body.set('restId', restId);
    this.category.updateCategory(body).subscribe(
      (res:any) => {
        this.toast.success(res.message,'Success')
        this.getCategoryList()
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
        this.getCategoryList()
      }
    )
  }

  approveOrReject(event,id){
    if(event == "true"){
      var val = true
    }else{
      var val = false
    }
    //console.log(val)
    this.category.approveCategory({'_id':id,'is_request_for_update':val}).subscribe(
      (res:any) => {
        this.toast.success(res.message,'Success')
        this.getCategoryList()
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
        this.getCategoryList()
      }
    )
  }

}
