import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata.service'; 

@Injectable({
  providedIn: 'root'
})
export class DriverService {

  BaseUrl = ''
  Token = ''

  constructor(private http: HttpClient, @Inject('BASE_URL') _base,private userdata: UserdataService) {
    this.BaseUrl = _base
    this.Token = userdata.getToken()
  }

  public addDriver(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/adddriverbysystem",form, { headers: header_object})
  }
  
  public listDriver(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/alldrivers",form,{ headers: header_object})
  } 

  public getDriverById(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/fetchdriverbyid",form, { headers: header_object})
  }

  public updateDriverStatus(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/updatedriverstatus", form, { headers: header_object})
  }

  public updateDriver(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/updatedriverbysystem", form, { headers: header_object})
  }

  public sendagreement(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/senddriveragreement", form, { headers: header_object})
  }

  public approveReject(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/approveorrejectdriver", form, { headers: header_object})
  }

  public driverOrder(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken()).set('Content-Type', 'application/x-www-form-urlencoded')
    return this.http.post(this.BaseUrl + "/api/allorderadmin",form.toString(), { headers: header_object})
  }

  public driverTransactions(form){//driverId--mandatory
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken()).set('Content-Type', 'application/x-www-form-urlencoded')
    return this.http.post(this.BaseUrl + "/api/driveralltxnhistory",form.toString(), { headers: header_object})
  }

  public driverPayout(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/addamountonorder",form, { headers: header_object})
  }

  public approveBank(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/approvedriverbank",form, { headers: header_object})
  }

  public cashInHand(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/getalldriverscashinhand",form, { headers: header_object})
  }

  public collectCash(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/addhandlingcashorupdate",form, { headers: header_object})
  }

  public cashInHandHistory(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/cashhandtxnhistory",form, { headers: header_object})
  }

  //------------------Restaurant Driver APIS
  public restAddDriver(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/rest/adddriverbyrest",form, { headers: header_object})
  }

  public restListDriver(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/rest/myrestdrivers",form,{ headers: header_object})
  } 

  public restUpdateDriverStatus(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/rest/updatemydriverstatus", form, { headers: header_object})
  }

  public restGetDriverById(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/rest/fetchmydriverbyid",form, { headers: header_object})
  }

  public restPendingDriver(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/rest/newdriverinmyrest",form, { headers: header_object})
  }

  public restApproveRejectDriver(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/rest/approveorrejectdriverbyrest",form, { headers: header_object})
  }
}
