import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserdataService } from 'src/app/shared/userdata.service'; 
import { SupportService } from 'src/app/shared/support/support.service';

@Component({
  selector: 'app-replydriverissue',
  templateUrl: './replydriverissue.component.html',
  styleUrls: ['./replydriverissue.component.css']
})
export class ReplydriverissueComponent implements OnInit {

  constructor(private activate: ActivatedRoute, private support: SupportService,private toast: ToastrService, private router:Router, private userdata: UserdataService) { }

  formGroup = new FormGroup({
    adminId: new FormControl('', [Validators.required]),
    issueId: new FormControl('', [Validators.required]),
    reply_by_system: new FormControl('',[Validators.required]),
    is_solved: new FormControl('',[Validators.required]),
    issue_title: new FormControl('',[Validators.required]),
  })

  id = ''
  adminId = ''
  isSolved = "true"
  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.adminId = this.userdata.getId()
    this.getDataById()
  }

  get f(){
    return this.formGroup.controls
  }

  getDataById(){
    this.support.singleDriverIssue({'adminId':this.adminId,'_id':this.id}).subscribe(
      (res:any) => {
        //console.log(res.result)    
        this.formGroup.patchValue({issue_title: res.data.issue_title});     
        this.formGroup.patchValue({issueId: res.data._id});     
        this.formGroup.patchValue({adminId: this.adminId});     
        // this.formGroup.patchValue({category_image: res.category_image});             
        this.f   
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        this.router.navigateByUrl('/admin/support/driverIssue/list')
      }
    )
  }

  public onsubmit(){
    this.support.replyDriverIssue(this.formGroup.value).subscribe(
      (res:any) => {
        // console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/support/driverIssue/list')
      },
      err => {
        // console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }
}
