import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TagService } from 'src/app/shared/tag/tag.service';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-listtag',
  templateUrl: './listtag.component.html',
  styleUrls: ['./listtag.component.css']
})
export class ListtagComponent implements OnInit {

  constructor(private toast: ToastrService,private tag: TagService) { }

  p: number = 1;
  term: string;
  temp = [];
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]
  columns = [{ prop: 'Name' }, { name: 'Status' }, { name: 'Added By' },
  { name: 'Action' }];
  ngOnInit(): void {
    // for page number 1 as offset starts with 0
    //this.table.offset = 0;
    this.getTagList()

  }


  public getRowIndex(row: any): number {
    return this.table.bodyComponent.getRowIndex(row);   // row being data object passed into the template
  }

  getTagList(){
    this.tag.listTag({}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.tag_name.toLowerCase().indexOf(val) !== -1 ||
      d.status.toLowerCase().indexOf(val) !== -1 ||
      d.added_by.toLowerCase().indexOf(val) !== -1 ||
      !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  changeStatus(val,id){
    //console.log(id)
    this.tag.updateTag({'_id':id,'status':val}).subscribe(
      (res:any) => {
        this.toast.success(res.message,'Success')
        this.getTagList()
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
        this.getTagList()
      }
    )
  }


}
