import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrdersService } from 'src/app/shared/orders/orders.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-refund-cod-order',
  templateUrl: './refund-cod-order.component.html',
  styleUrls: ['./refund-cod-order.component.css']
})
export class RefundCodOrderComponent implements OnInit {

  formGroup = new FormGroup({
    'orderId'   : new FormControl('',[Validators.required]),
    'is_cod'    : new FormControl(true,[Validators.required]),
    'refundmethod'  : new FormControl('Coupon',[Validators.required]),
    'is_coupon_refunded'  : new FormControl(true,[Validators.required]),
    'userId'  : new FormControl('',[Validators.required]),
    'solution_text'  : new FormControl('',[Validators.required]),
    'is_admin_bear_refund'  : new FormControl('',[Validators.required]),
  })
  constructor(private activate: ActivatedRoute, private userdata:UserdataService, private orders: OrdersService, private router:Router, private toast:ToastrService) { }

  id=''

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.formGroup.patchValue({'orderId':this.id})
    this.formGroup.patchValue({'userId':this.userdata.getId()})
  }

  get f(){
    return this.formGroup.controls
  }

  public onsubmit(){
    this.orders.refundOrder(this.formGroup.value).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/support/customerIssue/list')
      },err =>{
        this.toast.success(err.error.message,'Error') 
      }
    )
  }

}
