import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { ProducttagService } from 'src/app/shared/producttag/producttag.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-listproducttag',
  templateUrl: './listproducttag.component.html',
  styleUrls: ['./listproducttag.component.css']
})
export class ListproducttagComponent implements OnInit {

  constructor(private toast: ToastrService,private tag: ProducttagService, private userdata: UserdataService) { }

  p: number = 1;
  term: string;
  temp = [];
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]
  columns = [{ prop: 'Name' }, { name: 'Status' }, { name: 'Added By' },
  { name: 'Action' }];
  ngOnInit(): void {
    // for page number 1 as offset starts with 0
    //this.table.offset = 0;
    this.getTagList()

  }


  getTagList(){
    this.tag.listTag({}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  changeStatus(val,id){
    var user = this.userdata.getId()
    this.tag.updateTag({"status":val,"_id":id,"userId":user}).subscribe(
      (res:any) => {
        this.toast.success(res.message,'Success')
        this.getTagList()
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
        this.getTagList()
      }
    )
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.producttag_name.toLowerCase().indexOf(val) !== -1 ||
      d.status.toLowerCase().indexOf(val) !== -1 ||
      d.added_by.toLowerCase().indexOf(val) !== -1 ||
      !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

}
