<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/admin/category/view']">Category</a></li>
        <li class="active breadcrumb-item">Add</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Category</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title">Add Category</h3>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Form -->
                <form [formGroup]="formGroup" (submit)="onsubmit()">

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="category_name">Name</label>

                        <div class="col-xl-9">
                            <input type="text" class="form-control" id="category_name" placeholder="Enter Name" name="category_name" formControlName="category_name" [ngClass]="{'focus-red': unique>0}">
                            <small class="form-text" *ngIf="f.category_name.invalid && (f.category_name.dirty || f.category_name.touched)">
                                <small *ngIf="f.category_name.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="meta_desc">Meta Description</label>

                        <div class="col-xl-9">
                            <textarea class="form-control" id="meta_desc" placeholder="Enter Meta Description" name="meta_desc" formControlName="meta_desc"></textarea>
                            <small class="form-text" *ngIf="f.meta_desc.invalid && (f.meta_desc.dirty || f.meta_desc.touched)">
                                <small *ngIf="f.meta_desc.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="meta_keywords">Meta Keywords</label>

                        <div class="col-xl-9">
                            <textarea class="form-control" id="meta_keywords" placeholder="Enter Meta Keywords" name="meta_keywords" formControlName="meta_keywords"></textarea>
                            <small class="form-text" *ngIf="f.meta_keywords.invalid && (f.meta_keywords.dirty || f.meta_keywords.touched)">
                                <small *ngIf="f.meta_keywords.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="category_image">Image</label>

                        <div class="col-xl-9">
                            <input type="file" name="category_image" class="form-control" (change)="onFile($event)" formControlName="category_image">
                            <small class="form-text" *ngIf="f.category_image.invalid && (f.category_image.dirty || f.category_image.touched)">
                                <small *ngIf="f.category_image.errors.required">Invalid Value</small>
                            </small>
                            <small class="form-text">{{imageError}}</small>
                        </div>
                    </div>
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <div class="col-xl-9 offset-xl-3">
                            <button type="submit" class="btn btn-primary text-uppercase" [disabled]="!formGroup.valid">Save
                            </button>
                        </div>
                    </div>
                    <!-- /form group -->

                </form>
                <!-- /form -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->