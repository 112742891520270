import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TagInputModule } from 'ngx-chips';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';
import { FooterComponent } from './footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserComponent } from './user/user.component';
import { LoginComponent } from './user/login/login.component';
import { RegisterComponent } from './user/register/register.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AdduserComponent } from './user/adduser/adduser.component';
import { EdituserComponent } from './user/edituser/edituser.component';
import { ListuserComponent } from './user/listuser/listuser.component';
import { AddserviceComponent } from './service/addservice/addservice.component';
import { EditserviceComponent } from './service/editservice/editservice.component';
import { ListserviceComponent } from './service/listservice/listservice.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AddcuisineComponent } from './cuisine/addcuisine/addcuisine.component';
import { EditcuisineComponent } from './cuisine/editcuisine/editcuisine.component';
import { ListcuisineComponent } from './cuisine/listcuisine/listcuisine.component';
import { AddtagComponent } from './tag/addtag/addtag.component';
import { EdittagComponent } from './tag/edittag/edittag.component';
import { ListtagComponent } from './tag/listtag/listtag.component';
import { AddtimingComponent } from './timing/addtiming/addtiming.component';
import { EdittimingComponent } from './timing/edittiming/edittiming.component';
import { ListtimingComponent } from './timing/listtiming/listtiming.component';
import { ServiceComponent } from './service/service.component';
import { CuisineComponent } from './cuisine/cuisine.component';
import { TagComponent } from './tag/tag.component';
import { TimingComponent } from './timing/timing.component';
import { PasswordComponent } from './user/password/password.component';
import { CategoryComponent } from './category/category.component';
import { AddcategoryComponent } from './category/addcategory/addcategory.component';
import { EditcategoryComponent } from './category/editcategory/editcategory.component';
import { ListcategoryComponent } from './category/listcategory/listcategory.component';
import { CityComponent } from './city/city.component';
import { AddcityComponent } from './city/addcity/addcity.component';
import { EditcityComponent } from './city/editcity/editcity.component';
import { ListcityComponent } from './city/listcity/listcity.component';
import { RestaurantComponent } from './restaurant/restaurant.component';
import { AddrestaurantComponent } from './restaurant/addrestaurant/addrestaurant.component';
import { EditrestaurantComponent } from './restaurant/editrestaurant/editrestaurant.component';
import { ListrestaurantComponent } from './restaurant/listrestaurant/listrestaurant.component';
import { RestcategoryComponent } from './restcategory/restcategory.component';
import { AddrestcategoryComponent } from './restcategory/addrestcategory/addrestcategory.component';
import { EditrestcategoryComponent } from './restcategory/editrestcategory/editrestcategory.component';
import { ListrestcategoryComponent } from './restcategory/listrestcategory/listrestcategory.component';
import { RestsubcategoryComponent } from './restsubcategory/restsubcategory.component';
import { AddrestsubcategoryComponent } from './restsubcategory/addrestsubcategory/addrestsubcategory.component';
import { EditrestsubcategoryComponent } from './restsubcategory/editrestsubcategory/editrestsubcategory.component';
import { ListrestsubcategoryComponent } from './restsubcategory/listrestsubcategory/listrestsubcategory.component';
import { ApproverestaurantComponent } from './restaurant/approverestaurant/approverestaurant.component';
import { UpdateapplicationComponent } from './restaurant/updateapplication/updateapplication.component';
import { SendagreementComponent } from './restaurant/sendagreement/sendagreement.component';
import { ListagreementComponent } from './restaurant/listagreement/listagreement.component';
import { ProductComponent } from './product/product.component';
import { AddproductComponent } from './product/addproduct/addproduct.component';
import { EditproductComponent } from './product/editproduct/editproduct.component';
import { ListproductComponent } from './product/listproduct/listproduct.component';
import { ProducttagComponent } from './producttag/producttag.component';
import { AddproducttagComponent } from './producttag/addproducttag/addproducttag.component';
import { EditproducttagComponent } from './producttag/editproducttag/editproducttag.component';
import { ListproducttagComponent } from './producttag/listproducttag/listproducttag.component';
import { ListremarksComponent } from './restaurant/listremarks/listremarks.component';
import { AddsComponent } from './adds/adds.component';
import { AddadvertisementComponent } from './adds/addadvertisement/addadvertisement.component';
import { EditadvertisementComponent } from './adds/editadvertisement/editadvertisement.component';
import { ListadvertisementComponent } from './adds/listadvertisement/listadvertisement.component';
import { DatePipe } from '@angular/common';
import { DriverComponent } from './driver/driver.component';
import { AdddriverComponent } from './driver/adddriver/adddriver.component';
import { EditdriverComponent } from './driver/editdriver/editdriver.component';
import { ListdriverComponent } from './driver/listdriver/listdriver.component';
import { ListdriveragreementComponent } from './driver/listdriveragreement/listdriveragreement.component';
import { SenddriveragreementComponent } from './driver/senddriveragreement/senddriveragreement.component';
import { ListdriverapprovedComponent } from './driver/listdriverapproved/listdriverapproved.component';
import { SenddriverapproveComponent } from './driver/senddriverapprove/senddriverapprove.component';
import { AddonsComponent } from './product/addons/addons.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { AdddeliveryComponent } from './delivery/adddelivery/adddelivery.component';
import { EditdeliveryComponent } from './delivery/editdelivery/editdelivery.component';
import { ListdeliveryComponent } from './delivery/listdelivery/listdelivery.component';
import { SingleproductComponent } from './product/singleproduct/singleproduct.component';
import { SinglerestaurantComponent } from './restaurant/singlerestaurant/singlerestaurant.component';
import { OffersComponent } from './offers/offers.component';
import { AddoffersComponent } from './offers/addoffers/addoffers.component';
import { EditoffersComponent } from './offers/editoffers/editoffers.component';
import { ListoffersComponent } from './offers/listoffers/listoffers.component';
import { OpenregisterComponent } from './restaurant/openregister/openregister.component';
import { OrdersComponent } from './orders/orders.component';
import { AllordersComponent } from './orders/allorders/allorders.component';
import { SingleorderComponent } from './orders/singleorder/singleorder.component';
import { OrderdriverComponent } from './driver/orderdriver/orderdriver.component';
import { ListtransactionsComponent } from './driver/listtransactions/listtransactions.component';
import { PaydriverComponent } from './driver/paydriver/paydriver.component';
import { WalletrestaurantComponent } from './restaurant/walletrestaurant/walletrestaurant.component';
import { ApprovebankComponent } from './driver/approvebank/approvebank.component';
import { SingledriverComponent } from './driver/singledriver/singledriver.component';
import { PayrestaurantComponent } from './restaurant/payrestaurant/payrestaurant.component';
import { CustomizeComponent } from './customize/customize.component';
import { AddsliderComponent } from './customize/addslider/addslider.component';
import { AddtoprestaurantComponent } from './customize/addtoprestaurant/addtoprestaurant.component';
import { AddtopproductComponent } from './customize/addtopproduct/addtopproduct.component';
import { ListsliderComponent } from './customize/listslider/listslider.component';
import { ListtoprestaurantComponent } from './customize/listtoprestaurant/listtoprestaurant.component';
import { ListtopproductComponent } from './customize/listtopproduct/listtopproduct.component';
import { ReportComponent } from './report/report.component';
import { RestreportComponent } from './report/restreport/restreport.component';
import { DriverreportComponent } from './report/driverreport/driverreport.component';
import { ListcashinhandComponent } from './driver/listcashinhand/listcashinhand.component';
import { CollectcashComponent } from './driver/collectcash/collectcash.component';
import { ListcashhistoryComponent } from './driver/listcashhistory/listcashhistory.component';
import { AssignorderComponent } from './orders/assignorder/assignorder.component';
import { SupportComponent } from './support/support.component';
import { DriverissueComponent } from './support/driverissue/driverissue.component';
import { ListdriverissueComponent } from './support/driverissue/listdriverissue/listdriverissue.component';
import { ReplydriverissueComponent } from './support/driverissue/replydriverissue/replydriverissue.component';
import { MessagingService } from './shared/messaging/messaging.service';
import{AngularFireMessagingModule}from'@angular/fire/messaging';
import { AsyncPipe } from '@angular/common';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { RolesComponent } from './roles/roles.component';
import { AddroleComponent } from './roles/addrole/addrole.component';
import { ListroleComponent } from './roles/listrole/listrole.component';
import { HasRoleDirective } from './shared/directive/has-role.directive';
import { AddMycategoryComponent } from './restaurantpanel/mycategory/add-mycategory/add-mycategory.component';
import { EditMycategoryComponent } from './restaurantpanel/mycategory/edit-mycategory/edit-mycategory.component';
import { ListMycategoryComponent } from './restaurantpanel/mycategory/list-mycategory/list-mycategory.component';
import { ListMysubcategoryComponent } from './restaurantpanel/mysubcategory/list-mysubcategory/list-mysubcategory.component';
import { AddMysubcategoryComponent } from './restaurantpanel/mysubcategory/add-mysubcategory/add-mysubcategory.component';
import { EditMysubcategoryComponent } from './restaurantpanel/mysubcategory/edit-mysubcategory/edit-mysubcategory.component';
import { AgmCoreModule } from '@agm/core';
import { AddMydriverComponent } from './restaurantpanel/driver/add-mydriver/add-mydriver.component';
import { ListMydriverComponent } from './restaurantpanel/driver/list-mydriver/list-mydriver.component';
import { SingleMydriverComponent } from './restaurantpanel/driver/single-mydriver/single-mydriver.component';
import { AddMymenuComponent } from './restaurantpanel/menu/add-mymenu/add-mymenu.component';
import { ListMymenuComponent } from './restaurantpanel/menu/list-mymenu/list-mymenu.component';
import { DetailMymenuComponent } from './restaurantpanel/menu/detail-mymenu/detail-mymenu.component';
import { EditMymenuComponent } from './restaurantpanel/menu/edit-mymenu/edit-mymenu.component';
import { AddAddonsComponent } from './restaurantpanel/menu/add-addons/add-addons.component';
import { ListOrdersComponent } from './restaurantpanel/orders/list-orders/list-orders.component';
import { DetailMyorderComponent } from './restaurantpanel/orders/detail-myorder/detail-myorder.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RejectMyorderComponent } from './restaurantpanel/orders/reject-myorder/reject-myorder.component';
import { AssignMyorderComponent } from './restaurantpanel/orders/assign-myorder/assign-myorder.component';
import { MyearningComponent } from './restaurantpanel/wallet/myearning/myearning.component';
import { MyprofileComponent } from './restaurantpanel/profile/myprofile/myprofile.component';
import { AddMyoffersComponent } from './restaurantpanel/offers/add-myoffers/add-myoffers.component';
import { ListMyoffersComponent } from './restaurantpanel/offers/list-myoffers/list-myoffers.component';
import { EditMyoffersComponent } from './restaurantpanel/offers/edit-myoffers/edit-myoffers.component';
import { DetailcustomerComponent } from './customer/detailcustomer/detailcustomer.component';
import { ListcustomerComponent } from './customer/listcustomer/listcustomer.component';
import { AddSupportQuestionsComponent } from './customize/add-support-questions/add-support-questions.component';
import { ListSupportQuestionsComponent } from './customize/list-support-questions/list-support-questions.component';
import { ApprovecategoryComponent } from './restcategory/approvecategory/approvecategory.component';
import { ApprovesubcategoryComponent } from './restsubcategory/approvesubcategory/approvesubcategory.component';
import { ApproveproductComponent } from './product/approveproduct/approveproduct.component';
import { DetailapproveComponent } from './product/detailapprove/detailapprove.component';
import { PendingMycategoryComponent } from './restaurantpanel/mycategory/pending-mycategory/pending-mycategory.component';
import { PendingMysubcategoryComponent } from './restaurantpanel/mysubcategory/pending-mysubcategory/pending-mysubcategory.component';
import { PendingMymenuComponent } from './restaurantpanel/menu/pending-mymenu/pending-mymenu.component';
import { PendingMydriverComponent } from './restaurantpanel/driver/pending-mydriver/pending-mydriver.component';
import { UpdateMydriverComponent } from './restaurantpanel/driver/update-mydriver/update-mydriver.component';
import { PendingMyordersComponent } from './restaurantpanel/orders/pending-myorders/pending-myorders.component';
import { PreparingMyordersComponent } from './restaurantpanel/orders/preparing-myorders/preparing-myorders.component';
import { PreparedMyordersComponent } from './restaurantpanel/orders/prepared-myorders/prepared-myorders.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatRadioModule} from '@angular/material/radio';
import { ListcustomerissueComponent } from './support/customerissue/listcustomerissue/listcustomerissue.component';
import { DetailcustomerissueComponent } from './support/customerissue/detailcustomerissue/detailcustomerissue.component';
import { RefundOnlineOrderComponent } from './support/customerissue/refund-online-order/refund-online-order.component';
import { RefundCodOrderComponent } from './support/customerissue/refund-cod-order/refund-cod-order.component';
import { ActiveordersComponent } from './orders/activeorders/activeorders.component';
import { ListtimeoutComponent } from './orders/listtimeout/listtimeout.component';
import { ListunassignorderComponent } from './orders/listunassignorder/listunassignorder.component';
import { CancelrejectorderComponent } from './orders/cancelrejectorder/cancelrejectorder.component';
import { CompletedorderComponent } from './orders/completedorder/completedorder.component';
import { OnlineRefundOrderComponent } from './orders/online-refund-order/online-refund-order.component';
import { UpdateorderComponent } from './orders/updateorder/updateorder.component';
import { CodRefundOrderComponent } from './orders/cod-refund-order/cod-refund-order.component';
import { RejectRefundOrderComponent } from './support/customerissue/reject-refund-order/reject-refund-order.component';
import { DetaildriverissueComponent } from './support/driverissue/detaildriverissue/detaildriverissue.component';


@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    SidebarComponent,
    TopbarComponent,
    FooterComponent,
    DashboardComponent,
    UserComponent,
    LoginComponent,
    RegisterComponent,
    AdduserComponent,
    EdituserComponent,
    ListuserComponent,
    AddserviceComponent,
    EditserviceComponent,
    ListserviceComponent,
    AddcuisineComponent,
    EditcuisineComponent,
    ListcuisineComponent,
    AddtagComponent,
    EdittagComponent,
    ListtagComponent,
    AddtimingComponent,
    EdittimingComponent,
    ListtimingComponent,
    ServiceComponent,
    CuisineComponent,
    TagComponent,
    TimingComponent,
    PasswordComponent,
    CategoryComponent,
    AddcategoryComponent,
    EditcategoryComponent,
    ListcategoryComponent,
    CityComponent,
    AddcityComponent,
    EditcityComponent,
    ListcityComponent,
    RestaurantComponent,
    AddrestaurantComponent,
    EditrestaurantComponent,
    ListrestaurantComponent,
    RestcategoryComponent,
    AddrestcategoryComponent,
    EditrestcategoryComponent,
    ListrestcategoryComponent,
    RestsubcategoryComponent,
    AddrestsubcategoryComponent,
    EditrestsubcategoryComponent,
    ListrestsubcategoryComponent,
    ApproverestaurantComponent,
    UpdateapplicationComponent,
    SendagreementComponent,
    ListagreementComponent,
    ProductComponent,
    AddproductComponent,
    EditproductComponent,
    ListproductComponent,
    ProducttagComponent,
    AddproducttagComponent,
    EditproducttagComponent,
    ListproducttagComponent,
    ListremarksComponent,
    AddsComponent,
    AddadvertisementComponent,
    EditadvertisementComponent,
    ListadvertisementComponent,
    DriverComponent,
    AdddriverComponent,
    EditdriverComponent,
    ListdriverComponent,
    ListdriveragreementComponent,
    SenddriveragreementComponent,
    ListdriverapprovedComponent,
    SenddriverapproveComponent,
    AddonsComponent,
    DeliveryComponent,
    AdddeliveryComponent,
    EditdeliveryComponent,
    ListdeliveryComponent,
    SingleproductComponent,
    SinglerestaurantComponent,
    OffersComponent,
    AddoffersComponent,
    EditoffersComponent,
    ListoffersComponent,
    OpenregisterComponent,
    OrdersComponent,
    AllordersComponent,
    SingleorderComponent,
    OrderdriverComponent,
    ListtransactionsComponent,
    PaydriverComponent,
    WalletrestaurantComponent,
    ApprovebankComponent,
    SingledriverComponent,
    PayrestaurantComponent,
    CustomizeComponent,
    AddsliderComponent,
    AddtoprestaurantComponent,
    AddtopproductComponent,
    ListsliderComponent,
    ListtoprestaurantComponent,
    ListtopproductComponent,
    ReportComponent,
    RestreportComponent,
    DriverreportComponent,
    ListcashinhandComponent,
    CollectcashComponent,
    ListcashhistoryComponent,
    AssignorderComponent,
    SupportComponent,
    DriverissueComponent,
    ListdriverissueComponent,
    ReplydriverissueComponent,
    RolesComponent,
    AddroleComponent,
    ListroleComponent,
    HasRoleDirective,
    AddMycategoryComponent,
    EditMycategoryComponent,
    ListMycategoryComponent,
    ListMysubcategoryComponent,
    AddMysubcategoryComponent,
    EditMysubcategoryComponent,
    AddMydriverComponent,
    ListMydriverComponent,
    SingleMydriverComponent,
    AddMymenuComponent,
    ListMymenuComponent,
    DetailMymenuComponent,
    EditMymenuComponent,
    AddAddonsComponent,
    ListOrdersComponent,
    DetailMyorderComponent,
    RejectMyorderComponent,
    AssignMyorderComponent,
    MyearningComponent,
    MyprofileComponent,
    AddMyoffersComponent,
    ListMyoffersComponent,
    EditMyoffersComponent,
    DetailcustomerComponent,
    ListcustomerComponent,
    AddSupportQuestionsComponent,
    ListSupportQuestionsComponent,
    ApprovecategoryComponent,
    ApprovesubcategoryComponent,
    ApproveproductComponent,
    DetailapproveComponent,
    PendingMycategoryComponent,
    PendingMysubcategoryComponent,
    PendingMymenuComponent,
    PendingMydriverComponent,
    UpdateMydriverComponent,
    PendingMyordersComponent,
    PreparingMyordersComponent,
    PreparedMyordersComponent,
    ListcustomerissueComponent,
    DetailcustomerissueComponent,
    RefundOnlineOrderComponent,
    RefundCodOrderComponent,
    ActiveordersComponent,
    ListtimeoutComponent,
    ListunassignorderComponent,
    CancelrejectorderComponent,
    CompletedorderComponent,
    OnlineRefundOrderComponent,
    UpdateorderComponent,
    CodRefundOrderComponent,
    RejectRefundOrderComponent,
    DetaildriverissueComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    NgxUiLoaderModule, //loader added
    NgxDatatableModule,
    TagInputModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    AngularFirestoreModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCikrgrvaDgb61Zk1J4bbQ1CFcLX34BvkQ',
      libraries: ['places']
    }),
    NgbModule,
    MatSlideToggleModule,MatRadioModule
  ],
  providers: [DatePipe,MessagingService,AsyncPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
