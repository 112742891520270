import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-restcategory',
  templateUrl: './restcategory.component.html',
  styleUrls: ['./restcategory.component.css']
})
export class RestcategoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
