<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/admin/driver/cashInHand']">Driver Cash</a></li>
        <li class="active breadcrumb-item">Collect Cash In Hand</li>
    </ol>
    <!-- <h1 class="dt-page__title">Edit Manager</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title">Collect Cash In Hand</h3>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Form -->
                <form [formGroup]="formGroup" (submit)="onsubmit()">

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="cash_amount">Amount ($)</label>

                        <div class="col-xl-9">
                            <input type="text" class="form-control" id="cash_amount" placeholder="Enter Amount" name="cash_amount" formControlName="cash_amount" [readonly]="true">
                            <small class="form-text" *ngIf="f.cash_amount.invalid && (f.cash_amount.dirty || f.cash_amount.touched)">
                                <small *ngIf="f.cash_amount.errors.required">Invalid Value</small>
                                <small *ngIf="f.cash_amount.errors.max">Invalid Value</small>
                            </small>
                        </div>
                    </div> 
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="txnId">Transaction ID / Cheque Number</label>

                        <div class="col-xl-9">
                            <input type="text" class="form-control" id="txnId" placeholder="Enter Transaction ID / Cheque Number" name="txnId" formControlName="txnId">
                            <small class="form-text" *ngIf="f.txnId.invalid && (f.txnId.dirty || f.txnId.touched)">
                                <small *ngIf="f.txnId.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>
                    <!-- /form group --> 

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <div class="col-xl-9 offset-xl-3">
                            <button type="submit" class="btn btn-primary text-uppercase" [disabled]="!formGroup.valid">Save
                            </button>
                        </div>
                    </div>
                    <!-- /form group -->

                </form>
                <!-- /form -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->