import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OffersService } from 'src/app/shared/offers/offers.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-edit-myoffers',
  templateUrl: './edit-myoffers.component.html',
  styleUrls: ['./edit-myoffers.component.css']
})
export class EditMyoffersComponent implements OnInit {

  formGroup = new FormGroup({
    offers_type: new FormControl('', [Validators.required]),
    offer_amount: new FormControl('', [Validators.required]),
    minimum_order: new FormControl('', [Validators.required]),
    maximum_discount: new FormControl('', [Validators.required]),
    offer_desc: new FormControl(''),
    offers_code: new FormControl('', [Validators.required]),
    offers_image: new FormControl(''),
    end_date: new FormControl('', [Validators.required]),
    restId: new FormControl('', [Validators.required]),
    _id: new FormControl('', [Validators.required]),
  })
  offerType = [{'value':1,'name':'Fixed Amount'},{'value':2,'name':'Percent'}]
  id=""
  end_date = ""

  constructor(private offers:OffersService, private userdata: UserdataService, private toast:ToastrService, private router: Router, private datePipe: DatePipe, private activate:ActivatedRoute) { }

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getDataById() 
  }

  getDataById(){
    this.offers.getOfferById({'_id':this.id}).subscribe(
      (res:any) => {
        //console.log(res.result)
        this.formGroup.patchValue({offers_type: res.data.offers_type});
        this.formGroup.patchValue({offer_amount: res.data.offer_amount});   
        this.formGroup.patchValue({minimum_order: res.data.minimum_order});     
        this.formGroup.patchValue({maximum_discount: res.data.maximum_discount});     
        this.formGroup.patchValue({offer_desc: res.data.offer_desc});       
        this.formGroup.patchValue({offers_code: res.data.offers_code});    
        this.formGroup.patchValue({_id: res.data._id});     
        // this.formGroup.patchValue({category_image: res.category_image});     
        var id = this.userdata.getId()
        this.formGroup.patchValue({restId: id});
        this.end_date = this.datePipe.transform(res.data.end_date, 'yyyy-MM-dd')
        this.f   
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        this.router.navigateByUrl('/restaurant/offers/list')
      }
    )
  }

  public onFile(evnt){
    if(evnt.target.files.length > 0)
    {
      this.formGroup.patchValue({
        offers_image: evnt.target.files[0]
      })
    }
  }

  get f(){
    return this.formGroup.controls
  }

  compareFn(c1: any, c2:any): boolean {     
    return c1 && c2 ? c1.id === c2.id : c1 === c2; 
  }

  public onsubmit(){
    const data = new FormData()
    data.append("_id",this.formGroup.get("_id").value)
    data.append("offers_type",this.formGroup.get("offers_type").value)
    data.append("end_date",this.formGroup.get("end_date").value)
    data.append("offer_amount",this.formGroup.get("offer_amount").value)
    data.append("minimum_order",this.formGroup.get("minimum_order").value)
    data.append("maximum_discount",this.formGroup.get("maximum_discount").value)
    data.append("offer_desc",this.formGroup.get("offer_desc").value)
    data.append("restId",this.formGroup.get("restId").value)
    data.append("offers_image",this.formGroup.get("offers_image").value)
    this.offers.restUpdateOffer(data).subscribe(
      (res:any) => {
        //console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/restaurant/offers/list');
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
