import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormBuilder, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CityService } from 'src/app/shared/city/city.service';
import { UserdataService } from 'src/app/shared/userdata.service';
import { DeliveryService } from 'src/app/shared/delivery/delivery.service';

@Component({
  selector: 'app-editdelivery',
  templateUrl: './editdelivery.component.html',
  styleUrls: ['./editdelivery.component.css']
})
export class EditdeliveryComponent implements OnInit {

  formGroup = new FormGroup({
    cityId: new FormControl('', [Validators.required]),
    area: new FormControl('', [Validators.required]),
    street: new FormControl('', [Validators.required]),
    sector: new FormControl('', [Validators.required]),
    pincode: new FormControl('', [Validators.required]),
    charges: new FormControl('', [Validators.required]),
    updated_by: new FormControl('', [Validators.required]),
    updated_Id: new FormControl('', [Validators.required]),
    _id: new FormControl('', [Validators.required]),
  })

  id = ''
  constructor(private toast: ToastrService, private formBuilder: FormBuilder, private router: Router, private city: CityService, private userdata: UserdataService, private delivery: DeliveryService, private activate: ActivatedRoute) {}

  cityList = []

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getDataById()
    this.getCity()
    var added = this.userdata.getUserType()
    var userId = this.userdata.getId()
    this.formGroup.patchValue({ added_by: added });
    this.formGroup.patchValue({ userId: userId });
  }

  get f() {
    return this.formGroup.controls
  }

  getCity() {
    this.city.listCity({}).subscribe(
      (res: any) => {
        this.cityList = res.data
      },
      err => {
        //console.log(err)
      }
    )
  }

  compareFn(c1: any, c2:any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  getDataById(){
    let body = new URLSearchParams();
    body.set('_id', this.id);
    this.delivery.getDeliveryById(body).subscribe(
      (res:any) => {
        //console.log(res.result)
        this.formGroup.patchValue({cityId: res.data.cityId});
        this.formGroup.patchValue({area: res.data.area});
        this.formGroup.patchValue({street: res.data.street});
        this.formGroup.patchValue({sector: res.data.sector});
        this.formGroup.patchValue({pincode: res.data.pincode});
        this.formGroup.patchValue({charges: res.data.charges});
        this.formGroup.patchValue({_id: res.data._id});
        var id = this.userdata.getId()
        var added = this.userdata.getUserType()
        this.formGroup.patchValue({updated_Id: id});
        this.formGroup.patchValue({updated_by: added});
        this.f
      },
      err => {
        console.log(err)
        this.toast.error(err.error.message,'Error')
        this.router.navigateByUrl('/admin/tag/view')
      }
    )
  }

  public onsubmit() {
    this.delivery.updateDelivery(this.formGroup.value).subscribe(
      (res:any) => {
        //console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/delivery/view');
      },
      err => {
        console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
