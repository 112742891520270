<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/admin/menu/view']">Menu</a></li>
        <li class="active breadcrumb-item">Edit</li>
    </ol>
    <!-- <h1 class="dt-page__title">Edit Manager</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title">Edit Menu</h3>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Form -->
                <form [formGroup]="formGroup" (submit)="onsubmit()">

                    <div class="form-row">
                        <div class="col-sm-6 mb-3">
                            <label for="product_name">Name</label>
                            <input type="text" class="form-control" id="product_name" placeholder="Enter Name"
                                name="product_name" formControlName="product_name">
                            <small class="form-text"
                                *ngIf="f.product_name.invalid && (f.product_name.dirty || f.product_name.touched)">
                                <small *ngIf="f.product_name.errors.required">Invalid Value</small>
                            </small>
                        </div>
                        <div class="col-sm-6 mb-3">
                            <label for="product_image">Product Image</label>
                            <input type="file" name="product_image" class="form-control" (change)="onFile($event)"
                                formControlName="product_image">
                            <small class="form-text"
                                *ngIf="f.product_image.invalid && (f.product_image.dirty || f.product_image.touched)">
                                <small *ngIf="f.product_image.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col-sm-6 mb-3">
                            <label for="product_description">Product Description</label>
                            <textarea class="form-control" id="product_description"
                                placeholder="Enter Product Description" name="product_description"
                                formControlName="product_description"></textarea>
                            <small class="form-text"
                                *ngIf="f.product_description.invalid && (f.product_description.dirty || f.product_description.touched)">
                                <small *ngIf="f.product_description.errors.required">Invalid Value</small>
                            </small>
                        </div>
                        <div class="col-sm-6 mb-3">
                            <label for="meta_keywords">Meta Keywords</label>
                            <textarea class="form-control" id="meta_keywords" placeholder="Enter Meta Keywords"
                                name="meta_keywords" formControlName="meta_keywords"></textarea>
                            <small class="form-text"
                                *ngIf="f.meta_keywords.invalid && (f.meta_keywords.dirty || f.meta_keywords.touched)">
                                <small *ngIf="f.meta_keywords.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <div class="col-xl-8 offset-xl-4">
                            <button type="submit" class="btn btn-primary text-uppercase"
                                [disabled]="!formGroup.valid">Save
                            </button>
                        </div>
                    </div>
                    <!-- /form group -->

                </form>
                <!-- /form -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->