import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from 'src/app/shared/category/category.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-addcategory',
  templateUrl: './addcategory.component.html',
  styleUrls: ['./addcategory.component.css']
})
export class AddcategoryComponent implements OnInit {

  formGroup = new FormGroup({
    category_name: new FormControl('', [Validators.required]),
    userId: new FormControl('', [Validators.required]),
    added_by: new FormControl('', [Validators.required]),
    meta_desc: new FormControl('', [Validators.required]),
    meta_keywords: new FormControl('', [Validators.required]),
    category_image: new FormControl('', [Validators.required]),
  })
 
  constructor(private category:CategoryService, private userdata: UserdataService, private toast:ToastrService, private router: Router) { }

  unique = 0
  imageError: string

  ngOnInit(): void {
    var id = this.userdata.getId()
    var added = this.userdata.getUserType()
    this.formGroup.patchValue({userId: id});
    this.formGroup.patchValue({added_by: added});
  }
  
  get f(){
    return this.formGroup.controls
  }

  public onFile(evnt){
    this.imageError = null
    if(evnt.target.files.length > 0)
    {
      const max_size = 2097152
      //const max_size = 1000;
      if (evnt.target.files[0].size > max_size) {
        this.imageError = 'Maximum size allowed is ' + max_size / 1024/1024 + 'MB';
        this.f.category_image.errors.required ='error'
        return false
      }
      else{
        this.formGroup.patchValue({
          category_image: evnt.target.files[0]
        })
      }      
    }
  }

  public onsubmit(){
    const data = new FormData()
    data.append("category_name",this.formGroup.get("category_name").value)
    data.append("userId",this.formGroup.get("userId").value)
    data.append("added_by",this.formGroup.get("added_by").value)
    data.append("category_image",this.formGroup.get("category_image").value)
    data.append("meta_desc",this.formGroup.get("meta_desc").value)
    data.append("meta_keywords",this.formGroup.get("meta_keywords").value)

    this.category.addCategory(data).subscribe(
      (res:any) => {
        //console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/category/view');
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
        if(err.status==422){
          this.unique = 422
        }
      }
    )
  }
}
