<div class="dt-root">
    <div class="dt-root__inner">
        <header class="dt-header">
            <!-- Header -->
            <div style="background-color: #512da8;height:100px;" class="col-12"></div>
            <!-- /header -->
          </header>
        <!-- Site Main -->
        <main class="dt-main">
            <!-- Site Content Wrapper -->
            <div class="dt-content-wrapper">
                <!-- Site Content -->
                <div class="dt-content">
                    <!-- Content -->
                    <!-- Page Header -->
                    <div class="dt-page__header">
                        <!-- <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
                            <li class="breadcrumb-item"><a [routerLink]="['/admin/restaurant/view']">Restaurant</a></li>
                            <li class="active breadcrumb-item">Add</li>
                        </ol> -->
                        <h1 class="dt-page__title">Add Restaurant</h1>
                    </div>
                    <!-- /page header -->
                    <!-- Grid -->
                    <div class="row">
                        <!-- Grid Item -->
                        <div class="col-xl-12">
                            <!-- Form -->
		<form [formGroup]="formGroup" (submit)="onsubmit()">
			<!-- Card -->
			<div class="dt-card dt-card__full-height">
				<!-- Card Header -->
				<div class="dt-card__header">
					<!-- Card Heading -->
					<div class="dt-card__heading">
						<h3 class="dt-card__title">Basic info</h3>
					</div>
					<!-- /card heading -->
				</div>
				<!-- /card header -->
				<!-- Card Body -->
				<div class="dt-card__body">
					<div class="form-row">
						<div class="col-sm-3 mb-3">
							<label for="rest_name">Restaurant Name</label>
							<input type="text" class="form-control" id="rest_name" placeholder="Enter Restaurant Name"
								name="rest_name" formControlName="rest_name"> <small class="form-text"
								*ngIf="f.rest_name.invalid && (f.rest_name.dirty || f.rest_name.touched)">
								<small *ngIf="f.rest_name.errors.required">Invalid Value</small> </small>
						</div>
						<div class="col-sm-3 mb-3">
							<label for="rest_phone">Restaurant Phone</label>
							<input type="text" class="form-control" id="rest_phone" placeholder="Enter phone"
								name="rest_phone" formControlName="rest_phone"> <small class="form-text"
								*ngIf="f.rest_phone.invalid && (f.rest_phone.dirty || f.rest_phone.touched)">
								<small *ngIf="f.rest_phone.errors.required">Invalid Value</small>
								<small *ngIf="f.rest_phone.errors.pattern">Invalid Phone Number</small> </small>
						</div>
						<div class="col-sm-3 mb-3">
							<label for="person_name">Owner Name</label>
							<input type="text" class="form-control" id="person_name" placeholder="Enter Owner Name"
								name="person_name" formControlName="person_name"> <small class="form-text"
								*ngIf="f.person_name.invalid && (f.person_name.dirty || f.person_name.touched)">
								<small *ngIf="f.person_name.errors.required">Invalid Value</small> </small>
						</div>
						<div class="col-sm-3 mb-3">
							<label for="person_phone">Owner Phone</label>
							<input type="text" class="form-control" id="person_phone" placeholder="Enter phone"
								name="person_phone" formControlName="person_phone"> <small class="form-text"
								*ngIf="f.person_phone.invalid && (f.person_phone.dirty || f.person_phone.touched)">
								<small *ngIf="f.person_phone.errors.required">Invalid Value</small>
								<small *ngIf="f.person_phone.errors.pattern">Invalid Phone Number</small> </small>
						</div>
					</div>
				</div>
				<!-- /card body -->
			</div>
			<!-- /card -->
			<!-- Card -->
			<div class="dt-card dt-card__full-height">
				<!-- Card Header -->
				<div class="dt-card__header">
					<!-- Card Heading -->
					<div class="dt-card__heading">
						<h3 class="dt-card__title">Login Credentials</h3>
					</div>
					<!-- /card heading -->
				</div>
				<!-- /card header -->
				<!-- Card Body -->
				<div class="dt-card__body">
					<div class="form-row">
						<div class="col-sm-6 mb-3">
							<label for="person_email">Email</label>
							<input type="text" class="form-control" id="person_email" placeholder="Enter Email"
								name="person_email" formControlName="person_email"> <small class="form-text"
								*ngIf="f.person_email.invalid && (f.person_email.dirty || f.person_email.touched)">
								<small *ngIf="f.person_email.errors.required">Invalid Value</small> </small>
						</div>
						<div class="col-sm-6 mb-3">
							<label for="person_password">Password</label>
							<input type="password" class="form-control" id="person_password"
								placeholder="Enter Password" name="person_password" formControlName="person_password">
							<small class="form-text"
								*ngIf="f.person_password.invalid && (f.person_password.dirty || f.person_password.touched)">
								<small *ngIf="f.person_password.errors.required">Invalid Value</small> </small>
						</div>
					</div>
				</div>
				<!-- /card body -->
			</div>
			<!-- /card -->
			<!-- Card -->
			<div class="dt-card dt-card__full-height">
				<!-- Card Header -->
				<div class="dt-card__header">
					<!-- Card Heading -->
					<div class="dt-card__heading">
						<h3 class="dt-card__title">Characteristics</h3>
					</div>
					<!-- /card heading -->
				</div>
				<!-- /card header -->
				<!-- Card Body -->
				<div class="dt-card__body">
					<div class="form-row mb-2">
						<div class="col-sm-12 mb-3">
							<label for="services">Service</label><br />
							<tag-input [onlyFromAutocomplete]="true" [identifyBy]="'_id'" [displayBy]="'name'"
								[maxItems]='8' formControlName="services" placeholder="+Services"
								secondaryPlaceholder="Enter Services">
								<tag-input-dropdown [autocompleteItems]="serviceList" [showDropdownIfEmpty]="true"
									[dynamicUpdate]="false" [identifyBy]="'_id'" [displayBy]="'name'">
								</tag-input-dropdown>
							</tag-input>

							<small class="form-text"
								*ngIf="f.services.invalid && (f.services.dirty || f.services.touched)">
								<small *ngIf="f.services.errors.required">Invalid Value</small> </small>
						</div>
					</div>
					<div class="form-row mb-2">
						<div class="col-sm-6 mb-3">
							<label for="rest_street_number">Seating Type</label><br />
							<!-- Radio Button -->
							<div class="custom-control custom-radio custom-control-inline">
								<input type="radio" formControlName="setting_type" class="custom-control-input"
									id="Takeaway" value="Takeaway" [checked]="true">
								<label class="custom-control-label" for="Takeaway">Takeaway</label>
							</div>
							<!-- /radio button -->
							<!-- Radio Button -->
							<div class="custom-control custom-radio custom-control-inline">
								<input type="radio" formControlName="setting_type" class="custom-control-input"
									id="Din-in" value="Din-in">
								<label class="custom-control-label" for="Din-in">Din-in</label>
							</div>
							<!-- /radio button -->
							<!-- Radio Button -->
							<div class="custom-control custom-radio custom-control-inline">
								<input type="radio" formControlName="setting_type" class="custom-control-input"
									id="Both" value="Both">
								<label class="custom-control-label" for="Both">Both</label>
							</div>
							<!-- /radio button -->
							<small class="form-text"
								*ngIf="f.setting_type.invalid && (f.setting_type.dirty || f.setting_type.touched)"><small
									*ngIf="f.setting_type.errors.required">Invalid Value</small> </small>
						</div>
						<div class="col-sm-6 mb-3">
							<label for="rest_street_number">Payment Type</label><br />
							<!-- Radio Button -->
							<div class="custom-control custom-radio custom-control-inline">
								<input type="radio" formControlName="payment_type" class="custom-control-input"
									[checked]="true" id="Card" value="Card">
								<label class="custom-control-label" for="Card">Card</label>
							</div>
							<!-- /radio button -->
							<!-- Radio Button -->
							<div class="custom-control custom-radio custom-control-inline">
								<input type="radio" formControlName="payment_type" class="custom-control-input"
									id="Cash" value="Cash">
								<label class="custom-control-label" for="Cash">Cash</label>
							</div>
							<!-- /radio button -->
							<!-- Radio Button -->
							<div class="custom-control custom-radio custom-control-inline">
								<input type="radio" formControlName="payment_type" class="custom-control-input"
									id="Both_payment" value="Both_payment">
								<label class="custom-control-label" for="Both_payment">Both</label>
							</div>
							<!-- /radio button -->
							<small class="form-text"
								*ngIf="f.payment_type.invalid && (f.payment_type.dirty || f.payment_type.touched)"><small
									*ngIf="f.payment_type.errors.required">Invalid Value</small> </small>
						</div>
						<!-- <div class="col-sm-4 mb-3">
							<label for="vegtype">Food Type</label><br />
							
							<div class="custom-control custom-radio custom-control-inline">
								<input type="radio" formControlName="vegtype" class="custom-control-input"
									[checked]="true" id="Veg" value="Veg">
								<label class="custom-control-label" for="Veg">Veg</label>
							</div>
							
							<div class="custom-control custom-radio custom-control-inline">
								<input type="radio" formControlName="vegtype" class="custom-control-input" id="Nonveg"
									value="Nonveg">
								<label class="custom-control-label" for="Nonveg">Non-Veg</label>
							</div>
							
							<div class="custom-control custom-radio custom-control-inline">
								<input type="radio" formControlName="vegtype" class="custom-control-input"
									id="food_both" value="food_both">
								<label class="custom-control-label" for="food_both">Both</label>
							</div>
							<small class="form-text"
								*ngIf="f.vegtype.invalid && (f.vegtype.dirty || f.vegtype.touched)">
								<small *ngIf="f.vegtype.errors.required">Invalid Value</small> </small>
						</div> -->
					</div>
					<div class="form-row">
						<div class="col-sm-6 mb-3">
							<label for="cuisines">Cuisine</label><br />
							<tag-input [onlyFromAutocomplete]="true" [identifyBy]="'_id'" [displayBy]="'cuisine_name'"
								[maxItems]='8' formControlName="cuisines" placeholder="+Cuisines"
								secondaryPlaceholder="Enter Cuisines">
								<tag-input-dropdown [autocompleteItems]="cuisineList" [showDropdownIfEmpty]="true"
									[dynamicUpdate]="false" [identifyBy]="'_id'" [displayBy]="'cuisine_name'">
								</tag-input-dropdown>
							</tag-input>
							<small class="form-text"
								*ngIf="f.cuisines.invalid && (f.cuisines.dirty || f.cuisines.touched)"><small
									*ngIf="f.cuisines.errors.required">Invalid Value</small> </small>
						</div>
						<div class="col-sm-6 mb-3">
							<label for="tags">Tags</label><br />
							<tag-input [identifyBy]="'_id'" [displayBy]="'tag_name'" [onlyFromAutocomplete]="true"
								formControlName="tags" placeholder="+Tags" secondaryPlaceholder="Enter Tag">
								<tag-input-dropdown [autocompleteItems]="tagList" [showDropdownIfEmpty]="true"
									[dynamicUpdate]="false" [identifyBy]="'_id'" [displayBy]="'tag_name'">
								</tag-input-dropdown>
							</tag-input>
							<small class="form-text" *ngIf="f.tags.invalid && (f.tags.dirty || f.tags.touched)"><small
									*ngIf="f.tags.errors.required">Invalid Value</small> </small>
						</div>
					</div>
				</div>
				<!-- /card body -->
			</div>
			<!-- /card -->
			<!-- Card -->
			<div class="dt-card dt-card__full-height">
				<!-- Card Header -->
				<div class="dt-card__header">
					<!-- Card Heading -->
					<div class="dt-card__heading">
						<h3 class="dt-card__title">Timings</h3>
					</div>
					<!-- /card heading -->
				</div>
				<!-- /card header -->
				<!-- Card Body -->
				<div class="dt-card__body">
					<div class="form-row">
						<div class="col-sm-12 mb-3">
							<!-- <table class="table table-borderless table-sm">
								<tr>
									<th>Day</th>
									<th>Start Time</th>
									<th>End Time</th>
									<th>Closed</th>
								</tr>
								<tr>
									<td>Monday</td>
									<td><select class="form-control" id="start_monday" name="start_monday" formControlName="start_monday">
										<option value="" [selected]="true" [disabled]="true">From</option>
										<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00</option>
									</select></td>
									<td><select class="form-control" id="end_monday" name="end_monday" formControlName="end_monday">
										<option value="" [selected]="true" [disabled]="true">To</option>
										<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00</option>
									</select></td>
									<td> <input type="checkbox" name="monday" id="monday" value="CLOSED" formControlName="monday"> CLOSED</td>
								</tr>
								<tr>
									<td>Tuesday</td>
									<td><select class="form-control" id="start_tuesday" name="start_tuesday" formControlName="start_tuesday">
										<option value="" [selected]="true" [disabled]="true">From</option>
										<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00</option>
									</select></td>
									<td><select class="form-control" id="end_tuesday" name="end_tuesday" formControlName="end_tuesday">
										<option value="" [selected]="true" [disabled]="true">To</option>
										<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00</option>
									</select></td>
									<td> <input type="checkbox" name="tuesday" id="tuesday" value="CLOSED" formControlName="tuesday"> CLOSED</td>
								</tr>
								<tr>
									<td>Wednesday</td>
									<td><select class="form-control" id="start_wednesday" name="start_wednesday" formControlName="start_wednesday">
										<option value="" [selected]="true" [disabled]="true">From</option>
										<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00</option>
									</select></td>
									<td><select class="form-control" id="end_wednesday" name="end_wednesday" formControlName="end_wednesday">
										<option value="" [selected]="true" [disabled]="true">To</option>
										<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00</option>
									</select></td>
									<td><input type="checkbox" name="wednesday" id="wednesday" value="CLOSED" formControlName="wednesday"> CLOSED</td>
								</tr>
								<tr>
									<td>Thursday</td>
									<td><select class="form-control" id="start_thursday" name="start_thursday" formControlName="start_thursday">
										<option value="" [selected]="true" [disabled]="true">From</option>
										<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00</option>
									</select></td>
									<td><select class="form-control" id="end_thursday" name="end_thursday" formControlName="end_thursday">
										<option value="" [selected]="true" [disabled]="true">To</option>
										<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00</option>
									</select></td>
									<td><input type="checkbox" name="thursday" id="thursday" value="CLOSED" formControlName="thursday"> CLOSED</td>
								</tr>
								<tr>
									<td>Friday</td>
									<td><select class="form-control" id="start_friday" name="start_friday" formControlName="start_friday">
										<option value="" [selected]="true" [disabled]="true">From</option>
										<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00</option>
									</select></td>
									<td><select class="form-control" id="end_friday" name="end_friday" formControlName="end_friday">
										<option value="" [selected]="true" [disabled]="true">To</option>
										<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00</option>
									</select></td>
									<td><input type="checkbox" name="friday" id="friday" value="CLOSED" formControlName="friday"> CLOSED</td>
								</tr>
								<tr>
									<td>Saturday</td>
									<td><select class="form-control" id="start_saturday" name="start_saturday" formControlName="start_saturday">
										<option value="" [selected]="true" [disabled]="true">From</option>
										<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00</option>
									</select></td>
									<td><select class="form-control" id="end_saturday" name="end_saturday" formControlName="end_saturday">
										<option value="" [selected]="true" [disabled]="true">To</option>
										<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00</option>
									</select></td>
									<td><input type="checkbox" name="saturday" id="saturday" value="CLOSED" formControlName="saturday"> CLOSED</td>
								</tr>
								<tr>
									<td>Sunday</td>
									<td><select class="form-control" id="start_sunday" name="start_sunday" formControlName="start_sunday">
										<option value="" [selected]="true" [disabled]="true">From</option>
										<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00</option>
									</select></td>
									<td><select class="form-control" id="end_sunday" name="end_sunday" formControlName="end_sunday">
										<option value="" [selected]="true" [disabled]="true">To</option>
										<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00</option>
									</select></td>
									<td><input type="checkbox" name="sunday" id="sunday" value="CLOSED" formControlName="sunday"> CLOSED</td>
								</tr>
							</table> -->
							<table class="table table-borderless table-sm">
								<tr>
									<th>Day</th>
									<th>Start Time</th>
									<th>End Time</th>
									<th class="w-20">Open/Close</th>
								</tr>
								<tr>
									<td>Monday</td>
									<td><select class="form-control" id="start_monday" name="start_monday"
											formControlName="start_monday">
											<option value="" [selected]="true" [disabled]="true">From</option>
											<!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
											<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
											</option>
										</select></td>
									<td><select class="form-control" id="end_monday" name="end_monday"
											formControlName="end_monday">
											<option value="" [selected]="true" [disabled]="true">To</option>
											<!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
											<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
											</option>
										</select></td>
									<td>
										<mat-slide-toggle formControlName="monday"
											(change)="checkTimingStatus('monday',$event)" [(ngModel)]="isMondayChecked">
											{{isMondayChecked ? 'Open':'Closed'}}</mat-slide-toggle>
									</td>
								</tr>
								<tr>
									<td>Tuesday</td>
									<td><select class="form-control" id="start_tuesday" name="start_tuesday"
											formControlName="start_tuesday">
											<option value="" [selected]="true" [disabled]="true">From</option>
											<!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
											<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
											</option>
										</select></td>
									<td><select class="form-control" id="end_tuesday" name="end_tuesday"
											formControlName="end_tuesday">
											<option value="" [selected]="true" [disabled]="true">To</option>
											<!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
											<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
											</option>
										</select></td>
									<td>
										<mat-slide-toggle formControlName="tuesday"
											(change)="checkTimingStatus('tuesday',$event)"
											[(ngModel)]="isTuesdayChecked">{{isTuesdayChecked ? 'Open':'Closed'}}
										</mat-slide-toggle>
									</td>
								</tr>
								<tr>
									<td>Wednesday</td>
									<td><select class="form-control" id="start_wednesday" name="start_wednesday"
											formControlName="start_wednesday">
											<option value="" [selected]="true" [disabled]="true">From</option>
											<!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
											<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
											</option>
										</select></td>
									<td><select class="form-control" id="end_wednesday" name="end_wednesday"
											formControlName="end_wednesday">
											<option value="" [selected]="true" [disabled]="true">To</option>
											<!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
											<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
											</option>
										</select></td>
									<td>
										<mat-slide-toggle formControlName="wednesday"
											(change)="checkTimingStatus('wednesday',$event)"
											[(ngModel)]="isWednesdayChecked">{{isWednesdayChecked ? 'Open':'Closed'}}
										</mat-slide-toggle>
									</td>
								</tr>
								<tr>
									<td>Thursday</td>
									<td><select class="form-control" id="start_thursday" name="start_thursday"
											formControlName="start_thursday">
											<option value="" [selected]="true" [disabled]="true">From</option>
											<!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
											<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
											</option>
										</select></td>
									<td><select class="form-control" id="end_thursday" name="end_thursday"
											formControlName="end_thursday">
											<option value="" [selected]="true" [disabled]="true">To</option>
											<!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
											<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
											</option>
										</select></td>
									<td>
										<mat-slide-toggle formControlName="thursday"
											(change)="checkTimingStatus('thursday',$event)"
											[(ngModel)]="isThursdayChecked">{{isThursdayChecked ? 'Open':'Closed'}}
										</mat-slide-toggle>
									</td>
								</tr>
								<tr>
									<td>Friday</td>
									<td><select class="form-control" id="start_friday" name="start_friday"
											formControlName="start_friday">
											<option value="" [selected]="true" [disabled]="true">From</option>
											<!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
											<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
											</option>
										</select></td>
									<td><select class="form-control" id="end_friday" name="end_friday"
											formControlName="end_friday">
											<option value="" [selected]="true" [disabled]="true">To</option>
											<!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
											<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
											</option>
										</select></td>
									<td>
										<mat-slide-toggle formControlName="friday"
											(change)="checkTimingStatus('friday',$event)" [(ngModel)]="isFridayChecked">
											{{isFridayChecked ? 'Open':'Closed'}}</mat-slide-toggle>
									</td>
								</tr>
								<tr>
									<td>Saturday</td>
									<td><select class="form-control" id="start_saturday" name="start_saturday"
											formControlName="start_saturday">
											<option value="" [selected]="true" [disabled]="true">From</option>
											<!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
											<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
											</option>
										</select></td>
									<td><select class="form-control" id="end_saturday" name="end_saturday"
											formControlName="end_saturday">
											<option value="" [selected]="true" [disabled]="true">To</option>
											<!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
											<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
											</option>
										</select></td>
									<td>
										<mat-slide-toggle formControlName="saturday"
											(change)="checkTimingStatus('saturday',$event)"
											[(ngModel)]="isSaturdayChecked">{{isSaturdayChecked ? 'Open':'Closed'}}
										</mat-slide-toggle>
									</td>
								</tr>
								<tr>
									<td>Sunday</td>
									<td><select class="form-control" id="start_sunday" name="start_sunday"
											formControlName="start_sunday">
											<option value="" [selected]="true" [disabled]="true">From</option>
											<!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
											<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
											</option>
										</select></td>
									<td><select class="form-control" id="end_sunday" name="end_sunday"
											formControlName="end_sunday">
											<option value="" [selected]="true" [disabled]="true">To</option>
											<!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
											<option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
											</option>
										</select></td>
									<td>
										<mat-slide-toggle formControlName="sunday"
											(change)="checkTimingStatus('sunday',$event)" [(ngModel)]="isSundayChecked">
											{{isSundayChecked ? 'Open':'Closed'}}</mat-slide-toggle>
									</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
				<!-- /card body -->
			</div>
			<!-- /card -->
			<!-- Card -->
			<div class="dt-card dt-card__full-height">
				<!-- Card Header -->
				<div class="dt-card__header">
					<!-- Card Heading -->
					<div class="dt-card__heading">
						<h3 class="dt-card__title">Additional</h3>
					</div>
					<!-- /card heading -->
				</div>
				<!-- /card header -->
				<!-- Card Body -->
				<div class="dt-card__body">
					<div class="form-row">
						<div class="col-sm-4 mb-3">
							<label for="rest_street_number">Is Tax Payable?</label><br />
							<div class="material-div">
								<mat-radio-group aria-label="Select an option">
									<mat-radio-button (change)="taxValue($event)" value="tax_true">Yes
									</mat-radio-button>
									<mat-radio-button (change)="taxValue($event)" value="tax_false">No
									</mat-radio-button>
								</mat-radio-group>
							</div>
							<small class="form-text"
								*ngIf="f.is_tax.invalid && (f.is_tax.dirty || f.is_tax.touched)"><small
									*ngIf="f.is_tax.errors.required">Invalid Value</small> </small>
						</div>
						<div class="col-sm-4 mb-3" *ngIf="tax == 'tax_true'">
							<label for="rest_street_number">Tax Number</label>
							<input type="text" class="form-control" id="tax_number" placeholder="Enter Tax Number"
								name="tax_number" formControlName="tax_number">
							<small class="form-text"
								*ngIf="f.tax_number.invalid && (f.tax_number.dirty || f.tax_number.touched)">
								<small *ngIf="f.tax_number.errors.required">Invalid Value</small>
							</small>
						</div>
						<div class="col-sm-4 mb-3" *ngIf="tax == 'tax_true'">
							<label for="rest_street_number">Tax Registration Date</label>
							<input type="date" class="form-control" id="tax_reg_date"
								placeholder="Enter Tax Registration Date" name="tax_reg_date"
								formControlName="tax_reg_date">
							<small class="form-text"
								*ngIf="f.tax_reg_date.invalid && (f.tax_reg_date.dirty || f.tax_reg_date.touched)">
								<small *ngIf="f.tax_reg_date.errors.required">Invalid Value</small>
							</small>
						</div>
					</div>
					<div class="form-row">
						<div class="col-sm-4">
							<label for="rest_street_number">Is SFCR?</label><br />
							<div class="material-div">
								<mat-radio-group aria-label="Select an option">
									<mat-radio-button (change)="sfcrValue($event)" value="sfcr_true">Yes
									</mat-radio-button>
									<mat-radio-button (change)="sfcrValue($event)" value="sfcr_false">No
									</mat-radio-button>
								</mat-radio-group>

							</div>
							<small class="form-text"
								*ngIf="f.is_sfcr.invalid && (f.is_sfcr.dirty || f.is_sfcr.touched)"><small
									*ngIf="f.is_sfcr.errors.required">Value Required</small>
							</small>
						</div>
						<div class="col-sm-8 mb-3" *ngIf="sfcr == 'sfcr_true'">
							<label for="rest_street_number">SFCR Number</label>
							<input type="text" class="form-control" id="tax_number" placeholder="Enter SFCR Number"
								name="sfcr_number" formControlName="sfcr_number">
							<small class="form-text"
								*ngIf="f.sfcr_number.invalid && (f.sfcr_number.dirty || f.sfcr_number.touched)">
								<small *ngIf="f.sfcr_number.errors.required">Value
									Required</small>
							</small>
						</div>
					</div>
				</div>
				<!-- /card body -->
			</div>
			<!-- /card -->
			<!-- Card -->
			<div class="dt-card dt-card__full-height">
				<!-- Card Header -->
				<div class="dt-card__header">
					<!-- Card Heading -->
					<div class="dt-card__heading">
						<h3 class="dt-card__title">Documents</h3>
					</div>
					<!-- /card heading -->
				</div>
				<!-- /card header -->
				<!-- Card Body -->
				<div class="dt-card__body">
					<div class="form-row">
						<div class="col-sm-3 mb-3">
							<label for="person_id_proof">ID Proof</label><br />
							<input type="file" accept="image/*" class="form-control" id="person_id_proof"
								name="person_id_proof" (change)="proofFile($event)" formControlName="person_id_proof">
							<small class="form-text"
								*ngIf="f.person_id_proof.invalid && (f.person_id_proof.dirty || f.person_id_proof.touched)"><small
									*ngIf="f.person_id_proof.errors.required">Invalid Value</small> </small>
						</div>
						<div class="col-sm-3 mb-3">
							<label for="tax_proof">Tax Proof</label>
							<input type="file" accept="image/*" class="form-control" id="tax_proof" name="tax_proof"
								(change)="taxFile($event)" formControlName="tax_proof">
							<small class="form-text"
								*ngIf="f.tax_proof.invalid && (f.tax_proof.dirty || f.tax_proof.touched)">
								<small *ngIf="f.tax_proof.errors.required">Invalid Value</small>
							</small>
						</div>
						<div class="col-sm-3 mb-3">
							<label for="sfcr_proof">SFCR Proof</label>
							<input type="file" accept="image/*" class="form-control" id="sfcr_proof" name="sfcr_proof"
								(change)="sfcrFile($event)" formControlName="sfcr_proof">
							<small class="form-text"
								*ngIf="f.sfcr_proof.invalid && (f.sfcr_proof.dirty || f.sfcr_proof.touched)">
								<small *ngIf="f.sfcr_proof.errors.required">Invalid Value</small>
							</small>
						</div>
						<div class="col-sm-3 mb-3">
							<label for="logoFile">Restaurant Logo</label>
							<input type="file" accept="image/*" class="form-control" id="logo" name="logo"
								(change)="logoFile($event)" formControlName="logo">
							<small class="form-text" *ngIf="f.logo.invalid && (f.logo.dirty || f.logo.touched)">
								<small *ngIf="f.logo.errors.required">Invalid Value</small>
							</small>
						</div>
					</div>
				</div>
				<!-- /card body -->
			</div>
			<!-- /card -->
			<!-- Card -->
			<div class="dt-card dt-card__full-height">
				<!-- Card Header -->
				<div class="dt-card__header">
					<!-- Card Heading -->
					<div class="dt-card__heading">
						<h3 class="dt-card__title">Location</h3>
					</div>
					<!-- /card heading -->
				</div>
				<!-- /card header -->
				<!-- Card Body -->
				<div class="dt-card__body">
					<div class="form-row">
						<div class="col-sm-6 mb-3">
							<label for="rest_city">City</label>
							<select class="form-control" id="rest_city" name="rest_city" formControlName="rest_city"
								[(ngModel)]="defaultOption">
								<option [value]=null>Select City</option>
								<option *ngFor="let city of cityList" [value]="city._id">{{city.city_name}}</option>
							</select>
							<small class="form-text"
								*ngIf="f.rest_city.invalid && (f.rest_city.dirty || f.rest_city.touched)">
								<small *ngIf="f.rest_city.errors.required">Invalid Value</small> </small>
						</div>
						<div class="col-sm-6 mb-3">
							<label for="rest_street_number">Street Number</label>
							<input type="text" class="form-control" id="rest_street_number"
								placeholder="Enter Street Number" name="rest_street_number"
								formControlName="rest_street_number"> <small class="form-text"
								*ngIf="f.rest_street_number.invalid && (f.rest_street_number.dirty || f.rest_street_number.touched)">
								<small *ngIf="f.rest_street_number.errors.required">Invalid Value</small> </small>
						</div>
					</div>
					<div class="form-row">
						<div class="col-sm-12 mb-3">
							<label>Enter address</label>
							<input type="text" class="form-control" (keydown.enter)="$event.preventDefault()"
								placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off"
								spellcheck="off" type="text" #search>
						</div>
					</div>
					<div class="form-row">
						<div class="col-sm-12 mb-3">
							<label>Location</label>
							<agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
								<agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
									(dragEnd)="markerDragEnd($event)"></agm-marker>
							</agm-map>
						</div>
					</div>
					<div class="form-row">
						<div class="col-sm-6 mb-3">
							<label for="rest_address">Address</label>
							<textarea class="form-control" id="rest_address" name="rest_address" placeholder="Address"
								formControlName="rest_address" readonly></textarea>
							<small class="form-text"
								*ngIf="f.rest_address.invalid && (f.rest_address.dirty || f.rest_address.touched)">
								<small *ngIf="f.rest_address.errors.required">Invalid Value</small> </small>
						</div>
						<div class="col-sm-6 mb-3">
							<label for="address_in_docs">Address in Documents</label>
							<textarea class="form-control" id="address_in_docs" name="address_in_docs" placeholder="Address"
								formControlName="address_in_docs"></textarea>
							<small class="form-text"
								*ngIf="f.address_in_docs.invalid && (f.address_in_docs.dirty || f.address_in_docs.touched)">
								<small *ngIf="f.address_in_docs.errors.required">Invalid Value</small> </small>
						</div>
					</div>
					<div class="form-row">
						<div class="col-xl-8 offset-xl-4">
							<button type="submit" class="btn btn-primary text-uppercase" [disabled]="!formGroup.valid">Save
							</button>
						</div>
					</div>
				</div>
				<!-- /card body -->
			</div>
			<!-- /card -->
		</form>
		<!--/form-->
                        </div>
                        <!-- /grid item -->
                    </div>
                    <!-- /grid -->
                    <!-- /Content -->
                </div>
            </div>
            <!-- /site content wrapper -->
        </main>
    </div>
</div>