import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { OrdersService } from 'src/app/shared/orders/orders.service';
import { ProductService } from 'src/app/shared/product/product.service';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { UserdataService } from 'src/app/shared/userdata.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-list-orders',
  templateUrl: './list-orders.component.html',
  styleUrls: ['./list-orders.component.css']
})
export class ListOrdersComponent implements OnInit {

  myGroup = new FormGroup({
    'startdate' : new FormControl(''),
    'enddate' : new FormControl(''),
  })
  
  statusList = [
    {
      'value':101,
      'name':'Order Placed'
    },
    {
      'value':102,
      'name':'Payment Pending'
    },
    {
      'value':103,
      'name':'Order Preparing'
    },
    {
      'value':104,
      'name':'Order Rejected'
    },
    {
      'value':105,
      'name':'Order Prepared'
    },
    {
      'value':106,
      'name':'Pickup'
    },
    {
      'value':107,
      'name':'On Way'
    },
    {
      'value':108,
      'name':'Delivered'
    },
    {
      'value':109,
      'name':'Canceled'
    },
    {
      'value':110,
      'name':'Timeout'
    },
    {
      'value':111,
      'name':'Refunded'
    },
  ]
  constructor(private modalService: NgbModal,private toast: ToastrService,private product: ProductService, private userdata: UserdataService,private orders:OrdersService, private datePipe: DatePipe,private restaurant:RestaurantService) { }

  p: number = 1;
  term: string;
  temp = [];
  restList = []
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]
  closeResult = '';
  columns = [{ prop: 'Order ID' },{ name: 'Payment Type' },{ name: 'Order Type' },{ name: 'Total' }, { name: 'Driver' }, { name: 'Status' }, { name: 'Details' }];
  ngOnInit(): void {
    this.getAllOrders()   
  }

  getAllOrders(){
    this.orders.restAllOrders({'restId':this.userdata.getId()}).subscribe(
      (res:any) => {
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  } 

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.order_name.toLowerCase().indexOf(val) !== -1 ||
      d.restId.rest_name.toLowerCase().indexOf(val) !== -1 ||
      !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  transformDate(date){
    return this.datePipe.transform( date,'MM-dd-yyyy');
  }

  searchByDate(){
    if(this.myGroup.get('startdate').value !='' && this.myGroup.get('enddate').value!=''){
      this.orders.restAllOrders({'startdate':this.myGroup.get('startdate').value,'enddate':this.myGroup.get('enddate').value,'restId':this.userdata.getId()}).subscribe(
        (res:any) => {
          console.log(res.data,this.myGroup.get('startdate').value,this.myGroup.get('enddate').value)
          //this.toast.success(res.message,'Success')
          this.rows = []
          this.temp = []
          this.rows = res.data
          this.temp = this.rows
          //this.currentWallet = res.currentWallet
        },
        err => {
          //console.log(err)
          this.toast.error(err.error.message,'Error')
        }
      )
    }
    else if(this.myGroup.get('startdate').value =='' && this.myGroup.get('enddate').value==''){
      this.getAllOrders()    
    }
    else
    {
      this.toast.error('Enter Start Date and End Date','Error')
    }
  }

  acceptOrder(id){
    this.restaurant.acceptRejectByRest({'restId':this.userdata.getId(),'orderId':id,'is_order_accepted':true}).subscribe(
      (res:any) =>{
        if(!res.success){
          this.toast.error(res.message,'Error')
        }
        this.getAllOrders()
      },err =>{
        this.getAllOrders()
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  readyOrder(id){
    this.restaurant.orderPrepared({'restId':this.userdata.getId(),'orderId':id}).subscribe(
      (res:any)=>{
        if(!res.success){
          this.toast.error(res.message,'Error')
        }
        this.getAllOrders()
      },err=>{
        this.getAllOrders()
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
