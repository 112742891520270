import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata.service';

@Injectable({
  providedIn: 'root'
})
export class CustomizeService {

  BaseUrl = ''
  Token = ''

  constructor(private http: HttpClient, @Inject('BASE_URL') _base,private userdata: UserdataService) {
    this.BaseUrl = _base
    this.Token = userdata.getToken()
  }

  public addSlider(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/addbanner",form, { headers: header_object})
  }

  public listSlider(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/allbanners",form, { headers: header_object})
  }

  public deleteSlider(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/deletebanner",form, { headers: header_object})
  }

  public addRestaurant(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/addtoprests",form, { headers: header_object})
  }

  public listRestaurant(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/alltoprest",form, { headers: header_object})
  }

  public deleteRestaurant(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/deletefromtoprest",form, { headers: header_object})
  }

  //Support Question
  public listSupportQuestion(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/showcustomizationarraydata",form, { headers: header_object})
  }

  public addSupportQuestion(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/addsupportquestion",form, { headers: header_object})
  }

  public deleteSupportQuestion(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/deleteothervaluue",form, { headers: header_object})
  }
}
