import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { OrdersService } from 'src/app/shared/orders/orders.service';
import { SupportService } from 'src/app/shared/support/support.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-listcustomerissue',
  templateUrl: './listcustomerissue.component.html',
  styleUrls: ['./listcustomerissue.component.css']
})
export class ListcustomerissueComponent implements OnInit {

  constructor(private toast: ToastrService,private support: SupportService, private userdata: UserdataService, private orders:OrdersService) { }

  p: number = 1; 
  adminId = ""
  term: string;
  temp = [];
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]
  columns = [{ prop: 'Restaurant' }, { name: 'Customer' },{ name: 'OrderID' }, { name: 'Payment Type' }, { name: 'Issue' }, { name: 'Status' }, { name: 'Reply' }, { name: 'Details' }];
  ngOnInit(): void {
    // for page number 1 as offset starts with 0
    //this.table.offset = 0;
    this.adminId = this.userdata.getId()
    this.getCustomerIssueList()    
  }

  getCustomerIssueList(){
    this.support.listCustomerIssue({}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.restId.rest_name.toLowerCase().indexOf(val) !== -1 ||
      d.customerId.customer_name.toLowerCase().indexOf(val) !== -1 ||
      String(d.orderId.orderId).toLowerCase().indexOf(val) !== -1 ||
      !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  //Refund COD support order issue
  refundCodSupportOrder(id){
    this.orders.refundOrder({'orderId':id,'is_cod':true,'refudmethod':'Coupon','is_coupon_refunded':true,'userId':this.userdata.getId(),'solution_text':'Coupon Generated for this issue.'}).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.getCustomerIssueList()
      },err =>{
        this.toast.success(err.error.message,'Error') 
      }
    )
  }

}
