import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { RestcategoryService } from 'src/app/shared/restcategory/restcategory.service';
import { RestsubcategoryService } from 'src/app/shared/restsubcategory/restsubcategory.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-edit-mysubcategory',
  templateUrl: './edit-mysubcategory.component.html',
  styleUrls: ['./edit-mysubcategory.component.css']
})
export class EditMysubcategoryComponent implements OnInit {

  formGroup = new FormGroup({
    restsubcategory_name: new FormControl('', [Validators.required]),
    restId: new FormControl('', [Validators.required]),
    _id: new FormControl('', [Validators.required]),
    restcatId: new FormControl('', [Validators.required]),
    meta_desc: new FormControl(''),
    meta_keywords: new FormControl('')
  }) 

  id = ''
  restList=[]
  categoryList=[]

  constructor(private toast: ToastrService, private userdata: UserdataService,private subcategory: RestsubcategoryService,private ngxService: NgxUiLoaderService, private router: Router, private activate: ActivatedRoute, private restaurant: RestaurantService, private category: RestcategoryService) { }

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.formGroup.patchValue({restId: this.userdata.getId()});  
    this.getDataById()
    this.getCategory()
  }

  get f(){
    return this.formGroup.controls
  }

  compareFn(c1: any, c2:any): boolean {     
    return c1 && c2 ? c1.id === c2.id : c1 === c2; 
  }

  getDataById(){
    let body = new URLSearchParams();
    body.set('_id', this.id);
    this.subcategory.getSubcategoryById(body).subscribe(
      (res:any) => {
        this.formGroup.patchValue({restsubcategory_name: res.data.restsubcategory_name});    
        this.formGroup.patchValue({_id: res.data._id});         
        this.formGroup.patchValue({restcatId: res.data.restcatId});
        this.f   
      },
      err => {
        console.log(err) 
        this.toast.error(err.error.message,'Error')
        this.router.navigateByUrl('/restaurant/subcategory/view')
      }
    )
  }

  getCategory(){
    // let body = new URLSearchParams();
    // body.set('status', 'Enabled');
    this.category.listCategory({"restId":this.userdata.getId()}).subscribe(
      (res:any) => {
        this.categoryList = res.data
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  }

  public onsubmit(){
    this.subcategory.restUpdateSubcategory(this.formGroup.value).subscribe(
      (res:any) => {
        // console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/restaurant/subcategory/view')
      },
      err => {
        // console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
