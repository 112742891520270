<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="active breadcrumb-item">Top Restaurant</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Manager</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title float-left">View Top Restaurant</h3>
                    <a class="btn btn-primary float-right" [routerLink]="['/admin/customize/restaurant/add']">Add Top Restaurant</a>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Tables -->
                <table class="table table-stripped table-borderless">
                    <thead>
                        <tr>
                            <th>Restaurant</th>
                            <th>Image</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let row of rows">
                        <tr *ngFor="let a of row.top_rest">
                            <td>
                                {{a.restId.rest_name}}
                            </td>
                            <td>
                                <img [src]="getSanitizerUrl(a.restId.logo)" width="100" height="200" class="img-fluid">
                            </td>
                            <td>
                                <button (click)="deleteRestaurantList(a.restId._id)" title="Delete Restautant" class="icon icon-trash icon-fw icon-lg icon-delete btn-delete"></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- /tables -->

            </div>
            <!-- /card body -->
        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->