import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomizeService } from 'src/app/shared/customize/customize.service';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-addtoprestaurant',
  templateUrl: './addtoprestaurant.component.html',
  styleUrls: ['./addtoprestaurant.component.css']
})
export class AddtoprestaurantComponent implements OnInit {

  formGroup = new FormGroup({
    restId: new FormControl('', [Validators.required]),
    userId: new FormControl('', [Validators.required]),
    added_by: new FormControl('', [Validators.required]),
    customization_type: new FormControl('', [Validators.required]),
  })

  userId = ""
  userName = ""
  restList=[]
  constructor(private customize:CustomizeService, private userdata: UserdataService, private toast: ToastrService, private router: Router, private restaurant: RestaurantService) { }

  ngOnInit(): void {
    this.userId = this.userdata.getId()
    this.userName = this.userdata.getName()
    this.formGroup.patchValue({userId:this.userId})
    this.formGroup.patchValue({added_by:this.userName})
    this.formGroup.patchValue({customization_type:2})
    this.getRestaurant()
  }

  get f(){
    return this.formGroup.controls
  }

  getRestaurant(){
    let body = new URLSearchParams();
    body.set('status', 'Enabled');
    this.restaurant.searchRestaurant(body).subscribe(
      (res:any) => {
        //console.log(res)
        this.restList = res.data
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  public onsubmit(){    
    this.customize.addRestaurant(this.formGroup.value).subscribe(
      (res:any) => {
        //console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/customize/restaurant');
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }


}
