import { Injectable } from '@angular/core';
declare var $:any
@Injectable({
  providedIn: 'root'
})
export class JsService {

  constructor() { }

  callLayoutClass(){
    $.getScript("../../../assets/assets/js/default/functions.js",function(){});
    $.getScript("../../../assets/assets/js/default/script.js",function(){});
  }
}
