<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/admin/menu/view']">View Menu</a></li>
        <li class="active breadcrumb-item">Product</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Tag</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <div class="col-6"><img [src]="getSanitizerUrl(rows.product_image)" class="img img-fluid img-circle" height="100" width="100"></div>
                    <div class="col-6"><h3 class="dt-card__title float-left">{{rows.product_name}}</h3></div>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">
                <table class="table table-borderless">
                    <tr>
                        <th>Category Name</th>
                        <th>Restaurant Name</th>
                        <th>Restaurant Category</th>
                        <th>Restaurant Subcategory</th>
                    </tr>
                    <tr>
                        <td>{{rows.catId.category_name}}</td>
                        <td>{{rows.restId.rest_name}}</td>
                        <td>{{rows.restcatId.rest_cat_name}}</td>
                        <td>{{rows.restsubcatId.restsubcategory_name}}</td>
                    </tr>
                    <tr><td colspan="4"><hr></td></tr>
                    <tr>
                        <th>Product Description</th>
                        <th>Meta Keywords</th>
                        <th>Service Type</th>
                        <th>Food Type</th>
                    </tr>
                    <tr>
                        <td>{{rows.product_description}}</td>
                        <td>{{rows.meta_keywords}}</td>
                        <td>{{rows.service_type}}</td>
                        <td>{{rows.veg_type}}</td>
                    </tr>
                    <tr><td colspan="4"><hr></td></tr>
                    <tr>
                        <th colspan="4">Other Tags</th>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <span *ngFor="let tag of rows.other_tags" class="badge badge-pill badge-info mr-1"><a class="icon icon-remove icon-fw" (click)="deleteAddOn(rows._id,tag._id,'othertags')"></a>
                                {{tag.producttagId.producttag_name}}
                            </span>
                        </td>
                    </tr>
                    <tr><td colspan="4"><hr></td></tr>
                    <tr>
                        <th colspan="4">Add-Ons&nbsp;<a [routerLink]="['/admin/menu/addons/', rows._id]" title="Add Add-Ons" ><i class="icon icon-circle-add-o icon-fw icon-lg icon-edit"></i></a></th>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <div *ngFor="let add of rows.addon" class="mb-1">
                                {{add.addontype}} <a class="icon icon-remove icon-fw icon-lg text-danger link-action" *ngIf="add.addontype!='Price'" (click)="deleteAddOn(rows._id,add._id,'addon')"></a><br/>
                                <span *ngFor="let addsub of add.addonvalue" class="badge badge-pill badge-primary mr-1">
                                    {{addsub.valuename}}-{{addsub.valuedetail}} 
                                </span>                                
                            </div>
                        </td>
                    </tr>
                    <tr><td colspan="4"><hr></td></tr>
                    <tr>
                        <th>Added By</th>
                        <td>{{rows.added_by}}</td>
                        <th>Updated By</th>
                        <td>{{rows.updated_by}}</td>
                    </tr>
                </table>
                
            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid --> 