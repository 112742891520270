import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomizeService } from 'src/app/shared/customize/customize.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-add-support-questions',
  templateUrl: './add-support-questions.component.html',
  styleUrls: ['./add-support-questions.component.css']
})
export class AddSupportQuestionsComponent implements OnInit {

  formGroup = new FormGroup({
    userId: new FormControl('', [Validators.required]),
    customization_name: new FormControl('Support Questions', [Validators.required]),
    added_by: new FormControl('', [Validators.required]),
    customization_type: new FormControl(8, [Validators.required]),
    value : new FormControl('',[Validators.required]),
    is_rest_fault : new FormControl(''),
  })
  constructor(private customize:CustomizeService, private userdata: UserdataService, private toast:ToastrService, private router: Router) { }

  ngOnInit(): void {
    var id = this.userdata.getId()
    var added = this.userdata.getUserType()
    this.formGroup.patchValue({userId: id});
    this.formGroup.patchValue({added_by: added});
  }

  get f(){
    return this.formGroup.controls
  }

  public onsubmit(){
    console.log(this.formGroup.get('is_rest_fault').value)
    this.customize.addSupportQuestion(this.formGroup.value).subscribe(
      (res:any)   =>{
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/customize/supportQuestion')
      },err =>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
