<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/admin/users/view']">Manager</a></li>
        <li class="active breadcrumb-item">Edit</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Manager</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title">Edit Manager</h3>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Form -->
                <form [formGroup]="formGroup" (submit)="onsubmit()">

                    <div class="form-row">
                        <div class="col-sm-6 mb-3">
                            <label for="name">Name</label>
                            <input type="text" class="form-control" id="name" placeholder="Enter Name" name="name" formControlName="name">
                            <small class="form-text" *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)">
                                <small *ngIf="f.name.errors.required">Invalid Value</small>
                            </small>
                        </div>
                        <div class="col-sm-6 mb-3">
                            <label for="email">Email</label>
                            <input type="email" class="form-control" id="email" placeholder="Enter Email" name="email" formControlName="email">
                            <small class="form-text" *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">
                                <small *ngIf="f.email.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col-sm-4 mb-3">
                            <label for="phone">Phone</label>
                            <input type="text" class="form-control" id="phone" placeholder="Enter Phone" name="phone" formControlName="phone">
                            <small class="form-text" *ngIf="f.phone.invalid && (f.phone.dirty || f.phone.touched)">
                                <small *ngIf="f.phone.errors.required">Invalid Value</small>
                                <small *ngIf="f.phone.errors.pattern">Invalid Phone Number</small>
                            </small>
                        </div>
                        <div class="col-sm-4 mb-3">
                            <label for="proof_type">Document Type</label>
                            <input type="text" class="form-control" id="proof_type" placeholder="Enter Proof Type" name="proof_type" formControlName="proof_type">
                            <small class="form-text" *ngIf="f.proof_type.invalid && (f.proof_type.dirty || f.proof_type.touched)">
                                <small *ngIf="f.proof_type.errors.required">Invalid Value</small>
                            </small>
                        </div>
                        <div class="col-sm-4 mb-3">
                            <label for="proof_number">Document Number</label>
                            <input type="text" class="form-control" id="proof_number" placeholder="Enter Proof Number" name="proof_number" formControlName="proof_number">
                            <small class="form-text" *ngIf="f.proof_number.invalid && (f.proof_number.dirty || f.proof_number.touched)">
                                <small *ngIf="f.proof_number.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col-sm-12 mb-3">
                            <label for="address">Address</label>
                            <textarea class="form-control" id="address" placeholder="Enter Address" name="address" formControlName="address"></textarea>
                            <small class="form-text" *ngIf="f.address.invalid && (f.address.dirty || f.address.touched)">
                                <small *ngIf="f.address.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <div class="col-xl-9 offset-xl-3">
                            <button type="submit" class="btn btn-primary text-uppercase" [disabled]="!formGroup.valid">Save
                            </button>
                        </div>
                    </div>
                    <!-- /form group -->

                </form>
                <!-- /form -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->
