import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { CityService } from 'src/app/shared/city/city.service';
import { UserdataService } from 'src/app/shared/userdata.service';
import { DeliveryService } from 'src/app/shared/delivery/delivery.service';

@Component({
  selector: 'app-adddelivery',
  templateUrl: './adddelivery.component.html',
  styleUrls: ['./adddelivery.component.css']
})
export class AdddeliveryComponent implements OnInit {

  formGroup = new FormGroup({
    cityId: new FormControl('', [Validators.required]),
    area: new FormControl('', [Validators.required]),
    street: new FormControl('', [Validators.required]),
    sector: new FormControl('', [Validators.required]),
    pincode: new FormControl('', [Validators.required]),
    charges: new FormControl('', [Validators.required]),
    added_by: new FormControl('', [Validators.required]),
    userId: new FormControl('', [Validators.required]),
  })

  constructor(private toast: ToastrService, private formBuilder: FormBuilder, private router: Router, private city: CityService, private userdata: UserdataService, private delivery: DeliveryService) {}

  cityList = []

  ngOnInit(): void {
    this.getCity()
    var added = this.userdata.getUserType()
    var userId = this.userdata.getId()
    this.formGroup.patchValue({ added_by: added });
    this.formGroup.patchValue({ userId: userId });
  }

  get f() {
    return this.formGroup.controls
  }

  getCity() {
    this.city.listCity({}).subscribe(
      (res: any) => {
        this.cityList = res.data
      },
      err => {
        //console.log(err)
      }
    )
  }

  public onsubmit() {
    this.delivery.addDelivery(this.formGroup.value).subscribe(
      (res:any) => {
        //console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/delivery/view');
      },
      err => {
        console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }
}
