import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-addtopproduct',
  templateUrl: './addtopproduct.component.html',
  styleUrls: ['./addtopproduct.component.css']
})
export class AddtopproductComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
