import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from 'src/app/shared/category/category.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-listcategory',
  templateUrl: './listcategory.component.html',
  styleUrls: ['./listcategory.component.css']
})
export class ListcategoryComponent implements OnInit {

  imageURL = ''
  constructor(private toast: ToastrService,private category: CategoryService,private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl, private userdata:UserdataService) {
    this.imageURL = _imageurl
  }

  p: number = 1;
  term: string;
  temp = [];
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]
  columns = [{ prop: 'Name' }, { name: 'Meta Description' }, { name: 'Meta Keywords' }, { name: 'Image' }, { name: 'Status' }, { name: 'Added By' }, { name: 'Action' }];
  ngOnInit(): void {
    // for page number 1 as offset starts with 0
    //this.table.offset = 0;
    this.getCatgoryList()

  }

  getCatgoryList(){
    this.category.listCategory({}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  public getSanitizerUrl(photoname){
    return this.trusturl.bypassSecurityTrustUrl(this.imageURL + photoname)
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.category_name.toLowerCase().indexOf(val) !== -1 ||
      d.meta_desc.toLowerCase().indexOf(val) !== -1 ||
      d.meta_keywords.toLowerCase().indexOf(val) !== -1 ||
      d.status.toLowerCase().indexOf(val) !== -1 ||
      !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  changeStatus(val,id){
    const data = new FormData()
    data.append("status",val)
    data.append("_id",id)
    this.category.updateCategory(data).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.getCatgoryList()
      },
      err => {
        //console.log(err)
        //this.toast.error(err.error.message,'Error')
        this.getCatgoryList()
      }
    )
  }

}
