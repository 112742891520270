import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { CityService } from 'src/app/shared/city/city.service';
import { ServiceService } from 'src/app/shared/service/service.service';
import { CuisineService } from 'src/app/shared/cuisine/cuisine.service';
import { TagService } from 'src/app/shared/tag/tag.service';
import { UserdataService } from 'src/app/shared/userdata.service';
import { TimingService } from 'src/app/shared/timing/timing.service';  
import { MapsAPILoader, MouseEvent  } from '@agm/core';

export interface AutoCompleteModel {
  _id: any;
  cuisine_name: string;
}

@Component({
  selector: 'app-addrestaurant',
  templateUrl: './addrestaurant.component.html',
  styleUrls: ['./addrestaurant.component.css']
})

export class AddrestaurantComponent implements OnInit {

  //-----------Map
  latitude: number;

  longitude: number;

  zoom: number;

  address: string;

  private geoCoder; 



  @ViewChild('search')
  public searchElementRef: ElementRef;
  
  

  markers: marker[] = [
	  {
		  lat: 43.7181557,
		  lng: -79.5181431,
		  label: 'A',
		  draggable: true
	  }
  ]

  formGroup = new FormGroup({
    rest_lat: new FormControl('', [Validators.required]),
    rest_long: new FormControl('', [Validators.required]),
    person_email: new FormControl('', [Validators.required]),
    person_phone: new FormControl('', [Validators.required, Validators.pattern("[0-9]{10}")]),
    person_password: new FormControl('', [Validators.required]),
    person_designation: new FormControl('', [Validators.required]),
    person_name: new FormControl('', [Validators.required]),
    rest_name: new FormControl('', [Validators.required]),
    rest_address: new FormControl('', [Validators.required]),
    rest_phone: new FormControl('', [Validators.required, Validators.pattern("[0-9]{10}")]),
    rest_city: new FormControl('', [Validators.required]),
    rest_street_number: new FormControl('', [Validators.required]),
    serve_alchohal: new FormControl('', [Validators.required]),
    services: new FormControl('', [Validators.required]),
    setting_type: new FormControl('', [Validators.required]),
    payment_type: new FormControl('', [Validators.required]),
    cuisines: new FormControl('', [Validators.required]),
    tags: new FormControl('', [Validators.required]),
    vegtype: new FormControl('Veg', [Validators.required]),
    is_tax: new FormControl('', [Validators.required]),
    tax_number: new FormControl(''),
    tax_reg_date: new FormControl(''),
    is_sfcr: new FormControl('', [Validators.required]),
    sfcr_number: new FormControl(''),
    sfcr_expiry_date: new FormControl(''),
    address_in_docs: new FormControl('', [Validators.required]),
    is_self_reg: new FormControl('', [Validators.required]),
    added_by: new FormControl('', [Validators.required]),
    addedId: new FormControl('', [Validators.required]),
    person_id_proof: new FormControl('', [Validators.required]),
    tax_proof: new FormControl('', [Validators.required]),
    sfcr_proof: new FormControl('', [Validators.required]),
    logo: new FormControl('', [Validators.required]),
    terms: new FormControl(true, [Validators.requiredTrue]),
    monday: new FormControl(''),start_monday: new FormControl(''),end_monday: new FormControl(''),
    tuesday: new FormControl(''),start_tuesday: new FormControl(''),end_tuesday: new FormControl(''),
    wednesday: new FormControl(''),start_wednesday: new FormControl(''),end_wednesday: new FormControl(''),
    thursday: new FormControl(''),start_thursday: new FormControl(''),end_thursday: new FormControl(''),
    friday: new FormControl(''),start_friday: new FormControl(''),end_friday: new FormControl(''),
    saturday: new FormControl(''),start_saturday: new FormControl(''),end_saturday: new FormControl(''),
    sunday: new FormControl(''),start_sunday: new FormControl(''),end_sunday: new FormControl(''),
    orders: new FormArray([]),
  })

  constructor(private mapsAPILoader: MapsAPILoader,private ngZone: NgZone,private toast: ToastrService, private formBuilder: FormBuilder, private restaurant: RestaurantService, private router: Router, private city: CityService, private service: ServiceService, private cuisine: CuisineService, private tag: TagService, private userdata: UserdataService, private timing: TimingService) {
    // this.formGroup = this.formBuilder.group({
    //   orders: new FormArray([])
    // });
  }

  cityList = []
  serviceList = []
  cuisineList = []
  tagList = [] 
  timingList = []
  timingStat = ["0","1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24"]
  defaultOption = null;
  servicetags = []
  cuisinetags = []
  tags = []
  serviceJson = []
  cuisineJson = []
  tagJson = []
  mondayJson = []
  tuesdayJson = []
  wednesdayJson = []
  thursdayJson = []
  fridayJson = []
  saturdayJson = []
  sundayJson = []

  ngOnInit(): void {
    this.getCity()
    this.getService()
    this.getCuisine()
    this.getTag()
    // this.getTiming()
    var added = this.userdata.getUserType()
    var userId = this.userdata.getId()
    this.formGroup.patchValue({ added_by: added });
    this.formGroup.patchValue({ addedId: userId });
    this.formGroup.patchValue({ is_self_reg: false });
    this.formGroup.patchValue({ person_designation: 'Owner' });
    this.formGroup.patchValue({ serve_alchohal: 'no' });

    //------Map
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.getAddress(this.latitude,this.longitude)
        });
      });
    });
  }

  get f() {
    return this.formGroup.controls
  }

  getCity() {
    this.city.listCity({}).subscribe(
      (res: any) => {
        this.cityList = res.data
      },
      err => {
        console.log(err)
      }
    )
  }
  

  getService() {
    this.service.listService({'status':'Enabled'}).subscribe(
      (res: any) => {
        //console.log(res.data)
        this.serviceList = res.data
        this.serviceList.forEach(() => this.ordersFormArray.push(new FormControl(false)));
      },
      err => {
        console.log(err)
      }
    )
  }

  get ordersFormArray() {
    return this.formGroup.controls.orders as FormArray;
  }

  getCuisine() {
    this.cuisine.listCuisine({'status':'Enabled'}).subscribe(
      (res: any) => {
        this.cuisineList = res.data
      },
      err => {
        console.log(err)
      }
    )
  }

  getTag() {
    this.tag.listTag({'status':'Enabled'}).subscribe(
      (res: any) => {
        this.tagList = res.data
      },
      err => {
        console.log(err)
      }
    )
  }
//---------No use------------//
  Permissions = []
  public handle(per, event, i) {
    //console.log(i)
    if (event) {
      this.Permissions.push({ "PermissionName": per.permissionName })
    } else {
      this.Permissions.splice(i, 1)
    }
  }
//-------/No use-------------//
  // getTiming() {
  //   this.timing.listTiming().subscribe(
  //     (res: any) => {
  //       this.timingList = res.data
  //     },
  //     err => {
  //       console.log(err)
  //     }
  //   )
  // }

  public proofFile(evnt){
    if(evnt.target.files.length > 0)
    {
      this.formGroup.patchValue({
        person_id_proof: evnt.target.files[0]
      })
    }
  }

  public taxFile(evnt){
    if(evnt.target.files.length > 0)
    {
      this.formGroup.patchValue({
        tax_proof: evnt.target.files[0]
      })
    }
  }

  public sfcrFile(evnt){
    if(evnt.target.files.length > 0)
    {
      this.formGroup.patchValue({
        sfcr_proof: evnt.target.files[0]
      })
    }
  }

  public logoFile(evnt){
    if(evnt.target.files.length > 0)
    {
      this.formGroup.patchValue({
        logo: evnt.target.files[0]
      })
    }
  }

  public paymentTypeValue(){
    if (this.formGroup.get("payment_type").value == "Both_payment") {
      return "Both"
    }
    return this.formGroup.get("payment_type").value
  }

  public foodTypeValue(){
    if (this.formGroup.get("vegtype").value == "food_both") {
      return "Both"
    }
    return this.formGroup.get("vegtype").value
  }

  public onsubmit() {
    if(this.formGroup.get("monday").value)
    {
      this.mondayJson.push({"starttime":this.formGroup.get("start_monday").value,"endtime":this.formGroup.get("end_monday").value})
    }
    else
    {
      this.mondayJson = []
    }
    if(this.formGroup.get("tuesday").value)
    {
      this.tuesdayJson.push({"starttime":this.formGroup.get("start_tuesday").value,"endtime":this.formGroup.get("end_tuesday").value})
    }
    else
    {
      this.tuesdayJson = []
    }
    if(this.formGroup.get("wednesday").value)
    {
      this.wednesdayJson.push({"starttime":this.formGroup.get("start_wednesday").value,"endtime":this.formGroup.get("end_wednesday").value})
    }
    else
    {
      this.wednesdayJson = []
    }
    if(this.formGroup.get("thursday").value)
    {
      this.thursdayJson.push({"starttime":this.formGroup.get("start_thursday").value,"endtime":this.formGroup.get("end_thursday").value})
    }
    else
    {
      this.thursdayJson = []
    }
    if(this.formGroup.get("friday").value)
    {
      this.fridayJson.push({"starttime":this.formGroup.get("start_friday").value,"endtime":this.formGroup.get("end_friday").value})
    }
    else
    {
      this.fridayJson = []
    }
    if(this.formGroup.get("saturday").value)
    {
      this.saturdayJson.push({"starttime":this.formGroup.get("start_saturday").value,"endtime":this.formGroup.get("end_saturday").value})
    }
    else
    {
      this.saturdayJson = []
    }
    if(this.formGroup.get("sunday").value)
    {
      this.sundayJson.push({"starttime":this.formGroup.get("start_sunday").value,"endtime":this.formGroup.get("end_sunday").value})
    }
    else
    {
      this.sundayJson = []
    }
    // console.log(JSON.stringify(this.thursdayJson),JSON.stringify(this.mondayJson),JSON.stringify(this.tuesdayJson),JSON.stringify(this.wednesdayJson),JSON.stringify(this.fridayJson),JSON.stringify(this.saturdayJson),JSON.stringify(this.sundayJson))
    //-------service tags json------//
    var ser = this.formGroup.get("services").value
    ser.forEach(element => {
      this.servicetags.push(element._id)
    });
    this.servicetags.forEach(element => {
      this.serviceJson.push({"serviceId":element})
    });
    //console.log(this.serviceJson)
    //-------/service tags json-----//
    //-------cuisine tags json------//
    var cuis = this.formGroup.get("cuisines").value
    cuis.forEach(element => {
      this.cuisinetags.push(element._id)
    });
    this.cuisinetags.forEach(element => {
      this.cuisineJson.push({"cuisineId":element})
    });
    //console.log(this.cuisineJson)
    //-------/cuisine tags json-----//
    //-------tags json------//
    var t = this.formGroup.get("tags").value
    t.forEach(element => {
      this.tags.push(element._id)
    });
    this.tags.forEach(element => {
      this.tagJson.push({"tagId":element})
    });
    //console.log(this.tagJson)
    //-------/tags json-----//
    const data = new FormData()
    data.append("person_email",this.formGroup.get("person_email").value)
    data.append("person_phone",this.formGroup.get("person_phone").value)
    data.append("person_password",this.formGroup.get("person_password").value)
    data.append("person_designation",this.formGroup.get("person_designation").value)
    data.append("person_name",this.formGroup.get("person_name").value)
    data.append("rest_name",this.formGroup.get("rest_name").value)
    data.append("rest_address",this.formGroup.get("rest_address").value)
    data.append("rest_phone",this.formGroup.get("rest_phone").value)
    data.append("rest_city",this.formGroup.get("rest_city").value)
    data.append("rest_street_number",this.formGroup.get("rest_street_number").value)
    data.append("serve_alchohal",this.formGroup.get("serve_alchohal").value)
    data.append("services",JSON.stringify(this.serviceJson))
    data.append("setting_type",this.formGroup.get("setting_type").value)
    data.append("payment_type",this.paymentTypeValue())
    data.append("cuisines",JSON.stringify(this.cuisineJson))
    data.append("tags",JSON.stringify(this.tagJson))
    data.append("vegtype",this.foodTypeValue())
    data.append("is_tax",this.formGroup.get("is_tax").value)
    data.append("tax_number",this.formGroup.get("tax_number").value)
    data.append("tax_reg_date",this.formGroup.get("tax_reg_date").value)
    data.append("is_sfcr",this.formGroup.get("is_sfcr").value)
    data.append("sfcr_number",this.formGroup.get("sfcr_number").value)
    data.append("address_in_docs",this.formGroup.get("address_in_docs").value)
    data.append("is_self_reg",this.formGroup.get("is_self_reg").value)
    data.append("added_by",this.formGroup.get("added_by").value)
    data.append("addedId",this.formGroup.get("addedId").value)
    data.append("person_id_proof",this.formGroup.get("person_id_proof").value)
    data.append("tax_proof",this.formGroup.get("tax_proof").value)
    data.append("sfcr_proof",this.formGroup.get("sfcr_proof").value)
    data.append("rest_lat",String(this.formGroup.get("rest_lat").value))
    data.append("rest_long",String(this.formGroup.get("rest_long").value))
    data.append("logo",this.formGroup.get("logo").value)
    data.append("terms",this.formGroup.get("terms").value)
    data.append("monday",JSON.stringify(this.mondayJson))
    data.append("tuesday",JSON.stringify(this.tuesdayJson))
    data.append("wednesday",JSON.stringify(this.wednesdayJson))
    data.append("thrusday",JSON.stringify(this.thursdayJson))
    data.append("friday",JSON.stringify(this.fridayJson))
    data.append("saturday",JSON.stringify(this.saturdayJson))
    data.append("sunday",JSON.stringify(this.sundayJson))
    this.restaurant.addRestaurant(data).subscribe(
      (res:any) => {
        //console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('admin/restaurant/listaggreement');
      },
      err => {
        console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  //---------------------Map
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          this.formGroup.patchValue({rest_address:this.address})
          this.formGroup.patchValue({rest_lat:latitude})
          this.formGroup.patchValue({rest_long:longitude})
          //this.formGroup.patchValue({pincode:results[0].address_components[9].long_name})
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  markerDragEnd($event: MouseEvent) {
    this.formGroup.patchValue({'rest_lat':$event.coords.lat})
    this.formGroup.patchValue({'rest_long':$event.coords.lng})
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  //-------------Updation on 21-01-2021
  checkTimingStatus(val, event) {
    if (val == 'sunday') {
      if (!event.checked) {
        this.formGroup.get('end_sunday').disable();
        this.formGroup.get('start_sunday').disable();
      } else {
        this.formGroup.get('start_sunday').enable();
        this.formGroup.get('end_sunday').enable();
      }
    }
    if (val == 'monday') {
      if (!event.checked) {
        this.formGroup.get('end_monday').disable();
        this.formGroup.get('start_monday').disable();
      } else {
        this.formGroup.get('start_monday').enable();
        this.formGroup.get('end_monday').enable();
      }
    }
    if (val == 'tuesday') {
      if (!event.checked) {
        this.formGroup.get('end_tuesday').disable();
        this.formGroup.get('start_tuesday').disable();
      } else {
        this.formGroup.get('start_tuesday').enable();
        this.formGroup.get('end_tuesday').enable();
      }
    }
    if (val == 'wednesday') {
      if (!event.checked) {
        this.formGroup.get('end_wednesday').disable();
        this.formGroup.get('start_wednesday').disable();
      } else {
        this.formGroup.get('start_wednesday').enable();
        this.formGroup.get('end_wednesday').enable();
      }
    }
    if (val == 'thursday') {
      if (!event.checked) {
        this.formGroup.get('end_thursday').disable();
        this.formGroup.get('start_thursday').disable();
      } else {
        this.formGroup.get('start_thursday').enable();
        this.formGroup.get('end_thursday').enable();
      }
    }
    if (val == 'friday') {
      if (!event.checked) {
        this.formGroup.get('end_friday').disable();
        this.formGroup.get('start_friday').disable();
      } else {
        this.formGroup.get('start_friday').enable();
        this.formGroup.get('end_friday').enable();
      }
    }
    if (val == 'saturday') {
      if (!event.checked) {
        this.formGroup.get('end_saturday').disable();
        this.formGroup.get('start_saturday').disable();
      } else {
        this.formGroup.get('start_saturday').enable();
        this.formGroup.get('end_saturday').enable();
      }
    }
  }

  isMondayChecked: boolean = true;isTuesdayChecked: boolean = true;isWednesdayChecked: boolean = true;isThursdayChecked: boolean = true;isFridayChecked: boolean = true;isSaturdayChecked: boolean = true;isSundayChecked: boolean = true
  tax = 'tax_true'
  sfcr = 'sfcr_true'

  public taxValue(event){
    if(event.value == "tax_false"){
      this.formGroup.patchValue({'is_tax':false})
      this.tax = 'tax_false'
    }else{
      this.formGroup.patchValue({'is_tax':true})
      this.tax = 'tax_true'
    }
  }

  public sfcrValue(event){
    if(event.value == "sfcr_false"){
      this.formGroup.patchValue({'is_sfcr':false})
      this.sfcr = 'sfcr_false'
    }else{
      this.formGroup.patchValue({'is_sfcr':true})
      this.sfcr = 'sfcr_true'
    }
  }
} 

// just an interface for type safety.
interface marker {
	lat: number;
	lng: number;
	label?: string;
	draggable: boolean;
}
