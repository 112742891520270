import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DriverService } from 'src/app/shared/driver/driver.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-editdriver',
  templateUrl: './editdriver.component.html',
  styleUrls: ['./editdriver.component.css']
})
export class EditdriverComponent implements OnInit {

  visaList = ["Visitor Visa","Student Visa","Work Permit","Citizenship"]

  formGroup = new FormGroup({
    driver_email: new FormControl('', [Validators.required]),
    driver_name: new FormControl('', [Validators.required]),
    driver_phone: new FormControl('', [Validators.required, Validators.pattern("[0-9]{10}")]),
    driver_address: new FormControl('', [Validators.required]),
    province: new FormControl('', [Validators.required]),
    zip_code : new FormControl('', [Validators.required]),
    other_detail: new FormControl(''),
    vehicle_type: new FormControl('', [Validators.required]),
    vehicle_number: new FormControl('', [Validators.required]),
    driving_lic_number: new FormControl('', [Validators.required]),
    vehicle_reg_pic: new FormControl(''),
    dl_reg_pic: new FormControl(''),
    dlexpire : new FormControl('', [Validators.required]),
    driver_idproff_pic: new FormControl(''),
    payment_amount: new FormControl('', [Validators.required]),
    sin_number: new FormControl('', [Validators.required]),
    visa_type: new FormControl('', [Validators.required]),
    updated_Id: new FormControl('', [Validators.required]),
  })

  constructor(private toast: ToastrService,private driver:DriverService, private router: Router, private activate : ActivatedRoute, private userdata:UserdataService) { }

  id = ''

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getDataById()
    var user = this.userdata.getId()
    this.formGroup.patchValue({'updated_Id':user})
  }

  get f(){
    return this.formGroup.controls
  }

  compareFn(c1: any, c2:any): boolean {     
    return c1 && c2 ? c1.id === c2.id : c1 === c2; 
  }

  public proofFile(evnt){
    if(evnt.target.files.length > 0)
    {
      this.formGroup.patchValue({
        driver_idproff_pic: evnt.target.files[0]
      })
    }
  }

  public taxFile(evnt){
    if(evnt.target.files.length > 0)
    {
      this.formGroup.patchValue({
        dl_reg_pic: evnt.target.files[0]
      })
    }
  }

  public vehicleFile(evnt){
    if(evnt.target.files.length > 0)
    {
      this.formGroup.patchValue({
        vehicle_reg_pic: evnt.target.files[0]
      })
    }
  }

  data = ''
  getDataById(){
    this.driver.getDriverById({"_id":this.id}).subscribe(
      (res:any) => {
        this.formGroup.patchValue({zip_code: res.data.zip_code});  
        this.formGroup.patchValue({province: res.data.province});  
        this.formGroup.patchValue({dlexpire: res.data.dlexpire});  
        this.formGroup.patchValue({driver_email: res.data.driver_email});     
        this.formGroup.patchValue({driver_name: res.data.driver_name});    
        this.formGroup.patchValue({driver_phone: res.data.driver_phone});     
        this.formGroup.patchValue({driver_address: res.data.driver_address});     
        this.formGroup.patchValue({other_detail: res.data.other_detail});  
        this.formGroup.patchValue({vehicle_type: res.data.vehicle_type});  
        this.formGroup.patchValue({vehicle_number: res.data.vehicle_number});  
        this.formGroup.patchValue({driving_lic_number: res.data.driving_lic_number});
        this.formGroup.patchValue({payment_amount: res.data.payment_amount}); 
        this.formGroup.patchValue({sin_number: res.data.sin_number});  
        this.formGroup.patchValue({visa_type: res.data.visa_type});  
        this.f   
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        this.router.navigateByUrl('/admin/driver/view')
      }
    )
  }

  public onsubmit(){
    //console.log(this.formGroup.get("driver_email").value)
    const data = new FormData()
    data.append("driver_email",this.formGroup.get("driver_email").value)
    data.append("driver_name",this.formGroup.get("driver_name").value)
    data.append("driver_phone",this.formGroup.get("driver_phone").value)
    data.append("driver_address",this.formGroup.get("driver_address").value)
    data.append("other_detail",this.formGroup.get("other_detail").value)
    data.append("vehicle_type",this.formGroup.get("vehicle_type").value)
    data.append("vehicle_number",this.formGroup.get("vehicle_number").value)
    data.append("driving_lic_number",this.formGroup.get("driving_lic_number").value)
    data.append("vehicle_reg_pic",this.formGroup.get("vehicle_reg_pic").value)
    data.append("dl_reg_pic",this.formGroup.get("dl_reg_pic").value)
    data.append("driver_idproff_pic",this.formGroup.get("driver_idproff_pic").value)
    data.append("payment_amount",this.formGroup.get("payment_amount").value)
    data.append("sin_number",this.formGroup.get("sin_number").value)
    data.append("visa_type",this.formGroup.get("visa_type").value)
    data.append("dlexpire",this.formGroup.get("dlexpire").value)
    data.append("province",this.formGroup.get("province").value)
    data.append("zip_code",this.formGroup.get("zip_code").value)
    data.append("updated_Id",this.formGroup.get("updated_Id").value)
    this.driver.updateDriver(data).subscribe(
      (res:any) => {
        // console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/driver/view')
      },
      err => {
        // console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
