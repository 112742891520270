import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { OrdersService } from 'src/app/shared/orders/orders.service';
import { ProductService } from 'src/app/shared/product/product.service';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-pending-myorders',
  templateUrl: './pending-myorders.component.html',
  styleUrls: ['./pending-myorders.component.css']
})
export class PendingMyordersComponent implements OnInit {

  constructor(private modalService: NgbModal,private toast: ToastrService,private product: ProductService, private userdata: UserdataService,private orders:OrdersService, private datePipe: DatePipe,private restaurant:RestaurantService) { }

  p: number = 1;
  term: string;
  temp = [];
  restList = []
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]
  closeResult = '';
  columns = [{ prop: 'Order ID' },{ name: 'Payment Type' },{ name: 'Total' }, { name: 'Status' },{ name: 'Action' }, { name: 'Details' }];
  ngOnInit(): void {
    this.getRestPendingOrders()   
  }

  getRestPendingOrders(){
    this.orders.restPendingOrders({'restId':this.userdata.getId()}).subscribe(
      (res:any) => {
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  } 

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.order_name.toLowerCase().indexOf(val) !== -1 ||
      d.restId.rest_name.toLowerCase().indexOf(val) !== -1 ||
      !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  transformDate(date){
    return this.datePipe.transform( date,'MM-dd-yyyy');
  }

  acceptOrder(id){
    this.restaurant.acceptRejectByRest({'restId':this.userdata.getId(),'orderId':id,'is_order_accepted':true}).subscribe(
      (res:any) =>{
        if(!res.success){
          this.toast.error(res.message,'Error')
        }
        this.getRestPendingOrders()
      },err =>{
        this.getRestPendingOrders()
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
