import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  BaseUrl = ''
  Token = ''

  constructor(private http: HttpClient, @Inject('BASE_URL') _base,private userdata: UserdataService) {
    this.BaseUrl = _base
    this.Token = userdata.getToken()
  }

  public countRest(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/totalcountrest",form, { headers: header_object})
  }

  public countCustomer(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/totalcountcustomer",form, { headers: header_object})
  }

  public countDriver(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/totalcountdrivers",form, { headers: header_object})
  }

  public countAds(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/totalcountads",form, { headers: header_object})
  }

  public countOffers(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/totalcountoffer",form, { headers: header_object})
  }

  public countOrderStatus(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/allordersstatus",form, { headers: header_object})
  }

  public newRestaurant(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/newrestregister",form, { headers: header_object})
  }

  public newDriver(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/newdriverregister",form, { headers: header_object})
  }

  //-----------------------Web Restaurant Panel
  public restCountOrderStatus(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/rest/allordersstatusrestdashboard",form, { headers: header_object})
  }

  public restVariousCount(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/rest/restdashboard",form, { headers: header_object})
  }

  public restActiveDriverList(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/rest/showcurrentactivedrivers",form, { headers: header_object})
  }

  public restPendingDriverList(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/rest/newdriverinmyrest",form, { headers: header_object})
  }
}
