import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable'; 
import { UserdataService } from 'src/app/shared/userdata.service';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { OrdersService } from 'src/app/shared/orders/orders.service';
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from '@angular/router';
import { DriverService } from 'src/app/shared/driver/driver.service';

@Component({
  selector: 'app-assignorder',
  templateUrl: './assignorder.component.html',
  styleUrls: ['./assignorder.component.css'],
  providers: [DatePipe]
})
export class AssignorderComponent implements OnInit {

  constructor(private toast: ToastrService, private userdata: UserdataService,private orders:OrdersService,private driver:DriverService, private datePipe: DatePipe, private activate:ActivatedRoute, private router:Router) { }

  id=''
  p: number = 1;
  term: string;
  temp = [];
  restList = []
  orderList:any
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows = []
  columns = [{ prop: 'Driver Name' },{ name: 'Total Orders' },{ name: 'Cash In Hand' }, { name: 'Action' }];
  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getSingleOrder() 
  }

  getDrivers(id){
    this.driver.listDriver({'restId':id}).subscribe(
      (res:any) => {
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        this.toast.error(err.error.message,'Error')
      }
    )
  } 

  getSingleOrder(){
    this.orders.getOrderById({'_id':this.id}).subscribe(
      (res:any) => {
        this.orderList = res.data
        this.getDrivers(res.data.restId._id)
      },
      err => {
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.driver_name.toLowerCase().indexOf(val) !== -1 ||      
      !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  transformDate(date){
    return this.datePipe.transform( date,'MM-dd-yyyy');
  }

  assignOrder(event){
    //console.log(event.target.value)
    var data = {
      'driverId' : event.target.value,
      'orderId'  : this.id
    }
    this.orders.assignOrderManually(data).subscribe(
      (res:any) => {
        //console.log(res.data)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/home')
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
