<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="active breadcrumb-item">Slider</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Manager</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title float-left">View Slider</h3>
                    <a class="btn btn-primary float-right" [routerLink]="['/admin/customize/slider/add']">Add Slider</a>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Tables -->
                <ngx-datatable
                #table
                class="material"
                [columns]="columns"
                [columnMode]="ColumnMode.flex"
                [headerHeight]="50"
                [footerHeight]="50"
                rowHeight="auto"
                [limit]="10"
                [rows]="rows"
                #myFilterTable
                >
                    
                    <ngx-datatable-column name="Web Banner" prop="banner_image" [flexGrow]="2">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <a target="_blank" [attr.href]="getSanitizerUrl(row.banner_image)"><img [src]="getSanitizerUrl(row.banner_image)" class="img img-fluid img-custom"></a>
                        </ng-template>
                    </ngx-datatable-column>                    
                    <ngx-datatable-column name="Action" [flexGrow]='1'>
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <button (click)="deleteSliderList(row._id)" title="Delete Banner" class="icon icon-trash icon-fw icon-lg icon-delete btn-delete"></button>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
                <!-- /tables -->

            </div>
            <!-- /card body -->
        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->