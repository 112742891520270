<div class="dt-sidebar__container">
  <!-- Sidebar Navigation -->
  <ul class="dt-side-nav">
    <!-- Menu Header -->
    <li class="dt-side-nav__item dt-side-nav__header"> <span class="dt-side-nav__text">main</span> </li>
    <!-- /menu header -->
    <!-- Menu Item -->
    <li class="dt-side-nav__item">
      <a [routerLink]="['/admin/home']" routerLinkActive="active-link" class="dt-side-nav__link"> <i class="icon icon-dashboard icon-fw icon-lg"></i>
        <span class="dt-side-nav__text">Dashboard</span> </a>
    </li>
    <!-- <li class="dt-side-nav__item">
      <a href="javascript:void(0)" class="dt-side-nav__link dt-side-nav__arrow"> <i
          class="icon icon-navs-and-tabs icon-fw icon-lg"></i> <span class="dt-side-nav__text">Roles</span> </a> -->
      <!-- Sub-menu -->
      <!-- <ul class="dt-side-nav__sub-menu">
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/roles/add']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-addnew icon-fw icon-lg"></i> <span class="dt-side-nav__text">Add</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/roles/view']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-tables icon-fw icon-lg"></i> <span class="dt-side-nav__text">Manage</span> </a>
        </li>
      </ul> -->
      <!-- /sub-menu -->
    <!-- </li> -->
    <li *appHasRole="['User']" class="dt-side-nav__item">
      <a href="javascript:void(0)" class="dt-side-nav__link dt-side-nav__arrow"> <i
          class="icon icon-users icon-fw icon-lg"></i> <span class="dt-side-nav__text">Managers</span> </a>
      <!-- Sub-menu -->
      <ul class="dt-side-nav__sub-menu">
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/users/add']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-addnew icon-fw icon-lg"></i> <span class="dt-side-nav__text">Add</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/users/view']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-tables icon-fw icon-lg"></i> <span class="dt-side-nav__text">Manage</span> </a>
        </li>
      </ul>
      <!-- /sub-menu -->
    </li>
    <li *appHasRole="['Customer']" class="dt-side-nav__item">
      <a [routerLink]="['/admin/customer/view']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
          class="icon icon-user icon-fw icon-lg"></i> <span class="dt-side-nav__text">Customers</span> </a>
    </li>
    <li *appHasRole="['Service']" class="dt-side-nav__item">
      <a href="javascript:void(0)" class="dt-side-nav__link dt-side-nav__arrow"> <i
          class="icon icon-subscribe icon-fw icon-lg"></i> <span class="dt-side-nav__text">Service</span> </a>
      <!-- Sub-menu -->
      <ul class="dt-side-nav__sub-menu">
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/service/add']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-addnew icon-fw icon-lg"></i> <span class="dt-side-nav__text">Add</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/service/view']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-tables icon-fw icon-lg"></i> <span class="dt-side-nav__text">Manage</span> </a>
        </li>
      </ul>
      <!-- /sub-menu -->
    </li>
    <li *appHasRole="['Cuisines']" class="dt-side-nav__item">
      <a href="javascript:void(0)" class="dt-side-nav__link dt-side-nav__arrow"> <i
          class="icon icon-noodles icon-fw icon-lg"></i> <span class="dt-side-nav__text">Cuisines </span> </a>
      <!-- Sub-menu -->
      <ul class="dt-side-nav__sub-menu">
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/cuisine/add']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-addnew icon-fw icon-lg"></i> <span class="dt-side-nav__text">Add</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/cuisine/view']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-tables icon-fw icon-lg"></i> <span class="dt-side-nav__text">Manage</span> </a>
        </li>
      </ul>
      <!-- /sub-menu -->
    </li>
    <li *appHasRole="['Tags']" class="dt-side-nav__item">
      <a href="javascript:void(0)" class="dt-side-nav__link dt-side-nav__arrow"> <i
          class="icon icon-tag icon-fw icon-lg"></i> <span class="dt-side-nav__text">Tags </span> </a>
      <!-- Sub-menu -->
      <ul class="dt-side-nav__sub-menu">
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/tag/add']" routerLinkActive="active-link" class="dt-side-nav__link" title="Add Restaurant Tags"> <i
              class="icon icon-addnew icon-fw icon-lg"></i> <span class="dt-side-nav__text">Add Rest. Tags</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/tag/view']" routerLinkActive="active-link" class="dt-side-nav__link" title="Manage Restaurant Tags"> <i
              class="icon icon-tables icon-fw icon-lg"></i> <span class="dt-side-nav__text">Manage Rest. Tags</span>
          </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/productTag/add']" routerLinkActive="active-link" class="dt-side-nav__link" title="Add Product Tags"> <i
              class="icon icon-addnew icon-fw icon-lg"></i> <span class="dt-side-nav__text">Add Product Tags</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/productTag/view']" routerLinkActive="active-link" class="dt-side-nav__link" title="Manage Product Tags"> <i
              class="icon icon-tables icon-fw icon-lg"></i> <span class="dt-side-nav__text">Manage Product Tags</span>
          </a>
        </li>
      </ul>
      <!-- /sub-menu -->
    </li>
    <!-- <li class="dt-side-nav__item">
          <a href="javascript:void(0)" class="dt-side-nav__link dt-side-nav__arrow"> <i class="icon icon-time icon-fw icon-lg"></i> <span class="dt-side-nav__text">Timing </span> </a> -->
    <!-- Sub-menu -->
    <!-- <ul class="dt-side-nav__sub-menu">
            <li class="dt-side-nav__item">
              <a [routerLink]="['/admin/timing/add']" class="dt-side-nav__link"> <i class="icon icon-addnew icon-fw icon-lg"></i> <span class="dt-side-nav__text">Add</span> </a>
            </li>
            <li class="dt-side-nav__item">
              <a [routerLink]="['/admin/timing/view']" class="dt-side-nav__link"> <i class="icon icon-tables icon-fw icon-lg"></i> <span class="dt-side-nav__text">Manage</span> </a>
            </li>
          </ul> -->
    <!-- /sub-menu -->
    <!-- </li>  -->
    <li *appHasRole="['GlobalCategory']" class="dt-side-nav__item">
      <a href="javascript:void(0)" class="dt-side-nav__link dt-side-nav__arrow"> <i
          class="icon icon-list icon-fw icon-lg"></i> <span class="dt-side-nav__text">Category </span> </a>
      <!-- Sub-menu -->
      <ul class="dt-side-nav__sub-menu">
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/category/add']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-addnew icon-fw icon-lg"></i> <span class="dt-side-nav__text">Add</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/category/view']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-tables icon-fw icon-lg"></i> <span class="dt-side-nav__text">Manage</span> </a>
        </li>
      </ul>
      <!-- /sub-menu -->
    </li>
    <!-- <li class="dt-side-nav__item">
          <a href="javascript:void(0)" class="dt-side-nav__link dt-side-nav__arrow"> <i class="icon icon-pizza icon-fw icon-lg"></i> <span class="dt-side-nav__text">Sub-Category </span> </a> -->
    <!-- Sub-menu -->
    <!-- <ul class="dt-side-nav__sub-menu">
            <li class="dt-side-nav__item">
              <a [routerLink]="['/admin/subcategory/add']" class="dt-side-nav__link"> <i class="icon icon-addnew icon-fw icon-lg"></i> <span class="dt-side-nav__text">Add</span> </a>
            </li>
            <li class="dt-side-nav__item">
              <a [routerLink]="['/admin/subcategory/view']" class="dt-side-nav__link"> <i class="icon icon-tables icon-fw icon-lg"></i> <span class="dt-side-nav__text">Manage</span> </a>
            </li>
          </ul> -->
    <!-- /sub-menu -->
    <!-- </li>  -->
    <li *appHasRole="['City']" class="dt-side-nav__item">
      <a href="javascript:void(0)" class="dt-side-nav__link dt-side-nav__arrow"> <i
          class="icon icon-company icon-fw icon-lg"></i> <span class="dt-side-nav__text">City </span> </a>
      <!-- Sub-menu -->
      <ul class="dt-side-nav__sub-menu">
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/city/add']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-addnew icon-fw icon-lg"></i> <span class="dt-side-nav__text">Add</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/city/view']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-tables icon-fw icon-lg"></i> <span class="dt-side-nav__text">Manage</span> </a>
        </li>
      </ul>
      <!-- /sub-menu -->
    </li>
    <li *appHasRole="['Restaurant_detail']" class="dt-side-nav__item">
      <a href="javascript:void(0)" class="dt-side-nav__link dt-side-nav__arrow"> <i
          class="icon icon-home icon-fw icon-lg"></i> <span class="dt-side-nav__text">Restaurant </span> </a>
      <!-- Sub-menu -->
      <ul class="dt-side-nav__sub-menu">
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/restaurant/add']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-addnew icon-fw icon-lg"></i> <span class="dt-side-nav__text">Add</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/restaurant/view']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-tables icon-fw icon-lg"></i> <span class="dt-side-nav__text">Manage</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/restaurant/listaggreement']" routerLinkActive="active-link" class="dt-side-nav__link" title="New Request"> <i
              class="icon icon-send-new icon-fw icon-lg"></i> <span class="dt-side-nav__text">New Request</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/restaurant/approve']" routerLinkActive="active-link" class="dt-side-nav__link" title="Approve/Reject"> <i
              class="icon icon-check icon-fw icon-lg"></i> <span class="dt-side-nav__text">Approve/Reject</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/restaurant/updateApplication']" routerLinkActive="active-link" class="dt-side-nav__link"
            title="Updated Applications"> <i class="icon icon-assignment icon-fw icon-lg"></i> <span
              class="dt-side-nav__text">Updated Applications</span> </a>
        </li>
      </ul>
      <!-- /sub-menu -->
    </li>
    <li *appHasRole="['Restaurant_Setting']" class="dt-side-nav__item">
      <a href="javascript:void(0)" class="dt-side-nav__link dt-side-nav__arrow" title="Restaurant Setting"> <i
          class="icon icon-settings icon-fw icon-lg"></i> <span class="dt-side-nav__text">Restaurant Setting</span> </a>
      <!-- Sub-menu -->
      <ul class="dt-side-nav__sub-menu">
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/restcategory/add']" routerLinkActive="active-link" class="dt-side-nav__link" title="Add Category"> <i
              class="icon icon-addnew icon-fw icon-lg"></i> <span class="dt-side-nav__text">Add Category</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/restcategory/approve']" routerLinkActive="active-link" class="dt-side-nav__link"
            title="Approve Category"> <i class="icon icon-check icon-fw icon-lg"></i> <span
              class="dt-side-nav__text">Approve Category</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/restcategory/view']" routerLinkActive="active-link" class="dt-side-nav__link" title="Manage Category"> <i
              class="icon icon-tables icon-fw icon-lg"></i> <span class="dt-side-nav__text">Manage Category</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/restsubcategory/add']" routerLinkActive="active-link" class="dt-side-nav__link" title="Add Sub-Category"> <i
              class="icon icon-addnew icon-fw icon-lg"></i> <span class="dt-side-nav__text">Add Sub-Category</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/restsubcategory/approve']" routerLinkActive="active-link" class="dt-side-nav__link"
            title="Approve Subcategory"> <i class="icon icon-check icon-fw icon-lg"></i> <span
              class="dt-side-nav__text">Approve Subcategory</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/restsubcategory/view']" routerLinkActive="active-link" class="dt-side-nav__link" title="Manage Sub-Category"> <i
              class="icon icon-tables icon-fw icon-lg"></i> <span class="dt-side-nav__text">Manage Sub-Category</span>
          </a>
        </li>
      </ul>
      <!-- /sub-menu -->
    </li>
    <li *appHasRole="['Products']" class="dt-side-nav__item">
      <a href="javascript:void(0)" class="dt-side-nav__link dt-side-nav__arrow"> <i
          class="icon icon-price-list icon-fw icon-lg"></i> <span class="dt-side-nav__text">Menu </span> </a>
      <!-- Sub-menu -->
      <ul class="dt-side-nav__sub-menu">
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/menu/add']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-addnew icon-fw icon-lg"></i> <span class="dt-side-nav__text">Add</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/menu/approve']" routerLinkActive="active-link" class="dt-side-nav__link"
            title="Approve Menu"> <i class="icon icon-check icon-fw icon-lg"></i> <span
              class="dt-side-nav__text">Approve Menu</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/menu/view']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-tables icon-fw icon-lg"></i> <span class="dt-side-nav__text">Manage</span> </a>
        </li>
      </ul>
      <!-- /sub-menu -->
    </li>
    <li *appHasRole="['Drivers']" class="dt-side-nav__item">
      <a href="javascript:void(0)" class="dt-side-nav__link dt-side-nav__arrow"> <i
          class="icon icon-visits icon-fw icon-lg"></i> <span class="dt-side-nav__text">Drivers </span> </a>
      <!-- Sub-menu -->
      <ul class="dt-side-nav__sub-menu">
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/driver/add']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-addnew icon-fw icon-lg"></i> <span class="dt-side-nav__text">Add</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/driver/view']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-tables icon-fw icon-lg"></i> <span class="dt-side-nav__text">Manage</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/driver/listaggreement']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-send-new icon-fw icon-lg"></i> <span class="dt-side-nav__text">New Request</span> </a>
        </li>
        <!-- <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/driver/approve']" routerLinkActive="active-link" class="dt-side-nav__link" title="Approve/Reject"> <i
              class="icon icon-check icon-fw icon-lg"></i> <span class="dt-side-nav__text">Approve/Reject</span> </a>
        </li> -->
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/driver/cashInHand']" routerLinkActive="active-link" class="dt-side-nav__link" title="Collect Cash In Hand"> <i
              class="icon icon-revenue-new icon-fw icon-lg"></i> <span class="dt-side-nav__text">Collect Cash</span>
          </a>
        </li>
      </ul>
      <!-- /sub-menu -->
    </li>
    <li *appHasRole="['Offers']" class="dt-side-nav__item">
      <a href="javascript:void(0)" class="dt-side-nav__link dt-side-nav__arrow"> <i
          class="icon icon-percentage icon-fw icon-lg"></i> <span class="dt-side-nav__text">Offers </span> </a>
      <!-- Sub-menu -->
      <ul class="dt-side-nav__sub-menu">
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/offers/add']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-addnew icon-fw icon-lg"></i> <span class="dt-side-nav__text">Add</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/offers/view']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-tables icon-fw icon-lg"></i> <span class="dt-side-nav__text">Manage</span> </a>
        </li>
      </ul>
      <!-- /sub-menu -->
    </li>
    <li *appHasRole="['Orders']" class="dt-side-nav__item">
      <a [routerLink]="['/admin/orders/all']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
          class="icon icon-orders icon-fw icon-lg"></i>
        <span class="dt-side-nav__text">Orders</span> </a>
    </li>
    <li *appHasRole="['Ad-Center']" class="dt-side-nav__item">
      <a href="javascript:void(0)" class="dt-side-nav__link dt-side-nav__arrow"> <i
          class="icon icon-important icon-fw icon-lg"></i> <span class="dt-side-nav__text">Ads Center </span> </a>
      <!-- Sub-menu -->
      <ul class="dt-side-nav__sub-menu">
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/adcenter/add']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-addnew icon-fw icon-lg"></i> <span class="dt-side-nav__text">Add</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/adcenter/view']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-tables icon-fw icon-lg"></i> <span class="dt-side-nav__text">Manage</span> </a>
        </li>
      </ul>
      <!-- /sub-menu -->
    </li>
    <!-- <li *appHasRole="['Delivery_Charges']"  class="dt-side-nav__item">
      <a href="javascript:void(0)" class="dt-side-nav__link dt-side-nav__arrow"> <i
          class="icon icon-revenue2 icon-fw icon-lg"></i> <span class="dt-side-nav__text">Delivery Charges </span> </a> -->
      <!-- Sub-menu -->
      <!-- <ul class="dt-side-nav__sub-menu">
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/delivery/add']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-addnew icon-fw icon-lg"></i> <span class="dt-side-nav__text">Add</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/delivery/view']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-tables icon-fw icon-lg"></i> <span class="dt-side-nav__text">Manage</span> </a>
        </li>
      </ul> -->
      <!-- /sub-menu -->
    <!-- </li> -->
    <li *appHasRole="['Customize']" class="dt-side-nav__item">
      <a href="javascript:void(0)" class="dt-side-nav__link dt-side-nav__arrow"> <i
          class="icon icon-basic-components icon-fw icon-lg"></i> <span class="dt-side-nav__text">Customize </span> </a>
      <!-- Sub-menu -->
      <ul class="dt-side-nav__sub-menu">
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/customize/slider']" routerLinkActive="active-link" class="dt-side-nav__link" title="Slider or Banner Images"> <i
              class="icon icon-signup-page icon-fw icon-lg"></i> <span class="dt-side-nav__text">Slider Images</span>
          </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/customize/restaurant']" routerLinkActive="active-link" class="dt-side-nav__link" title="Top 5 Restaurant List"> <i
              class="icon icon-star-fill icon-fw icon-lg"></i> <span class="dt-side-nav__text">Top 5 Restaurant
              List</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/customize/supportQuestion']" class="dt-side-nav__link" title="Support Questions"> <i
              class="icon icon-question-circle icon-fw icon-lg"></i> <span class="dt-side-nav__text">Support Ques.</span>
          </a>
        </li>
      </ul>
      <!-- /sub-menu -->
    </li>
    <!-- <li *appHasRole="['Reports']" class="dt-side-nav__item">
      <a href="javascript:void(0)" class="dt-side-nav__link dt-side-nav__arrow"> <i
          class="icon icon-assignment icon-fw icon-lg"></i> <span class="dt-side-nav__text">Reports </span> </a> -->
      <!-- Sub-menu -->
      <!-- <ul class="dt-side-nav__sub-menu">
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/report/restaurant']" routerLinkActive="active-link" class="dt-side-nav__link" title="Restaurant Report"> <i
              class="icon icon-company icon-fw icon-lg"></i> <span class="dt-side-nav__text">Restaurant</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/report/driver']" routerLinkActive="active-link" class="dt-side-nav__link" title="Driver Report"> <i
              class="icon icon-customer icon-fw icon-lg"></i> <span class="dt-side-nav__text">Driver</span> </a>
        </li>
      </ul> -->
      <!-- /sub-menu -->
    <!-- </li> -->
    <li *appHasRole="['Support']" class="dt-side-nav__item">
      <a href="javascript:void(0)" class="dt-side-nav__link dt-side-nav__arrow"> <i
          class="icon icon-tooltip icon-fw icon-lg"></i> <span class="dt-side-nav__text">Support </span> </a>
      <!-- Sub-menu -->
      <ul class="dt-side-nav__sub-menu">
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/support/driverIssue/list']" routerLinkActive="active-link" class="dt-side-nav__link"
            title="Driver Issues"> <i class="icon icon-company icon-fw icon-lg"></i> <span
              class="dt-side-nav__text">Driver Issues</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/admin/support/customerIssue/list']" routerLinkActive="active-link" class="dt-side-nav__link"
            title="Customer Issues"> <i class="icon icon-user-account icon-fw icon-lg"></i> <span
              class="dt-side-nav__text">Customer Issues</span> </a>
        </li>
      </ul>
      <!-- /sub-menu -->
    </li>
    <!-------------Restaurant Routes--------------->
    <li *appHasRole="['Restaurant_Category']" class="dt-side-nav__item">
      <a href="javascript:void(0)" class="dt-side-nav__link dt-side-nav__arrow"> <i
          class="icon icon-list icon-fw icon-lg"></i> <span class="dt-side-nav__text">Category </span> </a>
      <!-- Sub-menu -->
      <ul class="dt-side-nav__sub-menu">
        <li class="dt-side-nav__item">
          <a [routerLink]="['/restaurant/category/add']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-addnew icon-fw icon-lg"></i> <span class="dt-side-nav__text">Add</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/restaurant/category/view']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-tables icon-fw icon-lg"></i> <span class="dt-side-nav__text">Manage</span> </a>
        </li>
      </ul>
      <!-- /sub-menu -->
    </li>
    <li *appHasRole="['Restaurant_Subcategory']" class="dt-side-nav__item">
      <a href="javascript:void(0)" class="dt-side-nav__link dt-side-nav__arrow"> <i
          class="icon icon-list icon-fw icon-lg"></i> <span class="dt-side-nav__text">Subcategory </span> </a>
      <!-- Sub-menu -->
      <ul class="dt-side-nav__sub-menu">
        <li class="dt-side-nav__item">
          <a [routerLink]="['/restaurant/subcategory/add']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-addnew icon-fw icon-lg"></i> <span class="dt-side-nav__text">Add</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/restaurant/subcategory/view']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-tables icon-fw icon-lg"></i> <span class="dt-side-nav__text">Manage</span> </a>
        </li>
      </ul>
      <!-- /sub-menu -->
    </li>
    <li *appHasRole="['RestDriver']" class="dt-side-nav__item">
      <a href="javascript:void(0)" class="dt-side-nav__link dt-side-nav__arrow"> <i
          class="icon icon-visits icon-fw icon-lg"></i> <span class="dt-side-nav__text">Drivers </span> </a>
      <!-- Sub-menu -->
      <ul class="dt-side-nav__sub-menu">
        <li class="dt-side-nav__item">
          <a [routerLink]="['/restaurant/driver/add']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-addnew icon-fw icon-lg"></i> <span class="dt-side-nav__text">Add</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/restaurant/driver/view']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-tables icon-fw icon-lg"></i> <span class="dt-side-nav__text">Manage</span> </a>
        </li>
        <!-- <li class="dt-side-nav__item">
          <a [routerLink]="['/restaurant/driver/pending']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-send-new icon-fw icon-lg"></i> <span class="dt-side-nav__text">New Request</span> </a>
        </li> -->
        <!-- <li class="dt-side-nav__item">
          <a [routerLink]="['/restaurant/driver/cashInHand']" routerLinkActive="active-link" class="dt-side-nav__link" title="Collect Cash In Hand"> <i
              class="icon icon-revenue-new icon-fw icon-lg"></i> <span class="dt-side-nav__text">Collect Cash</span>
          </a>
        </li> -->
      </ul>
      <!-- /sub-menu -->
    </li>
    <li *appHasRole="['Menu']" class="dt-side-nav__item">
      <a href="javascript:void(0)" class="dt-side-nav__link dt-side-nav__arrow"> <i
          class="icon icon-price-list icon-fw icon-lg"></i> <span class="dt-side-nav__text">Menu </span> </a>
      <!-- Sub-menu -->
      <ul class="dt-side-nav__sub-menu">
        <li class="dt-side-nav__item">
          <a [routerLink]="['/restaurant/menu/add']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-addnew icon-fw icon-lg"></i> <span class="dt-side-nav__text">Add</span> </a>
        </li>
        <li class="dt-side-nav__item">
          <a [routerLink]="['/restaurant/menu/view']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
              class="icon icon-tables icon-fw icon-lg"></i> <span class="dt-side-nav__text">Manage</span> </a>
        </li>
      </ul>
      <!-- /sub-menu -->
    </li>
    <li *appHasRole="['RestOrder']" class="dt-side-nav__item">
      <a [routerLink]="['/restaurant/orders/list']" routerLinkActive="active-link" class="dt-side-nav__link"> <i
          class="icon icon-orders icon-fw icon-lg"></i> <span class="dt-side-nav__text">Orders</span> </a>
    </li>
    <li *appHasRole="['RestEarning']" class="dt-side-nav__item">
      <a [routerLink]="['/restaurant/earning']" routerLinkActive="active-link" class="dt-side-nav__link"> <i class="icon icon-revenue icon-fw icon-lg"></i>
        <span class="dt-side-nav__text">Earning</span> </a>
    </li>
    <li *appHasRole="['MyProfile']" class="dt-side-nav__item">
      <a [routerLink]="['/restaurant/profile']" routerLinkActive="active-link" class="dt-side-nav__link"> <i class="icon icon-user icon-fw icon-lg"></i>
        <span class="dt-side-nav__text">My Profile</span> </a>
    </li>

    <li *appHasRole="['RestOffer']" class="dt-side-nav__item">
      <a [routerLink]="['/restaurant/offers/list']" routerLinkActive="active-link" class="dt-side-nav__link"> <i class="icon icon-percentage icon-fw icon-lg"></i>
        <span class="dt-side-nav__text">Offers</span> </a>
    </li>
  </ul>
  <!-- /sidebar navigation -->
</div>