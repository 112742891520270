import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata.service'; 

@Injectable({
  providedIn: 'root'
})
export class RestcategoryService {

  BaseUrl = ''
  Token = ''

  constructor(private http: HttpClient, @Inject('BASE_URL') _base,private userdata: UserdataService) {
    this.BaseUrl = _base
    this.Token = userdata.getToken()
  }

  public addCategory(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token).set('Content-Type', 'application/x-www-form-urlencoded')
    return this.http.post(this.BaseUrl + "/api/addrestcategoryadmin",form.toString(), { headers: header_object})
  }
  
  public listCategory(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/allrestcategory",form,{ headers: header_object})
  } 

  public getCategoryById(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token).set('Content-Type', 'application/x-www-form-urlencoded')
    return this.http.post(this.BaseUrl + "/api/fetchrestcatbyid",form.toString(), { headers: header_object})
  }

  public updateCategory(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token).set('Content-Type', 'application/x-www-form-urlencoded')
    return this.http.post(this.BaseUrl + "/api/updaterestcategoryadmin", form.toString(), { headers: header_object})
  }

  public pendingApproveCategory(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/requestforapprovalcategory",form,{ headers: header_object})
  }

  public approveCategory(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/approvalorrejectbyadmin",form,{ headers: header_object})
  }

  //--------------------Restaurant Web Panel Apis--------------------//
  public restAddCategory(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/rest/addrestcategoryself",form, { headers: header_object})
  }

  public restUpdateCategory(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/rest/updaterestcategoryself",form, { headers: header_object})
  }

  public restPendingCategory(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/rest/mypendingcategoryrequest",form, { headers: header_object})
  }
} 
