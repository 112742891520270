import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/shared/service/service.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-editservice',
  templateUrl: './editservice.component.html',
  styleUrls: ['./editservice.component.css']
})
export class EditserviceComponent implements OnInit {

  statusList: any = ['Enabled', 'Disabled']

  formGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    userId: new FormControl('', [Validators.required]),
    added_by: new FormControl('', [Validators.required]),
    meta_desc: new FormControl('', [Validators.required]),
    meta_keywords: new FormControl('', [Validators.required]), 
    // status: new FormControl('', [Validators.required]), 
    _id: new FormControl('', [Validators.required]), 
  }) 

  constructor(private toast: ToastrService,private service:ServiceService, private router: Router, private activate : ActivatedRoute, private userdata: UserdataService) { }

  id = ''

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getDataById()
  }

  get f(){
    return this.formGroup.controls
  }

  compareFn(c1: any, c2:any): boolean {     
    return c1 && c2 ? c1.id === c2.id : c1 === c2; 
  }

  data = ''
  getDataById(){
    let body = new URLSearchParams();
    body.set('_id', this.id);
    this.service.getServiceById(body).subscribe(
      (res:any) => {
        //console.log(res.result)
        this.formGroup.patchValue({name: res.data.name});     
        this.formGroup.patchValue({meta_desc: res.data.meta_desc});    
        this.formGroup.patchValue({meta_keywords: res.data.meta_keywords});     
        // this.formGroup.patchValue({status: res.data.status});     
        this.formGroup.patchValue({_id: res.data._id});     
        var id = this.userdata.getId()
        var added = this.userdata.getUserType()
        this.formGroup.patchValue({userId: id});
        this.formGroup.patchValue({added_by: added});
        this.f   
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        this.router.navigateByUrl('/admin/service/view')
      }
    )
  }

  public onsubmit(){
    // console.log(this.formGroup.value)
    this.service.updateService(this.formGroup.value).subscribe(
      (res:any) => {
        // console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/service/view')
      },
      err => {
        // console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
 