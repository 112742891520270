import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserdataService } from 'src/app/shared/userdata.service'; 
import { DriverService } from 'src/app/shared/driver/driver.service';

@Component({
  selector: 'app-senddriverapprove',
  templateUrl: './senddriverapprove.component.html',
  styleUrls: ['./senddriverapprove.component.css']
})
export class SenddriverapproveComponent implements OnInit {

  constructor(private activate: ActivatedRoute, private driver: DriverService,private toast: ToastrService, private router:Router, private userdata: UserdataService) { }

  statusList: any = ['Approved', 'Rejected']

  formGroup = new FormGroup({
    driverId: new FormControl('', [Validators.required]),
    staffId: new FormControl('', [Validators.required]),
    status: new FormControl('', [Validators.required]),
    remarks: new FormControl(''), 
  })

  id = ''

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getDataById()
  }

  get f(){
    return this.formGroup.controls
  }

  compareFn(c1: any, c2:any): boolean {     
    return c1 && c2 ? c1.id === c2.id : c1 === c2; 
  }

  getDataById(){
    this.driver.getDriverById({'_id': this.id}).subscribe(
      (res:any) => {
        //console.log(res.result)  
        this.formGroup.patchValue({current_status: res.data.current_status});     
        this.formGroup.patchValue({driverId: res.data._id});     
        // this.formGroup.patchValue({category_image: res.category_image});     
        var id = this.userdata.getId()
        this.formGroup.patchValue({staffId: id});
        this.f   
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        this.router.navigateByUrl('/admin/driver/listaggreement')
      }
    )
  }

  public onsubmit(){
    this.driver.approveReject(this.formGroup.value).subscribe(
      (res:any) => {
        // console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/driver/listaggreement')
      },
      err => {
        // console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }


}
