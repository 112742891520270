<!-- Page Header -->
<div class="dt-page__header">
	<ol class="breadcrumb">
		<li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
		<li class="breadcrumb-item"><a [routerLink]="['/admin/delivery/view']">Delivery Charges</a></li>
		<li class="active breadcrumb-item">Add</li>
	</ol>
	<!-- <h1 class="dt-page__title">Add Delivery Charges</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
	<!-- Grid Item -->
	<div class="col-xl-12">
		<!-- Form -->
		<form [formGroup]="formGroup" (submit)="onsubmit()">
			<!-- Card -->
			<div class="dt-card dt-card__full-height">
				<!-- Card Header -->
				<div class="dt-card__header">
					<!-- Card Heading -->
					<div class="dt-card__heading">
						<h3 class="dt-card__title">Add Delivery Charges</h3>
					</div>
					<!-- /card heading -->
				</div>
				<!-- /card header -->
				<!-- Card Body -->
				<div class="dt-card__body">
					<div class="form-row">
						<div class="col-sm-6 mb-3">
							<label for="cityId">City</label>
							<select class="form-control" id="cityId" name="cityId" formControlName="cityId">
								<option [selected]="true" [disabled]="true" value="">Select City</option>
								<option *ngFor="let city of cityList" [value]="city._id">{{city.city_name}}</option>
							</select>
							<small class="form-text"
								*ngIf="f.cityId.invalid && (f.cityId.dirty || f.cityId.touched)">
								<small *ngIf="f.cityId.errors.required">Invalid Value</small> </small>
                        </div>
                        <div class="col-sm-6 mb-3">
							<label for="area">Area</label>
							<input type="text" class="form-control" id="area"
								placeholder="Enter Area" name="area"
								formControlName="area"> <small class="form-text"
								*ngIf="f.area.invalid && (f.area.dirty || f.area.touched)">
								<small *ngIf="f.area.errors.required">Invalid Value</small> </small>
						</div>
					</div>
					<div class="form-row">
						<div class="col-sm-6 mb-3">
							<label for="sector">Sector Number</label>
							<input type="text" class="form-control" id="sector"
								placeholder="Enter Sector Number" name="sector"
								formControlName="sector"> <small class="form-text"
								*ngIf="f.sector.invalid && (f.sector.dirty || f.sector.touched)">
								<small *ngIf="f.sector.errors.required">Invalid Value</small> </small>
                        </div>
                        <div class="col-sm-6 mb-3">
							<label for="street">Street Number</label>
							<input type="text" class="form-control" id="street"
								placeholder="Enter Street Number" name="street"
								formControlName="street"> <small class="form-text"
								*ngIf="f.street.invalid && (f.street.dirty || f.street.touched)">
								<small *ngIf="f.street.errors.required">Invalid Value</small> </small>
						</div>
                    </div>
                    <div class="form-row">
						<div class="col-sm-6 mb-3">
							<label for="pincode">Pincode</label>
							<input type="text" class="form-control" id="pincode"
								placeholder="Enter Pincode" name="pincode"
								formControlName="pincode"> <small class="form-text"
								*ngIf="f.pincode.invalid && (f.pincode.dirty || f.pincode.touched)">
								<small *ngIf="f.pincode.errors.required">Invalid Value</small> </small>
                        </div>
                        <div class="col-sm-6 mb-3">
							<label for="charges">Charges ($)</label>
							<input type="number" class="form-control" id="charges"
								placeholder="Enter Charges" name="charges"
								formControlName="charges"> <small class="form-text"
								*ngIf="f.charges.invalid && (f.charges.dirty || f.charges.touched)">
								<small *ngIf="f.charges.errors.required">Invalid Value</small> </small>
						</div>
                    </div>
                    <div class="form-row">
						<div class="col-xl-8 offset-xl-4">
							<button type="submit" class="btn btn-primary text-uppercase"
								[disabled]="!formGroup.valid">Save
							</button>
						</div>
					</div>
				</div>
				<!-- /card body -->
			</div>
			<!-- /card -->
	
		</form>
		<!--/form-->
	</div>
	<!-- /grid item -->
</div>
<!-- /grid -->