import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from 'src/app/shared/category/category.service';
import { ProductService } from 'src/app/shared/product/product.service';
import { ProducttagService } from 'src/app/shared/producttag/producttag.service';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { RestcategoryService } from 'src/app/shared/restcategory/restcategory.service';
import { RestsubcategoryService } from 'src/app/shared/restsubcategory/restsubcategory.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-add-mymenu',
  templateUrl: './add-mymenu.component.html',
  styleUrls: ['./add-mymenu.component.css']
})
export class AddMymenuComponent implements OnInit {

  formGroup = new FormGroup({
    product_name: new FormControl('', [Validators.required]),
    userId: new FormControl('', [Validators.required]),
    catId: new FormControl('', [Validators.required]),
    added_by: new FormControl('', [Validators.required]),
    restcatId: new FormControl('', [Validators.required]),
    restsubcatId: new FormControl('', [Validators.required]),
    restId: new FormControl('', [Validators.required]),
    product_description: new FormControl('', [Validators.required]),
    product_image: new FormControl('', [Validators.required]),
    veg_type: new FormControl('', [Validators.required]),
    service_type: new FormControl(''),
    meta_keywords: new FormControl(''),
    other_tags: new FormControl('', [Validators.required]),
    price: new FormArray([
      new FormGroup({
        pricename: new FormControl('', [Validators.required]),
        pricevalue: new FormControl('', [Validators.required,Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")]),
      })
    ]),
    addon: new FormArray([
    ]),
  })

  subcategoryList = []
  categoryList = []
  gcategoryList = []
  restList = []
  tagList = []
  other_tag = []
  data = {}
  add_on = '{"addontype":"Price","addonvalue":[]}'
  overall = []
  vegOption= "vegan"
  serviceOption = "Delivery"
  constructor(private toast: ToastrService, private restaurant: RestaurantService, private router: Router, private tag: ProducttagService, private userdata: UserdataService, private category: RestcategoryService, private subcategory: RestsubcategoryService, private globalCat: CategoryService, private fb: FormBuilder, private product: ProductService) {
    // this.newAddon()
  }

  price(): FormArray {
    return this.formGroup.get("price") as FormArray
  }

  newQuantity(): FormGroup {
    return this.fb.group({
      pricename: '',
      pricevalue: ['',Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")],
    })
  }

  addQuantity() {
    this.price().push(this.newQuantity());
  }

  removeQuantity(i: number) {
    this.price().removeAt(i);
  }

  addon(): FormArray {
    return this.formGroup.get("addon") as FormArray
  }

  newAddon(): FormGroup {
    return this.fb.group({
      name: '',
      skills: this.fb.array([])
    })
  }


  addAddon() {
    this.addon().push(this.newAddon());
  }


  removeAddon(addonIndex: number) {
    this.addon().removeAt(addonIndex);
  }

  employeeSkills(addonIndex: number): FormArray {
    return this.addon().at(addonIndex).get("skills") as FormArray
  }

  newSkill(): FormGroup {
    return this.fb.group({
      addname: '',
      addvalue: '',
    })
  }

  addEmployeeSkill(addonIndex: number) {
    this.employeeSkills(addonIndex).push(this.newSkill());
  }

  removeEmployeeSkill(addonIndex: number, skillIndex: number) {
    this.employeeSkills(addonIndex).removeAt(skillIndex);
  }

  ngOnInit(): void {
    var added = this.userdata.getUserType()
    var id = this.userdata.getId()
    this.formGroup.patchValue({ added_by: added });
    this.formGroup.patchValue({ userId: id });
    this.formGroup.patchValue({ restId: this.userdata.getId() });
    this.getCategory()
    this.getGlobalCategory()
    this.getTag()
  }

  get f() {
    return this.formGroup.controls
  }

  public onFile(evnt) {
    if (evnt.target.files.length > 0) {
      this.formGroup.patchValue({
        product_image: evnt.target.files[0]
      })
    }
  }

  getCategory() {
    this.category.listCategory({ 'restId': this.userdata.getId() }).subscribe(
      (res: any) => {
        this.categoryList = res.data
      },
      err => {
        this.toast.error(err.error.message, 'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  }

  getSubcategoryList(event) {
    this.formGroup.patchValue({ restsubcatId: "" });
    this.subcategory.listSubcategory({ 'restcatId': event.target.value, 'restId':this.userdata.getId() }).subscribe(
      (res: any) => {
        this.subcategoryList = res.data
      },
      err => {
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  getGlobalCategory() {
    let body = new URLSearchParams();
    body.set('status', 'Enabled');
    this.globalCat.listCategory({}).subscribe(
      (res:any) => {
        this.gcategoryList = res.data
      },
      err => {
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  }

  getTag() {
    this.tag.listTag({}).subscribe(
      (res: any) => {
        this.tagList = res.data
      },
      err => {
        //console.log(err)
      }
    )
  }

  public onsubmit() {
    // console.log(this.formGroup.controls)
    // return
    var service_type = ["Delivery", "Take Away"]
    //console.log(this.formGroup.value)
    //-------Other tags json------//
    var other = this.formGroup.get("other_tags").value
    other.forEach(element => {
      this.other_tag.push(element._id)
    });
    //-------/other tags json-----//

    //------price json---------//
    var obj = JSON.parse(this.add_on)
    var price = this.formGroup.get('price').value
    price.forEach(element => {
      obj['addonvalue'].push({ "valuename": element.pricename, "valuedetail": element.pricevalue });
    });
    this.add_on = JSON.stringify(obj);
    //-----/price json---------//
    //----Addon ---//
    var objj = this.formGroup.get('addon').value
    var sub = []
    objj.forEach(element => {
      element.skills.forEach(elements => {
        sub.push({'valuename':elements.addname,'valuedetail':elements.addvalue})
      });
      //alert(element.name)
      this.data={'addontype':element.name,'addonvalue':sub}
      sub=[]
    });
    // console.log(JSON.stringify(obj))
    // console.log(JSON.stringify(this.data))
    this.overall.push(obj)
    this.overall.push(this.data)
    //console.log(JSON.stringify(this.overall))
    //----/addon---//
    //---------insertion data-----------//
    //console.log(this.formGroup.get("service_type").value)
    const data = new FormData()
    data.append("product_name", this.formGroup.get("product_name").value)
    data.append("userId", this.formGroup.get("userId").value)
    data.append("catId", this.formGroup.get("catId").value)
    data.append("added_by", this.formGroup.get("added_by").value)
    data.append("restcatId", this.formGroup.get("restcatId").value)
    data.append("restsubcatId", this.formGroup.get("restsubcatId").value)
    data.append("restId", this.formGroup.get("restId").value)
    data.append("product_description", this.formGroup.get("product_description").value)
    data.append("product_image", this.formGroup.get("product_image").value)
    data.append("veg_type", this.formGroup.get("veg_type").value)
    //data.append("service_type", this.formGroup.get("service_type").value)
    data.append("service_type", JSON.stringify(service_type))
    data.append("meta_keywords", this.formGroup.get("meta_keywords").value)
    data.append("addon", JSON.stringify(this.overall))
    data.append("other_tags", JSON.stringify(this.other_tag))
    this.product.restaddProduct(data).subscribe(
      (res: any) => {
        //console.log(res)
        this.toast.success(res.message, 'Success')
        this.router.navigateByUrl('/restaurant/menu/view');
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

}
