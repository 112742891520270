import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from 'src/app/shared/category/category.service';
import { OffersService } from 'src/app/shared/offers/offers.service';
import { ProductService } from 'src/app/shared/product/product.service';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-add-myoffers',
  templateUrl: './add-myoffers.component.html',
  styleUrls: ['./add-myoffers.component.css']
})
export class AddMyoffersComponent implements OnInit {

  offerType = [{'value':1,'name':'Fixed Amount'},{'value':2,'name':'Percent'}]
  offerOn = [{'value':1,'name':'All'},{'value':2,'name':'Category'},{'value':3,'name':'Product'},{'value':4,'name':'Restaurant'}]
  restList = [] 
  prodList = []
  catList = []
  prodRestList = []
  data:any = ""
  formGroup = new FormGroup({
    offer_amount: new FormControl('', [Validators.required,Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")]),
    offers_code: new FormControl('', [Validators.required]),
    minimum_order: new FormControl('', [Validators.required,Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")]),
    maximum_discount: new FormControl('0', [Validators.required,Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$")]),
    offer_on: new FormControl(4, [Validators.required]),
    offer_details: new FormControl(''),
    offer_desc: new FormControl(''),
    offers_image: new FormControl('', [Validators.required]),
    end_date: new FormControl('', [Validators.required]),
    offers_type: new FormControl('', [Validators.required]),
    added_by: new FormControl('', [Validators.required]),
    userId: new FormControl('', [Validators.required]),
    is_system_added: new FormControl('', [Validators.required]),
    productId: new FormControl(''),
    categoryId: new FormControl(''),
    restId: new FormControl(''),
    prodRestId: new FormControl(''),
  })

  constructor(private offers:OffersService, private userdata: UserdataService, private toast:ToastrService, private router: Router, private datePipe: DatePipe, private globalCat: CategoryService, private restaurant: RestaurantService, private product: ProductService) { }

  ngOnInit(): void {
    var id = this.userdata.getId()
    var added = this.userdata.getUserType()
    this.formGroup.patchValue({userId: id});
    this.formGroup.patchValue({restId: id});
    this.formGroup.patchValue({added_by: added});
    this.formGroup.patchValue({is_system_added: false});
  }

  public onFile(evnt){
    if(evnt.target.files.length > 0)
    {
      this.formGroup.patchValue({
        offers_image: evnt.target.files[0]
      })
    }
  }

  get f(){
    return this.formGroup.controls
  }

  getDetailList(event){
    //alert("hello")
    var type = event.target.value
    if(type==2){
      this.getGlobalCategory()
      this.data = 2
    }
    if(type==3){
      this.getProRestaurant()
      this.data = 3
    }
    if(type==4){
      this.getRestaurant()
      this.data = 4
    }
    if(type==1){
      this.restList = []
      this.prodList = []
      this.catList = []
      this.prodRestList = []
      this.data = ""
    }
  }

  getGlobalCategory() {
    this.restList = []
    this.prodList = []
    this.catList = []
    this.prodRestList = []
    let body = new URLSearchParams();
    body.set('status', 'Enabled');
    this.globalCat.listCategory({}).subscribe(
      (res:any) => {
        //console.log(res)
        //this.offerDetails = res.data
        res.data.forEach(element => {
          this.catList.push({'_id':element._id,'name':element.category_name})
        });
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  }

  getRestaurant() {
    this.restList = []
    this.prodList = []
    this.catList = []
    this.prodRestList = []
    let body = new URLSearchParams();
    body.set('status', 'Enabled');
    this.restaurant.searchRestaurant(body).subscribe(
      (res:any) => {
        //console.log(res)
        //this.offerDetails = res.data
        res.data.forEach(element => {
          this.restList.push({'_id':element._id,'name':element.rest_name})
        });
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  }

  getProRestaurant() {
    this.restList = []
    this.prodList = []
    this.catList = []
    this.prodRestList = []
    let body = new URLSearchParams();
    body.set('status', 'Enabled');
    this.restaurant.searchRestaurant(body).subscribe(
      (res:any) => {
        //console.log(res)
        //this.offerDetails = res.data
        res.data.forEach(element => {
          this.prodRestList.push({'_id':element._id,'name':element.rest_name})
        });
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  }

  getProduct(event) {
    this.restList = []
    this.prodList = []
    this.catList = []
    // this.prodRestList = []
    this.product.listProduct({'status':'Enabled','restId':event.target.value}).subscribe(
      (res:any) => {
        //console.log(res)
        //this.offerDetails = res.data
        res.data.forEach(element => {
          this.prodList.push({'_id':element._id,'name':element.product_name+'---'+element.restId.rest_name})
        });
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  }

  public onsubmit(){
    const data = new FormData()
    data.append("userId",this.formGroup.get("userId").value)
    data.append("added_by",this.formGroup.get("added_by").value)
    data.append("offers_type",this.formGroup.get("offers_type").value)
    //data.append("end_date",this.datePipe.transform(this.formGroup.get("end_date").value, 'MM-dd-yyyy'))
    data.append("end_date",this.formGroup.get("end_date").value)
    data.append("offer_amount",this.formGroup.get("offer_amount").value)
    data.append("minimum_order",this.formGroup.get("minimum_order").value)
    data.append("maximum_discount",this.formGroup.get("maximum_discount").value)
    data.append("offer_on",this.formGroup.get("offer_on").value)
    data.append("offers_code",this.formGroup.get("offers_code").value)
    data.append("offer_desc",this.formGroup.get("offer_desc").value)
    data.append("offers_image",this.formGroup.get("offers_image").value)
    data.append("productId",this.formGroup.get("productId").value)
    data.append("categoryId",this.formGroup.get("categoryId").value)
    data.append("restId",this.formGroup.get("restId").value)
    data.append("is_system_added",this.formGroup.get("is_system_added").value)
    this.offers.restAddOffer(data).subscribe(
      (res:any) => {
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/restaurant/offers/list')
      },
      err => {
        console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
