<div class="dt-root">
  <div class="dt-root__inner">
    <header class="dt-header">
    <!-- Header -->
    <app-topbar></app-topbar>
    <!-- /header -->
  </header>
    <!-- Site Main -->
    <main class="dt-main">
      <aside id="main-sidebar" class="dt-sidebar">
        <!-- Sidebar -->
        <app-sidebar></app-sidebar>
        <!-- /sidebar -->
      </aside>
      <!-- Site Content Wrapper -->
      <div class="dt-content-wrapper">
        <!-- Site Content -->
        <div class="dt-content">
          <!-- Content -->
          <router-outlet></router-outlet>
          <!-- /Content -->
        </div>
        <!-- Footer -->
        <app-footer></app-footer>
        <!-- /footer -->
      </div>
      <!-- /site content wrapper -->
    </main>
  </div>
</div>