import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable'; 
import { DeliveryService } from 'src/app/shared/delivery/delivery.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-listdelivery',
  templateUrl: './listdelivery.component.html',
  styleUrls: ['./listdelivery.component.css']
})
export class ListdeliveryComponent implements OnInit {

  constructor(private toast: ToastrService,private delivery: DeliveryService, private userdata:UserdataService) { }

  p: number = 1;
  term: string;
  temp = [];
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]
  columns = [{ prop: 'Name' }, { name: 'Area' },{ name: 'Street' },{ name: 'Sector' },{ name: 'Pincode' },{ name: 'Charges' },{ name: 'Status' }, { name: 'Added By' },
  { name: 'Action' }];
  ngOnInit(): void {
    // for page number 1 as offset starts with 0
    //this.table.offset = 0;
    this.getDeliveryList()
    
  }

  getDeliveryList(){
    this.delivery.listDelivery({}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  } 

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.cityId.city_name.toLowerCase().indexOf(val) !== -1 ||
      d.status.toLowerCase().indexOf(val) !== -1 ||
      d.area.toLowerCase().indexOf(val) !== -1 ||
      d.street.toLowerCase().indexOf(val) !== -1 ||
      d.sector.toLowerCase().indexOf(val) !== -1 ||
      d.added_by.toLowerCase().indexOf(val) !== -1 ||
      !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  changeStatus(val,id){
    var user = this.userdata.getId()
    var updatedBy = this.userdata.getUserType()
    this.delivery.updateDelivery({'updated_Id':user,'_id':id,'status':val,'updated_by':updatedBy}).subscribe(
      (res:any) => {
        this.toast.success(res.message,'Success')
        this.getDeliveryList()
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
        this.getDeliveryList()
      }
    )
  }

}
