<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="active breadcrumb-item">Approve Restaurant Category</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Tag</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title float-left">View Requested Category</h3>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Tables -->
                <input
                type="text" class="form-control search-input"
                placeholder="Type to filter the name column..."
                (keyup)="updateFilter($event)"
                />
                <ngx-datatable
                #table
                class="material"
                [columns]="columns"
                [columnMode]="ColumnMode.force"
                [headerHeight]="50"
                [footerHeight]="50"
                rowHeight="auto"
                [limit]="10"
                [rows]="rows"
                #myFilterTable
                >
                    <ngx-datatable-column name="Type">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        <span class="badge badge-primary" *ngIf="row.is_new_added">New</span>
                        <span class="badge badge-info" *ngIf="!row.is_new_added">Update</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Restaurant Name" prop="restId.rest_name">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.restId.rest_name}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Category Name" prop="rest_cat_name">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.rest_cat_name}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="New Name" prop="rest_cat_update_name">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.rest_cat_update_name}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Status">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span class="badge badge-pill badge-danger" *ngIf="row.is_request_for_update">
                                Pending
                            </span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Added By" prop="added_by">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.added_by}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Action">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <a (click)="approveOrReject('false',row._id)" title="Approve" class="text-success mr-1"> <i class="icon-circle-check-o icon-lg"></i> </a>
                            <a (click)="approveOrReject('true',row._id)" title="Reject" class="text-danger"> <i class="icon-circle-remove-o icon-lg"></i> </a>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
                <!-- /tables -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid --> 