import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-reject-myorder',
  templateUrl: './reject-myorder.component.html',
  styleUrls: ['./reject-myorder.component.css']
})
export class RejectMyorderComponent implements OnInit {

  formGroup = new FormGroup({
    reason : new FormControl('',Validators.required),
    restId : new FormControl('',Validators.required),
    orderId: new FormControl(''),
    is_order_accepted : new FormControl(false)
  })

  constructor(private restaurant:RestaurantService, private toast:ToastrService, private router:Router,private userdata:UserdataService,private activate:ActivatedRoute) { }

  ngOnInit(): void {
    this.formGroup.patchValue({'restId':this.userdata.getId()})
    this.formGroup.patchValue({'orderId':this.activate.snapshot.paramMap.get('id')})
  }

  rejectOrder(){
    this.restaurant.acceptRejectByRest(this.formGroup.value).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/restaurant/orders/list')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  get f(){
    return this.formGroup.controls
  }

}
