import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  BASEURI: string = ""
  constructor(private http: HttpClient, @Inject('BASE_URL') _baseUrl:  string) {
    this.BASEURI = _baseUrl
    //console.log(this.BASEURI)
   }

  login(form){
    var header_object = new HttpHeaders().set("Access-Control-Allow-Origin","*");
    return this.http.post(this.BASEURI + '/api/adminlogin', form)
  }
}
