import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-producttag',
  templateUrl: './producttag.component.html',
  styleUrls: ['./producttag.component.css']
})
export class ProducttagComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
