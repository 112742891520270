<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/admin/restsubcategory/view']">Restaurant Sub-Category</a></li>
        <li class="active breadcrumb-item">Edit</li>
    </ol>
    <!-- <h1 class="dt-page__title">Edit Category</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title">Edit Restaurant Sub-Category</h3>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Form -->
                <form [formGroup]="formGroup" (submit)="onsubmit()">

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="restId">Restaurant Name</label>

                        <div class="col-xl-9">
                            <select class="form-control" id="restId" name="restId" formControlName="restId" [compareWith]="compareFn" (change)="onChangeCategory($event)">
                                <option *ngFor="let restaurant of restList" [value]="restaurant._id">{{restaurant.rest_name}}</option>
                            </select>
                            <small class="form-text" *ngIf="f.restId.invalid && (f.restId.dirty || f.restId.touched)">
                                <small *ngIf="f.restId.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="restcatId">Category Name</label>

                        <div class="col-xl-9">
                            <select class="form-control" id="restcatId" name="restcatId" formControlName="restcatId" [compareWith]="compareFn">
                                <option *ngFor="let category of categoryList" [value]="category._id">{{category.rest_cat_name}}</option>
                            </select>
                            <small class="form-text" *ngIf="f.restcatId.invalid && (f.restcatId.dirty || f.restcatId.touched)">
                                <small *ngIf="f.restcatId.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="restsubcategory_name">Sub-Category Name</label>

                        <div class="col-xl-9">
                            <input type="text" class="form-control" id="restsubcategory_name" placeholder="Enter Category Name" name="restsubcategory_name" formControlName="restsubcategory_name">
                            <small class="form-text" *ngIf="f.restsubcategory_name.invalid && (f.restsubcategory_name.dirty || f.restsubcategory_name.touched)">
                                <small *ngIf="f.restsubcategory_name.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="meta_desc">Meta Description</label>

                        <div class="col-xl-9">
                            <textarea class="form-control" id="meta_desc" placeholder="Enter Meta Description" name="meta_desc" formControlName="meta_desc"></textarea>
                            <small class="form-text" *ngIf="f.meta_desc.invalid && (f.meta_desc.dirty || f.meta_desc.touched)">
                                <small *ngIf="f.meta_desc.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="meta_keywords">Meta Keywords</label>

                        <div class="col-xl-9">
                            <textarea class="form-control" id="meta_keywords" placeholder="Enter Meta Keywords" name="meta_keywords" formControlName="meta_keywords"></textarea>
                            <small class="form-text" *ngIf="f.meta_keywords.invalid && (f.meta_keywords.dirty || f.meta_keywords.touched)">
                                <small *ngIf="f.meta_keywords.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <div class="col-xl-9 offset-xl-3">
                            <button type="submit" class="btn btn-primary text-uppercase" [disabled]="!formGroup.valid">Save
                            </button>
                        </div>
                    </div>
                    <!-- /form group -->

                </form>
                <!-- /form -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->