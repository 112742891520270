import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable'; 
import { SupportService } from 'src/app/shared/support/support.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-listdriverissue',
  templateUrl: './listdriverissue.component.html',
  styleUrls: ['./listdriverissue.component.css']
})
export class ListdriverissueComponent implements OnInit {

  constructor(private toast: ToastrService,private support: SupportService, private userdata: UserdataService) { } 

  p: number = 1; 
  adminId = ""
  term: string;
  temp = [];
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]
  columns = [{ prop: 'Driver Name' }, { name: 'Issue Title' },{ name: 'Order Id' }, { name: 'Order Status' }, { name: 'Status' }, { name: 'Reply' }, { name: 'Action' }];
  ngOnInit(): void {
    // for page number 1 as offset starts with 0
    //this.table.offset = 0;
    this.adminId = this.userdata.getId()
    this.getDriverIssueList()    
  }

  getDriverIssueList(){
    this.support.listDriverIssue({'adminId':this.adminId}).subscribe(
      (res:any) => {
        //console.log(res.data)
        //this.toast.success(res.message,'Success')
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.driverId.driver_name.toLowerCase().indexOf(val) !== -1 ||
      !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

}
