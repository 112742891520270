import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ManagerService } from 'src/app/shared/manager/manager.service';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-listuser',
  templateUrl: './listuser.component.html',
  styleUrls: ['./listuser.component.css']
})
export class ListuserComponent implements OnInit {

  constructor(private toast: ToastrService,private manager: ManagerService, private userdata:UserdataService) { }

  p: number = 1;
  term: string;
  temp = [];
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]
  columns = [{ prop: 'Name' },{ name: 'Role' }, { name: 'Email' }, { name: 'Phone' }, { name: 'Address' }, { name: 'Document Type' }, { name: 'Document Number' }, { name: 'Status' },  { name: 'Action' }];

  ngOnInit(): void {
    // for page number 1 as offset starts with 0
    //this.table.offset = 0;
    this.getManagerList()
    
  }

  getManagerList(){
    this.manager.allManager({}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 ||
      d.email.toLowerCase().indexOf(val) !== -1 ||
      d.address.toLowerCase().indexOf(val) !== -1 ||
      d.proof_type.toLowerCase().indexOf(val) !== -1 ||
      d.proof_number.toLowerCase().indexOf(val) !== -1 ||
      d.status.toLowerCase().indexOf(val) !== -1 ||
      !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  changeStatus(val,id){
    //console.log(id)
    this.manager.updateStatus({'email':id,'status':val}).subscribe(
      (res:any) => {
        this.toast.success(res.message,'Success')
        this.getManagerList()
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
        this.getManagerList()
      }
    )
  }
}
