<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="active breadcrumb-item">My Profile</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Tag</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <nav ngbNav #nav="ngbNav" class="nav-tabs">
            <ng-container ngbNavItem>
                <a ngbNavLink>Details</a>
                <ng-template ngbNavContent>
                    <!-- Card -->
                    <div class="dt-card dt-card__full-height">

                        <!-- Card Header -->
                        <div class="dt-card__header">

                            <!-- Card Heading -->
                            <div class="dt-card__heading">
                                <div class="col-6"><img [src]="getSanitizerUrl(restDetails.logo)"
                                        class="img img-fluid img-circle" height="100" width="100"></div>
                                <div class="col-6">
                                    <h3 class="dt-card__title float-left">{{restDetails.rest_name}}</h3>
                                </div>
                            </div>
                            <!-- /card heading -->

                        </div>
                        <!-- /card header -->

                        <!-- Card Body -->
                        <div class="dt-card__body">
                            <table class="table table-borderless">
                                <tr>
                                    <th>Owner Name</th>
                                    <th>Owner Phone</th>
                                    <th>Restaurant Phone</th>
                                    <th>Email</th>
                                </tr>
                                <tr>
                                    <td>{{restDetails.person_name}}</td>
                                    <td>{{restDetails.person_phone}}</td>
                                    <td>{{restDetails.rest_phone}}</td>
                                    <td>{{restDetails.person_email}}</td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <hr>
                                    </td>
                                </tr>
                                <tr>
                                    <th>City</th>
                                    <th>Street Number</th>
                                    <th>Address</th>
                                    <th>Address In Doc.</th>
                                </tr>
                                <tr>
                                    <td *ngIf="restDetails.rest_city">{{restDetails.rest_city.city_name}}</td>
                                    <td>{{restDetails.rest_street_number}}</td>
                                    <td>{{restDetails.rest_address}}</td>
                                    <td>{{restDetails.address_in_docs}}</td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <hr>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Seating Type</th>
                                    <th>Payment Type</th>
                                    <th>Food Type</th>
                                    <th>Is SFCR?</th>
                                </tr>
                                <tr>
                                    <td>{{restDetails.setting_type}}</td>
                                    <td>{{restDetails.payment_type}}</td>
                                    <td>{{restDetails.vegtype}}</td>
                                    <td>{{restDetails.is_sfcr}}</td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <hr>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Is Taxable?</th>
                                    <th>Tax Number</th>
                                    <th>Tax Registration Date</th>
                                    <th>SFCR Number</th>
                                </tr>
                                <tr>
                                    <td>{{restDetails.is_tax}}</td>
                                    <td>{{restDetails.tax_number}}</td>
                                    <td>{{transformDate(restDetails.tax_reg_date)}}</td>
                                    <td>{{restDetails.sfcr_number}}</td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <hr>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="2">Services</th>
                                    <th colspan="2">Cuisine</th>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <span *ngFor="let service of restDetails.services"
                                            class="badge badge-pill badge-info mr-1"><a class="icon icon-remove icon-fw"
                                                (click)="deleteAddOn(restDetails._id,service._id,'services')"></a>
                                            {{service.serviceId.name}}
                                        </span>
                                    </td>
                                    <td colspan="2">
                                        <span *ngFor="let cuisine of restDetails.cuisines"
                                            class="badge badge-pill badge-primary mr-1"><a
                                                class="icon icon-remove icon-fw"
                                                (click)="deleteAddOn(restDetails._id,cuisine._id,'cuisines')"></a>
                                            {{cuisine.cuisineId.cuisine_name}}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <hr>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="4">Tags</th>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <span *ngFor="let tag of restDetails.tags"
                                            class="badge badge-pill badge-warning mr-1"><a
                                                class="icon icon-remove icon-fw"
                                                (click)="deleteAddOn(restDetails._id,tag._id,'tags')"></a>
                                            {{tag.tagId.tag_name}}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <hr>
                                    </td>
                                </tr>
                                <tr>
                                    <th>ID Proof</th>
                                    <th>Tax Proof</th>
                                    <th>Logo</th>
                                    <th>SFCR Proof</th>
                                </tr>
                                <tr>
                                    <td><img [src]="getSanitizerUrl(restDetails.person_id_proof)"
                                            class="img img-fluid img-circle" height="100" width="100"></td>
                                    <td><img [src]="getSanitizerUrl(restDetails.tax_proof)"
                                            class="img img-fluid img-circle" height="100" width="100"></td>
                                    <td><img [src]="getSanitizerUrl(restDetails.logo)" class="img img-fluid img-circle"
                                            height="100" width="100"></td>
                                    <td><img [src]="getSanitizerUrl(restDetails.sfcr_proof)"
                                            class="img img-fluid img-circle" height="100" width="100"></td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <hr>
                                    </td>
                                </tr>
                            </table>

                        </div>
                        <!-- /card body -->

                    </div>
                    <!-- /card -->
                </ng-template>
            </ng-container>
            <!-- <ng-container ngbNavItem>
                <a ngbNavLink>Update Basic Profile</a>
                <ng-template ngbNavContent>
                    <div class="dt-card dt-card__full-height">

                        
                        <div class="dt-card__header">

                            
                            <div class="dt-card__heading">
                                <h3 class="dt-card__title">Add Category</h3>
                            </div>

                        </div>

                        
                        <div class="dt-card__body">

                            
                            <form [formGroup]="formGroup" (submit)="onsubmit()">

                                
                                <div class="form-group form-row">
                                    <label class="col-xl-3 col-form-label text-sm-right" for="rest_phone">Phone</label>

                                    <div class="col-xl-9">
                                        <input type="text" class="form-control" id="rest_phone" name="rest_phone"
                                            formControlName="rest_phone">
                                        <small class="form-text"
                                            *ngIf="f.rest_phone.invalid && (f.rest_phone.dirty || f.rest_phone.touched)">
                                            <small *ngIf="f.rest_phone.errors">Invalid Value</small>
                                        </small>
                                    </div>
                                </div>
                                <div class="form-group form-row">
                                    <label class="col-xl-3 col-form-label text-sm-right"
                                        for="rest_description">Description</label>

                                    <div class="col-xl-9">
                                        <textarea class="form-control" id="rest_description" name="rest_description"
                                            formControlName="rest_description"></textarea>
                                        <small class="form-text"
                                            *ngIf="f.rest_description.invalid && (f.rest_description.dirty || f.rest_description.touched)">
                                            <small *ngIf="f.rest_description.errors.required">Invalid Value</small>
                                        </small>
                                    </div>
                                </div>
                                <div class="form-group form-row">
                                    <label class="col-xl-3 col-form-label text-sm-right" for="logo">Image</label>

                                    <div class="col-xl-9">
                                        <input type="file" name="logo" class="form-control" (change)="onFile($event)"
                                            formControlName="logo">
                                        <small class="form-text"
                                            *ngIf="f.logo.invalid && (f.logo.dirty || f.logo.touched)">
                                            <small *ngIf="f.logo.errors.required">Invalid Value</small>
                                        </small>
                                        <small class="form-text">{{imageError}}</small>
                                    </div>
                                </div>
                                <div class="form-group form-row">
                                    <div class="col-xl-9 offset-xl-3">
                                        <button type="submit" class="btn btn-primary text-uppercase"
                                            [disabled]="!formGroup.valid">Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>
            </ng-container> -->
            <ng-container ngbNavItem>
                <a ngbNavLink>Update Password</a>
                <ng-template ngbNavContent>
                    <!-- Card -->
                    <div class="dt-card dt-card__full-height">

                        <!-- Card Header -->
                        <div class="dt-card__header">

                            <!-- Card Heading -->
                            <div class="dt-card__heading">
                                <h3 class="dt-card__title">Change Password</h3>
                            </div>
                            <!-- /card heading -->

                        </div>
                        <!-- /card header -->

                        <!-- Card Body -->
                        <div class="dt-card__body">

                            <!-- Form -->
                            <form [formGroup]="passwordGroup" (submit)="updatePassword()">

                                <!-- Form Group -->
                                <div class="form-group form-row">
                                    <label class="col-xl-3 col-form-label text-sm-right" for="oldpassword">Old
                                        Password</label>

                                    <div class="col-xl-9">
                                        <input type="password" class="form-control" id="oldpassword" name="oldpassword"
                                            formControlName="oldpassword">
                                        <small class="form-text"
                                            *ngIf="p.oldpassword.invalid && (p.oldpassword.dirty || p.oldpassword.touched)">
                                            <small *ngIf="p.oldpassword.errors.required">Invalid Value</small>
                                        </small>
                                    </div>
                                </div>
                                <!-- /form group -->

                                <!-- Form Group -->
                                <div class="form-group form-row">
                                    <label class="col-xl-3 col-form-label text-sm-right" for="newpassword">New
                                        Password</label>

                                    <div class="col-xl-9">
                                        <input type="password" class="form-control" id="newpassword" name="newpassword"
                                            formControlName="newpassword">
                                        <small class="form-text"
                                            *ngIf="p.newpassword.invalid && (p.newpassword.dirty || p.newpassword.touched)">
                                            <small *ngIf="p.newpassword.errors.required">Invalid Value</small>
                                        </small>
                                    </div>
                                </div>
                                <!-- /form group -->

                                <!-- Form Group -->
                                <div class="form-group form-row">
                                    <div class="col-xl-9 offset-xl-3">
                                        <button type="submit" class="btn btn-primary text-uppercase"
                                            [disabled]="!passwordGroup.valid">Save
                                        </button>
                                    </div>
                                </div>
                                <!-- /form group -->

                            </form>
                            <!-- /form -->

                        </div>
                        <!-- /card body -->

                    </div>
                    <!-- /card -->
                </ng-template>
            </ng-container>
        </nav>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->