import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserdataService } from '../shared/userdata.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {

  name=''
  usertype=''
  constructor(private userdata:UserdataService,private router:Router,private toast: ToastrService) { }

  ngOnInit(): void {
    this.name = this.userdata.getName()
    this.usertype = this.userdata.getUserType()
  }

  public logout(){
    this.userdata.removeData()
    this.toast.success('Logout Successful','Success')
    this.router.navigateByUrl('/admin/login')
  }

}
