import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  BaseUrl = ''
  Token = ''

  constructor(private http: HttpClient, @Inject('BASE_URL') _base,private userdata: UserdataService) {
    this.BaseUrl = _base
    this.Token = userdata.getToken()
  } 

  public addProduct(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/addproduct",form, { headers: header_object})
  } 

  public listProduct(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/allproduct",form,{ headers: header_object})
  } 

  public getProductById(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token).set('Content-Type', 'application/x-www-form-urlencoded')
    return this.http.post(this.BaseUrl + "/api/fetchproductbyid",form.toString(), { headers: header_object})
  }

  public updateProduct(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/updateproduct", form, { headers: header_object})
  }

  public updateProductAddons(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/addmoreaddon", form, { headers: header_object})
  }

  public deleteAddonTag(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/deleteaddon", form, { headers: header_object})
  }

  public pendingApproveProduct(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/requestforproduct",form,{ headers: header_object})
  }

  public approveProduct(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/approvalorrejectproduct",form,{ headers: header_object})
  }

  public singleApproveProduct(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/requestsingle",form,{ headers: header_object})
  }


  //------------------------Web restaurant panel------------------------//
  public restaddProduct(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/rest/addproduct",form, { headers: header_object})
  } 

  public restUpdateProduct(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/rest/updateproduct",form, { headers: header_object})
  } 

  public restPendingProduct(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/rest/mypendingproductrequest",form, { headers: header_object})
  } 

}
