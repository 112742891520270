<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="active breadcrumb-item">Restaurant Report</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Cuisine</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title float-left">View Restaurant Report</h3>
                    <!-- <a class="btn btn-primary float-right" [routerLink]="['/admin/restaurant/add']">Add</a> -->
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">
                
                <!-- Tables -->
                <input
                type="text" class="form-control search-input"
                placeholder="Type to filter the name column..."
                (keyup)="updateFilter($event)"
                />
                <ngx-datatable
                #table
                class="material"
                [columns]="columns"
                [columnMode]="ColumnMode.force"
                [headerHeight]="50"
                [footerHeight]="50"
                rowHeight="auto"
                [limit]="10"
                [rows]="rows"
                #myFilterTable
                >
                    <ngx-datatable-column name="Name" prop="rest_name">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.rest_name}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Total Orders" prop="rest_address">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.rest_address}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Total Amount" prop="rest_phone">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.rest_phone}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Rest. Earning" prop="rest_city.city_name">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.rest_city.city_name}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Admin Earning" prop="vegtype">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.vegtype}}
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
                <!-- /tables -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->