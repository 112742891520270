<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/admin/adcenter/view']">Ad-Center</a></li>
        <li class="active breadcrumb-item">Edit</li>
    </ol>
    <!-- <h1 class="dt-page__title">Edit Category</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title">Edit Ad-Center</h3>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Form -->
                <form [formGroup]="formGroup" (submit)="onsubmit()">

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="ads_name">Name</label>

                        <div class="col-xl-9">
                            <input type="text" class="form-control" id="ads_name" placeholder="Enter Name" name="ads_name" formControlName="ads_name">
                            <small class="form-text" *ngIf="f.ads_name.invalid && (f.ads_name.dirty || f.ads_name.touched)">
                                <small *ngIf="f.ads_name.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="start_date">Start Date</label>

                        <div class="col-xl-9">
                            <input type="date" class="form-control" id="start_date" name="start_date" formControlName="start_date" [ngModel]="startdate">
                            <small class="form-text" *ngIf="f.start_date.invalid && (f.start_date.dirty || f.start_date.touched)">
                                <small *ngIf="f.start_date.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="end_date">End Date</label>

                        <div class="col-xl-9">
                            <input type="date" class="form-control" id="end_date" name="end_date" formControlName="end_date" [ngModel]="enddate">
                            <small class="form-text" *ngIf="f.end_date.invalid && (f.end_date.dirty || f.end_date.touched)">
                                <small *ngIf="f.end_date.errors.required">Invalid Value</small>
                            </small> 
                        </div>
                    </div>
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="ads_type">Ad Alignment</label>

                        <div class="col-xl-9">
                            <select class="form-control" id="ads_type" name="ads_type" formControlName="ads_type" [compareWith]="compareFn">
                                <option *ngFor="let align of alignList">{{align}}</option>
                            </select>
                            <small class="form-text" *ngIf="f.ads_type.invalid && (f.ads_type.dirty || f.ads_type.touched)">
                                <small *ngIf="f.ads_type.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="restId">Restaurant (If Restaurant Ad)</label>

                        <div class="col-xl-9">
                            <select class="form-control" id="restId" name="restId" formControlName="restId" [compareWith]="compareFn">
                                <option *ngFor="let restaurant of restList" [value]="restaurant._id">{{restaurant.rest_name}}</option>
                            </select>
                            <small class="form-text" *ngIf="f.restId.invalid && (f.restId.dirty || f.restId.touched)">
                                <small *ngIf="f.restId.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="ads_image">Image</label>

                        <div class="col-xl-9">
                            <input type="file" name="ads_image" class="form-control" (change)="onFile($event)" formControlName="ads_image">
                            <small class="form-text" *ngIf="f.ads_image.invalid && (f.ads_image.dirty || f.ads_image.touched)">
                                <small *ngIf="f.ads_image.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <div class="col-xl-9 offset-xl-3">
                            <button type="submit" class="btn btn-primary text-uppercase" [disabled]="!formGroup.valid">Save
                            </button>
                        </div>
                    </div>
                    <!-- /form group -->

                </form>
                <!-- /form -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->