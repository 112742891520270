import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata.service';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  BaseUrl = ''
  Token = ''

  constructor(private http: HttpClient, @Inject('BASE_URL') _base,private userdata: UserdataService) {
    this.BaseUrl = _base
    this.Token = userdata.getToken()
  } 

  public allOrders(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken()).set('Content-Type', 'application/x-www-form-urlencoded')
    return this.http.post(this.BaseUrl + "/api/allorderadmin",form.toString(),{ headers: header_object})
  } 

  public getOrderById(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/fetchsingleorderadmin",form,{ headers: header_object})
  } 

  public driverOrderCount(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/allorderadmin",form,{ headers: header_object})
  } 

  public assignOrderManually(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/assignordermannually",form,{ headers: header_object})
  } 

  public refundOrderCancelByCustomer(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/assignordermannually",form,{ headers: header_object})
  } //----Api not available

  public assignOrderManuallyRest(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/assignordertorest",form,{ headers: header_object})
  } 

  //Change Order Status
  public changeOrderStatus(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/changeorderstatusbyadmin",form,{ headers: header_object})
  }

  //Get Cancelled and Rejected Orders
  public cancelRejectOrders(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/showcancelandrejected",form,{ headers: header_object})
  }

  //Refund Support Issue Order 
  public refundOrder(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/refundorder",form,{ headers: header_object})
  }

  //Reject Support Issue Order 
  public rejectSupportOrder(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/rejectissue",form,{ headers: header_object})
  }

  //Show Active Orders
  public showactiveorders(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/showactiveorders",form,{ headers: header_object})
  }

  //Show Completed Orders
  public showdeliveredorders(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/showdeliveredorders",form,{ headers: header_object})
  }

  //Show Unassigned Orders
  public getUnassignOrders(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/orderhavingnodriver",form,{ headers: header_object})
  }

  
  //--------------------------Web Restaurant panel apis-----------------------------------//
  public restAllOrders(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/rest/restallorders",form,{ headers: header_object})
  }

  public restSingleOrders(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/rest/singleorderdetailrest",form,{ headers: header_object})
  }

  public restPendingOrders(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/rest/restallpendingorders",form,{ headers: header_object})
  }

  public restHandOverOrders(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/rest/changeordertohandover",form,{ headers: header_object})
  }
}
