import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { DriverService } from 'src/app/shared/driver/driver.service';
import { RestaurantService } from '../shared/restaurant/restaurant.service';
import { DashboardService } from '../shared/dashboard/dashboard.service';
import { UserdataService } from '../shared/userdata.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  pendingDriverList = []
  pendingRestaurantList = []
  totalCustomer=0
  totalRest=0
  totalDriver=0
  activeOffer=0
  activeAds=0
  todayEarning=0
  userId=""
  OrderStatus:any=""
  isAdmin = true

  constructor(private toast: ToastrService, private dashboard:DashboardService, private userdata: UserdataService) { }

  ngOnInit(): void {
    this.userId = this.userdata.getId()
    var type = this.userdata.getUserType()
    if(type == 'Admin'){
      this.isAdmin = true
      this.getPendingDriverList()
      this.getPendingRestaurantList()
      this.countAds()
      this.countCustomer()
      this.countDriver()
      this.countOffer()
      this.countRest()
      this.countOrderStatus()
    }else{
      this.isAdmin = false
      this.restCountOrderStatus()
      this.restVariousCounts()
    }
  }

  getPendingDriverList(){
    this.dashboard.newDriver({'userId':this.userId}).subscribe(
      (res:any) => {
        //console.log(res.data)
        //this.toast.success(res.message,'Success')
        this.pendingDriverList = res.data
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  getPendingRestaurantList(){
    this.dashboard.newRestaurant({'userId':this.userId}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.pendingRestaurantList = res.data
      },
      err => {
        //console.log(err)
        //this.toast.error(err.error.message,'Error')
      }
    )
  }

  countCustomer(){
    this.dashboard.countCustomer({'userId':this.userId}).subscribe(
      (res:any) => {
        this.totalCustomer = res.data
      },
      err => {
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  countRest(){
    this.dashboard.countRest({'userId':this.userId}).subscribe(
      (res:any) => {
        this.totalRest = res.data
      },
      err => {
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  countDriver(){
    this.dashboard.countDriver({'userId':this.userId}).subscribe(
      (res:any) => {
        this.totalDriver = res.data
      },
      err => {
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  countOffer(){
    this.dashboard.countOffers({'userId':this.userId,'status':'Enabled'}).subscribe(
      (res:any) => {
        this.activeOffer = res.data
      },
      err => {
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  countAds(){
    this.dashboard.countAds({'userId':this.userId,'status':'Enabled'}).subscribe(
      (res:any) => {
        this.activeAds = res.data
      },
      err => {
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  countOrderStatus(){
    this.dashboard.countOrderStatus({'userId':this.userId}).subscribe(
      (res:any) => {
        this.OrderStatus = res
        //console.log(this.OrderStatus)
      },
      err => {
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  //---------------------------Restaurant APIs
  restCountOrder:any = ''
  restVariousCount:any = ''
  restActiveDriver = []
  restPendingDriver = []
  restCountOrderStatus(){
    this.dashboard.restCountOrderStatus({'restId':this.userdata.getId()}).subscribe(
      (res:any) =>{
        this.restCountOrder = res
      },err =>{
        //console.log(err)
      }
    )
  }

  restVariousCounts(){
    this.dashboard.restVariousCount({'restId':this.userdata.getId()}).subscribe(
      (res:any) =>{
        this.restVariousCount = res
      },err =>{
        console.log(err)
      }
    )
  }

  restActiveDriverList(){
    this.dashboard.restActiveDriverList({'restId':this.userdata.getId()}).subscribe(
      (res:any) =>{
        this.restActiveDriver = res.data
      },err =>{
        console.log(err)
      }
    )
  }

  restPendingDriverList(){
    this.dashboard.restPendingDriverList({'restId':this.userdata.getId()}).subscribe(
      (res:any) =>{
        this.restPendingDriver = res.data
      },err =>{
        console.log(err)
      }
    )
  }
}
