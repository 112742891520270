import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { RestcategoryService } from 'src/app/shared/restcategory/restcategory.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-edit-mycategory',
  templateUrl: './edit-mycategory.component.html',
  styleUrls: ['./edit-mycategory.component.css']
})
export class EditMycategoryComponent implements OnInit {

  restList=[]

  formGroup = new FormGroup({
    rest_cat_name: new FormControl('', [Validators.required]),
    restId: new FormControl('', [Validators.required]),
    _id: new FormControl('', [Validators.required]),
  }) 

  id = ''
  constructor(private toast: ToastrService, private userdata: UserdataService,private category: RestcategoryService,private router: Router, private activate: ActivatedRoute, private restaurant: RestaurantService) { }

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getDataById()
  }

  get f(){
    return this.formGroup.controls
  }

  compareFn(c1: any, c2:any): boolean {     
    return c1 && c2 ? c1.id === c2.id : c1 === c2; 
  }

  getDataById(){
    let body = new URLSearchParams();
    body.set('_id', this.id);
    this.category.getCategoryById(body).subscribe(
      (res:any) => {
        //console.log(res.result)
        this.formGroup.patchValue({rest_cat_name: res.data.rest_cat_name}); 
        this.formGroup.patchValue({_id: res.data._id});  
        var id = this.userdata.getId()
        this.formGroup.patchValue({restId: id});
        this.f  
      },
      err => {
        console.log(err) 
        this.toast.error(err.error.message,'Error')
        this.router.navigateByUrl('/restaurant/category/view')
      }
    )
  }

  public onsubmit(){
    this.category.restUpdateCategory(this.formGroup.value).subscribe(
      (res:any) => {
        // console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/restaurant/category/view')
      },
      err => {
        // console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }
}
