import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrdersService } from 'src/app/shared/orders/orders.service';
import { UserdataService } from 'src/app/shared/userdata.service';
import { Location } from "@angular/common";

@Component({
  selector: 'app-updateorder',
  templateUrl: './updateorder.component.html',
  styleUrls: ['./updateorder.component.css']
})
export class UpdateorderComponent implements OnInit {

  statusList = [ 
    {
      'value':103,
      'name':'Cooking'
    },
    {
      'value':104,
      'name':'Rejected'
    },
    {
      'value':105,
      'name':'Cooked'
    },
    {
      'value':107,
      'name':'On Way'
    },
    {
      'value':108,
      'name':'Delivered'
    },
    {
      'value':109,
      'name':'Canceled'
    },
    {
      'value':111,
      'name':'Refunded'
    },
  ]
  formGroup = new FormGroup({
    orderId: new FormControl('', [Validators.required]),
    new_status: new FormControl('', [Validators.required]),
    comment: new FormControl('', [Validators.required]),
    userId: new FormControl('', [Validators.required]),
  }) 
  constructor(private toast: ToastrService, private userdata: UserdataService,private order: OrdersService,private router: Router,private activate: ActivatedRoute,private location: Location) { }
  
  id =''
  orderId:any = ''
  ngOnInit(): void {
    this.formGroup.patchValue({userId: this.userdata.getId()});
    this.id = this.activate.snapshot.paramMap.get('id')
    this.formGroup.patchValue({orderId: this.id});
    this.getSingleOrder()
  }

  get f(){
    return this.formGroup.controls
  }

  getSingleOrder(){
    this.order.getOrderById({'_id':this.id}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.orderId = res.data.orderId
      },
      err => {
        // console.log(err)
        // this.toast.error(err.error.message,'Error')
      }
    )
  }

  public onsubmit(){
    this.order.changeOrderStatus(this.formGroup.value).subscribe(
      (res:any) => {
        this.toast.success(res.message,'Success')
        //this.formGroup.reset()
        //this.router.navigateByUrl('/admin/orders/all');
        this.location.back()
      },
      err => {
        console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
