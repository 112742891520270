<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/admin/orders/all']">Orders</a></li>
        <li class="active breadcrumb-item">Assign Order</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Tag</h1> -->
</div>
<!-- /page header --> 
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">
        <!-- Card -->
        <div class="dt-card dt-card__full-height">
            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title float-left">Order Details</h3>
                    <!-- <a class="btn btn-primary float-right" [routerLink]="['/admin/menu/add']">Add</a> -->
                </div>
                <!-- /card heading -->
            </div>
            <!-- /card header -->
            <!-- Card Body -->
            <div class="dt-card__body">
                <!-- Tables -->
                <table class="table table-bordered table-striped">
                    <tbody>
                        <tr>
                            <td><strong>Order ID: </strong># {{orderList.orderId}}</td>
                            <td><strong>Customer Name: </strong>{{orderList.customerId.customer_name}}</td>
                            <td colspan="2"><strong>Address: </strong>{{orderList.addressId.address_full}}</td>
                        </tr>
                        <tr>
                            <td><strong>Restaurant: </strong>{{orderList.restId.rest_name}}</td>
                            <td><strong>Order Amount: </strong>{{orderList.total_paid |currency:'CAD':'symbol-narrow'}}
                            </td>
                            <td><strong>Delivery Charges:
                                </strong>{{orderList.del_charges |currency:'CAD':'symbol-narrow'}}</td>
                            <td><strong>Tip Amount: </strong>{{orderList.tip_amount |currency:'CAD':'symbol-narrow'}}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- /Tables-->
            </div>
        </div>
    </div>

    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title float-left">Assign Orders</h3>
                    <!-- <a class="btn btn-primary float-right" [routerLink]="['/admin/menu/add']">Add</a> -->
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Tables -->
                <input type="text" class="form-control search-input" placeholder="Type to filter the name column..."
                    (keyup)="updateFilter($event)" />
                <ngx-datatable #table class="material" [columns]="columns" [columnMode]="ColumnMode.force"
                    [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [limit]="10" [rows]="rows" #myFilterTable>
                    <ngx-datatable-column name="Driver Name" prop="driver_name">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{row.driver_name}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Total Orders" prop="current_order_number">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{row.current_order_number}}
                        </ng-template>
                    </ngx-datatable-column>
                    <!-- <ngx-datatable-column name="Cash In Hand" prop="cash_amount">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{row.cash_amount |currency:'CAD':'symbol-narrow'}}
                        </ng-template>
                    </ngx-datatable-column> -->

                    <ngx-datatable-column name="Action">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <button title="Assign Order" class="btn btn-outline-primary" (click)="assignOrder($event)" [value]="row._id">Assign Order</button>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
                <!-- /tables -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->