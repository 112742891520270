import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { OrdersService } from 'src/app/shared/orders/orders.service';
import { ProductService } from 'src/app/shared/product/product.service';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-completedorder',
  templateUrl: './completedorder.component.html',
  styleUrls: ['./completedorder.component.css']
})
export class CompletedorderComponent implements OnInit {

  constructor(private toast: ToastrService,private product: ProductService, private userdata: UserdataService,private orders:OrdersService, private datePipe: DatePipe,private restaurant:RestaurantService, private router:Router) { }

  p: number = 1;
  term: string;
  temp = [];
  restList = []
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]
  columns = [{ prop: 'Restaurant Name' },{ name: 'Order Id' },{ name: 'Payment Type' },{ name: 'Total' }, { name: 'Driver' }, { name: 'Status' }, { name: 'Action' }];

  ngOnInit(): void {
    this.getActiveOrders()    
  }

  getActiveOrders(){
    this.orders.showdeliveredorders({}).subscribe(
      (res:any) => {
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        console.log(err) 
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.order_name.toLowerCase().indexOf(val) !== -1 ||
      d.restId.rest_name.toLowerCase().indexOf(val) !== -1 ||
      !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  transformDate(date){
    return this.datePipe.transform( date,'MM-dd-yyyy');
  }

}
