<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/admin/support/driverIssue/list']">Driver Support</a></li>
        <li class="active breadcrumb-item">Issue Details</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Tag</h1> -->
</div>
<!-- /page header -->
<!-- Grid --> 
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <!-- <div class="col-6"><img [src]="getSanitizerUrl(orderData.product_image)" class="img img-fluid img-circle" height="100" width="100"></div> -->
                    <!-- <div class="col-6">
                        <h3 class="dt-card__title float-left">{{orderData.restId.rest_name}}</h3>
                    </div> -->
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">

                <nav ngbNav #nav="ngbNav" class="nav-tabs">
                    <ng-container ngbNavItem>
                        <a ngbNavLink>Issue Detail</a>
                        <ng-template ngbNavContent>
                            <table class="table table-bordered">
                                <tr>
                                    <th>Issue Type</th>
                                    <td><span class="badge badge-primary" *ngIf="issueData.issue_type == 1">Order Issue</span>
                                        <span class="badge badge-info" *ngIf="issueData.issue_type == 2">Cash In Hand Issue</span>
                                        <span class="badge badge-secondary" *ngIf="issueData.issue_type == 3">Wallet Issue</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Title</th>
                                    <td>{{issueData.issue_title}}</td>
                                </tr>
                                <tr>
                                    <th>Description</th>
                                    <td>{{issueData.issue_description}}</td>
                                </tr>
                                <!-- <tr>
                                    <th>Issue Amount</th>
                                    <td>{{issueData.amount}}</td>
                                </tr> -->
                                <tr *ngIf="issueData.issue_type == 1">
                                    <th>Order Amount</th>
                                    <td>
                                        {{issueData.orderId.total_paid | currency : 'CAD' :'symbol-narrow'}}
                                    </td>
                                </tr>
                                <tr *ngIf="issueData.issue_type == 1">
                                    <th>Order Status</th>
                                    <td>
                                        <span class="badge badge-pill badge-primary" *ngIf="issueData.orderId.order_current_status==101">
                                            Placed
                                        </span>
                                        <span class="badge badge-pill badge-danger" *ngIf="issueData.orderId.order_current_status==102">
                                            Payment Declined
                                        </span>
                                        <span class="badge badge-pill badge-warning" *ngIf="issueData.orderId.order_current_status==103">
                                            Cooking
                                        </span>
                                        <span class="badge badge-pill badge-danger" *ngIf="issueData.orderId.order_current_status==104">
                                            Rejected
                                        </span>
                                        <span class="badge badge-pill badge-warning" *ngIf="issueData.orderId.order_current_status==105">
                                            Cooked
                                        </span>
                                        <span class="badge badge-pill badge-info" *ngIf="issueData.orderId.order_current_status==106">
                                            Pickup
                                        </span>
                                        <span class="badge badge-pill badge-info" *ngIf="issueData.orderId.order_current_status==107">
                                            On Way
                                        </span>
                                        <span class="badge badge-pill badge-success" *ngIf="issueData.orderId.order_current_status==108">
                                            Delivered
                                        </span>
                                        <span class="badge badge-pill badge-danger" *ngIf="issueData.orderId.order_current_status==109">
                                            Canceled
                                        </span>
                                        <span class="badge badge-pill badge-danger" *ngIf="issueData.orderId.order_current_status==110">
                                            TimeOut
                                        </span>
                                        <span class="badge badge-pill badge-danger" *ngIf="issueData.orderId.order_current_status==111">
                                            Refunded
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Status</th>
                                    <td>
                                        <span class="badge badge-success" *ngIf="issueData.is_solved==true">Solved</span>
                                        <span class="badge badge-danger" *ngIf="issueData.is_solved==false">Pending</span>      
                                    </td>
                                </tr>
                                <tr *ngIf="issueData.is_reply_by_system">
                                    <th>Reply</th>
                                    <td>
                                        {{issueData.reply_by_system}}  
                                    </td>
                                </tr>
                            </table>
                        </ng-template>
                    </ng-container>
                    
                </nav>
                <div [ngbNavOutlet]="nav" class="mt-2"></div>


            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->