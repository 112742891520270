<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Body -->
            <div class="dt-card__body p-0">

                <!-- Tables -->
                <input
                type="text" class="form-control search-input"
                placeholder="Type to filter the name column..."
                (keyup)="updateFilter($event)"
                />
                <ngx-datatable
                #table
                class="material"
                [columns]="columns"
                [columnMode]="ColumnMode.force"
                [headerHeight]="50"
                [footerHeight]="50"
                rowHeight="auto"
                [limit]="10"
                [rows]="rows"
                #myFilterTable
                >
                    <ngx-datatable-column name="Name" prop="driver_name">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.driver_name}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Email" prop="driver_email">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.driver_email}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Phone" prop="driver_phone">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.driver_phone}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Address" prop="driver_address">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{row.driver_address}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Status">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span class="badge badge-pill badge-warning" *ngIf="row.current_status=='Registered'">
                                {{ row.current_status }}
                            </span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Details">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <a [routerLink]="['/restaurant/driver/', row._id]" title="Driver Details"><i
                                class="icon icon-eye icon-fw icon-lg text-success"></i></a>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Action">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <a [routerLink]="['/restaurant/driver/update', row._id]" title="Approve Or Reject Driver" ><i class="icon icon-editors icon-fw icon-lg icon-edit"></i></a>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
                <!-- /tables -->

            </div>
            <!-- /card body -->
        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->