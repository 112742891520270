<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
      <li class="breadcrumb-item"><a [routerLink]="['/admin/orders/all']">View Orders</a></li>
      <li class="active breadcrumb-item">Order Details</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Tag</h1> -->
  </div>
  <!-- /page header -->
  <!-- Grid -->
  <div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">
  
      <!-- Card -->
      <div class="dt-card dt-card__full-height">
  
        <!-- Card Header -->
        <div class="dt-card__header">
  
          <!-- Card Heading -->
          <div class="dt-card__heading">
            <!-- <div class="col-6"><img [src]="getSanitizerUrl(rows.product_image)" class="img img-fluid img-circle" height="100" width="100"></div> -->
            <div class="col-6">
              <h3 class="dt-card__title float-left">{{rows.restId.rest_name}}</h3>
            </div>
          </div>
          <!-- /card heading -->
  
        </div>
        <!-- /card header -->
  
        <!-- Card Body -->
        <div class="dt-card__body">
  
          <nav ngbNav #nav="ngbNav" class="nav-tabs">
            <ng-container ngbNavItem>
              <a ngbNavLink>Order Details</a>
              <ng-template ngbNavContent>
                <table class="table table-borderless">
                  <tr>
                    <th>Order Id</th>
                    <th>Customer Name</th>
                    <th>Customer Email</th>
                    <th>Customer Phone</th>
                  </tr>
                  <tr>
                    <td>{{rows.orderId}}</td>
                    <td>{{rows.customerId.customer_name}}</td>
                    <td>{{rows.customerId.customer_email}}</td>
                    <td>{{rows.customerId.customer_phone}}</td>
                  </tr>
                  <tr>
                    <td colspan="4">
                      <hr>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="2">Product Name</th>
                    <th>Quantity</th>
                    <th>Add-Ons</th>
                  </tr>
                  <tr *ngFor="let pro of getJson(rows.products)">
                    <td colspan="2">{{pro.product_name}}</td>
                    <td>{{pro.qty}}</td>
                    <td>
                      <div *ngFor="let add of pro.addOn" class="mb-1">
                        {{add.addontype}}<br />
                        <span *ngFor="let addsub of add.value" class="badge badge-pill badge-primary mr-1">
                          {{addsub.valuetype}}-{{addsub.valuesvalue}}
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4">
                      <hr>
                    </td>
                  </tr>
                  <tr>
                    <th>Sub Total($)</th>
                    <th>Tax Amount($)</th>
                    <th>Total($)</th>
                    <th>Coupon Discount ($)</th>
                  </tr>
                  <tr>
                    <td>{{rows.sub_total}}</td>
                    <td>{{rows.tax_amount}}</td>
                    <td>{{rows.product_total_with_tax}}</td>
                    <td>{{rows.coupon_discount}}</td>
  
                  </tr>
                  <tr>
                    <td colspan="4">
                      <hr>
                    </td>
                  </tr>
                  <tr>
                    <th>Delivery Charges($)</th>
                    <th>Tip Amount($)</th>
                    <th>Driver Amount ($)</th>
                    <th>Total Payable($)</th>
                  </tr>
                  <tr>
                    <td>{{rows.del_charges}}</td>
                    <td>{{rows.tip_amount}}</td>
                    <td>{{rows.driver_amount}}</td>
                    <td>{{rows.total_paid}}</td>
                  </tr>
                  <tr>
                    <td colspan="4">
                      <hr>
                    </td>
                  </tr>
  
                  <tr>
                    <th>Payment Mode</th>
                    <th>Payment Status</th>
                    <th>Order Status</th>
                    <th>Order Date</th>
                  </tr>
                  <tr>
                    <td>{{rows.payment_type}}</td>
                    <td><span class="badge badge-danger" *ngIf="!rows.payment_status">Pending</span><span
                        class="badge badge-success" *ngIf="rows.payment_status">Paid</span></td>
                    <td>
                      <span class="badge badge-pill badge-primary" *ngIf="rows.order_current_status==101">
                        Order Placed
                      </span>
                      <span class="badge badge-pill badge-primary" *ngIf="rows.order_current_status==102">
                        Payment Declined
                      </span>
                      <span class="badge badge-pill badge-warning" *ngIf="rows.order_current_status==103">
                        Order Preparing
                      </span>
                      <span class="badge badge-pill badge-danger" *ngIf="rows.order_current_status==104">
                        Order Rejected
                      </span>
                      <span class="badge badge-pill badge-warning" *ngIf="rows.order_current_status==105">
                        Order Prepared
                      </span>
                      <span class="badge badge-pill badge-info" *ngIf="rows.order_current_status==106">
                        Pickup
                      </span>
                      <span class="badge badge-pill badge-info" *ngIf="rows.order_current_status==107">
                        On Way
                      </span>
                      <span class="badge badge-pill badge-success" *ngIf="rows.order_current_status==108">
                        Delivered
                      </span>
                      <span class="badge badge-pill badge-danger" *ngIf="rows.order_current_status==109">
                        Canceled
                      </span>
                      <span class="badge badge-pill badge-danger" *ngIf="rows.order_current_status==110">
                        Timeout
                      </span>
                      <span class="badge badge-pill badge-danger" *ngIf="rows.order_current_status==111">
                        Refunded
                      </span>
                      <span class="badge badge-pill badge-danger" *ngIf="rows.order_current_status==112">
                        Cancel & Refund
                      </span>
  
                    </td>
                    <td>{{transformDate(rows.created_at)}}</td>
                  </tr>
                  <tr>
                    <td colspan="4">
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <th colspan="2">Additional Info.</th>
                    <th colspan="2">Order Details</th>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <strong *ngIf="rows.order_instructions != ''">Kitchen Instructions - </strong><label
                        *ngIf="rows.order_instructions != ''">{{rows.order_instructions}}</label><br
                        *ngIf="rows.order_instructions != ''" />
  
                      <strong *ngIf="rows.driver_instructions != ''">Delivery Instructions - </strong><label
                        *ngIf="rows.driver_instructions != ''">{{rows.driver_instructions}}</label><br
                        *ngIf="rows.driver_instructions != ''" />
  
                      <strong *ngIf="rows.is_order_contact_less">Is Contact-Less Delivery - </strong><label
                        *ngIf="rows.is_order_contact_less"><i class="icon icon-check text-success"></i></label><br
                        *ngIf="rows.is_order_contact_less" />
  
                      <strong *ngIf="!rows.is_bell_to_ring">Don't Ring Bell - </strong><label
                        *ngIf="!rows.is_bell_to_ring"><i class="icon icon-check text-success"></i></label><br
                        *ngIf="!rows.is_bell_to_ring" />
  
                      <strong>Order Type - </strong><label *ngIf="rows.order_takeaway">Takeaway</label><label
                        *ngIf="!rows.order_takeaway">Delivery</label>
                    </td>
                    <td colspan="2">
                      <div *ngIf="rows.order_cancel_reason_rest != ''">
                        <strong>Order Rejection Reason -</strong>
                        {{rows.order_cancel_reason_rest}}
                      </div>
                      <div *ngIf="(rows.order_preparing_time/60) >1">
                        <strong>Order Delay - </strong>
                        <span class="badge badge-danger">{{rows.order_preparing_time/60 | number:'1.0-0'}} Min</span>
                      </div>
                    </td>
                  </tr>
                </table>
              </ng-template>
            </ng-container>
          </nav>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
  
        </div>
        <!-- /card body -->
  
      </div>
      <!-- /card -->
  
    </div>
    <!-- /grid item -->
  
  </div>
  <!-- /grid -->
  