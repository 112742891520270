import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SupportService } from 'src/app/shared/support/support.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-detailcustomerissue',
  templateUrl: './detailcustomerissue.component.html',
  styleUrls: ['./detailcustomerissue.component.css'],
  providers: [DatePipe]
})
export class DetailcustomerissueComponent implements OnInit {

  id=''
  imageURL = ''
  issueData:any = ''
  orderData:any = ''
  customerData:any = ''
  constructor(private activate: ActivatedRoute, private toast: ToastrService,private support: SupportService, private userdata: UserdataService,private datePipe: DatePipe,private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl) {
    this.imageURL = _imageurl
   }

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getSingleCustomerIssue()
  }

  getSingleCustomerIssue(){
    this.support.singleCustomerIssue({'_id':this.id}).subscribe(
      (res:any) => {
        this.orderData = res.data.orderId
        this.customerData = res.data.customerId
        this.issueData = res.data
      },err =>{
        console.log(err.error.message)
      }
    )
  }

  getJson(value){
    return JSON.parse(value)
  }

  transformDate(date)
  {
    return this.datePipe.transform( date,'MM-dd-yyyy');
  }

  public getSanitizerUrl(photoname){
    return this.trusturl.bypassSecurityTrustUrl(this.imageURL + photoname)
  }  

}
