import { Component, OnInit } from '@angular/core';
import { JsService } from '../shared/js/js.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  constructor(private js:JsService) { }

  ngOnInit(): void {
    this.js.callLayoutClass()
  }

}
