<!-- Page Header -->
<div class="dt-page__header">
	<ol class="breadcrumb">
		<li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
		<li class="breadcrumb-item"><a [routerLink]="['/admin/offers/view']">Offers</a></li>
		<li class="active breadcrumb-item">Add</li>
	</ol>
	<!-- <h1 class="dt-page__title">Add Offers</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
	<!-- Grid Item -->
	<div class="col-xl-12">
		<!-- Form -->
		<form [formGroup]="formGroup" (submit)="onsubmit()">
			<!-- Card -->
			<div class="dt-card dt-card__full-height">
				<!-- Card Header -->
				<div class="dt-card__header">
					<!-- Card Heading -->
					<div class="dt-card__heading">
						<h3 class="dt-card__title">Offers</h3>
					</div>
					<!-- /card heading -->
				</div>
				<!-- /card header -->
				<!-- Card Body -->
				<div class="dt-card__body">
					<div class="form-row">
						<div class="col-sm-6 mb-3">
							<label for="offers_type">Offer Type</label>
							<select class="form-control" id="offers_type" name="offers_type" formControlName="offers_type">
                                <option value="" [selected]="true" [disabled]="true">Select Offer Type</option>
                                <option *ngFor="let type of offerType" [value]="type.value">{{type.name}}</option>
                            </select>
                                <small class="form-text"
								*ngIf="f.offers_type.invalid && (f.offers_type.dirty || f.offers_type.touched)">
								<small *ngIf="f.offers_type.errors">Invalid Value</small> </small>
						</div>
						<div class="col-sm-6 mb-3">
							<label for="offer_amount">Offer Amount ($)</label>
							<input type="number" class="form-control" id="offer_amount" placeholder="Offer Amount" name="offer_amount" formControlName="offer_amount"> <small class="form-text"
								*ngIf="f.offer_amount.invalid && (f.offer_amount.dirty || f.offer_amount.touched)">
								<small *ngIf="f.offer_amount.errors">Enter Number only . Eg: 1, 1.1, 1.12</small> </small>
						</div>
					</div>
					<div class="form-row">
						<div class="col-sm-6 mb-3">
							<label for="minimum_order">Minimum Order ($)</label>
							<input type="text" class="form-control" id="minimum_order" placeholder="Enter Minimum Order" name="minimum_order" formControlName="minimum_order"> <small class="form-text"
								*ngIf="f.minimum_order.invalid && (f.minimum_order.dirty || f.minimum_order.touched)">
								<small *ngIf="f.minimum_order.errors">Enter Number only . Eg: 1, 1.1, 1.12</small> </small>
						</div>
						<div class="col-sm-6 mb-3">
							<label for="offers_code">Offer Code</label>
							<input type="text" class="form-control" id="offers_code" placeholder="Enter Offer Code" name="offers_code" formControlName="offers_code"> <small class="form-text"
								*ngIf="f.offers_code.invalid && (f.offers_code.dirty || f.offers_code.touched)">
								<small *ngIf="f.offers_code.errors">Invalid Value</small> </small>
						</div>
                    </div>
                    <div class="form-row">
						<div class="col-sm-6 mb-3">
							<label for="maximum_discount">Maximum Discount ($)</label>
							<input type="text" class="form-control" id="maximum_discount" placeholder="Enter Maximum Discount" name="maximum_discount" formControlName="maximum_discount"> <small class="form-text"
								*ngIf="f.maximum_discount.invalid && (f.maximum_discount.dirty || f.maximum_discount.touched)">
								<small *ngIf="f.maximum_discount.errors">Enter Number only . Eg: 1, 1.1, 1.12</small> </small>
						</div>
						<div class="col-sm-6 mb-3">
							<label for="offer_on">Offer On</label>
							<select class="form-control" id="offer_on" name="offer_on" formControlName="offer_on" (change)="getDetailList($event)">
                                <option value="" [selected]="true" [disabled]="true">Select Offer On</option>
                                <option *ngFor="let on of offerOn" [value]="on.value">{{on.name}}</option>
                            </select>
                                <small class="form-text"
								*ngIf="f.offer_on.invalid && (f.offer_on.dirty || f.offer_on.touched)">
								<small *ngIf="f.offer_on.errors">Invalid Value</small> </small>
						</div>
						<!-- <div class="col-sm-6 mb-3">
							<label for="offer_details">Offer Details</label>
							<select class="form-control" id="offer_details" name="offer_details" formControlName="offer_details">
                                <option value="" [selected]="true" [disabled]="true">Select Offer Details</option>
                                <option *ngFor="let details of offerDetails" [value]="details._id">{{details.name}}</option>
                            </select>
                                <small class="form-text"
								*ngIf="f.offer_details.invalid && (f.offer_details.dirty || f.offer_details.touched)">
								<small *ngIf="f.offer_details.errors">Invalid Value</small> </small>
						</div> -->
					</div>
					<div class="form-row" *ngIf="data==2">
						<div class="col-sm-12 mb-3">
							<label for="categoryId">Select Category</label>
							<select class="form-control" id="categoryId" name="categoryId" formControlName="categoryId">
                                <option value="" [selected]="true" [disabled]="true">Select Category</option>
                                <option *ngFor="let cat of catList" [value]="cat._id">{{cat.name}}</option>
                            </select>
                                <small class="form-text"
								*ngIf="f.categoryId.invalid && (f.categoryId.dirty || f.categoryId.touched)">
								<small *ngIf="f.categoryId.errors">Invalid Value</small> </small>
						</div>
					</div>
					<div class="form-row" *ngIf="data==3">
						<div class="col-sm-6 mb-3">
							<label for="prodRestId">Select Restaurant</label>
							<select class="form-control" id="prodRestId" name="prodRestId" formControlName="prodRestId" (change)="getProduct($event)">
                                <option value="" [selected]="true" [disabled]="true">Select Restaurant</option>
                                <option *ngFor="let prodRest of prodRestList" [value]="prodRest._id">{{prodRest.name}}</option>
                            </select>
                                <small class="form-text"
								*ngIf="f.prodRestId.invalid && (f.prodRestId.dirty || f.prodRestId.touched)">
								<small *ngIf="f.prodRestId.errors">Invalid Value</small> </small>
						</div>
						<div class="col-sm-6 mb-3">
							<label for="productId">Select Product</label>
							<select class="form-control" id="productId" name="productId" formControlName="productId">
                                <option value="" [selected]="true" [disabled]="true">Select Product</option>
                                <option *ngFor="let prod of prodList" [value]="prod._id">{{prod.name}}</option>
                            </select>
                                <small class="form-text"
								*ngIf="f.productId.invalid && (f.productId.dirty || f.productId.touched)">
								<small *ngIf="f.productId.errors">Invalid Value</small> </small>
						</div>
					</div>
					<div class="form-row" *ngIf="data==4">
						<div class="col-sm-12 mb-3">
							<label for="restId">Select Restaurant</label>
							<select class="form-control" id="restId" name="restId" formControlName="restId">
                                <option value="" [selected]="true" [disabled]="true">Select Restaurant</option>
                                <option *ngFor="let rest of restList" [value]="rest._id">{{rest.name}}</option>
                            </select>
                                <small class="form-text"
								*ngIf="f.restId.invalid && (f.restId.dirty || f.restId.touched)">
								<small *ngIf="f.restId.errors">Invalid Value</small> </small>
						</div>
					</div>
                    <div class="form-row">
						<div class="col-sm-6 mb-3">
							<label for="offers_image">Offer Image</label>
							<input type="file" class="form-control" id="offers_image" name="offers_image" formControlName="offers_image" (change)="onFile($event)">
                                <small class="form-text"
								*ngIf="f.offers_image.invalid && (f.offers_image.dirty || f.offers_image.touched)">
								<small *ngIf="f.offers_image.errors">Invalid Value</small> </small>
						</div>
						<div class="col-sm-6 mb-3">
							<label for="end_date">Offer End Date</label>
							<input type="date" class="form-control" id="end_date" name="end_date" formControlName="end_date">
                                <small class="form-text"
								*ngIf="f.end_date.invalid && (f.end_date.dirty || f.end_date.touched)">
								<small *ngIf="f.end_date.errors">Invalid Value</small> </small>
						</div>
                    </div>
                    <div class="form-row">
						<div class="col-sm-12 mb-3">
							<label for="offer_desc">Offer Description</label>
							<textarea class="form-control" id="offer_desc" name="offer_desc" formControlName="offer_desc" placeholder="Eg. Get Flat $10 off on your order above $50 etc."></textarea>
                                <small class="form-text"
								*ngIf="f.offer_desc.invalid && (f.offer_desc.dirty || f.offer_desc.touched)">
								<small *ngIf="f.offer_desc.errors">Invalid Value</small> </small>
						</div>
					</div>
                    <div class="form-row">
						<div class="col-xl-8 offset-xl-4">
							<button type="submit" class="btn btn-primary text-uppercase"
								[disabled]="!formGroup.valid">Save
							</button>
						</div>
					</div>
				</div>
				<!-- /card body -->
			</div>
			<!-- /card -->
		</form>
		<!--/form-->
	</div>
	<!-- /grid item -->
</div>
<!-- /grid -->