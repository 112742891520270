import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata.service'; 

@Injectable({
  providedIn: 'root'
})
export class OffersService {

  BaseUrl = ''
  Token = ''

  constructor(private http: HttpClient, @Inject('BASE_URL') _base,private userdata: UserdataService) {
    this.BaseUrl = _base
    this.Token = userdata.getToken()
  }

  public addOffer(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/addnewoffers",form, { headers: header_object})
  }

  public allOffer(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/alloffer",form,{ headers: header_object})
  }

  public getOfferById(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/fetchofferbyid",form, { headers: header_object})
  }

  public updateOffer(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/updateoffer", form, { headers: header_object})
  }

  //-------------------Web restaurant panel apis----------------------//
  public restAddOffer(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/rest/addnewoffers", form, { headers: header_object})
  }

  public restListOffer(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/rest/myalloffers", form, { headers: header_object})
  }

  public restUpdateOffer(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/rest/updateoffer", form, { headers: header_object})
  }
}
