import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ManagerService } from 'src/app/shared/manager/manager.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.css']
})
export class EdituserComponent implements OnInit {

  statusList: any = ['Enabled', 'Disabled']

  formGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required, Validators.pattern("[0-9]{10}")]),
    address: new FormControl('', [Validators.required]),
    proof_type: new FormControl('', [Validators.required]),
    proof_number: new FormControl('', [Validators.required]),
    status: new FormControl('', [Validators.required]),
  })

  constructor(private toast: ToastrService,private manager:ManagerService, private router: Router, private activate : ActivatedRoute) { }

  id = ''

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getDataById()
  }

  get f(){
    return this.formGroup.controls
  }

  compareFn(c1: any, c2:any): boolean {     
    return c1 && c2 ? c1.id === c2.id : c1 === c2; 
  }

  data = ''
  getDataById(){
    this.manager.getManagerById({"_id":this.id}).subscribe(
      (res:any) => {
        //console.log(res.result)
        this.formGroup.patchValue({name: res.result.name});     
        this.formGroup.patchValue({email: res.result.email});    
        this.formGroup.patchValue({phone: res.result.phone});     
        this.formGroup.patchValue({address: res.result.address});     
        this.formGroup.patchValue({proof_type: res.result.proof_type});     
        this.formGroup.patchValue({proof_number: res.result.proof_number});  
        this.formGroup.patchValue({status: res.result.status});  
        this.f   
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        this.router.navigateByUrl('/admin/users/view')
      }
    )
  }

  public onsubmit(){
    // console.log(this.formGroup.value)
    this.manager.updateManager(this.formGroup.value).subscribe(
      (res:any) => {
        // console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/users/view')
      },
      err => {
        // console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }
}
