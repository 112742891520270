import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormBuilder, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { CityService } from 'src/app/shared/city/city.service';
import { ServiceService } from 'src/app/shared/service/service.service';
import { CuisineService } from 'src/app/shared/cuisine/cuisine.service';
import { TagService } from 'src/app/shared/tag/tag.service';
import { UserdataService } from 'src/app/shared/userdata.service';
import { TimingService } from 'src/app/shared/timing/timing.service';
import { RestcategoryService } from 'src/app/shared/restcategory/restcategory.service';
import { RestsubcategoryService } from 'src/app/shared/restsubcategory/restsubcategory.service';
import { CategoryService } from 'src/app/shared/category/category.service';
import { ProducttagService } from 'src/app/shared/producttag/producttag.service';
import { ProductService } from 'src/app/shared/product/product.service';


@Component({
  selector: 'app-editproduct',
  templateUrl: './editproduct.component.html',
  styleUrls: ['./editproduct.component.css']
})
export class EditproductComponent implements OnInit {

  formGroup = new FormGroup({
    _id: new FormControl('', [Validators.required]),
    product_name: new FormControl('', [Validators.required]),
    product_image: new FormControl(''),
    meta_keywords: new FormControl('', [Validators.required]),
    updated_Id: new FormControl('', [Validators.required]),
    product_description: new FormControl('', [Validators.required]),
    updated_by: new FormControl('', [Validators.required]),    
    restId: new FormControl('', [Validators.required]),    
  })

  
  id=''
  constructor(private toast: ToastrService, private restaurant: RestaurantService, private router: Router, private tag: ProducttagService, private userdata: UserdataService, private category: RestcategoryService, private subcategory: RestsubcategoryService, private globalCat: CategoryService,private fb:FormBuilder,private product: ProductService,private activate : ActivatedRoute) { }

  ngOnInit(): void {
    var idd = this.userdata.getId()
    var updated_by = this.userdata.getUserType()
    this.formGroup.patchValue({ updated_Id: idd });
    //console.log(idd)// Add by Guarv
    this.formGroup.patchValue({ updated_by: updated_by });
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getDataById()
  }

  getDataById(){
    let body = new URLSearchParams();
    body.set('_id', this.id);
    this.product.getProductById(body).subscribe(
      (res:any) => {
        this.formGroup.patchValue({_id: res.data._id}); 
        this.formGroup.patchValue({product_name: res.data.product_name}); 
        this.formGroup.patchValue({meta_keywords: res.data.meta_keywords}); 
        this.formGroup.patchValue({product_description: res.data.product_description});
        this.formGroup.patchValue({restId: res.data.restId}); 
        this.f   
      },
      err => {
        console.log(err) 
        this.toast.error(err.error.message,'Error')
        this.router.navigateByUrl('/admin/menu/view')
      }
    )
  }

  get f() {
    return this.formGroup.controls
  }

  compareFn(c1: any, c2:any): boolean {     
    return c1 && c2 ? c1.id === c2.id : c1 === c2; 
  }

  public onFile(evnt){
    if(evnt.target.files.length > 0)
    {
      this.formGroup.patchValue({
        product_image: evnt.target.files[0]
      })
    }
  }


  public onsubmit(){
    console.log(this.formGroup.get("updated_Id").value)
    console.log(this.formGroup.get("_id").value)
    const data = new FormData()
    data.append("product_name",this.formGroup.get("product_name").value)
    data.append("product_image",this.formGroup.get("product_image").value)
    data.append("meta_keywords",this.formGroup.get("meta_keywords").value)
    data.append("updated_Id",this.formGroup.get("updated_Id").value)
    data.append("product_description",this.formGroup.get("product_description").value)
    data.append("restId",this.formGroup.get("restId").value)
    data.append("_id",this.formGroup.get("_id").value)
    data.append("updated_by",this.formGroup.get("updated_by").value)
    this.product.updateProduct(data).subscribe(
      (res:any) => {
        // console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/menu/view')
      },
      err => {
        // console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
