<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="active breadcrumb-item">Restaurant Remarks</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Cuisine</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title float-left">View Remarks</h3>
                    <a class="btn btn-primary float-right" [routerLink]="['/admin/restaurant/add']">Add</a>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">
                
               <!-- Module Content -->
               <div class="dt-module__content ps-custom-scrollbar">

                <!-- Module Content Inner -->
                <div class="dt-module__content-inner">

                    <!-- Chat Conversation -->
                    <div class="dt-chat__conversation">
                        <div *ngFor="let u of row">
                        <!-- Chat Item -->
                        <div class="dt-chat__item" *ngIf="u.is_rest==true">                     

                            <!-- Messages -->
                            <div class="dt-chat__message-wrapper">

                                <!-- Message -->
                                <div class="dt-chat__message">
                                    {{u.remarks}}
                                    <p class="text-right">{{transformDate(u.remarks_date)}}</p>
                                </div>
                                
                                <!-- /message -->

                            </div>
                            <!-- /messages -->

                        </div>
                        <!-- /chat item -->

                        <!-- Chat Item -->
                        <div class="dt-chat__item reply" *ngIf="u.is_system==true">

                            <!-- Messages -->
                            <div class="dt-chat__message-wrapper">

                                <!-- Message -->
                                <div class="dt-chat__message">
                                    {{u.remarks}}
                                    <p class="text-right">{{transformDate(u.remarks_date)}}</p>
                                </div>
                                <!-- /message -->

                            </div> 
                            <!-- /messages -->

                        </div>
                        <!-- /chat item -->

                    </div>
                    <!-- /chat conversation -->
                </div>
                </div>
                <!-- /module content inner -->

            </div>
            <!-- /module content -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->