import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { RestcategoryService } from 'src/app/shared/restcategory/restcategory.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-editrestcategory',
  templateUrl: './editrestcategory.component.html',
  styleUrls: ['./editrestcategory.component.css']
})
export class EditrestcategoryComponent implements OnInit {

  statusList: any = ['Enabled', 'Disabled']
  restList=[]

  formGroup = new FormGroup({
    rest_cat_name: new FormControl('', [Validators.required]),
    userId: new FormControl('', [Validators.required]),
    added_by: new FormControl('', [Validators.required]),
    // status: new FormControl('', [Validators.required]),
    _id: new FormControl('', [Validators.required]),
  }) 

  id = ''

  constructor(private toast: ToastrService, private userdata: UserdataService,private category: RestcategoryService,private ngxService: NgxUiLoaderService, private router: Router, private activate: ActivatedRoute, private restaurant: RestaurantService) { }

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getDataById()
    //this.getRestaurant()
  }

  get f(){
    return this.formGroup.controls
  }

  compareFn(c1: any, c2:any): boolean {     
    return c1 && c2 ? c1.id === c2.id : c1 === c2; 
  }

  getDataById(){
    let body = new URLSearchParams();
    body.set('_id', this.id);
    this.category.getCategoryById(body).subscribe(
      (res:any) => {
        //console.log(res.result)
        this.formGroup.patchValue({rest_cat_name: res.data.rest_cat_name});    
        // this.formGroup.patchValue({status: res.data.status});     
        this.formGroup.patchValue({_id: res.data._id});     
        // this.formGroup.patchValue({restId: res.data.restId});     
        var id = this.userdata.getId()
        var added = this.userdata.getUserType()
        this.formGroup.patchValue({userId: id});
        this.formGroup.patchValue({added_by: added});
        this.f  
      },
      err => {
        console.log(err) 
        this.toast.error(err.error.message,'Error')
        this.router.navigateByUrl('/admin/restcategory/view')
      }
    )
  }

  // getRestaurant(){
  //   this.restaurant.allRestaurant().subscribe(
  //     (res:any) => {
  //       //console.log(res)
  //       this.restList = res.data
  //     },
  //     err => {
  //       //console.log(err) 
  //       this.toast.error(err.error.message,'Error')
  //       this.router.navigateByUrl('/admin/service/view')
  //     }
  //   )
  // }

  public onsubmit(){
    let data = new URLSearchParams();
    data.set("rest_cat_name",this.formGroup.get("rest_cat_name").value)
    data.set("updated_Id",this.formGroup.get("userId").value)
    data.set("_id",this.formGroup.get("_id").value)
    data.set("updated_by",this.formGroup.get("added_by").value)
    this.category.updateCategory(data).subscribe(
      (res:any) => {
        // console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/restcategory/view')
      },
      err => {
        // console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }
}
