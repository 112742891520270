<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="active breadcrumb-item">Orders</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Tag</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12 p-0">

        <!-- Card -->
        <div class="dt-card dt-card__full-height p-0 box-shadow-0">
            <!-- Card Body -->
            <div class="dt-card__body p-0">
                <nav ngbNav #nav="ngbNav" class="nav-tabs">
                    <ng-container ngbNavItem>
                        <a ngbNavLink>Pending Orders</a>
                        <ng-template ngbNavContent>
                            <app-pending-myorders></app-pending-myorders>
                        </ng-template>
                    </ng-container>
                    <ng-container ngbNavItem>
                        <a ngbNavLink>Accepted Orders</a>
                        <ng-template ngbNavContent>
                            <app-preparing-myorders></app-preparing-myorders>
                        </ng-template>
                    </ng-container>
                    <ng-container ngbNavItem>
                        <a ngbNavLink>Ready Orders</a>
                        <ng-template ngbNavContent>
                            <app-prepared-myorders></app-prepared-myorders>
                        </ng-template>
                    </ng-container>
                    <ng-container ngbNavItem>
                        <a ngbNavLink>Order History</a>
                        <ng-template ngbNavContent>
                            <!-- Grid Item -->
                            <div class="col-xl-12">
                                <!-- Card -->
                                <div class="dt-card dt-card__full-height">
                                    <!-- Card Header -->
                                    <div class="dt-card__header">

                                        <!-- Card Heading -->
                                        <div class="dt-card__heading">
                                            <h3 class="dt-card__title float-left">Search:</h3>
                                            <!-- <a class="btn btn-primary float-right" [routerLink]="['/admin/driver/add']">Add</a><br/> -->
                                        </div>
                                        <!-- /card heading -->
                                    </div>
                                    <!-- /card header -->
                                    <!-- Card Body -->
                                    <div class="dt-card__body">

                                        <div class="row mb-1" [formGroup]="myGroup">
                                            <div class="col-5">
                                                Select Start Date:
                                                <input type="date" name="startdate" id="startdate"
                                                    formControlName="startdate" class="form-control">
                                            </div>
                                            <div class="col-5">
                                                Select End Date:
                                                <input type="date" name="enddate" id="enddate" formControlName="enddate"
                                                    class="form-control">
                                            </div>
                                            <div class="col-2">
                                                <br>
                                                <button class="btn btn-primary btn-block"
                                                    (click)="searchByDate()">Search</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Grid Item -->
                            <div class="col-xl-12">

                                <!-- Card -->
                                <div class="dt-card dt-card__full-height">
                                    <!-- Card Body -->
                                    <div class="dt-card__body p-0">

                                        <!-- Tables -->
                                        <input type="text" class="form-control search-input"
                                            placeholder="Type to filter the name column..."
                                            (keyup)="updateFilter($event)" />
                                        <ngx-datatable #table class="material" [columns]="columns"
                                            [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50"
                                            rowHeight="auto" [limit]="10" [rows]="rows" #myFilterTable>
                                            <ngx-datatable-column name="Order Id" prop="orderId">
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    #{{row.orderId}}
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Payment Type" prop="payment_type">
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    {{row.payment_type}}
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Order Type" prop="order_takeaway">
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    <span *ngIf="row.order_takeaway">Take Away</span>
                                                    <span *ngIf="!row.order_takeaway">Delivery</span>
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Total" prop="total_paid">
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    {{row.total_paid |currency:'CAD':'symbol-narrow'}}
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Driver" prop="driverId.driver_name">
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    <span *ngIf="row.driverId">
                                                        {{row.driverId.driver_name}}
                                                    </span>
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Status">
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    <span class="badge badge-pill badge-primary"
                                                        *ngIf="row.order_current_status==101">
                                                        Order Placed
                                                    </span>
                                                    <span class="badge badge-pill badge-danger"
                                                        *ngIf="row.order_current_status==102">
                                                        Payment Declined
                                                    </span>
                                                    <span class="badge badge-pill badge-warning"
                                                        *ngIf="row.order_current_status==103">
                                                        Order Preparing
                                                    </span>
                                                    <span class="badge badge-pill badge-danger"
                                                        *ngIf="row.order_current_status==104">
                                                        Order Rejected
                                                    </span>
                                                    <span class="badge badge-pill badge-warning"
                                                        *ngIf="row.order_current_status==105">
                                                        Order Prepared
                                                    </span>
                                                    <span class="badge badge-pill badge-info"
                                                        *ngIf="row.order_current_status==106">
                                                        Pickup
                                                    </span>
                                                    <span class="badge badge-pill badge-info"
                                                        *ngIf="row.order_current_status==107">
                                                        On Way
                                                    </span>
                                                    <span class="badge badge-pill badge-success"
                                                        *ngIf="row.order_current_status==108">
                                                        Delivered
                                                    </span>
                                                    <span class="badge badge-pill badge-danger"
                                                        *ngIf="row.order_current_status==109">
                                                        Canceled
                                                    </span>
                                                    <span class="badge badge-pill badge-danger"
                                                        *ngIf="row.order_current_status==110">
                                                        TimeOut
                                                    </span>
                                                    <span class="badge badge-pill badge-danger"
                                                        *ngIf="row.order_current_status==111">
                                                        Refunded
                                                    </span>
                                                    <span class="badge badge-pill badge-danger"
                                                        *ngIf="row.order_current_status==112">
                                                        Cancel & Refund
                                                    </span>
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Date" prop="created_at">
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    {{transformDate(row.created_at)}}
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <!-- <ngx-datatable-column name="Action">
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    <span *ngIf="row.order_current_status == 101"><a
                                                            class="btn btn-sm btn-outline-success mb-1"
                                                            (click)="acceptOrder(row._id)"
                                                            title="Accept Order">ACCEPT</a><a
                                                            class="btn btn-sm btn-outline-primary"
                                                            [routerLink]="['/restaurant/orders/reject/', row._id]"
                                                            title="Reject Order">REJECT</a></span>
                                                    <span
                                                        *ngIf="(row.order_current_status == 103 || row.order_current_status == 105) && row.driverId == null"><a
                                                            class="btn btn-sm btn-outline-success mb-1"
                                                            [routerLink]="['/restaurant/orders/assign/',row._id]"
                                                            title="Assign Order">ASSIGN</a></span>
                                                    <span *ngIf="row.order_current_status == 103"><a
                                                            class="btn btn-sm btn-outline-info mb-1"
                                                            (click)="readyOrder(row._id)"
                                                            title="Order Ready">READY</a></span>
                                                </ng-template>
                                            </ngx-datatable-column> -->
                                            <ngx-datatable-column name="Details">
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    <a [routerLink]="['/restaurant/orders/', row._id]"
                                                        title="View Order"><i
                                                            class="icon icon-eye icon-fw icon-lg icon-view"></i></a>
                                                </ng-template>
                                            </ngx-datatable-column>
                                        </ngx-datatable>
                                        <!-- /tables -->

                                    </div>
                                    <!-- /card body -->

                                </div>
                                <!-- /card -->

                            </div>
                            <!-- /grid item -->
                        </ng-template>
                    </ng-container>
                </nav>
                <div [ngbNavOutlet]="nav" class="mt-2"></div>


            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->