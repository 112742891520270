import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from 'src/app/shared/category/category.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserdataService } from 'src/app/shared/userdata.service';  

@Component({
  selector: 'app-editcategory',
  templateUrl: './editcategory.component.html',
  styleUrls: ['./editcategory.component.css']
})

export class EditcategoryComponent implements OnInit {

  constructor(private activate: ActivatedRoute, private category: CategoryService,private toast: ToastrService, private router:Router, private userdata: UserdataService) { }

  statusList: any = ['Enabled', 'Disabled']

  formGroup = new FormGroup({
    category_name: new FormControl('', [Validators.required]),
    userId: new FormControl('', [Validators.required]),
    added_by: new FormControl('', [Validators.required]),
    meta_desc: new FormControl('', [Validators.required]),
    meta_keywords: new FormControl('', [Validators.required]),
    category_image: new FormControl(''),
    _id: new FormControl('', [Validators.required]),
  })

  id = ''
  imageError:string
  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getDataById()
  }

  get f(){
    return this.formGroup.controls
  }

  public onFile(evnt){
    this.imageError = null
    if(evnt.target.files.length > 0)
    {
      const max_size = 2097152
      //const max_size = 1000;
      if (evnt.target.files[0].size > max_size) {
        this.imageError = 'Maximum size allowed is ' + max_size / 1024/1024 + 'MB';
        this.f.category_image.errors.required ='error'
        return false
      }
      else{
        this.formGroup.patchValue({
          category_image: evnt.target.files[0]
        })
      }      
    }
  }

  compareFn(c1: any, c2:any): boolean {     
    return c1 && c2 ? c1.id === c2.id : c1 === c2; 
  }

  getDataById(){
    let body = new URLSearchParams();
    body.set('_id', this.id);
    this.category.getCategoryById(body).subscribe(
      (res:any) => {
        //console.log(res.result)
        this.formGroup.patchValue({category_name: res.data.category_name});     
        this.formGroup.patchValue({meta_desc: res.data.meta_desc});    
        this.formGroup.patchValue({meta_keywords: res.data.meta_keywords});     
        this.formGroup.patchValue({status: res.data.status});     
        this.formGroup.patchValue({_id: res.data._id});     
        // this.formGroup.patchValue({category_image: res.category_image});     
        var id = this.userdata.getId()
        var added = this.userdata.getUserType()
        this.formGroup.patchValue({userId: id});
        this.formGroup.patchValue({added_by: added});
        this.f   
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        this.router.navigateByUrl('/admin/category/view')
      }
    )
  }

  public onsubmit(){
    const data = new FormData()
    data.append("category_name",this.formGroup.get("category_name").value)
    data.append("userId",this.formGroup.get("userId").value)
    data.append("added_by",this.formGroup.get("added_by").value)
    data.append("category_image",this.formGroup.get("category_image").value)
    data.append("meta_desc",this.formGroup.get("meta_desc").value)
    data.append("meta_keywords",this.formGroup.get("meta_keywords").value)
    data.append("_id",this.formGroup.get("_id").value)
    //console.log(data)
    this.category.updateCategory(data).subscribe(
      (res:any) => {
        // console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/category/view')
      },
      err => {
        // console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
