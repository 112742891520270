import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrdersService } from 'src/app/shared/orders/orders.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-online-refund-order',
  templateUrl: './online-refund-order.component.html',
  styleUrls: ['./online-refund-order.component.css']
})
export class OnlineRefundOrderComponent implements OnInit {

  formGroup = new FormGroup({
    orderId: new FormControl('', [Validators.required]),
    new_status: new FormControl(112, [Validators.required]),
    comment: new FormControl('', [Validators.required]),
    userId: new FormControl('', [Validators.required]),
  }) 

  id =''
  orderId:any = ''
  constructor(private toast: ToastrService, private userdata: UserdataService,private order: OrdersService,private router: Router,private activate: ActivatedRoute) { }

  ngOnInit(): void {
    this.formGroup.patchValue({userId: this.userdata.getId()});
    this.id = this.activate.snapshot.paramMap.get('id')
    this.formGroup.patchValue({orderId: this.id});
    this.getSingleOrder()
  }

  getSingleOrder(){
    this.order.getOrderById({'_id':this.id}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.orderId = res.data.orderId
      },
      err => {
        // console.log(err)
        // this.toast.error(err.error.message,'Error')
      }
    )
  }

  get f(){
    return this.formGroup.controls
  }

  public onsubmit(){
    this.order.changeOrderStatus(this.formGroup.value).subscribe(
      (res:any) => {
        this.toast.success(res.message,'Success')
        //this.formGroup.reset()
        this.router.navigateByUrl('/admin/orders/all');
      },
      err => {
        console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
