import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { UserdataService } from '../shared/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private userdata: UserdataService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.userdata.getToken() == null) {
      this.router.navigateByUrl('/admin/login')
      return false
    }

    if (!route.data || !route.data['permission']) {
      return true;
    }
    const roles = route.data['permission'] as Array<string>
    if (roles) {
      const match = this.userdata.roleMatch(roles)
      if (match) {
        return true;
      } else {
        this.router.navigate([this.selectBestRoute()])
        return false;
      }
    }
    this.router.navigate([this.selectBestRoute()])
    return false;

  }
  selectBestRoute(): string {
    if (this.userdata.getToken() == null) {
      return '/admin/login';
    }
    return 'admin/home'
  }

}
