import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserdataService } from 'src/app/shared/userdata.service'; 
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { RolesService } from 'src/app/shared/roles/roles.service';

@Component({
  selector: 'app-editrestaurant',
  templateUrl: './editrestaurant.component.html',
  styleUrls: ['./editrestaurant.component.css']
})
export class EditrestaurantComponent implements OnInit {

  constructor(private activate: ActivatedRoute, private restaurant: RestaurantService,private toast: ToastrService, private router:Router, private userdata: UserdataService, private roles:RolesService) { }

  statusList: any = ['Approved', 'Rejected']

  formGroup = new FormGroup({
    restId: new FormControl('', [Validators.required]),
    staffId: new FormControl('', [Validators.required]),
    status: new FormControl('',[Validators.required]),
    roleId: new FormControl('',[Validators.required]),
    remarks: new FormControl(''),
  })

  id = ''

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getDataById()
    this.getRoles()
  }

  get f(){
    return this.formGroup.controls
  }

  compareFn(c1: any, c2:any): boolean {     
    return c1 && c2 ? c1.id === c2.id : c1 === c2; 
  }

  getDataById(){
    this.restaurant.getRestaurantById({'_id':this.id}).subscribe(
      (res:any) => {
        //console.log(res.result)  
        this.formGroup.patchValue({current_status: res.data.current_status});     
        this.formGroup.patchValue({restId: res.data._id});     
        // this.formGroup.patchValue({category_image: res.category_image});     
        var id = this.userdata.getId()
        this.formGroup.patchValue({staffId: id});
        this.f   
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        this.router.navigateByUrl('/admin/restaurant/approve')
      }
    )
  }

  public onsubmit(){
    let body = new URLSearchParams();
    body.set('status', this.formGroup.get("status").value);
    body.set('restId', this.formGroup.get("restId").value);
    body.set('staffId', this.formGroup.get("staffId").value);
    body.set('remarks', this.formGroup.get("remarks").value);
    if(this.formGroup.get("status").value == "Approved"){
      body.set('roleId', this.formGroup.get("roleId").value);
    }
    this.restaurant.approveReject(body).subscribe(
      (res:any) => {
        // console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/restaurant/approve')
      },
      err => {
        // console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  //-------Roles
  roleList = []

  getRoles(){
    this.roles.listrole({}).subscribe(
      (res:any) => {
        this.roleList = res.data
      },
      err => {
         console.log(err)
        //this.toast.error(err.error.message,'Error')
      }
    )
  }

}
