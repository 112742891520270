import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata.service';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

  BaseUrl = ''
  Token = ''

  constructor(private http: HttpClient, @Inject('BASE_URL') _base,private userdata: UserdataService) {
    this.BaseUrl = _base
    this.Token = userdata.getToken()
  }

  public addDelivery(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/adddcharges",form, { headers: header_object})
  } 

  public listDelivery(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/alldcharges",form,{ headers: header_object})
  } 

  public getDeliveryById(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token).set('Content-Type', 'application/x-www-form-urlencoded')
    return this.http.post(this.BaseUrl + "/api/fetchdchargesbyid",form.toString(), { headers: header_object})
  }

  public updateDelivery(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/updatedcharges", form, { headers: header_object})
  }

}
