<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/admin/support/driverIssue/list']">Driver Issue</a></li>
        <li class="active breadcrumb-item">Issue Reply</li>
    </ol>
    <!-- <h1 class="dt-page__title">Edit Manager</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title">Issue Reply</h3>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Form -->
                <form [formGroup]="formGroup" (submit)="onsubmit()">

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="issue_title">Issue Title</label>

                        <div class="col-xl-9">
                            <input type="text" class="form-control" id="issue_title" placeholder="Issue Title" name="issue_title" formControlName="issue_title" [readonly]="true">
                            <small class="form-text" *ngIf="f.issue_title.invalid && (f.issue_title.dirty || f.issue_title.touched)">
                                <small *ngIf="f.issue_title.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div> 
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="reply_by_system">Reply</label>

                        <div class="col-xl-9">
                            <textarea class="form-control" id="reply_by_system" placeholder="Enter Reply" name="reply_by_system" formControlName="reply_by_system"></textarea>
                            <small class="form-text" *ngIf="f.reply_by_system.invalid && (f.reply_by_system.dirty || f.reply_by_system.touched)">
                                <small *ngIf="f.reply_by_system.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>
                    <!-- /form group --> 

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="reply_by_system">Is Solved?</label>

                        <div class="col-xl-9">
                            <br>
                            <!-- Radio Button -->
                                <input type="radio" formControlName="is_solved" [(ngModel)]="isSolved" id="true" value="true">
                                <label for="true">Yes</label>&nbsp;
                            <!-- /radio button -->
                            <!-- Radio Button -->
                                <input type="radio" formControlName="is_solved" [(ngModel)]="isSolved" id="false" value="false">
                                <label for="false">No</label>
                            <!-- /radio button -->
                            <small class="form-text" *ngIf="f.is_solved.invalid && (f.is_solved.dirty || f.is_solved.touched)">
                                <small *ngIf="f.is_solved.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>
                    <!-- /form group --> 

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <div class="col-xl-9 offset-xl-3">
                            <button type="submit" class="btn btn-primary text-uppercase" [disabled]="!formGroup.valid">Save
                            </button>
                        </div>
                    </div>
                    <!-- /form group -->

                </form>
                <!-- /form -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->