<!-- Page Header -->
<div class="dt-page__header">
	<ol class="breadcrumb">
		<li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
		<li class="breadcrumb-item"><a [routerLink]="['/restaurant/driver/view']">Driver</a></li>
		<li class="active breadcrumb-item">Add</li>
	</ol>
	<h1 class="dt-page__title">Add Driver</h1>
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
	<!-- Grid Item -->
	<div class="col-xl-12">
		<!-- Form -->
		<form [formGroup]="formGroup" (submit)="onsubmit()">
			<!-- Card -->
			<div class="dt-card dt-card__full-height">
				<!-- Card Header -->
				<div class="dt-card__header">
					<!-- Card Heading -->
					<div class="dt-card__heading">
						<h3 class="dt-card__title">Basic info</h3>
					</div>
					<!-- /card heading -->
				</div>
				<!-- /card header -->
				<!-- Card Body -->
				<div class="dt-card__body">
					<div class="form-row">
						<div class="col-sm-6 mb-3">
							<label for="driver_name">Driver Name</label>
							<input type="text" class="form-control" id="driver_name" placeholder="Enter Driver Name"
								name="driver_name" formControlName="driver_name"> <small class="form-text"
								*ngIf="f.driver_name.invalid && (f.driver_name.dirty || f.driver_name.touched)">
								<small *ngIf="f.driver_name.errors.required">Invalid Value</small> </small>
						</div>
						<div class="col-sm-6 mb-3">
							<label for="driver_phone">Driver Phone</label>
							<input type="text" class="form-control" id="driver_phone" placeholder="Enter phone"
								name="driver_phone" formControlName="driver_phone"> <small class="form-text"
								*ngIf="f.driver_phone.invalid && (f.driver_phone.dirty || f.driver_phone.touched)">
								<small *ngIf="f.driver_phone.errors.required">Invalid Value</small>
								<small *ngIf="f.driver_phone.errors.pattern">Invalid Phone Number</small></small>
								 
                        </div>
                    </div>
                    <div class="form-row">
                        <!-- <div class="col-sm-4 mb-3">
							<label for="payment_amount">Payment Per Order (%)</label>
							<input type="text" class="form-control" id="payment_amount" placeholder="Enter Payment Per Order"
								name="payment_amount" formControlName="payment_amount"> <small class="form-text"
								*ngIf="f.payment_amount.invalid && (f.payment_amount.dirty || f.payment_amount.touched)">
								<small *ngIf="f.payment_amount.errors.required">Invalid Value</small> </small>
                        </div> -->
                        <div class="col-sm-6 mb-3">
                            <label for="visa_type">Visa Type</label>
                            <select name="visa_type" id="visa_type" formControlName="visa_type" class="form-control">
                                <option value="" [selected]="true" [disabled]="true">Select Visa Type</option>
                                <option *ngFor="let visa of visaList">{{visa}}</option>
                            </select>
							<small class="form-text"
								*ngIf="f.visa_type.invalid && (f.visa_type.dirty || f.visa_type.touched)">
								<small *ngIf="f.visa_type.errors.required">Invalid Value</small> </small>
                        </div>
                        <div class="col-sm-6 mb-3">
                            <label for="sin_number">SIN</label>
                            <input type="text" class="form-control" id="sin_number" placeholder="Enter Sin Number"
								name="sin_number" formControlName="sin_number">
							<small class="form-text"
								*ngIf="f.sin_number.invalid && (f.sin_number.dirty || f.sin_number.touched)">
								<small *ngIf="f.sin_number.errors">Invalid SIN.</small> </small>
						</div>
					</div>
					<div class="form-row">
						<div class="col-sm-6 mb-3">
							<label for="driver_address">Address</label>
							<textarea class="form-control" id="driver_address" placeholder="Enter Address" name="driver_address" formControlName="driver_address"></textarea>
                            <small class="form-text"
								*ngIf="f.driver_address.invalid && (f.driver_address.dirty || f.driver_address.touched)">
								<small *ngIf="f.driver_address.errors.required">Invalid Value</small> </small>
						</div>
						<div class="col-sm-6 mb-3">
							<label for="other_detail">Other Details</label>
							<textarea class="form-control" id="other_detail" placeholder="Enter Other Details" name="other_detail" formControlName="other_detail"></textarea>
                            <small class="form-text" *ngIf="f.other_detail.invalid && (f.other_detail.dirty || f.other_detail.touched)">
                                <small *ngIf="f.other_detail.errors.required">Invalid Value</small> 
                            </small>
						</div>
					</div>
					<div class="form-row">
						<div class="col-sm-6 mb-3">
                            <label for="province">Province</label>
                            <input type="text" class="form-control" id="province" placeholder="Enter Province"
								name="province" formControlName="province">
							<small class="form-text"
								*ngIf="f.province.invalid && (f.province.dirty || f.province.touched)">
								<small *ngIf="f.province.errors.required">Invalid Value</small> </small>
						</div>
                        <div class="col-sm-6 mb-3">
							<label for="zip_code">Postal Code</label>
							<input type="text" class="form-control" id="zip_code" placeholder="Enter Postal Code Eg. X1X1X1" name="zip_code" formControlName="zip_code"> 
                                <small class="form-text" *ngIf="f.zip_code.invalid && (f.zip_code.dirty || f.zip_code.touched)">
                                    <small *ngIf="f.zip_code.errors">Invalid Postal Code</small> 
                                </small>
                        </div>
					</div>
				</div>
				<!-- /card body -->
			</div>
			<!-- /card -->
			<!-- Card -->
			<div class="dt-card dt-card__full-height">
				<!-- Card Header -->
				<div class="dt-card__header">
					<!-- Card Heading -->
					<div class="dt-card__heading">
						<h3 class="dt-card__title">Login Credentials</h3>
					</div>
					<!-- /card heading -->
				</div>
				<!-- /card header -->
				<!-- Card Body -->
				<div class="dt-card__body">
					<div class="form-row">
						<div class="col-sm-6 mb-3">
							<label for="driver_email">Email</label>
							<input type="text" class="form-control" id="driver_email" placeholder="Enter Email"
								name="driver_email" formControlName="driver_email"> <small class="form-text"
								*ngIf="f.driver_email.invalid && (f.driver_email.dirty || f.driver_email.touched)">
								<small *ngIf="f.driver_email.errors">Invalid Email</small> </small>
						</div>
						<div class="col-sm-6 mb-3">
							<label for="driver_password">Password</label>
							<input type="password" class="form-control" id="driver_password"
								placeholder="Enter Password" name="driver_password" formControlName="driver_password">
							<small class="form-text"
								*ngIf="f.driver_password.invalid && (f.driver_password.dirty || f.driver_password.touched)">
								<small *ngIf="f.driver_password.errors.required">Invalid Value</small> </small>
						</div>
					</div>
				</div>
				<!-- /card body -->
			</div>
			<!-- /card -->
			<!-- Card -->
			<div class="dt-card dt-card__full-height">
				<!-- Card Header -->
				<div class="dt-card__header">
					<!-- Card Heading -->
					<div class="dt-card__heading">
						<h3 class="dt-card__title">Vehicle Information</h3>
					</div>
					<!-- /card heading -->
				</div>
				<!-- /card header -->
				<!-- Card Body -->
				<div class="dt-card__body">
					<div class="form-row">
						<div class="col-sm-3 mb-3">
							<label for="vehicle_type">Vehicle Type</label>
							<!-- <input type="text" class="form-control" id="vehicle_type" placeholder="Enter Vehicle Type"
								name="vehicle_type" formControlName="vehicle_type">  -->
							<select formControlName="vehicle_type" class="form-control">
								<option [selected]="true">2 wheeler</option>
								<option>4 wheeler</option>
							</select>
								<small class="form-text"
								*ngIf="f.vehicle_type.invalid && (f.vehicle_type.dirty || f.vehicle_type.touched)">
								<small *ngIf="f.vehicle_type.errors.required">Invalid Value</small> </small>
						</div>
						<div class="col-sm-3 mb-3">
							<label for="vehicle_number">Vehicle Number</label>
							<input type="text" class="form-control" id="vehicle_number" placeholder="Enter Vehicle Number"
								name="vehicle_number" formControlName="vehicle_number"> <small class="form-text"
								*ngIf="f.vehicle_number.invalid && (f.vehicle_number.dirty || f.vehicle_number.touched)">
								<small *ngIf="f.vehicle_number.errors.required">Invalid Value</small> </small>
                        </div>
                        <div class="col-sm-3 mb-3">
							<label for="driving_lic_number">Driving License Number</label>
							<input type="text" class="form-control" id="driving_lic_number" placeholder="Enter Driving License Number"
								name="driving_lic_number" formControlName="driving_lic_number"> <small class="form-text"
								*ngIf="f.driving_lic_number.invalid && (f.driving_lic_number.dirty || f.driving_lic_number.touched)">
								<small *ngIf="f.driving_lic_number.errors.required">Invalid Value</small> </small>
						</div>
						<div class="col-sm-3 mb-3">
							<label for="dlexpire">Driving License Expire</label>
							<input type="date" class="form-control" id="dlexpire" placeholder="Enter Driving License Expire"
								name="dlexpire" formControlName="dlexpire"> <small class="form-text"
								*ngIf="f.dlexpire.invalid && (f.dlexpire.dirty || f.dlexpire.touched)">
								<small *ngIf="f.dlexpire.errors.required">Invalid Value</small> </small>
						</div>
					</div>
				</div>
				<!-- /card body -->
			</div>
			<!-- /card -->
			<!-- Card -->
			<div class="dt-card dt-card__full-height">
				<!-- Card Header -->
				<div class="dt-card__header">
					<!-- Card Heading -->
					<div class="dt-card__heading">
						<h3 class="dt-card__title">Documents</h3>
					</div>
					<!-- /card heading -->
				</div>
				<!-- /card header -->
				<!-- Card Body -->
				<div class="dt-card__body">
					<div class="form-row">
						<div class="col-sm-4 mb-3">
							<label for="driver_idproff_pic">ID Proof</label><br />
							<input type="file" accept="image/*" class="form-control" id="driver_idproff_pic" name="driver_idproff_pic" (change)="proofFile($event)"
								formControlName="driver_idproff_pic">
							<small class="form-text"
								*ngIf="f.driver_idproff_pic.invalid && (f.driver_idproff_pic.dirty || f.driver_idproff_pic.touched)"><small
									*ngIf="f.driver_idproff_pic.errors.required">Invalid Value</small> </small>
						</div>
						<div class="col-sm-4 mb-3">
							<label for="dl_reg_pic">Front of Driving License</label>
							<input type="file" accept="image/*" class="form-control" id="dl_reg_pic" name="dl_reg_pic" (change)="taxFile($event)" formControlName="dl_reg_pic">
							<small class="form-text"
								*ngIf="f.dl_reg_pic.invalid && (f.dl_reg_pic.dirty || f.dl_reg_pic.touched)">
								<small *ngIf="f.dl_reg_pic.errors.required">Invalid Value</small>
							</small>
						</div>
						<div class="col-sm-4 mb-3">
							<label for="dl_reg_pic">Back of Driving License</label>
							<input type="file" accept="image/*" class="form-control" id="dl_back_pic" name="dl_back_pic" (change)="licenseBackFile($event)" formControlName="dl_back_pic">
							<small class="form-text"
								*ngIf="f.dl_back_pic.invalid && (f.dl_back_pic.dirty || f.dl_back_pic.touched)">
								<small *ngIf="f.dl_back_pic.errors.required">Invalid Value</small>
							</small>
						</div>
					</div>
					<div class="form-row">
						<div class="col-sm-4 mb-3">
							<label for="vehicle_reg_pic">Vehicle Registration</label>
							<input type="file" accept="image/*" class="form-control" id="vehicle_reg_pic" name="vehicle_reg_pic" (change)="vehicleFile($event)" formControlName="vehicle_reg_pic">
							<small class="form-text"
								*ngIf="f.vehicle_reg_pic.invalid && (f.vehicle_reg_pic.dirty || f.vehicle_reg_pic.touched)">
								<small *ngIf="f.vehicle_reg_pic.errors.required">Invalid Value</small>
							</small>
						</div>
						<div class="col-sm-4 mb-3">
							<label for="vehicle_reg_pic">Vehicle Insurance</label>
							<input type="file" accept="image/*" class="form-control" id="vehicle_insurance_pic" name="vehicle_insurance_pic" (change)="vehicleInsuranceFile($event)" formControlName="vehicle_insurance_pic">
							<small class="form-text"
								*ngIf="f.vehicle_insurance_pic.invalid && (f.vehicle_insurance_pic.dirty || f.vehicle_insurance_pic.touched)">
								<small *ngIf="f.vehicle_insurance_pic.errors.required">Invalid Value</small>
							</small>
						</div>
						<div class="col-sm-4 mb-3">
							<label for="other_id_pic">Additional Picture (If any)</label>
							<input type="file" accept="image/*" class="form-control" id="other_id_pic" name="other_id_pic" (change)="otherFile($event)" formControlName="other_id_pic">
							<small class="form-text"
								*ngIf="f.other_id_pic.invalid && (f.other_id_pic.dirty || f.other_id_pic.touched)">
								<small *ngIf="f.other_id_pic.errors.required">Invalid Value</small>
							</small>
						</div>
					</div>
					<div class="form-row">
						<div class="col-xl-8 offset-xl-4">
							<button type="submit" class="btn btn-primary text-uppercase"
								[disabled]="!formGroup.valid">Save
							</button>
						</div>
					</div>
				</div>
				<!-- /card body -->
			</div>
			<!-- /card -->
		</form>
		<!--/form-->
	</div>
	<!-- /grid item -->
</div>
<!-- /grid -->