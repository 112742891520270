import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddadvertisementComponent } from './adds/addadvertisement/addadvertisement.component';
import { AddsComponent } from './adds/adds.component';
import { EditadvertisementComponent } from './adds/editadvertisement/editadvertisement.component';
import { ListadvertisementComponent } from './adds/listadvertisement/listadvertisement.component';
import { AuthGuard } from './auth/auth.guard';
import { AddcategoryComponent } from './category/addcategory/addcategory.component';
import { CategoryComponent } from './category/category.component';
import { EditcategoryComponent } from './category/editcategory/editcategory.component';
import { ListcategoryComponent } from './category/listcategory/listcategory.component';
import { AddcityComponent } from './city/addcity/addcity.component';
import { CityComponent } from './city/city.component';
import { EditcityComponent } from './city/editcity/editcity.component';
import { ListcityComponent } from './city/listcity/listcity.component';
import { AddcuisineComponent } from './cuisine/addcuisine/addcuisine.component';
import { CuisineComponent } from './cuisine/cuisine.component';
import { EditcuisineComponent } from './cuisine/editcuisine/editcuisine.component';
import { ListcuisineComponent } from './cuisine/listcuisine/listcuisine.component';
import { AddsliderComponent } from './customize/addslider/addslider.component';
import { AddtopproductComponent } from './customize/addtopproduct/addtopproduct.component';
import { AddtoprestaurantComponent } from './customize/addtoprestaurant/addtoprestaurant.component';
import { CustomizeComponent } from './customize/customize.component';
import { ListsliderComponent } from './customize/listslider/listslider.component';
import { ListtopproductComponent } from './customize/listtopproduct/listtopproduct.component';
import { ListtoprestaurantComponent } from './customize/listtoprestaurant/listtoprestaurant.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdddeliveryComponent } from './delivery/adddelivery/adddelivery.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { EditdeliveryComponent } from './delivery/editdelivery/editdelivery.component';
import { ListdeliveryComponent } from './delivery/listdelivery/listdelivery.component';
import { AdddriverComponent } from './driver/adddriver/adddriver.component';
import { ApprovebankComponent } from './driver/approvebank/approvebank.component';
import { CollectcashComponent } from './driver/collectcash/collectcash.component';
import { DriverComponent } from './driver/driver.component';
import { EditdriverComponent } from './driver/editdriver/editdriver.component';
import { ListcashhistoryComponent } from './driver/listcashhistory/listcashhistory.component';
import { ListcashinhandComponent } from './driver/listcashinhand/listcashinhand.component';
import { ListdriverComponent } from './driver/listdriver/listdriver.component';
import { ListdriveragreementComponent } from './driver/listdriveragreement/listdriveragreement.component';
import { ListdriverapprovedComponent } from './driver/listdriverapproved/listdriverapproved.component';
import { ListtransactionsComponent } from './driver/listtransactions/listtransactions.component';
import { OrderdriverComponent } from './driver/orderdriver/orderdriver.component';
import { PaydriverComponent } from './driver/paydriver/paydriver.component';
import { SenddriveragreementComponent } from './driver/senddriveragreement/senddriveragreement.component';
import { SenddriverapproveComponent } from './driver/senddriverapprove/senddriverapprove.component';
import { SingledriverComponent } from './driver/singledriver/singledriver.component';
import { LayoutComponent } from './layout/layout.component';
import { AddoffersComponent } from './offers/addoffers/addoffers.component';
import { EditoffersComponent } from './offers/editoffers/editoffers.component';
import { ListoffersComponent } from './offers/listoffers/listoffers.component';
import { OffersComponent } from './offers/offers.component';
import { AllordersComponent } from './orders/allorders/allorders.component';
import { AssignorderComponent } from './orders/assignorder/assignorder.component';
import { OrdersComponent } from './orders/orders.component';
import { SingleorderComponent } from './orders/singleorder/singleorder.component';
import { AddonsComponent } from './product/addons/addons.component';
import { AddproductComponent } from './product/addproduct/addproduct.component';
import { EditproductComponent } from './product/editproduct/editproduct.component';
import { ListproductComponent } from './product/listproduct/listproduct.component';
import { ProductComponent } from './product/product.component';
import { SingleproductComponent } from './product/singleproduct/singleproduct.component';
import { AddproducttagComponent } from './producttag/addproducttag/addproducttag.component';
import { EditproducttagComponent } from './producttag/editproducttag/editproducttag.component';
import { ListproducttagComponent } from './producttag/listproducttag/listproducttag.component';
import { ProducttagComponent } from './producttag/producttag.component';
import { DriverreportComponent } from './report/driverreport/driverreport.component';
import { ReportComponent } from './report/report.component';
import { RestreportComponent } from './report/restreport/restreport.component';
import { AddrestaurantComponent } from './restaurant/addrestaurant/addrestaurant.component';
import { ApproverestaurantComponent } from './restaurant/approverestaurant/approverestaurant.component';
import { EditrestaurantComponent } from './restaurant/editrestaurant/editrestaurant.component';
import { ListagreementComponent } from './restaurant/listagreement/listagreement.component';
import { ListremarksComponent } from './restaurant/listremarks/listremarks.component';
import { ListrestaurantComponent } from './restaurant/listrestaurant/listrestaurant.component';
import { OpenregisterComponent } from './restaurant/openregister/openregister.component';
import { PayrestaurantComponent } from './restaurant/payrestaurant/payrestaurant.component';
import { RestaurantComponent } from './restaurant/restaurant.component';
import { SendagreementComponent } from './restaurant/sendagreement/sendagreement.component';
import { SinglerestaurantComponent } from './restaurant/singlerestaurant/singlerestaurant.component';
import { UpdateapplicationComponent } from './restaurant/updateapplication/updateapplication.component';
import { WalletrestaurantComponent } from './restaurant/walletrestaurant/walletrestaurant.component';
import { AddMydriverComponent } from './restaurantpanel/driver/add-mydriver/add-mydriver.component';
import { ListMydriverComponent } from './restaurantpanel/driver/list-mydriver/list-mydriver.component';
import { SingleMydriverComponent } from './restaurantpanel/driver/single-mydriver/single-mydriver.component';
import { AddAddonsComponent } from './restaurantpanel/menu/add-addons/add-addons.component';
import { AddMymenuComponent } from './restaurantpanel/menu/add-mymenu/add-mymenu.component';
import { DetailMymenuComponent } from './restaurantpanel/menu/detail-mymenu/detail-mymenu.component';
import { EditMymenuComponent } from './restaurantpanel/menu/edit-mymenu/edit-mymenu.component';
import { ListMymenuComponent } from './restaurantpanel/menu/list-mymenu/list-mymenu.component';
import { AddMycategoryComponent } from './restaurantpanel/mycategory/add-mycategory/add-mycategory.component';
import { EditMycategoryComponent } from './restaurantpanel/mycategory/edit-mycategory/edit-mycategory.component';
import { ListMycategoryComponent } from './restaurantpanel/mycategory/list-mycategory/list-mycategory.component';
import { AddMysubcategoryComponent } from './restaurantpanel/mysubcategory/add-mysubcategory/add-mysubcategory.component';
import { EditMysubcategoryComponent } from './restaurantpanel/mysubcategory/edit-mysubcategory/edit-mysubcategory.component';
import { ListMysubcategoryComponent } from './restaurantpanel/mysubcategory/list-mysubcategory/list-mysubcategory.component';
import { AssignMyorderComponent } from './restaurantpanel/orders/assign-myorder/assign-myorder.component';
import { DetailMyorderComponent } from './restaurantpanel/orders/detail-myorder/detail-myorder.component';
import { ListOrdersComponent } from './restaurantpanel/orders/list-orders/list-orders.component';
import { RejectMyorderComponent } from './restaurantpanel/orders/reject-myorder/reject-myorder.component';
import { MyearningComponent } from './restaurantpanel/wallet/myearning/myearning.component';
import { MyprofileComponent } from './restaurantpanel/profile/myprofile/myprofile.component';
import { AddrestcategoryComponent } from './restcategory/addrestcategory/addrestcategory.component';
import { EditrestcategoryComponent } from './restcategory/editrestcategory/editrestcategory.component';
import { ListrestcategoryComponent } from './restcategory/listrestcategory/listrestcategory.component';
import { RestcategoryComponent } from './restcategory/restcategory.component';
import { AddrestsubcategoryComponent } from './restsubcategory/addrestsubcategory/addrestsubcategory.component';
import { EditrestsubcategoryComponent } from './restsubcategory/editrestsubcategory/editrestsubcategory.component';
import { ListrestsubcategoryComponent } from './restsubcategory/listrestsubcategory/listrestsubcategory.component';
import { RestsubcategoryComponent } from './restsubcategory/restsubcategory.component';
import { AddroleComponent } from './roles/addrole/addrole.component';
import { ListroleComponent } from './roles/listrole/listrole.component';
import { RolesComponent } from './roles/roles.component';
import { AddserviceComponent } from './service/addservice/addservice.component';
import { EditserviceComponent } from './service/editservice/editservice.component';
import { ListserviceComponent } from './service/listservice/listservice.component';
import { ServiceComponent } from './service/service.component';
import { ListdriverissueComponent } from './support/driverissue/listdriverissue/listdriverissue.component';
import { ReplydriverissueComponent } from './support/driverissue/replydriverissue/replydriverissue.component';
import { SupportComponent } from './support/support.component';
import { AddtagComponent } from './tag/addtag/addtag.component';
import { EdittagComponent } from './tag/edittag/edittag.component';
import { ListtagComponent } from './tag/listtag/listtag.component';
import { TagComponent } from './tag/tag.component';
import { AddtimingComponent } from './timing/addtiming/addtiming.component';
import { EdittimingComponent } from './timing/edittiming/edittiming.component';
import { ListtimingComponent } from './timing/listtiming/listtiming.component';
import { TimingComponent } from './timing/timing.component';
import { AdduserComponent } from './user/adduser/adduser.component';
import { EdituserComponent } from './user/edituser/edituser.component';
import { ListuserComponent } from './user/listuser/listuser.component';
import { LoginComponent } from './user/login/login.component';
import { PasswordComponent } from './user/password/password.component';
import { UserComponent } from './user/user.component';
import { AddMyoffersComponent } from './restaurantpanel/offers/add-myoffers/add-myoffers.component';
import { ListMyoffersComponent } from './restaurantpanel/offers/list-myoffers/list-myoffers.component';
import { EditMyoffersComponent } from './restaurantpanel/offers/edit-myoffers/edit-myoffers.component';
import { DetailcustomerComponent } from './customer/detailcustomer/detailcustomer.component';
import { ListcustomerComponent } from './customer/listcustomer/listcustomer.component';
import { AddSupportQuestionsComponent } from './customize/add-support-questions/add-support-questions.component';
import { ListSupportQuestionsComponent } from './customize/list-support-questions/list-support-questions.component';
import { ApprovecategoryComponent } from './restcategory/approvecategory/approvecategory.component';
import { ApprovesubcategoryComponent } from './restsubcategory/approvesubcategory/approvesubcategory.component';
import { ApproveproductComponent} from './product/approveproduct/approveproduct.component';
import { DetailapproveComponent } from './product/detailapprove/detailapprove.component';
import { UpdateMydriverComponent } from './restaurantpanel/driver/update-mydriver/update-mydriver.component';
import { ListcustomerissueComponent } from './support/customerissue/listcustomerissue/listcustomerissue.component';
import { DetailcustomerissueComponent } from './support/customerissue/detailcustomerissue/detailcustomerissue.component';
import { RefundOnlineOrderComponent } from './support/customerissue/refund-online-order/refund-online-order.component';
import { RefundCodOrderComponent } from './support/customerissue/refund-cod-order/refund-cod-order.component';
import { CancelrejectorderComponent } from './orders/cancelrejectorder/cancelrejectorder.component';
import { ListtimeoutComponent } from './orders/listtimeout/listtimeout.component';
import { ListunassignorderComponent } from './orders/listunassignorder/listunassignorder.component';
import { OnlineRefundOrderComponent } from './orders/online-refund-order/online-refund-order.component';
import { UpdateorderComponent } from './orders/updateorder/updateorder.component';
import { CodRefundOrderComponent } from './orders/cod-refund-order/cod-refund-order.component';
import { RejectRefundOrderComponent } from './support/customerissue/reject-refund-order/reject-refund-order.component';
import { DetaildriverissueComponent } from './support/driverissue/detaildriverissue/detaildriverissue.component';


const routes: Routes = [
  {path:'',redirectTo:'admin/login',pathMatch:'full'},

  {path:'admin/login',component:LoginComponent},
  {path:'registerRestaurant',component:OpenregisterComponent},

  {path:'admin',component:LayoutComponent,canActivate:[AuthGuard],
    children:[
      {path:'',component:DashboardComponent},

      {path:'home',component:DashboardComponent},

      {path:'users',component:UserComponent,canActivate:[AuthGuard],data:{permission: ['User']},
      children:[
        {path:'add',component:AdduserComponent,canActivate:[AuthGuard],data:{permission: ['User']}},
        {path:'view',component:ListuserComponent,canActivate:[AuthGuard],data:{permission: ['User']}},
        {path:'edit/:id',component:EdituserComponent,canActivate:[AuthGuard],data:{permission: ['User']}},
        {path:'password/:id',component:PasswordComponent,canActivate:[AuthGuard],data:{permission: ['User']}}
      ]},

      //Customer
      {path:'customer/view',component:ListcustomerComponent,canActivate:[AuthGuard],data:{permission: ['Customer']}},
      {path:'customer/:id',component:DetailcustomerComponent,canActivate:[AuthGuard],data:{permission: ['Customer']}},
      
      {path:'service',component:ServiceComponent,canActivate:[AuthGuard],data:{permission: ['Service']},
      children:[
        {path:'add',component:AddserviceComponent,canActivate:[AuthGuard],data:{permission: ['Service']}},
        {path:'view',component:ListserviceComponent,canActivate:[AuthGuard],data:{permission: ['Service']}},
        {path:'edit/:id',component:EditserviceComponent,canActivate:[AuthGuard],data:{permission: ['Service']}}
      ]},

      {path:'cuisine',component:CuisineComponent,canActivate:[AuthGuard],data:{permission: ['Cuisine']},
      children:[
        {path:'add',component:AddcuisineComponent,canActivate:[AuthGuard],data:{permission: ['Cuisine']}},
        {path:'view',component:ListcuisineComponent,canActivate:[AuthGuard],data:{permission: ['Cuisine']}},
        {path:'edit/:id',component:EditcuisineComponent,canActivate:[AuthGuard],data:{permission: ['Cuisine']}}
      ]},

      {path:'tag',component:TagComponent,canActivate:[AuthGuard],data:{permission: ['Tag']},
      children:[
        {path:'add',component:AddtagComponent,canActivate:[AuthGuard],data:{permission: ['Tag']}},
        {path:'view',component:ListtagComponent,canActivate:[AuthGuard],data:{permission: ['Tag']}},
        {path:'edit/:id',component:EdittagComponent,canActivate:[AuthGuard],data:{permission: ['Tag']}}
      ]},

      {path:'timing',component:TimingComponent,canActivate:[AuthGuard],data:{permission: ['Timing']},
      children:[
        {path:'add',component:AddtimingComponent,canActivate:[AuthGuard],data:{permission: ['Timing']}},
        {path:'view',component:ListtimingComponent,canActivate:[AuthGuard],data:{permission: ['Timing']}},
        {path:'edit/:id',component:EdittimingComponent,canActivate:[AuthGuard],data:{permission: ['Timing']}}
      ]},

      {path:'category',component:CategoryComponent,canActivate:[AuthGuard],data:{permission: ['Category']},
      children:[
        {path:'add',component:AddcategoryComponent,canActivate:[AuthGuard],data:{permission: ['Category']}},
        {path:'view',component:ListcategoryComponent,canActivate:[AuthGuard],data:{permission: ['Category']}},
        {path:'edit/:id',component:EditcategoryComponent,canActivate:[AuthGuard],data:{permission: ['Category']}}
      ]},

      {path:'city',component:CityComponent,canActivate:[AuthGuard],data:{permission: ['City']},
      children:[
        {path:'add',component:AddcityComponent,canActivate:[AuthGuard],data:{permission: ['City']}},
        {path:'view',component:ListcityComponent,canActivate:[AuthGuard],data:{permission: ['City']}},
        {path:'edit/:id',component:EditcityComponent,canActivate:[AuthGuard],data:{permission: ['City']}}
      ]},

      {path:'restaurant',component:RestaurantComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant_detail']},
      children:[ 
        {path:'add',component:AddrestaurantComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant_detail']}},
        {path:'view',component:ListrestaurantComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant_detail']}},
        {path:'updateApplication',component:UpdateapplicationComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant_detail']}},
        {path:'approve',component:ApproverestaurantComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant_detail']}},
        {path:'edit/:id',component:EditrestaurantComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant_detail']}},
        {path:'aggreement/:id',component:SendagreementComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant_detail']}},
        {path:'listaggreement',component:ListagreementComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant_detail']}},
        {path:'remarks/:id',component:ListremarksComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant_detail']}},
        {path:'single/:id',component:SinglerestaurantComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant_detail']}},
        {path:'wallet/:id',component:WalletrestaurantComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant_detail']}},
        {path:'pay/:id',component:PayrestaurantComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant_detail']}},
      ]},

      {path:'restcategory',component:RestcategoryComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant Setting']},
      children:[
        {path:'add',component:AddrestcategoryComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant Setting']}},
        {path:'view',component:ListrestcategoryComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant Setting']}},
        {path:'approve',component:ApprovecategoryComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant Setting']}},
        {path:'edit/:id',component:EditrestcategoryComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant Setting']}}
      ]},

      {path:'restsubcategory',component:RestsubcategoryComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant Setting']},
      children:[
        {path:'add',component:AddrestsubcategoryComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant Setting']}},
        {path:'view',component:ListrestsubcategoryComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant Setting']}},
        {path:'approve',component:ApprovesubcategoryComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant Setting']}},
        {path:'edit/:id',component:EditrestsubcategoryComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant Setting']}}
      ]},

      {path:'menu',component:ProductComponent,canActivate:[AuthGuard],data:{permission: ['Products']},
      children:[
        {path:'add',component:AddproductComponent,canActivate:[AuthGuard],data:{permission: ['Products']}},
        {path:'view',component:ListproductComponent,canActivate:[AuthGuard],data:{permission: ['Products']}},
        {path:'edit/:id',component:EditproductComponent,canActivate:[AuthGuard],data:{permission: ['Products']}},
        {path:'addons/:id',component:AddonsComponent,canActivate:[AuthGuard],data:{permission: ['Products']}},
        {path:'single/:id',component:SingleproductComponent,canActivate:[AuthGuard],data:{permission: ['Products']}},
        {path:'approve',component:ApproveproductComponent,canActivate:[AuthGuard],data:{permission: ['Products']}},
        {path:'details/:id',component:DetailapproveComponent,canActivate:[AuthGuard],data:{permission: ['Products']}},
      ]},

      {path:'productTag',component:ProducttagComponent,canActivate:[AuthGuard],data:{permission: ['Products']},
      children:[
        {path:'add',component:AddproducttagComponent,canActivate:[AuthGuard],data:{permission: ['Products']}},
        {path:'view',component:ListproducttagComponent,canActivate:[AuthGuard],data:{permission: ['Products']}},
        {path:'edit/:id',component:EditproducttagComponent,canActivate:[AuthGuard],data:{permission: ['Products']}}
      ]},

      {path:'adcenter',component:AddsComponent,canActivate:[AuthGuard],data:{permission: ['Ad-Center']},
      children:[
        {path:'add',component:AddadvertisementComponent,canActivate:[AuthGuard],data:{permission: ['Ad-Center']}},
        {path:'view',component:ListadvertisementComponent,canActivate:[AuthGuard],data:{permission: ['Ad-Center']}},
        {path:'edit/:id',component:EditadvertisementComponent,canActivate:[AuthGuard],data:{permission: ['Ad-Center']}}
      ]},

      {path:'driver',component:DriverComponent,canActivate:[AuthGuard],data:{permission: ['Drivers']},
      children:[
        {path:'add',component:AdddriverComponent,canActivate:[AuthGuard],data:{permission: ['Drivers']}},
        {path:'view',component:ListdriverComponent,canActivate:[AuthGuard],data:{permission: ['Drivers']}},
        {path:'updateApplication',component:UpdateapplicationComponent,canActivate:[AuthGuard],data:{permission: ['Drivers']}},
        {path:'approve',component:ListdriverapprovedComponent,canActivate:[AuthGuard],data:{permission: ['Drivers']}},
        {path:'edit/:id',component:EditdriverComponent,canActivate:[AuthGuard],data:{permission: ['Drivers']}},
        {path:'details/:id',component:SingledriverComponent,canActivate:[AuthGuard],data:{permission: ['Drivers']}},
        {path:'aggreement/:id',component:SenddriveragreementComponent,canActivate:[AuthGuard],data:{permission: ['Drivers']}},
        {path:'verify/:id',component:SenddriverapproveComponent,canActivate:[AuthGuard],data:{permission: ['Drivers']}},
        {path:'listaggreement',component:ListdriveragreementComponent,canActivate:[AuthGuard],data:{permission: ['Drivers']}},
        {path:'remarks/:id',component:ListremarksComponent,canActivate:[AuthGuard],data:{permission: ['Drivers']}},
        {path:'orders/:id',component:OrderdriverComponent,canActivate:[AuthGuard],data:{permission: ['Drivers']}},
        {path:'transactions/:id',component:ListtransactionsComponent,canActivate:[AuthGuard],data:{permission: ['Drivers']}},
        {path:'pay/:id',component:PaydriverComponent,canActivate:[AuthGuard],data:{permission: ['Drivers']}},
        {path:'approveBank/:id/:bank',component:ApprovebankComponent,canActivate:[AuthGuard],data:{permission: ['Drivers']}},
        {path:'cashInHand',component:ListcashinhandComponent,canActivate:[AuthGuard],data:{permission: ['Drivers']}},
        {path:'cashHistory/:id',component:ListcashhistoryComponent,canActivate:[AuthGuard],data:{permission: ['Drivers']}},
        {path:'collect/:id',component:CollectcashComponent,canActivate:[AuthGuard],data:{permission: ['Drivers']}},
      ]},
      {path:'delivery',component:DeliveryComponent,canActivate:[AuthGuard],data:{permission: ['Delivery_Charges']},
      children:[
        {path:'add',component:AdddeliveryComponent,canActivate:[AuthGuard],data:{permission: ['Delivery_Charges']}},
        {path:'view',component:ListdeliveryComponent,canActivate:[AuthGuard],data:{permission: ['Delivery_Charges']}},
        {path:'edit/:id',component:EditdeliveryComponent,canActivate:[AuthGuard],data:{permission: ['Delivery_Charges']}},
      ]},
      {path:'offers',component:OffersComponent,canActivate:[AuthGuard],data:{permission: ['Offers']},
      children:[
        {path:'add',component:AddoffersComponent,canActivate:[AuthGuard],data:{permission: ['Offers']}},
        {path:'view',component:ListoffersComponent,canActivate:[AuthGuard],data:{permission: ['Offers']}},
        {path:'edit/:id',component:EditoffersComponent,canActivate:[AuthGuard],data:{permission: ['Offers']}},
      ]},
      {path:'orders',component:OrdersComponent,
      children:[
        {path:'all',component:AllordersComponent},
        {path:'unassign',component:ListunassignorderComponent},
        {path:'timeout',component:ListtimeoutComponent},
        {path:'cancelReject',component:CancelrejectorderComponent},
        {path:'single/:id',component:SingleorderComponent},
        {path:'assignOrder/:id',component:AssignorderComponent},
        {path:'update/:id',component:UpdateorderComponent},
        {path:'refundsOrder/:id',component:CodRefundOrderComponent},
        {path:'refundOrder/:id',component:OnlineRefundOrderComponent},
      ]},
      {path:'customize',component:CustomizeComponent,canActivate:[AuthGuard],data:{permission: ['Customize']},
      children:[
        {path:'slider/add',component:AddsliderComponent,canActivate:[AuthGuard],data:{permission: ['Customize']}},
        {path:'slider',component:ListsliderComponent,canActivate:[AuthGuard],data:{permission: ['Customize']}},
        {path:'restaurant/add',component:AddtoprestaurantComponent,canActivate:[AuthGuard],data:{permission: ['Customize']}},
        {path:'restaurant',component:ListtoprestaurantComponent,canActivate:[AuthGuard],data:{permission: ['Customize']}},
        {path:'product/add',component:AddtopproductComponent,canActivate:[AuthGuard],data:{permission: ['Customize']}},
        {path:'product',component:ListtopproductComponent,canActivate:[AuthGuard],data:{permission: ['Customize']}},
        //Support Questions
        {path:'supportQuestion',component:ListSupportQuestionsComponent},
        {path:'supportQuestion/add',component:AddSupportQuestionsComponent},
      ]},
      {path:'report',component:ReportComponent,canActivate:[AuthGuard],data:{permission: ['Reports']},
      children:[
        {path:'restaurant',component:RestreportComponent,canActivate:[AuthGuard],data:{permission: ['Reports']}},
        {path:'driver',component:DriverreportComponent,canActivate:[AuthGuard],data:{permission: ['Reports']}},
      ]},
      {path:'support',component:SupportComponent,canActivate:[AuthGuard],data:{permission: ['Support']},
      children:[
        {path:'driverIssue/list',component:ListdriverissueComponent,canActivate:[AuthGuard],data:{permission: ['Support']}},
        {path:'driverIssue/:id',component:DetaildriverissueComponent,canActivate:[AuthGuard],data:{permission: ['Support']}},
        {path:'driverIssue/reply/:id',component:ReplydriverissueComponent,canActivate:[AuthGuard],data:{permission: ['Support']}},
        //Customer Issue
        {path:'customerIssue/list',component:ListcustomerissueComponent},
        {path:'customerIssue/:id',component:DetailcustomerissueComponent},
        {path:'customerIssue/refund/:id',component:RefundOnlineOrderComponent},
        {path:'customerIssue/refundCod/:id',component:RefundCodOrderComponent},
        {path:'customerIssue/reject/:id',component:RejectRefundOrderComponent},
      ]},
      {path:'roles',component:RolesComponent,canActivate:[AuthGuard],data:{permission: ['Products']},
      children:[
        {path:'add',component:AddroleComponent,canActivate:[AuthGuard],data:{permission: ['Products']}},
        {path:'view',component:ListroleComponent,canActivate:[AuthGuard],data:{permission: ['Products']}},
      ]},
    ]
  },

  {path:'restaurant',component:LayoutComponent,canActivate:[AuthGuard],
    children:[
      {path:'',component:DashboardComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant_Category']}},
      {path:'category/add',component:AddMycategoryComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant_Category']}},
      {path:'category/edit/:id',component:EditMycategoryComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant_Category']}},
      {path:'category/view',component:ListMycategoryComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant_Category']}},

      //----------Subcatgeory
      {path:'subcategory/add',component:AddMysubcategoryComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant_Subcategory']}},
      {path:'subcategory/edit/:id',component:EditMysubcategoryComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant_Subcategory']}},
      {path:'subcategory/view',component:ListMysubcategoryComponent,canActivate:[AuthGuard],data:{permission: ['Restaurant_Subcategory']}},

      //----------Driver
      {path:'driver/add',component:AddMydriverComponent,canActivate:[AuthGuard],data:{permission:['RestDriver']}},
      {path:'driver/view',component:ListMydriverComponent,canActivate:[AuthGuard],data:{permission:['RestDriver']}},
      {path:'driver/:id',component:SingleMydriverComponent,canActivate:[AuthGuard],data:{permission:['RestDriver']}},
      {path:'driver/update/:id',component:UpdateMydriverComponent,canActivate:[AuthGuard],data:{permission:['RestDriver']}},

      //----------Menu
      {path:'menu/add',component:AddMymenuComponent,canActivate:[AuthGuard],data:{permission:['Menu']}},
      {path:'menu/view',component:ListMymenuComponent,canActivate:[AuthGuard],data:{permission:['Menu']}},
      {path:'menu/:id',component:DetailMymenuComponent,canActivate:[AuthGuard],data:{permission:['Menu']}},
      {path:'menu/edit/:id',component:EditMymenuComponent,canActivate:[AuthGuard],data:{permission:['Menu']}},
      {path:'menu/addons/:id',component:AddAddonsComponent,canActivate:[AuthGuard],data:{permission:['Menu']}},

      //----------Orders
      {path:'orders/list',component:ListOrdersComponent,canActivate:[AuthGuard],data:{permission:['RestOrder']}},
      {path:'orders/:id',component:DetailMyorderComponent,canActivate:[AuthGuard],data:{permission:['RestOrder']}},
      {path:'orders/reject/:id',component:RejectMyorderComponent,canActivate:[AuthGuard],data:{permission:['RestOrder']}},
      {path:'orders/assign/:id',component:AssignMyorderComponent,canActivate:[AuthGuard],data:{permission:['RestOrder']}},

      //---------Earning
      {path:'earning',component:MyearningComponent,canActivate:[AuthGuard],data:{permission:['RestEarning']}},

      //--------Profile
      {path:'profile',component:MyprofileComponent,canActivate:[AuthGuard],data:{permission:['MyProfile']}},
      
      //--------Offers
      {path:'offers',component:AddMyoffersComponent,canActivate:[AuthGuard],data:{permission:['RestOffer']}},
      {path:'offers/list',component:ListMyoffersComponent,canActivate:[AuthGuard],data:{permission:['RestOffer']}},
      {path:'offers/:id',component:EditMyoffersComponent,canActivate:[AuthGuard],data:{permission:['RestOffer']}},
    ]
  },
  {path:'**',component:LoginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
