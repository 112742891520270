<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/restaurant/menu/view']">Menu</a></li>
        <li class="active breadcrumb-item">Add</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Manager</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title">Add Menu</h3>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Form -->
                <form [formGroup]="formGroup" (submit)="onsubmit()">

                    <div class="form-row">
                        <div class="col-sm-12 mb-3">
                            <label for="addon">AddOns</label>&nbsp;<button type="button" (click)="addAddon()" class="btn btn-primary">Add AddOn</button>
                            <div formArrayName="addon">
                                <div *ngFor="let add of addon().controls; let addonIndex=index">
                                    <div class="row" [formGroupName]="addonIndex">
                                        <div class="col-sm-10">Name :<input type="text" formControlName="name" class="form-control"></div>

                                        <div class="col-sm-1"><button (click)="removeAddon(addonIndex)" class="btn btn-danger" title="Remove Addon"><i class="icon icon-circle-remove-o icon-fw icon-lg"></i></button></div>
                                        
                                        <div class="col-sm-1"><button type="button" (click)="addEmployeeSkill(addonIndex)" class="btn btn-primary" title="Add Price"><i class="icon icon-circle-add-o icon-fw icon-lg"></i></button></div>
                                        <div formArrayName="skills">
                                            <div *ngFor="let skill of employeeSkills(addonIndex).controls; let skillIndex=index">
                                                <div [formGroupName]="skillIndex">
                                                    <div class="row">
                                                        <div class="col-sm-5">Name :<input type="text" class="form-control" formControlName="addname"></div>
                                                        <div class="col-sm-5">Price :<input type="text" class="form-control" formControlName="addvalue"></div>
                                                        <div class="col-sm-2"><button (click)="removeEmployeeSkill(addonIndex,skillIndex)" class="btn btn-danger"><i class="icon icon-circle-remove-o icon-fw icon-lg"></i></button></div> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <div class="col-xl-8 offset-xl-4">
                            <button type="submit" class="btn btn-primary text-uppercase"
                                [disabled]="!formGroup.valid">Save
                            </button>
                        </div>
                    </div>
                    <!-- /form group -->

                </form>
                <!-- /form -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->