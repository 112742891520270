import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata.service';

@Injectable({
  providedIn: 'root'
})
export class RestaurantService {

  BaseUrl = ''
  Token = ''

  constructor(private http: HttpClient, @Inject('BASE_URL') _base,private userdata: UserdataService) {
    this.BaseUrl = _base
    this.Token = userdata.getToken()
  }

  public addRestaurant(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/addrestadmin",form, { headers: header_object})
  }

  public allRestaurant(){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/allrestautrant",{ headers: header_object})
  }

  public getRestaurantById(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/restdetailbyid",form, { headers: header_object})
  }

  public updateRestaurant(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken()).set('Content-Type', 'application/x-www-form-urlencoded')
    return this.http.post(this.BaseUrl + "/api/updaterestautrant",form.toString(), { headers: header_object})
  }

  public searchRestaurant(form){ 
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken()).set('Content-Type', 'application/x-www-form-urlencoded')
    return this.http.post(this.BaseUrl + "/api/allrestautrant",form.toString(), { headers: header_object})
  }

  public approveReject(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken()).set('Content-Type', 'application/x-www-form-urlencoded')
    return this.http.post(this.BaseUrl + "/api/approveorrejectrest",form.toString(), { headers: header_object})
  }

  public sendAgreement(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken()).set('Content-Type', 'application/x-www-form-urlencoded')
    return this.http.post(this.BaseUrl + "/api/sendagreement",form.toString(), { headers: header_object})
  }

  public deleteTags(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/deleterestdataadmin",form,{ headers: header_object})
  }

  public restTransactions(form) {//restId---mandatory
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/restalltxnhistory",form, { headers: header_object})
  }

  public restPayout(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/addamountrest",form, { headers: header_object})
  }

  //------Assign order to driver
  public assignOrderManually(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/assignorderbyresttodriver",form, { headers: header_object})
  }


  //------------------APIS for restaurant panel----------------------//

  //---accept reject order by restaurant
  public acceptRejectByRest(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/rest/orderaceptorrejectbyrest",form, { headers: header_object})
  }

  //---order preparing -> prepared
  public orderPrepared(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/rest/changeordertoprepared",form, { headers: header_object})
  }

  public restAssignOrderManually(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/rest/assignorderbyresttodriver",form, { headers: header_object})
  }

  //---------Update Password
  public changePasswordRest(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/rest/changepasswordrest",form, { headers: header_object})
  }

  //-------Get restaurant earning transactions
  public restWalletTransactions(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/rest/restalltxnhistory",form, { headers: header_object})
  }

  //-------Get restaurant Current wallet balance
  public restCurrentBalance(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/rest/getcurrentwalletamount",form, { headers: header_object})
  }

}
