import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable'; 
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';

@Component({
  selector: 'app-updateapplication',
  templateUrl: './updateapplication.component.html',
  styleUrls: ['./updateapplication.component.css']
})
export class UpdateapplicationComponent implements OnInit {

  constructor(private toast: ToastrService,private restaurant: RestaurantService) { }

  p: number = 1;
  term: string;
  temp = [];
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]
  columns = [{ prop: 'Name' }, { name: 'Address' }, { name: 'Phone' }, { name: 'City' }, { name: 'Food Type' }, { name: 'Status' }, { name: 'Action' }];
  ngOnInit(): void {
    // for page number 1 as offset starts with 0
    //this.table.offset = 0;
    this.getPendingRestaurantList()    
  }

  getPendingRestaurantList(){
    let body = new URLSearchParams();
    body.set('current_status', 'Application Updated');
    this.restaurant.searchRestaurant(body).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.rest_name.toLowerCase().indexOf(val) !== -1 ||
      d.rest_phone.toLowerCase().indexOf(val) !== -1 ||
      d.rest_city.city_name.toLowerCase().indexOf(val) !== -1 ||
      !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

}
