<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="active breadcrumb-item">Customer Issues</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Cuisine</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title float-left">View Customer Issues</h3>
                    <!-- <a class="btn btn-primary float-right" [routerLink]="['/admin/restaurant/add']">Add</a> -->
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Tables -->
                <input
                type="text" class="form-control search-input"
                placeholder="Type to filter the name column..."
                (keyup)="updateFilter($event)"
                />
                <ngx-datatable
                #table
                class="material"
                [columns]="columns"
                [columnMode]="ColumnMode.force"
                [headerHeight]="50"
                [footerHeight]="50"
                rowHeight="auto"
                [limit]="10"
                [rows]="rows"
                #myFilterTable
                >
                    <ngx-datatable-column name="Restaurant" prop="restId.rest_name">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.restId.rest_name}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Customer" prop="customerId.customer_name">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.customerId.customer_name}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="OrderID" prop="orderId._id">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.orderId.orderId}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Payment Type">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        <span *ngIf="row.orderId.is_cod" class="badge badge-primary">Cash</span>
                        <span *ngIf="row.orderId.is_online" class="badge badge-info">Online</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Issue" prop="issue_name">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.issue_name}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Status" prop="is_issued_used">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span class="badge badge-success" *ngIf="row.is_issued_used">
                                Solved
                            </span>
                            <span class="badge badge-danger" *ngIf="!row.is_issued_used">
                                Pending
                            </span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Action">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <a *ngIf="!row.is_issued_used && row.orderId.is_cod" [routerLink]="['/admin/support/customerIssue/refundCod/',row.orderId._id]" title="Refund" class="p-1 btn btn-outline-primary btn-sm mr-1">Refund</a>
                            <a *ngIf="!row.is_issued_used && row.orderId.is_online" [routerLink]="['/admin/support/customerIssue/refund/',row.orderId._id]" title="Refund" class="p-1 btn btn-outline-primary btn-sm mr-1">Refund</a>
                            <a *ngIf="!row.is_issued_used" [routerLink]="['/admin/support/customerIssue/reject/',row.orderId._id]" title="Reject" class="p-1 btn btn-outline-danger btn-sm mr-1">Reject</a>
                            <span class="badge badge-success" *ngIf="row.is_issued_used && row.is_issue_accepted">REFUNDED</span>
                            <span class="badge badge-warning" *ngIf="row.is_issued_used && !row.is_issue_accepted">REJECTED</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Details">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <a [routerLink]="['/admin/support/customerIssue/', row._id]" title="View Details" class="icon icon-eye"></a>&nbsp;
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
                <!-- /tables -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->
