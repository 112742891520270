<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="active breadcrumb-item">Driver Issues</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Cuisine</h1> -->
</div>
<!-- /page header --> 
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title float-left">View Driver Issues</h3>
                    <!-- <a class="btn btn-primary float-right" [routerLink]="['/admin/restaurant/add']">Add</a> -->
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">
                
                <!-- Tables -->
                <input
                type="text" class="form-control search-input"
                placeholder="Type to filter the name column..."
                (keyup)="updateFilter($event)"
                />
                <ngx-datatable
                #table
                class="material"
                [columns]="columns"
                [columnMode]="ColumnMode.force"
                [headerHeight]="50"
                [footerHeight]="50"
                rowHeight="auto"
                [limit]="10"
                [rows]="rows"
                #myFilterTable
                >
                    <!-- <ngx-datatable-column name="Type">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span class="badge badge-pill badge-primary" *ngIf="row.issue_type==1">
                                Order
                            </span>
                            <span class="badge badge-pill badge-secondary" *ngIf="row.issue_type==2">
                                Cash In Hand
                            </span>
                            <span class="badge badge-pill badge-info" *ngIf="row.issue_type==3">
                                Wallet
                            </span>
                        </ng-template>
                    </ngx-datatable-column> -->
                    <ngx-datatable-column name="Driver Name" prop="driverId.driver_name">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.driverId.driver_name}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Issue Title" prop="issue_title">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.issue_title}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Order ID" prop="orderId.orderId">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        #{{row.orderId.orderId}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Order Status">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span class="badge badge-pill badge-primary" *ngIf="row.orderId.order_current_status==101">
                                Placed
                            </span>
                            <span class="badge badge-pill badge-danger" *ngIf="row.orderId.order_current_status==102">
                                Payment Declined
                            </span>
                            <span class="badge badge-pill badge-warning" *ngIf="row.orderId.order_current_status==103">
                                Cooking
                            </span>
                            <span class="badge badge-pill badge-danger" *ngIf="row.orderId.order_current_status==104">
                                Rejected
                            </span>
                            <span class="badge badge-pill badge-warning" *ngIf="row.orderId.order_current_status==105">
                                Cooked
                            </span>
                            <span class="badge badge-pill badge-info" *ngIf="row.orderId.order_current_status==106">
                                Pickup
                            </span>
                            <span class="badge badge-pill badge-info" *ngIf="row.orderId.order_current_status==107">
                                On Way
                            </span>
                            <span class="badge badge-pill badge-success" *ngIf="row.orderId.order_current_status==108">
                                Delivered
                            </span>
                            <span class="badge badge-pill badge-danger" *ngIf="row.orderId.order_current_status==109">
                                Canceled
                            </span>
                            <span class="badge badge-pill badge-danger" *ngIf="row.orderId.order_current_status==110">
                                TimeOut
                            </span>
                            <span class="badge badge-pill badge-danger" *ngIf="row.orderId.order_current_status==111">
                                Refunded
                            </span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Status" prop="vegtype">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span class="badge badge-success" *ngIf="row.is_solved==true">
                                Solved
                            </span>
                            <span class="badge badge-danger" *ngIf="row.is_solved==false">
                                Pending
                            </span>                            
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Reply" prop="status">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span *ngIf="row.is_reply_by_system==true; else showreply">
                                {{row.reply_by_system}}
                            </span>
                            <ng-template #showreply><a *ngIf="row.is_solved==false" [routerLink]="['/admin/support/driverIssue/reply/', row._id]" title="Reply" class="btn btn-outline-primary btn-sm p-1">Reply</a></ng-template>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Action">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <a [routerLink]="['/admin/support/driverIssue/', row._id]" title="View Details" class="btn btn-outline-info btn-sm p-1 mr-1">Details</a>

                            <a *ngIf="row.orderId" [routerLink]="['/admin/orders/update/', row.orderId._id]" title="Update Order Status" class="btn btn-outline-danger btn-sm p-1" >Update</a>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
                <!-- /tables -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->