<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/admin/driver/cashInHand']">Driver Cash In Hand</a></li>
        <li class="active breadcrumb-item">Cash In Hand Transactions</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Manager</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">
        <h3 class="dt-card__title float-left">View Driver Cash In Hand Transactions</h3>
    </div>
    <div class="col-xl-12">
        <div class="row mb-1">
            <div class="col-11" *ngIf="totaltxns > 0">
                <h4 class="dt-card__title">Current Balance - {{presentCash |currency:'CAD':'symbol-narrow'}}</h4>
            </div>
            <div class="col-11" *ngIf="totaltxns == 0">
                <h4 class="dt-card__title">Current Balance - $0</h4>
            </div>
            <!-- <div class="col-1" *ngIf="presentCash > 0">
                <a class="btn btn-info float-right" [routerLink]="['/admin/restaurant/pay/',id]" >Pay</a>
            </div> -->
        </div>
    </div>
    <!-- Grid Item -->
    <div class="col-xl-12">
        <!-- Card -->
        <div class="dt-card dt-card__full-height">
            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title float-left">Search:</h3>
                    <!-- <a class="btn btn-primary float-right" [routerLink]="['/admin/driver/add']">Add</a><br/> -->
                </div>
                <!-- /card heading -->
            </div>
            <!-- /card header -->
            <!-- Card Body -->
            <div class="dt-card__body">
                <div class="row mb-1 mt-1">
                    <div class="col-3">
                        <button class="btn btn-block btn-primary" (click)="searchButton(7)">Weekly</button>
                    </div>
                    <div class="col-3">
                        <button class="btn btn-block btn-primary" (click)="searchButton(30)">Monthly</button>
                    </div>
                    <div class="col-3">
                        <button class="btn btn-block btn-primary" (click)="searchButton(90)">Quaterly</button>
                    </div>
                    <div class="col-3">
                        <button class="btn btn-block btn-primary" (click)="searchButton(366)">Yearly</button>
                    </div>
                </div>
                <hr>
                <div class="row mb-1" [formGroup]="myGroup">
                    <div class="col-5">
                        Select Start Date:
                        <input type="date" name="startdate" id="startdate" formControlName="startdate" class="form-control">
                    </div>
                    <div class="col-5">
                        Select End Date:
                        <input type="date" name="enddate" id="enddate" formControlName="enddate" class="form-control">
                    </div>
                    <div class="col-2">
                        <br>
                        <button class="btn btn-primary btn-block" (click)="searchByDate()">Search</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Body -->
            <div class="dt-card__body">
                
                <!-- Tables -->
                <ngx-datatable
                #table
                class="material"
                [columns]="columns"
                [columnMode]="ColumnMode.force"
                [headerHeight]="50"
                [footerHeight]="50"
                rowHeight="auto"
                [limit]="10"
                [rows]="rows"
                #myFilterTable
                >
                    <ngx-datatable-column name="Type" prop="row.txn_type">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        <span *ngIf="row.txn_type==1" class="badge badge-success">
                            Cash Collected
                        </span>
                        <span *ngIf="row.txn_type==2" class="badge badge-primary">
                            Cash Paid
                        </span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Order Amount" prop="orderId">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        <span *ngIf="row.orderId !== null">
                            {{row.orderId.product_total_with_tax |currency:'CAD':'symbol-narrow'}}
                        </span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Cash Collected" prop="cash_amount">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span *ngIf="row.txn_type==1">
                                {{row.cash_amount |currency:'CAD':'symbol-narrow'}}
                            </span>
                            <span *ngIf="row.txn_type==2">
                                $0
                            </span>
                        </ng-template>
                    </ngx-datatable-column>   
                    <ngx-datatable-column name="Transaction ID" prop="txnId">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.txnId}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Amount Paid" prop="cash_amount">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span *ngIf="row.txn_type==1">
                                $0
                            </span>
                            <span *ngIf="row.txn_type==2">
                                {{row.cash_amount |currency:'CAD':'symbol-narrow'}}
                            </span>
                        </ng-template>
                    </ngx-datatable-column>                 
                    <ngx-datatable-column name="Balance" prop="current_cash">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.current_cash | number:'1.1-3' |currency:'CAD':'symbol-narrow'}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Date" prop="created_at">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{transformDate(row.created_at)}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Created By" prop="created_at">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.entry_by}}
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
                <!-- /tables -->

            </div>
            <!-- /card body -->
        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->