<!-- Grid --> 
<div class="row">
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">
            <!-- Card Body -->
            <div class="dt-card__body p-0">

                <!-- Tables -->
                <input type="text" class="form-control search-input"
                placeholder="Type to filter the name column..." (keyup)="updateFilter($event)" />
            <ngx-datatable #table class="material" [columns]="columns" [columnMode]="ColumnMode.force"
                [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [limit]="10" [rows]="rows"
                #myFilterTable>
                <ngx-datatable-column name="Type">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                    <span class="badge badge-primary" *ngIf="row.is_new_added">New</span>
                    <span class="badge badge-info" *ngIf="!row.is_new_added">Update</span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Category Name" prop="rest_cat_name">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.restcatId.rest_cat_name}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Subcategory Name" prop="rest_cat_name">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.restsubcategory_name}}
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
            <!-- /tables -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->  