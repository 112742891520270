import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormBuilder, FormArray } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { ProductService } from 'src/app/shared/product/product.service';


@Component({
  selector: 'app-addons',
  templateUrl: './addons.component.html',
  styleUrls: ['./addons.component.css']
})
export class AddonsComponent implements OnInit {

  formGroup = new FormGroup({
    addon: new FormArray([
    ]),
  })

  data=[]

  id=''
  constructor(private toast: ToastrService,private fb: FormBuilder, private product: ProductService,private router: Router, private activate: ActivatedRoute) { }

  addon(): FormArray {
    return this.formGroup.get("addon") as FormArray
  }

  newAddon(): FormGroup {
    return this.fb.group({
      name: '',
      skills: this.fb.array([])
    })
  }


  addAddon() {
    console.log("Adding a Addon");
    this.addon().push(this.newAddon());
  }


  removeAddon(addonIndex: number) {
    this.addon().removeAt(addonIndex);
  }

  employeeSkills(addonIndex: number): FormArray {
    return this.addon().at(addonIndex).get("skills") as FormArray
  }

  newSkill(): FormGroup {
    return this.fb.group({
      addname: '',
      addvalue: '',
    })
  }

  addEmployeeSkill(addonIndex: number) {
    this.employeeSkills(addonIndex).push(this.newSkill());
  }

  removeEmployeeSkill(addonIndex: number, skillIndex: number) {
    this.employeeSkills(addonIndex).removeAt(skillIndex);
  }

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
  }

  get f() {
    return this.formGroup.controls
  }

  public onsubmit(){
    //console.log(this.formGroup.value)
    var obj = this.formGroup.get('addon').value
    var sub = []
    obj.forEach(element => {
      element.skills.forEach(elements => {
        sub.push({'valuename':elements.addname,'valuedetail':elements.addvalue})
      });
      //alert(element.name)
      this.data.push({'addontype':element.name,'addonvalue':sub})
      sub=[]
    });
    this.product.updateProductAddons({'_id':this.id,'addon':JSON.stringify(this.data)}).subscribe(
      (res:any) => {
        // console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/menu/single/'+this.id)
      },
      err => {
        // console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
    //console.log(JSON.stringify(this.data))
  }

}
