import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { RestcategoryService } from 'src/app/shared/restcategory/restcategory.service';
import { RestsubcategoryService } from 'src/app/shared/restsubcategory/restsubcategory.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-add-mysubcategory',
  templateUrl: './add-mysubcategory.component.html',
  styleUrls: ['./add-mysubcategory.component.css']
})
export class AddMysubcategoryComponent implements OnInit {

  formGroup = new FormGroup({
    restsubcategory_name: new FormControl('', [Validators.required]),
    added_by: new FormControl('', [Validators.required]),
    restId: new FormControl('', [Validators.required]),
    restcatId: new FormControl('', [Validators.required]),
    meta_desc: new FormControl(''),
    meta_keywords: new FormControl(''),
  })

  categoryList=[]
  constructor(private category:RestcategoryService, private userdata: UserdataService, private toast: ToastrService, private router: Router, private restaurant: RestaurantService, private subcategory : RestsubcategoryService) { }

  ngOnInit(): void {
    var id = this.userdata.getId()
    var added = this.userdata.getUserType()
    this.formGroup.patchValue({restId: id});
    this.formGroup.patchValue({added_by: added});
    this.getCategory()
  }

  get f(){
    return this.formGroup.controls
  }

  getCategory(){
    this.category.listCategory({'restId':this.userdata.getId()}).subscribe( 
      (res:any) => {
        //console.log(res)
        this.categoryList = res.data
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  }

  public onsubmit(){
    this.subcategory.restAddSubcategory(this.formGroup.value).subscribe(
      (res:any) => {
        //console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/restaurant/subcategory/view');
      },
      err => {
        console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
