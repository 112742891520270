import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/shared/customer/customer.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-listcustomer',
  templateUrl: './listcustomer.component.html',
  styleUrls: ['./listcustomer.component.css']
})
export class ListcustomerComponent implements OnInit {

  constructor(private toast: ToastrService,private customer: CustomerService, private userdata:UserdataService) { }

  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]
  temp = [];
  columns = [{ prop: 'Name' }, { name: 'Email' }, { name: 'Phone' }, { name: 'Rewards' },{ name: 'Total Orders' }, { name: 'Status' }, { name: 'Action' }];

  ngOnInit(): void {
    // for page number 1 as offset starts with 0
    //this.table.offset = 0;
    this.getCustomerList()
    
  }

  getCustomerList(){
    this.customer.listCustomer({}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.customer_name.toLowerCase().indexOf(val) !== -1 ||
      d.status.toLowerCase().indexOf(val) !== -1 ||
      String(d.customer_phone).toLowerCase().indexOf(val) !== -1 ||
      String(d.orders_detail.length).toLowerCase().indexOf(val) !== -1 ||
      !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  changeStatus(val,id){
    //console.log(id)
    this.customer.updateCustomer({'_id':id,'status':val,'userId':this.userdata.getId()}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.getCustomerList()
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
        this.getCustomerList()
      }
    )
  }

}
