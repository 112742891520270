import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable'; 
import { UserdataService } from 'src/app/shared/userdata.service';
import { ProductService } from 'src/app/shared/product/product.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import Swal from 'src/assets/node_modules/sweetalert2/dist/sweetalert2.js';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-singlerestaurant',
  templateUrl: './singlerestaurant.component.html',
  styleUrls: ['./singlerestaurant.component.css'],
  providers: [DatePipe]
})
export class SinglerestaurantComponent implements OnInit {

  id=""
  rows:any=""
  imageURL = ''
  
  constructor(private toast: ToastrService,private restaurant: RestaurantService, private userdata:UserdataService, private activate: ActivatedRoute,private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl,private datePipe: DatePipe) { 
    this.imageURL = _imageurl
  }

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    console.log(this.userdata.getToken())
    this.getSingleRestaurant()
  }

  transformDate(date)
  {
    return this.datePipe.transform( date,'MM-dd-yyyy');
  }

  public getSanitizerUrl(photoname){
    return this.trusturl.bypassSecurityTrustUrl(this.imageURL + photoname)
  } 

  getSingleRestaurant(){
    this.restaurant.getRestaurantById({"_id":this.id}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.rows = res.data
      },
      err => {
        console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  deleteAddOn(restId,id,type){
    Swal({
      title: 'Are you sure you want to delete this?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      reverseButtons: true
  }).then((result) => {
      if (result.value) {
        var user = this.userdata.getId()
        this.restaurant.deleteTags({'restId':restId,'typeId':id,'type':type,'addedId':user}).subscribe(
          (res:any) => {
            this.toast.success(res.message,'Success')
            this.getSingleRestaurant()
          },
          err => {
            //console.log(err)
            this.toast.error(err.error.message,'Error')
            this.getSingleRestaurant()
          }
        )
      }
  })
  }

}
