<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="active breadcrumb-item">Driver</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Manager</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title float-left">View Driver</h3>
                    <a class="btn btn-primary float-right" [routerLink]="['/restaurant/driver/add']">Add Driver</a>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">
                <nav ngbNav #nav="ngbNav" class="nav-tabs">
                    <ng-container ngbNavItem>
                        <a ngbNavLink>Approved Drivers</a>
                        <ng-template ngbNavContent>
                            <!-- Tables -->
                            <input type="text" class="form-control search-input"
                                placeholder="Type to filter the name column..." (keyup)="updateFilter($event)" />
                            <ngx-datatable #table class="material" [columns]="columns" [columnMode]="ColumnMode.force"
                                [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [limit]="10" [rows]="rows"
                                #myFilterTable>
                                <ngx-datatable-column name="Name" prop="driver_name">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        {{row.driver_name}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Email" prop="driver_email">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        {{row.driver_email}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Phone" prop="driver_phone">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        {{row.driver_phone}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Bank Account" prop="bank_detail_driver.account_number">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <div *ngIf="row.bank_detail_driver.length > 0; else other_content">
                                            <div *ngFor="let bank of row.bank_detail_driver">
                                                {{bank.account_number}}
                                                <a *ngIf="bank.is_approved == false" class="icon-remove text-danger"
                                                    title="Approve Bank Account"
                                                    [routerLink]="['/admin/driver/approveBank/',row._id,bank._id]"></a>
                                                <span *ngIf="bank.is_approved == true" class="icon-check text-success"
                                                    title="Approved Bank Account"></span>
                                            </div>
                                        </div>
                                        <ng-template #other_content>No Bank Account</ng-template>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Status">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <span class="badge badge-pill badge-success" *ngIf="row.status=='Enabled'"
                                            (click)="changeStatus('Disabled', row._id)">
                                            {{ row.status }}
                                        </span>
                                        <span class="badge badge-pill badge-danger" *ngIf="row.status=='Disabled'"
                                            (click)="changeStatus('Enabled', row._id)">
                                            {{ row.status }}
                                        </span>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Duty">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <span class="badge badge-success" *ngIf="row.is_current_active">
                                            ON
                                        </span>
                                        <span class="badge badge-danger" *ngIf="!row.is_current_active">
                                            OFF
                                        </span>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Action">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <!-- <a [routerLink]="['/admin/driver/edit/', row._id]" title="Edit Driver" ><i class="icon icon-editors icon-fw icon-lg icon-edit"></i></a><a [routerLink]="['/admin/driver/orders/', row._id]" title="Driver Orders"><i class="icon icon-orders icon-fw icon-lg icon-password"></i></a><a [routerLink]="['/admin/driver/transactions/', row._id]" title="Driver Wallet"><i class="icon icon-revenue icon-fw icon-lg icon-delete"></i></a> -->
                                        <a [routerLink]="['/restaurant/driver/', row._id]" title="Driver Details"><i
                                                class="icon icon-eye icon-fw icon-lg text-success"></i></a>
                                    </ng-template>
                                </ngx-datatable-column>
                            </ngx-datatable>
                            <!-- /tables -->
                        </ng-template>
                    </ng-container>
                    <ng-container ngbNavItem>
                        <a ngbNavLink>Pending Drivers</a>
                        <ng-template ngbNavContent>
                            <app-pending-mydriver></app-pending-mydriver>
                        </ng-template>
                    </ng-container>
                </nav>
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
            <!-- /card body -->
        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->