import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserdataService } from 'src/app/shared/userdata.service'; 
import { DriverService } from 'src/app/shared/driver/driver.service';

@Component({
  selector: 'app-senddriveragreement',
  templateUrl: './senddriveragreement.component.html',
  styleUrls: ['./senddriveragreement.component.css']
})
export class SenddriveragreementComponent implements OnInit {

  constructor(private activate: ActivatedRoute, private driver: DriverService,private toast: ToastrService, private router:Router, private userdata: UserdataService) { }

  formGroup = new FormGroup({
    driverId: new FormControl('', [Validators.required]),
    staffId: new FormControl('', [Validators.required]),
    agreemment_amount: new FormControl('', [Validators.required]),
  })

  id = ''
  driverName = ''

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getDataById()
  }

  get f(){
    return this.formGroup.controls
  }

  getDataById(){
    this.driver.getDriverById({'_id':this.id}).subscribe(
      (res:any) => {
        //console.log(res.result)  
        this.formGroup.patchValue({current_status: res.data.current_status});     
        this.formGroup.patchValue({driverId: res.data._id});     
        this.driverName = res.data.driver_name     
        // this.formGroup.patchValue({category_image: res.category_image});     
        var id = this.userdata.getEmail()
        this.formGroup.patchValue({staffId: id});
        this.f   
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        this.router.navigateByUrl('/admin/driver/listaggreement')
      }
    )
  }

  public onsubmit(){
    // let body = new URLSearchParams();
    // body.set('restId', this.formGroup.get("restId").value);
    // body.set('staffId', this.formGroup.get("staffId").value);
    // body.set('agreement_per', this.formGroup.get("agreement_per").value);
    //console.log(data)
    this.driver.sendagreement(this.formGroup.value).subscribe(
      (res:any) => {
        // console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/driver/listaggreement')
      },
      err => {
        // console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }


}
