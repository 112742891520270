import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormBuilder, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserdataService } from 'src/app/shared/userdata.service';
import { DriverService } from 'src/app/shared/driver/driver.service';

@Component({
  selector: 'app-approvebank',
  templateUrl: './approvebank.component.html',
  styleUrls: ['./approvebank.component.css']
})
export class ApprovebankComponent implements OnInit {

  id=''
  bank=''
  statusList = [{'value':true,'name':'Approved'},{'value':false,'name':'Rejected'}]
  formGroup = new FormGroup({
    driverId: new FormControl('', [Validators.required]),
    bankdetailid: new FormControl('', [Validators.required]),
    approval_message: new FormControl('', [Validators.required]),
    is_approved: new FormControl('', [Validators.required]),
    updated_Id: new FormControl('', [Validators.required]),
    updated_by: new FormControl('', [Validators.required]),
  })

  constructor(private toast: ToastrService, private formBuilder: FormBuilder, private driver: DriverService, private router: Router, private userdata: UserdataService, private activate: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.bank = this.activate.snapshot.paramMap.get('bank')
    var added = this.userdata.getUserType()
    var userId = this.userdata.getId()
    this.formGroup.patchValue({ updated_by: added });
    this.formGroup.patchValue({ updated_Id: userId });
    this.formGroup.patchValue({ bankdetailid: this.bank });
    this.formGroup.patchValue({ driverId: this.id });
  }

  get f() {
    return this.formGroup.controls
  }

  public onsubmit() {
    this.driver.approveBank(this.formGroup.value).subscribe(
      (res:any) => {
        //console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/driver/view');
      },
      err => {
        console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }


}
