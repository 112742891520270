import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrdersService } from 'src/app/shared/orders/orders.service';
import { ProductService } from 'src/app/shared/product/product.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-detail-myorder',
  templateUrl: './detail-myorder.component.html',
  styleUrls: ['./detail-myorder.component.css']
})
export class DetailMyorderComponent implements OnInit {

  id=""
  rows:any=""
  imageURL = ''
  constructor(private toast: ToastrService,private order: OrdersService, private userdata:UserdataService, private activate: ActivatedRoute,private product: ProductService,private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl,private datePipe: DatePipe) { 
    this.imageURL = _imageurl
  }

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getSingleOrder()
  }

  public getSanitizerUrl(photoname){
    return this.trusturl.bypassSecurityTrustUrl(this.imageURL + photoname)
  } 

  getSingleOrder(){
    this.order.restSingleOrders({'orderId':this.id,'restId':this.userdata.getId()}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.rows = res.data
        console.log(res.data)
      },
      err => {
        console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  getJson(value){
    return JSON.parse(value)
  }

  transformDate(date)
  {
    return this.datePipe.transform( date,'MM-dd-yyyy');
  }

}
