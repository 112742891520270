<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="active breadcrumb-item">Restaurant</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Cuisine</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title float-left">View Restaurant</h3>
                    <a class="btn btn-primary float-right" [routerLink]="['/admin/restaurant/add']">Add</a>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">
                
                <!-- Tables -->
                <input
                type="text" class="form-control search-input"
                placeholder="Type to filter the name column..."
                (keyup)="updateFilter($event)"
                />
                <ngx-datatable
                #table
                class="material"
                [columns]="columns"
                [columnMode]="ColumnMode.force"
                [headerHeight]="50"
                [footerHeight]="50"
                rowHeight="auto"
                [limit]="10"
                [rows]="rows"
                #myFilterTable
                >
                    <ngx-datatable-column name="Name" prop="rest_name">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.rest_name}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Address" prop="rest_address">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.rest_address}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Phone" prop="rest_phone">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.rest_phone}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="City" prop="rest_city.city_name">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.rest_city.city_name}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Food Type" prop="vegtype">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.vegtype}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Status" prop="status">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span class="badge badge-pill badge-success" *ngIf="row.status=='Enabled'" (click)="changeStatus('Disabled', row._id)">
                                {{ row.status }}
                            </span>
                            
                            <span class="badge badge-pill badge-danger" *ngIf="row.status=='Disabled'" (click)="changeStatus('Enabled', row._id)">
                                {{ row.status }}
                            </span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Action">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <a [routerLink]="['/admin/restaurant/remarks/', row._id]" title="View Remarks" ><i class="icon icon-chat-app icon-fw icon-lg icon-edit"></i></a>&nbsp;<a [routerLink]="['/admin/restaurant/single/', row._id]" title="View Info" ><i class="icon icon-eye icon-fw icon-lg icon-view"></i></a>&nbsp;<a [routerLink]="['/admin/restaurant/wallet/', row._id]" title="View Wallet" ><i class="icon icon-revenue icon-fw icon-lg icon-delete"></i></a>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
                <!-- /tables -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->