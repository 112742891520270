import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable'; 
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service'; 
import { DriverService } from 'src/app/shared/driver/driver.service';

@Component({
  selector: 'app-listdriveragreement',
  templateUrl: './listdriveragreement.component.html',
  styleUrls: ['./listdriveragreement.component.css']
})
export class ListdriveragreementComponent implements OnInit {

  constructor(private toast: ToastrService,private driver: DriverService) { }

  p: number = 1;
  term: string;
  temp = [];
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]
  columns = [{ name: 'Restaurant' },{ prop: 'Name' }, { name: 'Email' }, { name: 'Phone' }, { name: 'Address' }, { name: 'Status' }, { name: 'Action' }];
  ngOnInit(): void {
    // for page number 1 as offset starts with 0
    //this.table.offset = 0;
    this.getPendingDriverList()    
  }

  getPendingDriverList(){
    // let body = new URLSearchParams();
    // body.set('current_status', 'Registered');
    this.driver.listDriver({'current_status':'Registered'}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.driver_name.toLowerCase().indexOf(val) !== -1 ||
      d.driver_email.toLowerCase().indexOf(val) !== -1 ||
      d.status.toLowerCase().indexOf(val) !== -1 ||
      !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

}
