import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ProducttagService } from 'src/app/shared/producttag/producttag.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-editproducttag',
  templateUrl: './editproducttag.component.html',
  styleUrls: ['./editproducttag.component.css']
})
export class EditproducttagComponent implements OnInit {

  formGroup = new FormGroup({
    producttag_name: new FormControl('', [Validators.required]),
    userId: new FormControl('', [Validators.required]),
    added_by: new FormControl('', [Validators.required]),
    _id: new FormControl('', [Validators.required]),
  }) 

  id = ''

  constructor(private toast: ToastrService, private userdata: UserdataService,private tag: ProducttagService,private ngxService: NgxUiLoaderService, private router: Router, private activate: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getDataById()
  }

  get f(){
    return this.formGroup.controls
  }

  getDataById(){
    let body = new URLSearchParams();
    body.set('_id', this.id);
    this.tag.getTagById(body).subscribe(
      (res:any) => {
        //console.log(res.result)
        this.formGroup.patchValue({producttag_name: res.data.producttag_name});
        this.formGroup.patchValue({_id: res.data._id});     
        var id = this.userdata.getId()
        var added = this.userdata.getUserType()
        this.formGroup.patchValue({userId: id});
        this.formGroup.patchValue({added_by: added});
        this.f   
      },
      err => {
        console.log(err) 
        this.toast.error(err.error.message,'Error')
        this.router.navigateByUrl('/admin/productTag/view')
      }
    )
  }

  public onsubmit(){
    // console.log(this.formGroup.value)
    this.tag.updateTag(this.formGroup.value).subscribe(
      (res:any) => {
        // console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/productTag/view')
      },
      err => {
        // console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }
  
}
