import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class UserdataService {

  secureKey = "resto-app@admin123/O7services@2020"

  constructor() { }

  public setData(userdata) {
    //console.log(userdata)
    var str = '"[';
    userdata.data.roleId.premissions.forEach(element => {
      str = str.concat("'"+element+"',")
    });
    str = str.concat(']"');
    var enc = CryptoJS.AES.encrypt(userdata.token, this.secureKey).toString();
    sessionStorage.setItem('email', userdata.data.email)
    if(userdata.data.is_restaurant){
      sessionStorage.setItem('id', userdata.data.restId)
    }
    else{
      sessionStorage.setItem('id', userdata.data._id)
    }
    sessionStorage.setItem('token', enc)
    //console.log(enc)
    sessionStorage.setItem('usertype', userdata.usertype)
    sessionStorage.setItem('name', userdata.data.name)
    // if(userdata.data.roleId == null){
    //   sessionStorage.setItem('permission', null)
    // }
    // else{
      sessionStorage.setItem('permission', str)
      //console.log(sessionStorage.getItem('permission'))
    //}
  }

  public setFcm(data) {
    sessionStorage.setItem('fcm', data)
  }

  public getEmail() {
    return sessionStorage.getItem('email')
  }

  public getId() {
    return sessionStorage.getItem('id')
  }

  public getName() {
    return sessionStorage.getItem('name')
  }

  public getUserType() {
    return sessionStorage.getItem('usertype')
  }

  public getToken() {

    if (sessionStorage.getItem('token') != null) {
      //console.log(sessionStorage.getItem('token'))
      var dev = CryptoJS.AES.decrypt(sessionStorage.getItem("token"), this.secureKey).toString(CryptoJS.enc.Utf8);
      return dev
    }
    return null
  }

  public getFcm() {
    return sessionStorage.getItem('fcm')
  }

  public removeData() {
    sessionStorage.removeItem('email')
    sessionStorage.removeItem('name')
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('id')
    sessionStorage.removeItem('usertype')
    sessionStorage.removeItem('permission')
  }

  public getPermissions() {
    return sessionStorage.getItem("permission")
    //return "['Category','Subcategory','User','Products',]"
  }

  public roleMatch(allowedRoles): boolean {
    let isMatch = false;
    const userRoles = this.getPermissions()
    
    allowedRoles.forEach(element => {
      if (userRoles.includes(element)) {
        isMatch = true;
        return;
      }
    });
    return isMatch;
  }
}
