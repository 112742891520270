import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { DriverService } from 'src/app/shared/driver/driver.service';
import { UserdataService } from 'src/app/shared/userdata.service';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from "@angular/common";
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-listcashinhand',
  templateUrl: './listcashinhand.component.html',
  styleUrls: ['./listcashinhand.component.css']
})
export class ListcashinhandComponent implements OnInit {

  constructor(private toast: ToastrService,private driver: DriverService,private userdata: UserdataService,private activate: ActivatedRoute, private datePipe: DatePipe) { }

  id=''
  p: number = 1;
  term: string;
  temp = [];
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[] 
  columns = [{ prop: 'Driver Name' }, { name: 'Cash Amount' }, { name: 'Action' }];
  ngOnInit(): void {
    // for page number 1 as offset starts with 0
    //this.table.offset = 0;
    this.getcashInHandList()    
  }

  getcashInHandList(){
    this.driver.cashInHand({}).subscribe(
      (res:any) => {
        //console.log(res.data)
        //this.toast.success(res.message,'Success')
        this.rows = []
        this.temp = []
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
