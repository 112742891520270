import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormBuilder, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserdataService } from 'src/app/shared/userdata.service';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';

@Component({
  selector: 'app-payrestaurant',
  templateUrl: './payrestaurant.component.html',
  styleUrls: ['./payrestaurant.component.css']
})
export class PayrestaurantComponent implements OnInit {

  id=''
  currentWallet = 0
  formGroup = new FormGroup({
    restId: new FormControl('', [Validators.required]),
    entry_by: new FormControl('', [Validators.required]),
    amount_paid: new FormControl('', [Validators.required]),
    txn_type: new FormControl('', [Validators.required]),
    userId: new FormControl('', [Validators.required]),
    txnId: new FormControl('', [Validators.required]),
  })

  constructor(private toast: ToastrService, private formBuilder: FormBuilder, private restaurant: RestaurantService, private router: Router, private userdata: UserdataService, private activate: ActivatedRoute) { }

  ngOnInit(): void {
    var added = this.userdata.getUserType()
    var userId = this.userdata.getId()
    this.formGroup.patchValue({ entry_by: added });
    this.formGroup.patchValue({ userId: userId });
    this.formGroup.patchValue({ txn_type: 2 });
    this.getTransactionList() 
  }

  get f() {
    return this.formGroup.controls 
  }

  getTransactionList(){
    this.id = this.activate.snapshot.paramMap.get('id')
    this.restaurant.restTransactions({'restId':this.id}).subscribe(
      (res:any) => {
        //console.log(res.data)
        //this.toast.success(res.message,'Success')
        this.currentWallet = res.currentWallet
        this.formGroup.patchValue({ restId: this.id });
        this.formGroup.patchValue({ amount_paid: res.currentWallet });
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  public onsubmit() {
    this.restaurant.restPayout(this.formGroup.value).subscribe(
      (res:any) => {
        //console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/restaurant/view');
      },
      err => {
        console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
