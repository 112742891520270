import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrdersService } from 'src/app/shared/orders/orders.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-refund-online-order',
  templateUrl: './refund-online-order.component.html',
  styleUrls: ['./refund-online-order.component.css']
})
export class RefundOnlineOrderComponent implements OnInit {

  formGroup = new FormGroup({
    orderId: new FormControl('', [Validators.required]),
    is_cod: new FormControl(false, [Validators.required]),
    refudmethod: new FormControl('', [Validators.required]),
    is_coupon_refunded: new FormControl(false, [Validators.required]),
    is_admin_bear_refund: new FormControl(false, [Validators.required]),
    is_rest_also_bear: new FormControl(true),
    rest_bear_percentage: new FormControl(10),
    txnId: new FormControl(''),
    userId: new FormControl('', [Validators.required]),
    solution_text: new FormControl(''),
  })

  id = ''
  isRestBearRefund = true
  isRefund = true
  percentageList = [10,20,30,40,50,60,70,80,90,100]

  constructor(private order:OrdersService, private toast:ToastrService, private activate: ActivatedRoute, private router: Router, private userdata: UserdataService) { }

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.formGroup.patchValue({'orderId':this.id})
    this.formGroup.patchValue({'userId':this.userdata.getId()})
  }

  checkRefundType(event){
    if(event.target.value == 'refund'){
      this.isRefund = true
      this.formGroup.patchValue({'is_coupon_refunded':false})
    }else{
      this.isRefund = false
      this.formGroup.patchValue({'is_coupon_refunded':true})
    }
  }

  isRestBear(event){
    if(event.target.value == 'true'){
      this.formGroup.patchValue({'is_rest_also_bear':true})
      this.formGroup.patchValue({'is_admin_bear_refund':false})
      this.formGroup.patchValue({'rest_bear_percentage':100})
      this.isRestBearRefund = true
    }else{
      this.formGroup.patchValue({'is_rest_also_bear':false})
      this.formGroup.patchValue({'is_admin_bear_refund':true})
      this.formGroup.patchValue({'rest_bear_percentage':10})
      this.isRestBearRefund = false
    }

  }

  compareFn(c1: any, c2:any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  get f(){
    return this.formGroup.controls
  }

  onSubmit(){
    //console.log(this.formGroup.get('is_admin_bear_refund').value)
    this.order.refundOrder(this.formGroup.value).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/support/customerIssue/list')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
