<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="active breadcrumb-item">Offers</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Manager</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title float-left">View Offers</h3>
                    <a class="btn btn-primary float-right" [routerLink]="['/admin/offers/add']">Add</a>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Tables -->
                <input
                type="text" class="form-control search-input"
                placeholder="Type to filter the name column..."
                (keyup)="updateFilter($event)"
                />
                <ngx-datatable
                #table
                class="material"
                [columns]="columns"
                [columnMode]="ColumnMode.force"
                [headerHeight]="50"
                [footerHeight]="50"
                rowHeight="auto"
                [limit]="10"
                [rows]="rows"
                #myFilterTable
                >
                    <ngx-datatable-column name="Offer Code" prop="offers_code">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.offers_code}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Offer Type" prop="offers_type">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        <span *ngIf="row.offers_type==1">Fixed Amount </span>
                        <span *ngIf="row.offers_type==2">Percent</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Offer Amount" prop="offer_amount">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.offer_amount}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Min. Order" prop="minimum_order">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.minimum_order}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Max. Discount" prop="maximum_discount">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.maximum_discount}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Offer On" prop="offer_on">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span *ngIf="row.offer_on==1">All </span>
                            <span *ngIf="row.offer_on==2">Global Category</span>
                            <span *ngIf="row.offer_on==3">Product</span>
                            <span *ngIf="row.offer_on==4">Restaurant</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Details">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span *ngIf="row.offer_on==1">All </span>
                            <span *ngIf="row.offer_on==2">{{row.categoryId.category_name}}</span>
                            <span *ngIf="row.offer_on==3">{{row.productId.product_name}}</span>
                            <span *ngIf="row.offer_on==4">{{row.restId.rest_name}}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="End Date" prop="end_date">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{transformDate(row.end_date)}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Image" prop="offers_image">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <img [src]="getSanitizerUrl(row.offers_image)" width="100" height="200" class="img-fluid">
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Status">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span class="badge badge-pill badge-success" *ngIf="row.status=='Enabled'" (click)="changeStatus('Disabled', row._id)">
                                {{ row.status }}
                            </span>
                            <span class="badge badge-pill badge-danger" *ngIf="row.status=='Disabled'" (click)="changeStatus('Enabled', row._id)">
                                {{ row.status }}
                            </span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Added By" prop="added_by">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.added_by}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Action">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <a [routerLink]="['/admin/offers/edit/', row._id]" title="Edit Offers" ><i class="icon icon-editors icon-fw icon-lg icon-edit"></i></a>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
                <!-- /tables -->

            </div>
            <!-- /card body -->
        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->