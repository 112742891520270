<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/admin/restcategory/view']">Restaurant Category</a></li>
        <li class="active breadcrumb-item">Edit</li>
    </ol>
    <!-- <h1 class="dt-page__title">Edit Manager</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title">Edit Restaurant Category</h3>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Form -->
                <form [formGroup]="formGroup" (submit)="onsubmit()">

                    <!-- Form Group -->
                    <!-- <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="restId">Restaurant Name</label>

                        <div class="col-xl-9">
                            <select class="form-control" id="restId" name="restId" formControlName="restId" [compareWith]="compareFn">
                                <option *ngFor="let restaurant of restList" [value]="restaurant._id">{{restaurant.rest_name}}</option>
                            </select>
                            <small class="form-text" *ngIf="f.restId.invalid && (f.restId.dirty || f.restId.touched)">
                                <small *ngIf="f.restId.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div> -->
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="rest_cat_name">Category Name</label>

                        <div class="col-xl-9">
                            <input type="text" class="form-control" id="rest_cat_name" placeholder="Enter Category Name" name="rest_cat_name" formControlName="rest_cat_name">
                            <small class="form-text" *ngIf="f.rest_cat_name.invalid && (f.rest_cat_name.dirty || f.rest_cat_name.touched)">
                                <small *ngIf="f.rest_cat_name.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>
                    <!-- /form group -->

                    <!-- Form Group -->
                    <!-- <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="status">Status</label>

                        <div class="col-xl-9">
                            <select class="form-control" id="status" name="status" formControlName="status" [compareWith]="compareFn">
                                <option *ngFor="let stat of statusList">{{stat}}</option>
                            </select>
                            <small class="form-text" *ngIf="f.status.invalid && (f.status.dirty || f.status.touched)">
                                <small *ngIf="f.status.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div> -->
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <div class="col-xl-9 offset-xl-3">
                            <button type="submit" class="btn btn-primary text-uppercase" [disabled]="!formGroup.valid">Save
                            </button>
                        </div>
                    </div>
                    <!-- /form group -->

                </form>
                <!-- /form -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->