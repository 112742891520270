<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/restaurant/driver/view']">Driver</a></li>
        <li class="active breadcrumb-item">Driver Details</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Manager</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title float-left">View Driver Details</h3>
                    <a class="btn btn-primary float-right" [routerLink]="['/restaurant/driver/add']">Add</a>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">
                <h1 class="p-2 text-center bg-primary text-white"> Basic Info</h1>
                <table class="table table-striped table-bordered">
                    <tr>
                        <td><strong class="col-heading">Name : </strong>{{rows.driver_name}}</td>
                        <td colspan="2"><strong class="col-heading">Email : </strong>{{rows.driver_email}}</td>
                        <td><strong class="col-heading">Phone : </strong>{{rows.driver_phone}}</td>
                                               
                    </tr>
                    <tr>
                        <!-- <td><strong class="col-heading">Payment Per Order : </strong>{{rows.payment_amount}}%</td> -->
                        <td><strong class="col-heading">Visa Type : </strong>{{rows.visa_type}}</td> 
                        <td><strong class="col-heading">Sin Number : </strong>{{rows.sin_number}}</td>
                        <td><strong class="col-heading">Province : </strong>{{rows.province}}</td>
                        <td><strong class="col-heading">Zip Code : </strong>{{rows.zip_code}}</td>                        
                    </tr>
                    <tr>
                        <td colspan="2"><strong class="col-heading">Address : </strong>{{rows.driver_address}}</td>
                        <td colspan="2"><strong class="col-heading">Other Details : </strong>{{rows.other_details}}</td>        
                    </tr>
                </table>
                <hr>
                <h1 class="p-2 text-center bg-primary text-white"> Vehicle Info</h1>
                <table class="table table-striped table-bordered">
                    <tr>
                        <td><strong class="col-heading">Vehicle Type : </strong>{{rows.vehicle_type}}</td>
                        <td><strong class="col-heading">Vehicle Number : </strong>{{rows.vehicle_number}}</td>
                        <td><strong class="col-heading">Driving License Number : </strong>{{rows.driving_lic_number}}</td>
                        <td><strong class="col-heading">Driving License Expire : </strong>{{rows.dlexpire}}</td>                        
                    </tr>
                </table>
                <hr *ngIf="rows.bank_detail_driver.length > 0">
                <h1 class="p-2 text-center bg-primary text-white" *ngIf="rows.bank_detail_driver.length > 0"> Bank Info</h1>
                <table class="table table-striped table-bordered" *ngIf="rows.bank_detail_driver.length > 0">
                    <tr *ngFor="let bank of rows.bank_detail_driver">
                        <td><strong class="col-heading">Account Holder Name : </strong>{{bank.account_holder_name}}</td>
                        <td><strong class="col-heading">Account Number : </strong>{{bank.account_number}}</td>
                        <td><strong class="col-heading">Bank Name : </strong>{{bank.bank_name}}</td>
                        <td><strong class="col-heading">Routing Number : </strong>{{bank.routing_number}}</td>                        
                        <td><strong class="col-heading">Bank Address : </strong>{{bank.bank_address}}</td>                        
                    </tr>
                </table>
                <hr>
                <h1 class="p-2 text-center bg-primary text-white"> Documents</h1>
                <table class="table table-striped table-bordered">
                    <tr>
                        <td rowspan="2"><strong class="col-heading">Picture : </strong>
                            <!-- <img [src]="getSanitizerUrl(rows.driver_pic)" height="100" width="100"> -->
                        </td>  
                        <td><strong class="col-heading">ID Proof : </strong><img [src]="getSanitizerUrl(rows.driver_idproff_pic)" height="100" width="100"></td>
                        <td><strong class="col-heading">Front Of Driving License : </strong><img [src]="getSanitizerUrl(rows.dl_reg_pic)" height="100" width="100"></td>
                        <td><strong class="col-heading">Back Of Driving License : </strong><img [src]="getSanitizerUrl(rows.dl_back_pic)" height="100" width="100"></td>                                     
                    </tr>
                    <tr>
                        <td><strong class="col-heading">Vehicle Registration : </strong><img [src]="getSanitizerUrl(rows.vehicle_reg_pic)" height="100" width="100"></td>                                     
                        <td><strong class="col-heading">Vehicle Insurance : </strong><img [src]="getSanitizerUrl(rows.vehicle_insurance_pic)" height="100" width="100"></td>
                        <td><strong class="col-heading">Additional : </strong><img [src]="getSanitizerUrl(rows.other_id_pic)" height="100" width="100" *ngIf="rows.other_id_pic"></td>
                    </tr>
                </table>
            </div>
            <!-- /card body -->
        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->