import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomizeService } from 'src/app/shared/customize/customize.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-addslider',
  templateUrl: './addslider.component.html',
  styleUrls: ['./addslider.component.css']
})
export class AddsliderComponent implements OnInit {

  formGroup = new FormGroup({
    // title: new FormControl('', [Validators.required]),
    banner_image: new FormControl('', [Validators.required]),
    userId: new FormControl('', [Validators.required]),
    added_by: new FormControl('', [Validators.required]),
    customization_type: new FormControl('1', [Validators.required]),
  })

  constructor(private customize:CustomizeService, private userdata: UserdataService, private toast:ToastrService, private router: Router) { }

  unique = 0
  imageError: string

  ngOnInit(): void {
    var id = this.userdata.getId()
    var added = this.userdata.getUserType()
    this.formGroup.patchValue({userId: id});
    this.formGroup.patchValue({added_by: added});
  }
  
  get f(){
    return this.formGroup.controls
  }

  public onFile(evnt){
    this.imageError = null
    if(evnt.target.files.length > 0)
    {
      const max_size = 2097152
      //const max_size = 1000;
      if (evnt.target.files[0].size > max_size) {
        this.imageError = 'Maximum size allowed is ' + max_size / 1024/1024 + 'MB';
        this.f.banner_image.errors.required ='error'
        return false
      }
      else{
        this.formGroup.patchValue({
          banner_image: evnt.target.files[0]
        })
      }      
    }
  }

  public onsubmit(){
    const data = new FormData()
    // data.append("title",this.formGroup.get("title").value)
    data.append("banner_image",this.formGroup.get("banner_image").value)
    data.append("userId",this.formGroup.get("userId").value)
    data.append("added_by",this.formGroup.get("added_by").value)
    data.append("customization_type",this.formGroup.get("customization_type").value)

    this.customize.addSlider(data).subscribe(
      (res:any) => {
        //console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/customize/slider');
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
        if(err.status==422){
          this.unique = 422
        }
      }
    )
  }

}
