<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="active breadcrumb-item">Support Questions</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Manager</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title float-left">View Reward Details</h3>
                    <a class="btn btn-primary float-right" [routerLink]="['/admin/customize/supportQuestion/add']">Add Questions</a>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Tables -->
                <table class="table table-bordered">
                    <thead>
                        <th>Name</th>
                        <!-- <th>Is Restaurant Fault?</th> -->
                        <th>Action</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of dataList">
                            <td>{{data.value}}</td>
                            <!-- <td><span *ngIf="data.is_rest_fault" class="badge badge-success">Yes</span><span *ngIf="!data.is_rest_fault" class="badge badge-danger">No</span></td> -->
                            <td><a class="icon icon-trash icon-delete" title="Edit" (click)="deleteSupportQuestion(data._id)" role="button"></a></td>
                        </tr>
                        <tr *ngIf="dataList.length == 0">
                            <td colspan="3">No Question.</td>
                        </tr>
                    </tbody>
                </table>
                <!-- /tables -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->