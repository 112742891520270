import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CategoryService } from 'src/app/shared/category/category.service';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { RestcategoryService } from 'src/app/shared/restcategory/restcategory.service';
import { RestsubcategoryService } from 'src/app/shared/restsubcategory/restsubcategory.service';
import { UserdataService } from 'src/app/shared/userdata.service'; 

@Component({
  selector: 'app-editrestsubcategory',
  templateUrl: './editrestsubcategory.component.html',
  styleUrls: ['./editrestsubcategory.component.css']
})
export class EditrestsubcategoryComponent implements OnInit {

  statusList: any = ['Enabled', 'Disabled']

  formGroup = new FormGroup({
    restsubcategory_name: new FormControl('', [Validators.required]),
    updated_Id: new FormControl('', [Validators.required]),
    updated_by: new FormControl('', [Validators.required]),
    _id: new FormControl('', [Validators.required]),
    restId: new FormControl('', [Validators.required]),
    restcatId: new FormControl('', [Validators.required]),
    meta_desc: new FormControl(''),
    meta_keywords: new FormControl(''),
  }) 

  id = ''
  restList=[]
  categoryList=[]

  constructor(private toast: ToastrService, private userdata: UserdataService,private subcategory: RestsubcategoryService,private ngxService: NgxUiLoaderService, private router: Router, private activate: ActivatedRoute, private restaurant: RestaurantService, private category: RestcategoryService) { }

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getDataById()
    this.getRestaurant()
    //this.getCategory()
  }

  get f(){
    return this.formGroup.controls
  }

  compareFn(c1: any, c2:any): boolean {     
    return c1 && c2 ? c1.id === c2.id : c1 === c2; 
  }

  getDataById(){
    let body = new URLSearchParams();
    body.set('_id', this.id);
    this.subcategory.getSubcategoryById(body).subscribe(
      (res:any) => {
        console.log(res.result)
        this.formGroup.patchValue({restsubcategory_name: res.data.restsubcategory_name});    
        this.formGroup.patchValue({_id: res.data._id});     
        this.formGroup.patchValue({meta_desc: res.data.meta_desc});     
        this.formGroup.patchValue({meta_keywords: res.data.meta_keywords});     
        this.formGroup.patchValue({restId: res.data.restId._id});     
        this.formGroup.patchValue({restcatId: res.data.restcatId});     
        var id = this.userdata.getId()
        var added = this.userdata.getUserType()
        this.formGroup.patchValue({updated_Id: id});
        this.formGroup.patchValue({updated_by: added});
        this.getCategory(res.data.restId._id)
        this.f   
      },
      err => {
        console.log(err) 
        this.toast.error(err.error.message,'Error')
        this.router.navigateByUrl('/admin/restsubcategory/view')
      }
    )
  }

  getRestaurant(){
    let body = new URLSearchParams();
    body.set('status', 'Enabled');
    this.restaurant.searchRestaurant(body).subscribe(
      (res:any) => {
        //console.log(res)
        this.restList = res.data
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  }

  getCategory(id){
    // let body = new URLSearchParams();
    // body.set('status', 'Enabled');
    this.category.listCategory({"restId":id}).subscribe(
      (res:any) => {
        console.log(res.data)
        this.categoryList = res.data
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  }

  onChangeCategory(event){
    this.category.listCategory({'restId':event.target.value}).subscribe(
      (res:any) => {
        //console.log(res)
        this.categoryList = res.data
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
    this.formGroup.patchValue({restcatId: ""}); 
  }

  public onsubmit(){
    let data = new URLSearchParams();
    data.set("restsubcategory_name",this.formGroup.get("restsubcategory_name").value)
    data.set("updated_Id",this.formGroup.get("updated_Id").value)
    data.set("_id",this.formGroup.get("_id").value)
    data.set("updated_by",this.formGroup.get("updated_by").value)
    data.set("restId",this.formGroup.get("restId").value)
    data.set("restcatId",this.formGroup.get("restcatId").value)
    data.set("meta_desc",this.formGroup.get("meta_desc").value)
    data.set("meta_keywords",this.formGroup.get("meta_keywords").value)
    this.subcategory.updateSubcategory(data).subscribe(
      (res:any) => {
        // console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/restsubcategory/view')
      },
      err => {
        // console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
