import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { DriverService } from 'src/app/shared/driver/driver.service';
import { OrdersService } from 'src/app/shared/orders/orders.service';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-assign-myorder',
  templateUrl: './assign-myorder.component.html',
  styleUrls: ['./assign-myorder.component.css']
})
export class AssignMyorderComponent implements OnInit {

  constructor(private restaurant:RestaurantService ,private toast: ToastrService, private userdata: UserdataService,private orders:OrdersService,private driver:DriverService, private datePipe: DatePipe, private activate:ActivatedRoute, private router:Router) { }

  id=''
  p: number = 1;
  term: string;
  temp = [];
  restList = []
  orderList:any
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows = []
  columns = [{ prop: 'Driver Name' },{ name: 'Total Orders' },{ name: 'Cash In Hand' }, { name: 'Action' }];
  ngOnInit(): void {
    // this.driverOrderCount()
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getSingleOrder()
    this.getDrivers()
    // for page number 1 as offset starts with 0
    //this.table.offset = 0;    
  }

  getDrivers(){
    this.driver.restListDriver({'restId':this.userdata.getId()}).subscribe(
      (res:any) => {
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  } 

  getSingleOrder(){
    this.orders.getOrderById({'_id':this.id}).subscribe(
      (res:any) => {
        //console.log(res.data)
        //this.toast.success(res.message,'Success')
        this.orderList = res.data
        //console.log(this.orderList)
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.driver_name.toLowerCase().indexOf(val) !== -1 ||      
      !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  transformDate(date){
    return this.datePipe.transform( date,'MM-dd-yyyy');
  }

  assignOrder(event){
    var data = {
      'driverId' : event.target.value,
      'restId' : this.userdata.getId(),
      'orderId'  : this.id
    }
    this.restaurant.restAssignOrderManually(data).subscribe(
      (res:any) => {
        if(res.success){
          this.toast.success(res.message,'Success')
          this.router.navigateByUrl('/restaurant/orders/list')
        }else{
          this.toast.error(res.message,'Error')
        }
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
