import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SupportService } from 'src/app/shared/support/support.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-detaildriverissue',
  templateUrl: './detaildriverissue.component.html',
  styleUrls: ['./detaildriverissue.component.css']
})
export class DetaildriverissueComponent implements OnInit {

  id=''
  issueData:any = ''
  orderData:any = ''
  customerData:any = ''
  constructor(private activate: ActivatedRoute, private toast: ToastrService,private support: SupportService, private userdata: UserdataService,private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getSingleDriverIssue()
  }

  getSingleDriverIssue(){
    this.support.singleDriverIssue({'adminId':this.userdata.getId(),'_id':this.id}).subscribe(
      (res:any) => {
        this.orderData = res.data.orderId
        this.customerData = res.data.customerId
        this.issueData = res.data
      },err =>{
        console.log(err.error.message)
      }
    )
  }
}
