<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/admin/support/customerIssue/list']">Customer Support</a></li>
        <li class="active breadcrumb-item">Issue Details</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Tag</h1> -->
</div>
<!-- /page header -->
<!-- Grid --> 
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <!-- <div class="col-6"><img [src]="getSanitizerUrl(orderData.product_image)" class="img img-fluid img-circle" height="100" width="100"></div> -->
                    <!-- <div class="col-6">
                        <h3 class="dt-card__title float-left">{{orderData.restId.rest_name}}</h3>
                    </div> -->
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">

                <nav ngbNav #nav="ngbNav" class="nav-tabs">
                    <ng-container ngbNavItem>
                        <a ngbNavLink>Issue Detail</a>
                        <ng-template ngbNavContent>
                            <table class="table table-bordered">
                                <tr>
                                    <th>Issue</th>
                                    <td>{{issueData.issue_name}}</td>
                                </tr>
                                <tr>
                                    <th>Description</th>
                                    <td>{{issueData.issue_description}}</td>
                                </tr>
                                <tr>
                                    <th>Customer Refund Type Suggestion</th>
                                    <td>
                                        <span class="badge badge-primary" *ngIf="issueData.refund_type == 1">Refund</span>
                                        <span class="badge badge-info" *ngIf="issueData.refund_type == 2">Coupon</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Admin Refund Type</th>
                                    <td>
                                        <span class="badge badge-primary" *ngIf="!issueData.is_coupon_refunded">Refund</span>
                                        <span class="badge badge-info" *ngIf="issueData.is_coupon_refunded">Coupon</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Status</th>
                                    <td>
                                        <span class="badge badge-success" *ngIf="issueData.is_issued_used">SOLVED</span>
                                        <span class="badge badge-warning" *ngIf="!issueData.is_issued_used">PENDING</span>
                                    </td>
                                </tr>
                                <tr *ngIf="issueData.is_issued_used">
                                    <th>Action</th>
                                    <td>
                                        <span class="badge badge-success" *ngIf="issueData.is_issue_accepted">REFUNDED</span>
                                        <span class="badge badge-warning" *ngIf="!issueData.is_issue_accepted">REJECTED</span>
                                    </td>
                                </tr>
                                <tr *ngIf="issueData.is_issue_accepted && issueData.is_issued_used">
                                    <th>Refund Details</th>
                                    <td>
                                        <span *ngIf="!issueData.is_coupon_refunded">Transaction ID : {{issueData.txnId}}</span>
                                        <span *ngIf="issueData.is_coupon_refunded">Coupon Code : {{issueData.coupon}}</span>
                                    </td>
                                </tr>
                                <tr *ngIf="issueData.orderissue_image != ''">
                                    <th>Image</th>
                                    <td>
                                        <img [src]="getSanitizerUrl(issueData.orderissue_image)" class="img img-fluid h-100">
                                    </td>
                                </tr>
                                <tr *ngIf="issueData.is_issued_used && issueData.is_issue_accepted">
                                    <th>Is Admin at Fault?</th>
                                    <td>
                                        <span *ngIf="issueData.is_admin_bear_refund">YES</span>
                                        <span *ngIf="!issueData.is_admin_bear_refund">NO</span>
                                    </td>
                                </tr>
                                <tr *ngIf="issueData.is_issued_used && issueData.is_issue_accepted">
                                    <th>Is Restaurant at Fault?</th>
                                    <td>
                                        <span *ngIf="issueData.is_rest_also_bear">YES
                                            <span *ngIf="issueData.rest_bear_percentage != null">({{issueData.rest_bear_percentage}} %)</span>
                                        </span>
                                        <span *ngIf="!issueData.is_rest_also_bear">NO</span>
                                    </td>
                                </tr>
                            </table>
                        </ng-template>
                    </ng-container>
                    <ng-container ngbNavItem>
                        <a ngbNavLink>Order Detail</a>
                        <ng-template ngbNavContent>
                            <table class="table table-borderless tables">
                                <tr>
                                    <th>Order Id</th>
                                    <th>Customer Name</th>
                                    <th>Customer Email</th>
                                    <th>Customer Phone</th>
                                </tr>
                                <tr>
                                    <td>{{orderData.orderId}}</td>
                                    <td>{{customerData.customer_name}}</td>
                                    <td>{{customerData.customer_email}}</td>
                                    <td>{{customerData.customer_phone}}</td>
                                </tr>
                                <tr><td colspan="4"><hr></td></tr>
                                <tr>
                                    <th colspan="2">Product Name</th>
                                    <th>Quantity</th>
                                    <th>Add-Ons</th>
                                </tr>
                                <tr *ngFor="let pro of getJson(orderData.products)">
                                    <td colspan="2">{{pro.product_name}}</td>
                                    <td>{{pro.qty}}</td>
                                    <td>
                                        <div *ngFor="let add of pro.addOn" class="mb-1">
                                            {{add.addontype}}<br/>
                                            <span *ngFor="let addsub of add.value" class="badge badge-pill badge-primary mr-1">
                                                {{addsub.valuetype}}-{{addsub.valuesvalue}} 
                                            </span>                                
                                        </div>
                                    </td>
                                </tr>
                                <tr><td colspan="4"><hr></td></tr>
                                <tr>
                                    <th>Sub Total($)</th>
                                    <th>Tax Amount($)</th>
                                    <th>Total($)</th>
                                    <th>Coupon Discount ($)</th>
                                </tr>
                                <tr>
                                    <td>{{orderData.sub_total}}</td>
                                    <td>{{orderData.tax_amount}}</td>
                                    <td>{{orderData.product_total_with_tax}}</td>
                                    <td>{{orderData.coupon_discount}}</td>
                                    
                                </tr>
                                <tr><td colspan="4"><hr></td></tr>
                                <tr>
                                    <th>Delivery Charges($)</th>
                                    <th>Tip Amount($)</th>
                                    <th>Driver Amount ($)</th>
                                    <th>Total Payable($)</th>
                                </tr>
                                <tr>
                                    <td>{{orderData.del_charges}}</td>
                                    <td>{{orderData.tip_amount}}</td>
                                    <td>{{orderData.driver_amount}}</td>
                                    <td>{{orderData.total_paid}}</td>
                                </tr>
                                <tr><td colspan="4"><hr></td></tr>
                                
                                <tr>
                                    <th>Payment Mode</th>
                                    <th>Payment Status</th>
                                    <th>Order Status</th>
                                    <th>Order Date</th>
                                </tr>
                                <tr>
                                    <td>{{orderData.payment_type}}</td>
                                    <td><span class="badge badge-danger" *ngIf="!orderData.payment_status">Pending</span><span class="badge badge-success" *ngIf="orderData.payment_status">Paid</span></td>
                                    <td>
                                        <span class="badge badge-pill badge-primary" *ngIf="orderData.order_current_status==101">
                                            Placed
                                        </span>
                                        <span class="badge badge-pill badge-primary" *ngIf="orderData.order_current_status==102">
                                            Payment Declined
                                        </span>
                                        <span class="badge badge-pill badge-warning" *ngIf="orderData.order_current_status==103">
                                            Cooking
                                        </span>
                                        <span class="badge badge-pill badge-danger" *ngIf="orderData.order_current_status==104">
                                            Rejected
                                        </span>
                                        <span class="badge badge-pill badge-warning" *ngIf="orderData.order_current_status==105">
                                            Cooked
                                        </span>
                                        <span class="badge badge-pill badge-info" *ngIf="orderData.order_current_status==106">
                                            Pickup
                                        </span>
                                        <span class="badge badge-pill badge-info" *ngIf="orderData.order_current_status==107">
                                            On Way
                                        </span>
                                        <span class="badge badge-pill badge-success" *ngIf="orderData.order_current_status==108">
                                            Delivered
                                        </span>
                                        <span class="badge badge-pill badge-danger" *ngIf="orderData.order_current_status==109">
                                            Canceled
                                        </span>
                                        <br/>
                                        <small class="text-danger">{{orderData.order_cancel_reason_rest}}</small>
                                    </td>
                                    <td>{{transformDate(orderData.created_at)}}</td>
                                </tr>
                                <tr><td colspan="4"><hr/></td></tr>
                                <tr>
                                    <th colspan="3">Additional Info.</th>
                                    <th>Order Details</th>
                                </tr>
                                <tr>
                                    <td colspan="3">
                                        <strong *ngIf="orderData.order_instructions != ''">Kitchen Instructions - </strong><label *ngIf="orderData.order_instructions != ''">{{orderData.order_instructions}}</label><br *ngIf="orderData.order_instructions != ''"/>

                                        <strong *ngIf="orderData.driver_instructions != ''">Delivery Instructions - </strong><label *ngIf="orderData.driver_instructions != ''">{{orderData.driver_instructions}}</label><br *ngIf="orderData.driver_instructions != ''"/>

                                        <strong *ngIf="orderData.is_order_contact_less">Is Contact-Less Delivery - </strong><label *ngIf="orderData.is_order_contact_less"><i class="icon icon-check text-success"></i></label><br *ngIf="orderData.is_order_contact_less"/>

                                        <strong *ngIf="orderData.is_bell_to_ring">Don't Ring Bell - </strong><label *ngIf="orderData.is_bell_to_ring"><i class="icon icon-check text-success"></i></label><br *ngIf="orderData.is_bell_to_ring"/>

                                        <strong>Order Type - </strong><label *ngIf="orderData.order_takeaway">Takeaway</label><label *ngIf="!orderData.order_takeaway">Delivery</label>
                                    </td>
                                    <td>
                                        <div *ngIf="orderData.order_cancel_reason_rest != ''">
                                            <strong>Order Rejection Reason -</strong>
                                            {{orderData.order_cancel_reason_rest}}
                                        </div>
                                        <div *ngIf="(orderData.order_preparing_time/60) >1">
                                            <strong>Order Delay - </strong>
                                            <span class="badge badge-danger">{{orderData.order_preparing_time/60 | number:'1.0-0'}} Min</span>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </ng-template>
                    </ng-container>
                </nav>
                <div [ngbNavOutlet]="nav" class="mt-2"></div>


            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->