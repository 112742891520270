<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="active breadcrumb-item">Menu</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Tag</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title float-left">View Menu</h3>
                    <a class="btn btn-primary float-right" [routerLink]="['/admin/menu/add']">Add Menu</a>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->
            <!-- Card Body -->
            <div class="dt-card__body" [formGroup]="myGroup">
                <select class="form-control" id="restId" name="restId" formControlName="restId" (change)="getProductList($event)">
                    <option value="" [selected]="true" [disabled]="true">Select Restaurant</option>
                    <option *ngFor="let restaurant of restList" [value]="restaurant._id">{{restaurant.rest_name}}</option>
                </select>
            </div>
            <!-- /card body -->


            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Tables --> 
                <input
                type="text" class="form-control search-input"
                placeholder="Type to filter the name column..."
                (keyup)="updateFilter($event)"
                />
                <ngx-datatable
                #table
                class="material"
                [columns]="columns"
                [columnMode]="ColumnMode.force"
                [headerHeight]="50"
                [footerHeight]="50"
                rowHeight="auto"
                [limit]="10"
                [rows]="rows"
                #myFilterTable
                >
                    <ngx-datatable-column name="Category Name" prop="restcatId.rest_cat_name">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.restcatId.rest_cat_name}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="SubCategory Name" prop="restsubcatId.restsubcategory_name">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.restsubcatId.restsubcategory_name}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Product Name" prop="product_name">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.product_name}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Image" prop="product_image">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <img [src]="getSanitizerUrl(row.product_image)" width="100" height="200" class="img-fluid">
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Status">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span class="badge badge-pill badge-success" *ngIf="row.status=='Enabled'" (click)="changeStatus('Disabled', row._id)">
                                {{ row.status }}
                            </span>
                            <span class="badge badge-pill badge-danger" *ngIf="row.status=='Disabled'" (click)="changeStatus('Enabled', row._id)">
                                {{ row.status }} 
                            </span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Added By" prop="added_by">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.added_by}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Updated By" prop="updated_by">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.updated_by}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Action">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <a [routerLink]="['/admin/menu/edit/', row._id]" title="Edit Product Tag" ><i class="icon icon-editors icon-fw icon-lg icon-edit"></i></a>&nbsp;<a [routerLink]="['/admin/menu/single/', row._id]" title="View Product" ><i class="icon icon-info icon-fw icon-lg icon-info"></i></a>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
                <!-- /tables -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid --> 