<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/admin/driver/view']">Drivers</a></li>
        <li class="active breadcrumb-item">View Driver Orders</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Tag</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title float-left">View Driver Orders</h3>
                    <!-- <a class="btn btn-primary float-right" [routerLink]="['/admin/menu/add']">Add</a> -->
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->
            <!-- Card Body -->
            <div class="dt-card__body d-flex" [formGroup]="myGroup">
                <div class="col-5">
                    Search By Restaurant:
                    <select class="form-control" id="restId" name="restId" formControlName="restId">
                    <option value="" [selected]="true">All</option>
                    <option *ngFor="let restaurant of restList" [value]="restaurant._id">{{restaurant.rest_name}}</option>
                    </select>
                </div>
                <div class="col-3">
                    Select Start Date:
                    <input type="date" class="form-control" id="startDate" name="startDate" formControlName="startDate">
                </div>
                <div class="col-3">
                    Select End Date:
                    <input type="date" class="form-control" id="endDate" name="endDate" formControlName="endDate">
                </div>
                <div class="col-1">
                    <br>
                    <button class="btn btn-primary" (click)="getOrderListSearch()">Search</button>
                </div>
            </div>
            <!-- /card body -->


            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Tables --> 
                <input
                type="text" class="form-control search-input"
                placeholder="Type to filter the name column..."
                (keyup)="updateFilter($event)"
                />
                <ngx-datatable
                #table
                class="material"
                [columns]="columns"
                [columnMode]="ColumnMode.force"
                [headerHeight]="50"
                [footerHeight]="50"
                rowHeight="auto"
                [limit]="10"
                [rows]="rows"
                #myFilterTable
                >
                    <ngx-datatable-column name="Restaurant Name" prop="restId.rest_name">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.restId.rest_name}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Order Name" prop="order_name">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.order_name}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Payment Type" prop="payment_type">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.payment_type}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Total" prop="total_paid">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.total_paid | currency:'CAD':'symbol-narrow'}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Driver Amount" prop="driver_amount">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.driver_amount | currency:'CAD':'symbol-narrow'}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Tip Amount" prop="tip_amount">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.tip_amount | currency:'CAD':'symbol-narrow'}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Admin Commission" prop="admin_total_earning">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.admin_total_earning | currency:'CAD':'symbol-narrow'}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Status">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span class="badge badge-pill badge-primary" *ngIf="row.order_current_status==101">
                                Order Placed
                            </span>
                            <span class="badge badge-pill badge-primary" *ngIf="row.order_current_status==102">
                                Payment Pending
                            </span>
                            <span class="badge badge-pill badge-warning" *ngIf="row.order_current_status==103">
                                Order Preparing
                            </span>
                            <span class="badge badge-pill badge-danger" *ngIf="row.order_current_status==104">
                                Order Rejected
                            </span>
                            <span class="badge badge-pill badge-warning" *ngIf="row.order_current_status==105">
                                Order Prepared
                            </span>
                            <span class="badge badge-pill badge-info" *ngIf="row.order_current_status==106">
                                Pickup
                            </span>
                            <span class="badge badge-pill badge-info" *ngIf="row.order_current_status==107">
                                On Way
                            </span>
                            <span class="badge badge-pill badge-success" *ngIf="row.order_current_status==108">
                                Delivered
                            </span>
                            <span class="badge badge-pill badge-danger" *ngIf="row.order_current_status==109">
                                Canceled
                            </span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Date" prop="created_at">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                        {{transformDate(row.created_at)}}
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
                <!-- /tables -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid --> 