import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CuisineService } from 'src/app/shared/cuisine/cuisine.service';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-listcuisine',
  templateUrl: './listcuisine.component.html',
  styleUrls: ['./listcuisine.component.css']
})
export class ListcuisineComponent implements OnInit {

  constructor(private toast: ToastrService,private cuisine: CuisineService) { }

  p: number = 1;
  term: string;
  temp = [];
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]
  columns = [{ prop: 'Name' }, { name: 'Meta Description' }, { name: 'Meta Keywords' }, { name: 'Status' }, { name: 'Added By' }, { name: 'Action' }];
  ngOnInit(): void {
    // for page number 1 as offset starts with 0
    //this.table.offset = 0;
    this.getCuisineList()

  }

  getCuisineList(){
    this.cuisine.listCuisine({}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.cuisine_name.toLowerCase().indexOf(val) !== -1 ||
      d.meta_desc.toLowerCase().indexOf(val) !== -1 ||
      d.meta_keywords.toLowerCase().indexOf(val) !== -1 ||
      d.status.toLowerCase().indexOf(val) !== -1 ||
      !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  changeStatus(val,id){
    //console.log(id)
    this.cuisine.updateCuisine({'_id':id,'status':val}).subscribe(
      (res:any) => {
        this.toast.success(res.message,'Success')
        this.getCuisineList()
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
        this.getCuisineList()
      }
    )
  }

}
