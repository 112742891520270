import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ManagerService } from 'src/app/shared/manager/manager.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {

  formGroup = new FormGroup({
    oldpassword: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
  }) 
  id = ''
  constructor(private toast: ToastrService,private manager:ManagerService, private router: Router, private activate: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.formGroup.patchValue({email: this.id});
    console.log(this.formGroup.value)
  }

  get f(){
    return this.formGroup.controls
  }

  public onsubmit(){
    this.manager.updatePassword(this.formGroup.value).subscribe(
      (res:any) => {
        this.toast.success(res.message,'Success')
        //this.formGroup.reset()
        this.router.navigateByUrl('/admin/users/view');
      },
      err => {
        this.toast.error(err.error.message,'Error')
        //this.formGroup.reset()
      }
    )
  }

}
