import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { JsService } from 'src/app/shared/js/js.service';
import { AuthService } from 'src/app/shared/user/auth.service';
import { UserdataService } from 'src/app/shared/userdata.service';
import Swal from 'src/assets/node_modules/sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
    fcm: new FormControl(''),
  })
  constructor(private userdata:UserdataService, private router:Router,private userauth:AuthService, private toast:ToastrService) { }

  ngOnInit(): void {
    if(this.userdata.getToken() != null){
      this.router.navigateByUrl('admin/home')
    }
    this.loginForm.patchValue({'fcm':this.userdata.getFcm()})
    //console.log(this.loginForm.get('fcm'))
  }

  public onClick(){
    this.userauth.login(this.loginForm.value).subscribe(
      (res: any) => {
        this.toast.success('Login Successfully','Success')
        //console.log(res)
        this.userdata.setData(res)
        this.router.navigateByUrl('admin/home')
      },
      err => {
        this.toast.error(err.error.message,'Error')
        //console.log(err)
      }
    )
    // if (this.loginForm.get('email').value == 'navneesh') {
    //     this.userdata.setData(this.loginForm.value)
    //     this.router.navigateByUrl('admin/home')
    // } else {
    //   Swal('Opps','Wrong Email/Password','error')
    // }
  }
}
