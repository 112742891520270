import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserdataService } from 'src/app/shared/userdata.service'; 
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';

@Component({
  selector: 'app-sendagreement',
  templateUrl: './sendagreement.component.html',
  styleUrls: ['./sendagreement.component.css']
})
export class SendagreementComponent implements OnInit {

  constructor(private activate: ActivatedRoute, private restaurant: RestaurantService,private toast: ToastrService, private router:Router, private userdata: UserdataService) { }

  formGroup = new FormGroup({
    restId: new FormControl('', [Validators.required]),
    staffId: new FormControl('', [Validators.required]),
    agreement_per: new FormControl('', [Validators.required]),
  })

  id = ''
  restName = ''

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getDataById()
  }

  get f(){
    return this.formGroup.controls
  }

  getDataById(){
    this.restaurant.getRestaurantById({'_id':this.id}).subscribe(
      (res:any) => {
        //console.log(res.result)  
        this.formGroup.patchValue({current_status: res.data.current_status});     
        this.formGroup.patchValue({restId: res.data._id});     
        this.restName = res.data.rest_name     
        // this.formGroup.patchValue({category_image: res.category_image});     
        var id = this.userdata.getEmail()
        this.formGroup.patchValue({staffId: id});
        this.f   
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        this.router.navigateByUrl('/admin/restaurant/listaggreement')
      }
    )
  }

  public onsubmit(){
    let body = new URLSearchParams();
    body.set('restId', this.formGroup.get("restId").value);
    body.set('staffId', this.formGroup.get("staffId").value);
    body.set('agreement_per', this.formGroup.get("agreement_per").value);
    //console.log(data)
    this.restaurant.sendAgreement(body).subscribe(
      (res:any) => {
        // console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/restaurant/listaggreement')
      },
      err => {
        // console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
