<!-- Page Header -->
<div class="dt-page__header">
	<ol class="breadcrumb">
		<li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
		<li class="breadcrumb-item"><a [routerLink]="['/restaurant/offers/list']">Offers</a></li>
		<li class="active breadcrumb-item">Add</li>
	</ol>
	<!-- <h1 class="dt-page__title">Add Offers</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
	<!-- Grid Item -->
	<div class="col-xl-12">
		<!-- Form -->
		<form [formGroup]="formGroup" (submit)="onsubmit()">
			<!-- Card -->
			<div class="dt-card dt-card__full-height">
				<!-- Card Header -->
				<div class="dt-card__header">
					<!-- Card Heading -->
					<div class="dt-card__heading">
						<h3 class="dt-card__title">Offers</h3>
					</div>
					<!-- /card heading -->
				</div>
				<!-- /card header -->
				<!-- Card Body -->
				<div class="dt-card__body">
					<div class="form-row">
						<div class="col-sm-6 mb-3">
							<label for="offers_type">Offer Type</label>
							<select class="form-control" id="offers_type" name="offers_type" formControlName="offers_type">
                                <option value="" [selected]="true" [disabled]="true">Select Offer Type</option>
                                <option *ngFor="let type of offerType" [value]="type.value">{{type.name}}</option>
                            </select>
                                <small class="form-text"
								*ngIf="f.offers_type.invalid && (f.offers_type.dirty || f.offers_type.touched)">
								<small *ngIf="f.offers_type.errors">Invalid Value</small> </small>
						</div>
						<div class="col-sm-6 mb-3">
							<label for="offer_amount">Offer Amount ($)</label>
							<input type="text" class="form-control" id="offer_amount" placeholder="Offer Amount" name="offer_amount" formControlName="offer_amount"> <small class="form-text"
								*ngIf="f.offer_amount.invalid && (f.offer_amount.dirty || f.offer_amount.touched)">
								<small *ngIf="f.offer_amount.errors">Enter Number only . Eg: 1, 1.1, 1.12</small> </small>
						</div>
					</div>
					<div class="form-row">
						<div class="col-sm-6 mb-3">
							<label for="minimum_order">Minimum Order ($)</label>
							<input type="text" class="form-control" id="minimum_order" placeholder="Enter Minimum Order" name="minimum_order" formControlName="minimum_order"> <small class="form-text"
								*ngIf="f.minimum_order.invalid && (f.minimum_order.dirty || f.minimum_order.touched)">
								<small *ngIf="f.minimum_order.errors">Enter Number only . Eg: 1, 1.1, 1.12</small> </small>
						</div>
						<div class="col-sm-6 mb-3">
							<label for="offers_code">Offer Code</label>
							<input type="text" class="form-control" id="offers_code" placeholder="Enter Offer Code" name="offers_code" formControlName="offers_code"> <small class="form-text"
								*ngIf="f.offers_code.invalid && (f.offers_code.dirty || f.offers_code.touched)">
								<small *ngIf="f.offers_code.errors">Invalid Value</small> </small>
						</div>
                    </div>
                    <div class="form-row">
						<div class="col-sm-4 mb-3">
							<label for="maximum_discount">Maximum Discount ($)</label>
							<input type="text" class="form-control" id="maximum_discount" placeholder="Enter Maximum Discount" name="maximum_discount" formControlName="maximum_discount"> <small class="form-text"
								*ngIf="f.maximum_discount.invalid && (f.maximum_discount.dirty || f.maximum_discount.touched)">
								<small *ngIf="f.maximum_discount.errors">Enter Number only . Eg: 1, 1.1, 1.12</small> </small>
						</div>
						<div class="col-sm-4 mb-3">
							<label for="offers_image">Offer Image</label>
							<input type="file" class="form-control" id="offers_image" name="offers_image" formControlName="offers_image" (change)="onFile($event)">
                                <small class="form-text"
								*ngIf="f.offers_image.invalid && (f.offers_image.dirty || f.offers_image.touched)">
								<small *ngIf="f.offers_image.errors">Invalid Value</small> </small>
						</div>
						<div class="col-sm-4 mb-3">
							<label for="end_date">Offer End Date</label>
							<input type="date" class="form-control" id="end_date" name="end_date" formControlName="end_date">
                                <small class="form-text"
								*ngIf="f.end_date.invalid && (f.end_date.dirty || f.end_date.touched)">
								<small *ngIf="f.end_date.errors">Invalid Value</small> </small>
						</div>
					</div>
                    <div class="form-row">
						<div class="col-sm-12 mb-3">
							<label for="offer_desc">Offer Description</label>
							<textarea class="form-control" id="offer_desc" name="offer_desc" formControlName="offer_desc" placeholder="Eg. Get Flat $10 off on your order above $50 etc."></textarea>
                                <small class="form-text"
								*ngIf="f.offer_desc.invalid && (f.offer_desc.dirty || f.offer_desc.touched)">
								<small *ngIf="f.offer_desc.errors">Invalid Value</small> </small>
						</div>
					</div>
                    <div class="form-row">
						<div class="col-xl-8 offset-xl-4">
							<button type="submit" class="btn btn-primary text-uppercase"
								[disabled]="!formGroup.valid">Save
							</button>
						</div>
					</div>
				</div>
				<!-- /card body -->
			</div>
			<!-- /card -->
		</form>
		<!--/form-->
	</div>
	<!-- /grid item -->
</div>
<!-- /grid -->