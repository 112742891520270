<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/admin/customer/view']">View Customers</a></li>
        <li class="active breadcrumb-item">Order Details</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Tag</h1> -->
</div>
<!-- /page header -->
<!-- Grid --> 
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <!-- <div class="col-6"><img [src]="getSanitizerUrl(rows.product_image)" class="img img-fluid img-circle" height="100" width="100"></div> -->
                    <!-- <div class="col-6">
                        <h3 class="dt-card__title float-left">{{rows.restId.rest_name}}</h3>
                    </div> -->
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body"> 

                <nav ngbNav #nav="ngbNav" class="nav-tabs">
                    <ng-container ngbNavItem>
                        <a ngbNavLink>Order Details</a>
                        <ng-template ngbNavContent>
                            <!-- Tables -->
                            <input type="text" class="form-control search-input"
                                placeholder="Type to filter the name column..." (keyup)="updateFilter($event)" />
                            <ngx-datatable #table class="material" [columns]="columns" [columnMode]="ColumnMode.force"
                                [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [limit]="10" [rows]="rows"
                                #myFilterTable>
                                <ngx-datatable-column name="Restaurant Name" prop="restId.rest_name">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        {{row.restId.rest_name}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Order Name" prop="orderId">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        {{row.orderId}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Payment Type" prop="payment_type">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        {{row.payment_type}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Total" prop="total_paid">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        {{row.total_paid |currency:'CAD':'symbol-narrow'}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Driver" prop="driverId.driver_name">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <span *ngIf="row.driverId">
                                            {{row.driverId.driver_name}}
                                        </span>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Status">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <span class="badge badge-pill badge-primary"
                                            *ngIf="row.order_current_status==101">
                                            Placed
                                        </span>
                                        <span class="badge badge-pill badge-danger"
                                            *ngIf="row.order_current_status==102">
                                            Payment Declined
                                        </span>
                                        <span class="badge badge-pill badge-warning"
                                            *ngIf="row.order_current_status==103">
                                            Cooking
                                        </span>
                                        <span class="badge badge-pill badge-danger"
                                            *ngIf="row.order_current_status==104">
                                            Rejected
                                        </span>
                                        <span class="badge badge-pill badge-warning"
                                            *ngIf="row.order_current_status==105">
                                            Cooked
                                        </span>
                                        <span class="badge badge-pill badge-info" *ngIf="row.order_current_status==106">
                                            Pickup
                                        </span>
                                        <span class="badge badge-pill badge-info" *ngIf="row.order_current_status==107">
                                            On Way
                                        </span>
                                        <span class="badge badge-pill badge-success"
                                            *ngIf="row.order_current_status==108">
                                            Delivered
                                        </span>
                                        <span class="badge badge-pill badge-danger"
                                            *ngIf="row.order_current_status==109">
                                            Canceled
                                        </span>
                                        <span class="badge badge-pill badge-danger"
                                            *ngIf="row.order_current_status==110">
                                            TimeOut
                                        </span>
                                        <span class="badge badge-pill badge-danger"
                                            *ngIf="row.order_current_status==111">
                                            Refunded
                                        </span>
                                        <span class="badge badge-pill badge-danger"
                                            *ngIf="row.order_current_status==112">
                                            Cancel & Refund
                                        </span>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Date" prop="created_at">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        {{transformDate(row.created_at)}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Action">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <a [routerLink]="['/admin/orders/single/', row._id]" title="View Order"><i
                                                class="icon icon-eye icon-fw icon-lg icon-view"></i></a>
                                    </ng-template>
                                </ngx-datatable-column>
                            </ngx-datatable>
                            <!-- /tables -->
                        </ng-template>
                    </ng-container>
                    <ng-container ngbNavItem>
                        <a ngbNavLink>Fav Restaurant</a>
                        <ng-template ngbNavContent>
                            <table class="table table-bordered table-sm table-striped">
                                <thead>
                                    <tr>
                                        <th>Restaurant Logo</th>
                                        <th>Restaurant Name</th>
                                        <th>Restaurant Email</th>
                                        <th>Restaurant Phone</th>
                                        <th>Restaurant Address</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let fav of favRestList">
                                        <td><img [src]="getSanitizerUrl(fav.restId.logo)" width="100" height="200"
                                                class="img-fluid"></td>
                                        <td>{{fav.restId.rest_name}}</td>
                                        <td>{{fav.restId.person_email}}</td>
                                        <td>{{fav.restId.rest_phone}}</td>
                                        <td>{{fav.restId.rest_address}}</td>
                                    </tr>
                                    <tr *ngIf="favRestList.length <= 0">
                                        <th colspan="5">No Favourite Restaurant.</th>
                                    </tr>
                                </tbody>
                            </table>
                        </ng-template>
                    </ng-container>
                    <ng-container ngbNavItem>
                        <a ngbNavLink>Reward History</a>
                        <ng-template ngbNavContent>
                            <table class="table table-bordered table-sm table-striped">
                                <thead>
                                    <th>Type</th>
                                    <th>Code</th>
                                    <th>Points</th>
                                    <th>Order Amount</th>
                                    <th>Balance</th>
                                    <th>Date</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let history of walletHistory">
                                        <td>
                                            <span *ngIf="history.txn_type == 1" class="badge badge-success">Credited</span>
                                            <span *ngIf="history.txn_type == 2" class="badge badge-danger">Redeemed</span>
                                        </td>
                                        <td>
                                            {{history.coupounCodeGenerated}}
                                        </td>
                                        <td>
                                            {{history.reward_earn}}
                                        </td>
                                        <td>
                                            <span *ngIf="history.orderId != null">{{history.orderId.total_paid | currency :'CAD':'symbol-narrow'}}</span>
                                        </td>
                                        <td>
                                            {{history.current_rewards}}
                                        </td>
                                        <td>
                                            {{history.created_at | date : 'shortDate'}}
                                        </td>
                                    </tr>
                                    <tr *ngIf="walletHistory.length <= 0">
                                        <th colspan="6">No Reward History.</th>
                                    </tr>
                                </tbody>
                            </table>
                        </ng-template>
                    </ng-container>
                </nav>
                <div [ngbNavOutlet]="nav" class="mt-2"></div>


            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->