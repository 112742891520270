import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata.service';

@Injectable({
  providedIn: 'root'
})
export class ManagerService {

  BaseUrl = ''
  Token = ''

  constructor(private http: HttpClient, @Inject('BASE_URL') _base,private userdata: UserdataService) {
    this.BaseUrl = _base
    this.Token = userdata.getToken()
  }

  public addManager(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/registersubadmin",form, { headers: header_object})
  }

  // public allManager(){
  //   var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
  //   return this.http.post(this.BaseUrl + "/api/allsubadmin",{ headers: header_object})
  // }

  public allManager(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/alloperators",form,{ headers: header_object})
  }

  public getManagerById(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/searchsubadmin",form, { headers: header_object})
  }

  public updateManager(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/updatesubadmin", form, { headers: header_object})
  }

  public updatePassword(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/updatepassword", form, { headers: header_object})
  }

  public updateStatus(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/api/statuschangersubadmin", form, { headers: header_object})
  }
}
