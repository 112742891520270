import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserdataService } from 'src/app/shared/userdata.service';
import { ServiceService } from 'src/app/shared/service/service.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';

@Component({
  selector: 'app-addservice',
  templateUrl: './addservice.component.html',
  styleUrls: ['./addservice.component.css']
})
export class AddserviceComponent implements OnInit {
  formGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    userId: new FormControl('', [Validators.required]),
    added_by: new FormControl('', [Validators.required]),
    meta_desc: new FormControl('', [Validators.required]),
    meta_keywords: new FormControl('', [Validators.required]), 
  }) 

  constructor(private toast: ToastrService, private userdata: UserdataService,private service: ServiceService,private ngxService: NgxUiLoaderService, private router: Router) { }

  ngOnInit(): void {
    var id = this.userdata.getId()
    var added = this.userdata.getUserType()
    this.formGroup.patchValue({userId: id});
    this.formGroup.patchValue({added_by: added});
  }

  get f(){
    return this.formGroup.controls
  }

  public onsubmit(){
    this.ngxService.start()
    this.service.addService(this.formGroup.value).subscribe(
      (res:any) => {
        this.ngxService.stop()
        this.toast.success(res.message,'Success')
        //this.formGroup.reset()
        this.router.navigateByUrl('/admin/service/view');
      },
      err => {
        this.ngxService.stop()
        this.toast.error(err.error.message,'Error')
        //this.formGroup.reset()
      }
    )
  }
}
