import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable'; 
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from "@angular/common";

@Component({
  selector: 'app-listremarks',
  templateUrl: './listremarks.component.html',
  styleUrls: ['./listremarks.component.css'],
  providers: [DatePipe]
})
export class ListremarksComponent implements OnInit {

  row = []
  id=''
  constructor(private toast: ToastrService,private restaurant: RestaurantService,private activate: ActivatedRoute, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getRestaurantById(this.id)    
  }

  transformDate(date)
  {
    return this.datePipe.transform( date,'MMM d, y, h:mm:ss a');
  }

  getRestaurantById(id){
    this.restaurant.getRestaurantById({'_id':id}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.row = res.data.remarks
        // console.log(this.row)
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
