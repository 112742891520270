<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/restaurant/menu/view']">Menu</a></li>
        <li class="active breadcrumb-item">Edit</li>
    </ol>
    <!-- <h1 class="dt-page__title">Edit Manager</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title">Edit Menu</h3>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Form -->
                <form [formGroup]="formGroup" (submit)="onsubmit()">

                    <div class="form-row">
                        <div class="col-sm-4 mb-3">
                            <label for="catId">Global Category</label>
                            <select class="form-control" id="catId" name="catId" formControlName="catId">
                                <option value="" [selected]="true" [disabled]="true">Select Category</option>
                                <option *ngFor="let gcategory of gcategoryList" [value]="gcategory._id">
                                    {{gcategory.category_name}}</option>
                            </select>
                            <small class="form-text" *ngIf="f.catId.invalid && (f.catId.dirty || f.catId.touched)">
                                <small *ngIf="f.catId.errors.required">Invalid Value</small>
                            </small>
                        </div>
                        <div class="col-sm-4 mb-3">
                            <label for="restcatId">Category</label>
                            <select class="form-control" id="restcatId" name="restcatId" formControlName="restcatId"
                                (change)="getSubcategoryList()">
                                <option value="" [selected]="true" [disabled]="true">Select Category</option>
                                <option *ngFor="let category of categoryList" [value]="category._id">
                                    {{category.rest_cat_name}}</option>
                            </select>
                            <small class="form-text"
                                *ngIf="f.restcatId.invalid && (f.restcatId.dirty || f.restcatId.touched)">
                                <small *ngIf="f.restcatId.errors.required">Invalid Value</small>
                            </small>
                        </div>
                        <div class="col-sm-4 mb-3">
                            <label for="restsubcatId">Subcategory</label>
                            <select class="form-control" id="restsubcatId" name="restsubcatId"
                                formControlName="restsubcatId">
                                <option value="" [selected]="true" [disabled]="true">Select Sub-Category</option>
                                <option *ngFor="let subcategory of subcategoryList" [value]="subcategory._id">
                                    {{subcategory.restsubcategory_name}}</option>
                            </select>
                            <small class="form-text"
                                *ngIf="f.restsubcatId.invalid && (f.restsubcatId.dirty || f.restsubcatId.touched)">
                                <small *ngIf="f.restsubcatId.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col-sm-6 mb-3">
                            <label for="product_name">Name</label>
                            <input type="text" class="form-control" id="product_name" placeholder="Enter Name"
                                name="product_name" formControlName="product_name">
                            <small class="form-text"
                                *ngIf="f.product_name.invalid && (f.product_name.dirty || f.product_name.touched)">
                                <small *ngIf="f.product_name.errors.required">Invalid Value</small>
                            </small>
                        </div>
                        <div class="col-sm-6 mb-3">
                            <label for="product_image">Product Image</label>
                            <input type="file" name="product_image" class="form-control" (change)="onFile($event)"
                                formControlName="product_image">
                            <small class="form-text"
                                *ngIf="f.product_image.invalid && (f.product_image.dirty || f.product_image.touched)">
                                <small *ngIf="f.product_image.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col-sm-8 mb-3">
                            <label for="product_description">Product Description</label>
                            <textarea class="form-control" id="product_description"
                                placeholder="Enter Product Description" name="product_description"
                                formControlName="product_description"></textarea>
                            <small class="form-text"
                                *ngIf="f.product_description.invalid && (f.product_description.dirty || f.product_description.touched)">
                                <small *ngIf="f.product_description.errors.required">Invalid Value</small>
                            </small>
                        </div>
                        <div class="col-sm-4 mb-3">
                            <label for="veg_type">Food Type</label><br />
                            <!-- Radio Button -->
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" formControlName="veg_type" [(ngModel)]="vegOption" class="custom-control-input" id="Vegan"
                                    value="Vegan">
                                <label class="custom-control-label" for="Vegan">Vegan</label>
                            </div>
                            <!-- /radio button -->
                            <!-- Radio Button -->
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" formControlName="veg_type" [(ngModel)]="vegOption" class="custom-control-input" id="Veg"
                                    value="Veg">
                                <label class="custom-control-label" for="Veg">Veg</label>
                            </div>
                            <!-- /radio button -->
                            <small class="form-text"
                                *ngIf="f.veg_type.invalid && (f.veg_type.dirty || f.veg_type.touched)">
                                <small *ngIf="f.veg_type.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col-sm-12 mb-3">
                            <label for="meta_keywords">Other Tags</label>
                            <tag-input [identifyBy]="'_id'" [displayBy]="'producttag_name'"
                                [onlyFromAutocomplete]="true" formControlName="other_tags" placeholder="+Other tags"
                                secondaryPlaceholder="Enter Tag" [ngModel]="defaultTag">
                                <tag-input-dropdown [autocompleteItems]="tagList" [showDropdownIfEmpty]="true"
                                    [dynamicUpdate]="false" [identifyBy]="'_id'" [displayBy]="'producttag_name'">
                                </tag-input-dropdown>
                            </tag-input>
                            <small class="form-text"
                                *ngIf="f.other_tags.invalid && (f.other_tags.dirty || f.other_tags.touched)">
                                <small *ngIf="f.other_tags.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div>

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <div class="col-xl-8 offset-xl-4">
                            <button type="submit" class="btn btn-primary text-uppercase"
                                [disabled]="!formGroup.valid">Save
                            </button>
                        </div>
                    </div>
                    <!-- /form group -->

                </form>
                <!-- /form -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->