import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrdersService } from 'src/app/shared/orders/orders.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-reject-refund-order',
  templateUrl: './reject-refund-order.component.html',
  styleUrls: ['./reject-refund-order.component.css']
})
export class RejectRefundOrderComponent implements OnInit {

  formGroup = new FormGroup({
    orderId: new FormControl('', [Validators.required]),
    userId: new FormControl('', [Validators.required]),
    is_issued_used : new FormControl(true),
    is_issue_accepted : new FormControl(false),
    solution_text: new FormControl('',[Validators.required]),
  })

  id = ''

  constructor(private order:OrdersService, private toast:ToastrService, private activate: ActivatedRoute, private router: Router, private userdata: UserdataService) { }

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.formGroup.patchValue({'orderId':this.id})
    this.formGroup.patchValue({'userId':this.userdata.getId()})
  }

  get f(){
    return this.formGroup.controls
  }

  onSubmit(){
    //console.log(this.formGroup.get('is_admin_bear_refund').value)
    this.order.rejectSupportOrder(this.formGroup.value).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/support/customerIssue/list')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }


}
