import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ManagerService } from 'src/app/shared/manager/manager.service';
import { Router } from '@angular/router'; 

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.css']
})
export class AdduserComponent implements OnInit {
  formGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required, Validators.pattern("[0-9]{10}")]),
    address: new FormControl('', [Validators.required]),
    proof_type: new FormControl('', [Validators.required]),
    proof_number: new FormControl('', [Validators.required]),
  })  

  constructor(private toast: ToastrService,private manager:ManagerService, private router: Router) { }

  ngOnInit(): void {
  }
  unique = 0
  get f(){
    return this.formGroup.controls
  }

  public onsubmit(){
    this.manager.addManager(this.formGroup.value).subscribe(
      (res:any) => {
        this.toast.success(res.message,'Success')
        //this.formGroup.reset()
        this.router.navigateByUrl('/admin/users/view');
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
        if(err.status==403){
          this.unique = 403
        }
        //this.formGroup.reset()
      }
    )
  }
}
 