import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { DriverService } from 'src/app/shared/driver/driver.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-list-mydriver',
  templateUrl: './list-mydriver.component.html',
  styleUrls: ['./list-mydriver.component.css']
})
export class ListMydriverComponent implements OnInit {

  imageURL = ''
  constructor(private toast: ToastrService,private driver: DriverService,private userdata: UserdataService,private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl) { 
    this.imageURL = _imageurl
  }

  p: number = 1;
  term: string;
  temp = [];
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]
  columns = [{ prop: 'Name' }, { name: 'Email' }, { name: 'Phone' }, { name: 'Bank Account' }, { name: 'Status' },{ name: 'Duty' }, { name: 'Action' }];
  ngOnInit(): void {
    // for page number 1 as offset starts with 0
    //this.table.offset = 0;
    this.getDriverList()
    
  }

  getDriverList(){
    this.driver.restListDriver({'restId':this.userdata.getId(),'alldriver':true}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.rows = res.data
        this.temp = this.rows
        //console.log(res.data)
      },
      err => {
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  public getSanitizerUrl(photoname){
    return this.trusturl.bypassSecurityTrustUrl(this.imageURL + photoname)
  }  

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.driver_name.toLowerCase().indexOf(val) !== -1 ||
      d.driver_email.toLowerCase().indexOf(val) !== -1 ||
      d.status.toLowerCase().indexOf(val) !== -1 ||
      !val;
    }); 
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  } 

  changeStatus(val,id){
    var user = this.userdata.getId()
    this.driver.restUpdateDriverStatus({'restId':user,'driverId':id,'status':val}).subscribe(
      (res:any) => {
        this.toast.success(res.message,'Success')
        this.getDriverList()
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
        this.getDriverList()
      }
    )
  }

}
