import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { UserdataService } from 'src/app/shared/userdata.service';
import { CustomizeService } from 'src/app/shared/customize/customize.service';

@Component({
  selector: 'app-listtoprestaurant',
  templateUrl: './listtoprestaurant.component.html',
  styleUrls: ['./listtoprestaurant.component.css']
})
export class ListtoprestaurantComponent implements OnInit {

  imageURL = ''
  userId = ''
  constructor(private toast: ToastrService,private customize: CustomizeService,private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl, private userdata:UserdataService) { 
    this.imageURL = _imageurl
  }  
  
  rows:any
  ngOnInit(): void {
    // for page number 1 as offset starts with 0
    //this.table.offset = 0;
    this.getRestaurantList()
    
  }

  getRestaurantList(){
    this.userId = this.userdata.getId()
    this.customize.listRestaurant({}).subscribe(
      (res:any) => {
        //this.toast.success(res.message,'Success')
        this.rows = res.toprest
      },
      err => {
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  deleteRestaurantList(id){
    this.userId = this.userdata.getId()
    this.customize.deleteRestaurant({'userId':this.userId,'_id':id}).subscribe(
      (res:any) => {
        this.getRestaurantList()
        this.toast.success(res.message,'Success')
      },
      err => {
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  public getSanitizerUrl(photoname){
    return this.trusturl.bypassSecurityTrustUrl(this.imageURL + photoname)
  } 


}
