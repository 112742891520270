import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata.service';

@Injectable({
  providedIn: 'root'
})
export class AddsService {

  BaseUrl = ''
  Token = ''

  constructor(private http: HttpClient, @Inject('BASE_URL') _base,private userdata: UserdataService) {
    this.BaseUrl = _base
    this.Token = userdata.getToken()
  }

  public addAdvertisement(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/addnewads",form, { headers: header_object})
  }

  public listAdvertisement(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/allads",form,{ headers: header_object})
  }

  public getAdvertisementById(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token).set('Content-Type', 'application/x-www-form-urlencoded')
    return this.http.post(this.BaseUrl + "/api/fetchadsbyid",form.toString(), { headers: header_object})
  }

  public updateAdvertisement(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/api/updateads", form, { headers: header_object})
  }

}
