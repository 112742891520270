<!-- Page Header -->
<div class="dt-page__header">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/admin/home']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/admin/customize/slider']">Slider</a></li>
        <li class="active breadcrumb-item">Add</li>
    </ol>
    <!-- <h1 class="dt-page__title">Add Category</h1> -->
</div>
<!-- /page header -->
<!-- Grid -->
<div class="row">
    <!-- Grid Item -->
    <div class="col-xl-12">

        <!-- Card -->
        <div class="dt-card dt-card__full-height">

            <!-- Card Header -->
            <div class="dt-card__header">

                <!-- Card Heading -->
                <div class="dt-card__heading">
                    <h3 class="dt-card__title">Add Slider</h3>
                </div>
                <!-- /card heading -->

            </div>
            <!-- /card header -->

            <!-- Card Body -->
            <div class="dt-card__body">

                <!-- Form -->
                <form [formGroup]="formGroup" (submit)="onsubmit()">

                    <!-- Form Group -->
                    <!-- <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="title">Title</label>

                        <div class="col-xl-9">
                            <input type="text" class="form-control" id="title" placeholder="Enter Name" name="title" formControlName="title" [ngClass]="{'focus-red': unique>0}">
                            <small class="form-text" *ngIf="f.title.invalid && (f.title.dirty || f.title.touched)">
                                <small *ngIf="f.title.errors.required">Invalid Value</small>
                            </small>
                        </div>
                    </div> -->
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <label class="col-xl-3 col-form-label text-sm-right" for="image">Image</label>

                        <div class="col-xl-9">
                            <input type="file" name="banner_image" class="form-control" (change)="onFile($event)" formControlName="banner_image">
                            <small class="form-text" *ngIf="f.banner_image.invalid && (f.banner_image.dirty || f.banner_image.touched)">
                                <small *ngIf="f.banner_image.errors.required">Invalid Value</small>
                            </small>
                            <small class="form-text">{{imageError}}</small>
                        </div>
                    </div>
                    <!-- /form group -->

                    <!-- Form Group -->
                    <div class="form-group form-row">
                        <div class="col-xl-9 offset-xl-3">
                            <button type="submit" class="btn btn-primary text-uppercase" [disabled]="!formGroup.valid">Save
                            </button>
                        </div>
                    </div>
                    <!-- /form group -->

                </form>
                <!-- /form -->

            </div>
            <!-- /card body -->

        </div>
        <!-- /card -->

    </div>
    <!-- /grid item -->

</div>
<!-- /grid -->