import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AddsService } from 'src/app/shared/adds/adds.service';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { UserdataService } from 'src/app/shared/userdata.service';

@Component({
  selector: 'app-addadvertisement',
  templateUrl: './addadvertisement.component.html',
  styleUrls: ['./addadvertisement.component.css'],
  providers:[DatePipe]
})
export class AddadvertisementComponent implements OnInit {

  alignList = ["Horizontal","Vertical"]
  restList = []
  formGroup = new FormGroup({
    ads_name: new FormControl('', [Validators.required]),
    start_date: new FormControl('', [Validators.required]),
    end_date: new FormControl('', [Validators.required]),
    ads_type: new FormControl('', [Validators.required]),
    ads_image: new FormControl('', [Validators.required]),
    is_rest_ads: new FormControl(''),
    restId: new FormControl(''),
    added_by: new FormControl('', [Validators.required]),
    userId: new FormControl('', [Validators.required]),
  })

  constructor(private adds:AddsService, private userdata: UserdataService, private toast:ToastrService, private router: Router, private restaurant: RestaurantService,private datePipe: DatePipe) { }

  ngOnInit(): void {
    var id = this.userdata.getId()
    var added = this.userdata.getUserType()
    this.formGroup.patchValue({userId: id});
    this.formGroup.patchValue({added_by: added});
    this.getRestaurant()
  }

  getRestaurant(){
    let body = new URLSearchParams();
    body.set('status', 'Enabled');
    this.restaurant.searchRestaurant(body).subscribe(
      (res:any) => {
        //console.log(res)
        this.restList = res.data
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  }

  public onFile(evnt){
    if(evnt.target.files.length > 0)
    {
      this.formGroup.patchValue({
        ads_image: evnt.target.files[0]
      })
    }
  }

  get f(){
    return this.formGroup.controls
  }

  public onsubmit(){
    console.log()
    if(this.formGroup.get("restId").value=="")
    {
      var is_rest_ads = "false"
    }
    else
    {
      var is_rest_ads = "true"
    }
    const data = new FormData()
    data.append("userId",this.formGroup.get("userId").value)
    data.append("added_by",this.formGroup.get("added_by").value)
    data.append("ads_name",this.formGroup.get("ads_name").value)
    data.append("start_date",this.datePipe.transform(this.formGroup.get("start_date").value, 'MM-dd-yyyy'))
    data.append("end_date",this.datePipe.transform(this.formGroup.get("end_date").value, 'MM-dd-yyyy'))
    data.append("ads_type",this.formGroup.get("ads_type").value)
    data.append("ads_image",this.formGroup.get("ads_image").value)
    data.append("restId",this.formGroup.get("restId").value)
    data.append("is_rest_ads",is_rest_ads)

    this.adds.addAdvertisement(data).subscribe(
      (res:any) => {
        //console.log(res)
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/admin/adcenter/view');
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }
}
