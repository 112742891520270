import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cod-refund-order',
  templateUrl: './cod-refund-order.component.html',
  styleUrls: ['./cod-refund-order.component.css']
})
export class CodRefundOrderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
