import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable'; 
import { UserdataService } from 'src/app/shared/userdata.service';
import { ProductService } from 'src/app/shared/product/product.service';
import { RestaurantService } from 'src/app/shared/restaurant/restaurant.service';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { OrdersService } from 'src/app/shared/orders/orders.service';
import { DatePipe } from "@angular/common";

@Component({
  selector: 'app-allorders',
  templateUrl: './allorders.component.html',
  styleUrls: ['./allorders.component.css'],
  providers: [DatePipe]
})
export class AllordersComponent implements OnInit {

  myGroup = new FormGroup({
    'startdate' : new FormControl(''),
    'enddate' : new FormControl(''),
  })

  formGroup = new FormGroup({
    'restId' : new FormControl(''),
    'status' : new FormControl(''),
  })

  statusList = [
    {
      'value':101,
      'name':'Order Placed'
    },
    {
      'value':102,
      'name':'Payment Pending'
    },
    {
      'value':103,
      'name':'Order Preparing'
    },
    {
      'value':104,
      'name':'Order Rejected'
    },
    {
      'value':105,
      'name':'Order Prepared'
    },
    {
      'value':106,
      'name':'Pickup'
    },
    {
      'value':107,
      'name':'On Way'
    },
    {
      'value':108,
      'name':'Delivered'
    },
    {
      'value':109,
      'name':'Canceled'
    },
    {
      'value':110,
      'name':'Timeout'
    },
    {
      'value':111,
      'name':'Refunded'
    },
  ]
  constructor(private toast: ToastrService,private product: ProductService, private userdata: UserdataService,private orders:OrdersService, private datePipe: DatePipe,private restaurant:RestaurantService) { }

  p: number = 1;
  term: string;
  temp = [];
  restList = []
  @ViewChild('table') table: DatatableComponent;
  ColumnMode = ColumnMode;
  rows =[]
  columns = [{ prop: 'Restaurant Name' },{ name: 'Order Name' },{ name: 'Payment Type' },{ name: 'Total' }, { name: 'Driver' }, { name: 'Status' }, { name: 'Action' }];
  ngOnInit(): void {
    this.getAllOrders()
    this.getRestaurant()

    // for page number 1 as offset starts with 0
    //this.table.offset = 0;    
  }

  getAllOrders(){
    let body = new URLSearchParams()
    body.set('today',"true")
    this.orders.allOrders(body).subscribe(
      (res:any) => {
        this.rows = res.data
        this.temp = this.rows
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  } 

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.order_name.toLowerCase().indexOf(val) !== -1 ||
      d.restId.rest_name.toLowerCase().indexOf(val) !== -1 ||
      !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  transformDate(date){
    return this.datePipe.transform( date,'MM-dd-yyyy');
  }

  getRestaurant(){
    let body = new URLSearchParams();
    body.set('status', 'Enabled');
    this.restaurant.searchRestaurant(body).subscribe(
      (res:any) => {
        //console.log(res)
        this.restList = res.data
      },
      err => {
        //console.log(err) 
        this.toast.error(err.error.message,'Error')
        //this.router.navigateByUrl('/admin/service/view')
      }
    )
  } 

  searchButton(val){
    //------Today --------//
    var date = new Date
    var dd = String(date.getDate()).padStart(2, '0')
    var mm = String(date.getMonth()+1).padStart(2, '0')
    var yy = String(date.getFullYear())
    var today = yy+'-'+mm+'-'+dd
    //-------Dynamic days Before-----//
    var thatDay = date.getDate() - val; //Current Date
    date.setDate(thatDay);
    let day = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let year = date.getFullYear();
    var finalDate = year+'-'+month+'-'+day
    let body = new URLSearchParams()
    body.set('startdate',finalDate)
    body.set('enddate',today)
    this.orders.allOrders(body).subscribe(
      (res:any) => {
        // console.log(res.data)
        //this.toast.success(res.message,'Success')
        this.rows = []
        this.temp = []
        this.rows = res.data
        this.temp = this.rows
        //this.currentWallet = res.currentWallet
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  searchByDate(){
    if(this.myGroup.get('startdate').value !='' && this.myGroup.get('enddate').value!=''){
      let body = new URLSearchParams()
      body.set('startdate',this.myGroup.get('startdate').value)
      body.set('enddate',this.myGroup.get('enddate').value)
      this.orders.allOrders(body).subscribe(
        (res:any) => {
          // console.log(res.data)
          //this.toast.success(res.message,'Success')
          this.rows = []
          this.temp = []
          this.rows = res.data
          this.temp = this.rows
          //this.currentWallet = res.currentWallet
        },
        err => {
          //console.log(err)
          this.toast.error(err.error.message,'Error')
        }
      )
    }
    else if(this.myGroup.get('startdate').value =='' && this.myGroup.get('enddate').value==''){
      this.getAllOrders()    
    }
    else
    {
      this.toast.error('Enter Start Date and End Date','Error')
    }
  }

  searchByRestAndStatus(){
    console.log(this.formGroup.get('restId').value)
    console.log(this.formGroup.get('status').value)
    let body = new URLSearchParams()
    if(this.formGroup.get('restId').value =='' && this.formGroup.get('status').value==''){
      this.getAllOrders()    
    }
    else
    {
      if(this.formGroup.get('restId').value !='' && this.formGroup.get('status').value!='')
      {
        body.set('restId',this.formGroup.get('restId').value)
        body.set('order_current_status',this.formGroup.get('status').value)
      }
      else if(this.formGroup.get('restId').value =='' && this.formGroup.get('status').value!='')
      {
        body.set('order_current_status',this.formGroup.get('status').value)
      }
      else if(this.formGroup.get('restId').value !='' && this.formGroup.get('status').value =='')
      {
        body.set('restId',this.formGroup.get('restId').value)
      }
      this.orders.allOrders(body).subscribe(
        (res:any) => {
          console.log(res.data)
          //this.toast.success(res.message,'Success')
          this.rows = []
          this.temp = []
          this.rows = res.data
          this.temp = this.rows
          //this.currentWallet = res.currentWallet
        },
        err => {
          // console.log(err)
          this.toast.error(err.error.message,'Error')
        }
      )
    }
  }

}
